import React, { useEffect } from 'react'
import LocationSection from '../component/landing/locationSection'
import BlogSection from '../component/landing/blogSection'
import Section from '../component/landing/section'
import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import { Check } from '@mui/icons-material'
import YoutubePlayer from '../component/youtubePlayer'
import MembershipPathwayChart from '../component/landing/membershipPathwayChart'
import STYLES from '../component/landing/landingStyles'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { useOutletContext } from 'react-router-dom'

const MembershipLanding = () => {
  const [locations] = useOutletContext()
  const PARAGRAPH = '20px'
  let styles = {
    ...STYLES,
    membershipPlanText: {
      fontSize: '1rem'
    }
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/membershipLanding',
      title: 'membershipLanding'
    })
    ReactPixel.pageView()
  }, [])

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const MEMBERSHIP_PLANS = [
    {
      image: 'Asset_22.png',
      imageDimensions: { marginLeft: largeScreen ? '12%': '25.5%' , width: largeScreen ? '80%': '48%' },
      header: 'In Person',
      url: '/memberships/in-person',
      ages: '5 to 12',
      inviteOnly: false,
      weekFrequency: 1,
      listOpener: 'Age and skill appropriate small classes: ',
      listContent: [
        'Junior Roboteers',
        'Intro to Coding',
        'Roboteers',
        'Team Prep',
        'Advanced Coder',
        'Homeschool'
      ],
      listCloser: 'Join any time year round.',
      registerText: 'Or Register For The 2 Times/week Option'
    },
    {
      image: 'Asset_20.png',
      imageDimensions: { width: '195px'},
      header: 'FLL Team',
      ages: '9 to 14',
      inviteOnly: false,
      weekFrequency: 1,
      addDetails: '2 Local Tournaments',
      listContent: [
        'Learn FLL challenge',
        'Build LEGO Robot',
        'Code LEGO Robot',
        'Compete in Robot Game, Robot Design & Core Values'
      ],
      listCloser: 'Open Enroll: Aug. - Sept.'
    },
    {
      image: 'Asset_21.png',
      imageDimensions: { width: '100%' },
      header: 'VEX VIQC Team',
      ages: '9 to 12',
      inviteOnly: false,
      weekFrequency: 2,
      addDetails: '3 Local Tournaments+',
      listContent: [
        'Learn VIQC Game',
        'Build VEX IQ Robot',
        'Code VEX IQ Robot',
        'Join local tournament for a chance to qualify for state championship'
      ],
      listCloser: 'Open Enroll: June - Jan.'
    },
    {
      image: 'Asset_19.png',
      imageDimensions: {  paddingLeft: largeScreen ? null : '14.5%', width: largeScreen ? '100%' : '70%' },
      header: 'VEX VRC Team',
      ages: '12 to 18',
      inviteOnly: true,
      weekFrequency: 2,
      addDetails: '3 Local Tournaments+',
      listContent: [
        'Learn VRC Game',
        'Build V5 Robot',
        'Code V5 Robot',
        'Join local tournament for a chance to qualify for state championship'
      ],
      listCloser: 'Season Starts: May',
      registerText: 'Or Sign Up for the VRC HC Team'
    },
    {
      image: 'Asset_18.png',
      imageDimensions: { paddingLeft: largeScreen ? '5%': '12%', width: largeScreen ? '90%' : '75%' },
      header: 'Young Inventors',
      ages: '7 to 18',
      inviteOnly: true,
      weekFrequency: 1,
      listContent: [
        'Junior Inventors',
        'Edison Hackers',
        'JV: InvenTeam',
        'InvenTeam'
      ],
      listCloser: 'Join anytime year round.'
    }
  ]

  const MembershipPlanCard = ({ plan }) => {
    let FormattedListCloser = <>{plan.listCloser}</>
    if (plan.listCloser.includes(':')) {
      let split = plan.listCloser.split(':')
      FormattedListCloser = (
        <>
          {split[0]}: <b>{split[1]}</b>
        </>
      )
    }
    return (
      <Box
        key={plan.header}
        sx={{
          border: `3px solid ${styles.yellow}`,
          borderRadius: '20px',
          display: 'grid',
          gridTemplateRows: { xs: '200px auto', sm: '150px auto' },
          height: '600px',
          width: { xs: '100%', sm: '400px', lg: '100%' }
        }}
      >
        <Box
          sx={{
            borderBottom: `3px solid ${styles.yellow}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
          }}
        >
          <picture>
            <source
              type='image/webp'
              srcset={require(`../images/membershipLanding/${plan.image}`)}
              media='(min-width:500px)'
            />
            <Box
              component='img'
              alt={plan.header}
              src={require(`../images/membershipLanding/${plan.image}`)}
              sx={
                plan.imageDimensions ? plan.imageDimensions : { width: '70%' }
              }
            />
          </picture>
        </Box>
        <Box
          sx={{
            padding: '10px',
            display: 'grid',
            gridTemplateRows: '30% 55% auto'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant='h5'>{plan.header}</Typography>
              <Typography>Ages: {plan.ages}</Typography>
              <Typography>{plan.weekFrequency} Time/Week</Typography>
              <Typography>{plan.addDetails}</Typography>
            </Box>
            <Typography variant='body1'>{plan.listOpener}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ul style={{ listStyleType: 'none', padding: '0px', flexGrow: 1 }}>
              {plan.listContent.map((item) => (
                <li key={item} style={{ display: 'flex' }}>
                  <Check sx={{ color: styles.red }} />{' '}
                  <Typography variant='body1'>{item}</Typography>
                </li>
              ))}
            </ul>
            <Typography variant='body1'>{FormattedListCloser}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '5px'
            }}
          >
            <Button
              onClick={() => {
                plan.url
                  ? (window.location.pathname = '/home/memberships/in-person')
                  : window.open('/memberships')
              }}
            >
              <Typography sx={{ color: '#fff' }}>Register</Typography>
            </Button>
            <Typography sx={{ fontSize: '9px', textAlign: 'center' }}>
              {plan.registerText}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
    // }
  }

  const mobileLineBreak = mobile ? <br /> : null
  const nonMobileLineBreak = mobile ? null : <br />

  return (
    <>
      <Section
        id='header'
        sx={{
          backgroundColor: styles.red,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <picture>
          <source
            type='image/webp'
            srcset={require('../images/membershipLanding/Asset_26.webp')}
            media='(min-width:600px)'
          />
          <Box
            component='img'
            alt='blue-gears-left'
            src={require('../images/membershipLanding/Asset_26.png')}
            sx={{
              height: { xs: '280px', sm: '60vw', md: '32vw' },
              bottom: { xs: '18%', sm: '10%', md: '-18vw' },
              zIndex: 10,
              position: 'absolute',
              left: 0
            }}
          />
        </picture>
        <picture>
          <source
            type='image/webp'
            srcset={require('../images/membershipLanding/Asset_25.webp')}
            media='(min-width:600px)'
          />
          <Box
            component='img'
            alt='blue-gears-right'
            src={require('../images/membershipLanding/Asset_25.png')}
            sx={{
              minHeight: { md: '300px' },
              maxHeight: '700px',
              height: { xs: '340px', sm: '70vw', md: '45vw' },
              position: 'absolute',
              bottom: { xs: '5%', md: '-25%' },
              right: { xs: '-100px', md: 0 }
            }}
          />
        </picture>
        <Box
          sx={{
            ...styles.box,
            display: 'grid',
            gridTemplateColumns: '55% auto',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography
            variant='h1'
            sx={{
              marginBottom: PARAGRAPH,
              gridColumn: { xs: '1/3', md: '1/2' },
              alignSelf: 'end',
              color: '#fff',
              fontSize: { xs: '2.2rem', sm: null }
            }}
          >
            Rolling Robots Membership {mobile ? <br /> : null} Programs
          </Typography>
          <Box
            sx={{
              gridColumn: { xs: '1/3', md: '1/2' },
              marginRight: { md: '50px' }
            }}
          >
            <Typography
              sx={{
                marginBottom: '10px',
                color: '#fff',
                fontWeight: 600,
                letterSpacing: 0,
                lineHeight: 1.3
              }}
            >
              Welcome to Rolling Robots, {mobileLineBreak}
              where we are {nonMobileLineBreak}
              building a {mobileLineBreak}
              community of the next generation {nonMobileLineBreak}
              of creative tech geniuses.
            </Typography>
            {smallScreen ? null : (
              <Button
                sx={{
                  backgroundColor: styles.lightBlue,
                  color: '#000',
                  '&:hover': {
                    backgroundColor: styles.blue
                  },
                  marginTop: '10px'
                }}
                onClick={() => {
                  window.location.pathname = '/memberships'
                }}
              >
                Register Now
              </Button>
            )}
          </Box>
          <Box
            sx={{
              gridColumn: { xs: '1/3', md: '2/3' },
              gridRow: { xs: null, md: '1/3' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <YoutubePlayer playerImage={'membershipLanding/header_image.jpg'} />
          </Box>
          {smallScreen ? (
            <Box
              sx={{
                padding: '20px',
                gridColumn: '1/3',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                sx={{
                  backgroundColor: styles.lightBlue,
                  color: '#000',
                  '&:hover': {
                    backgroundColor: styles.blue
                  }
                }}
                onClick={() => {
                  window.location.pathname = '/memberships'
                }}
              >
                Register Now
              </Button>
            </Box>
          ) : null}
        </Box>
      </Section>
      <Section
        tagline={'About The'}
        title={'Membership Programs'}
        capitalCase
        sx={{ minHeight: '60vh' }}
      >
        <Box sx={{ ...styles.box }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {xs: '100%', md:'50% auto'},
              gridGap: {xs: '0px', md:'30px'},
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Box sx={{ gridColumn: { xs: '1/3', md: '1/2' } }}>
              <Typography sx={{ marginBottom: PARAGRAPH }}>
                Our exclusive membership programs are designed to ignite your
                child's tech creativity mindset while providing a fun,
                interactive, and supportive environment. Embrace the future
                today as our proven results showcase the transformation of
                curious minds into innovative problem solvers and tech wizards.
              </Typography>
              <Typography sx={{ marginBottom: PARAGRAPH }}>
                Dare to explore a universe of robotics and coding where
                imagination fuels innovation.
              </Typography>
              <Typography
                sx={{
                  marginBottom: PARAGRAPH,
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  fontSize: '1.2rem'
                }}
              >
                Let's shape the tech leaders of tomorrow together.
              </Typography>
              <Typography sx={{ marginBottom: PARAGRAPH }}>
                Click on any of the programs below to discover more about how
                our memberships can empower your child to transcend the ordinary
                and embrace the extraordinary.
              </Typography>
            </Box>
            <picture>
              <source
                type='image/webp'
                srcset={require('../images/membershipLanding/Asset_27.webp')}
                media='(min-width:600px)'
              />
              <Box
                sx={{
                  backgroundImage: `url(${require('../images/membershipLanding/Asset_27.png')})`,
                  backgroundSize: 'cover',
                  backgroundPositionX: 'center',
                  WebkitBoxShadow: `inset 0 0 0 6px ${styles.yellow}`,
                  boxShadow: `inset 0 0 0 6px ${styles.yellow}`,
                  borderRadius: '20px',
                  minHeight: { xs: '300px', sm: '400px' },
                  gridRowStart: { xs: 1, md: null },
                  gridColumn: { xs: '1/3', md: '2/3' }
                }}
              />
            </picture>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '40px 0px'
            }}
          >
            <Button
              onClick={() => {
                window.open('/memberships')
              }}
            >
              Register
            </Button>
          </Box>
        </Box>
      </Section>
      <Section
        title={
          <>
            The Membership Pathway
            <br />
            To Becoming A Creative Tech Genius
          </>
        }
        capitalCase
        sx={{ minHeight: '60vh', backgroundColor: styles.lightGrey }}
      >
        <Box sx={{ ...styles.box }}>
          <Typography sx={{ textAlign: 'center', marginBottom: PARAGRAPH }}>
            Join our membership programs at any age between 5 and 15 years old.
            Go from learning the basics of robotics to becoming a creative tech
            genius, ready for schools such as MIT and UC Berkeley and careers in
            engineering, product design and many more...
          </Typography>
        </Box>
        <MembershipPathwayChart />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '40px 0px'
          }}
        >
          <Button
            onClick={() => {
              window.open('/memberships')
            }}
          >
            Register
          </Button>
        </Box>
      </Section>
      <Section
        tagline={'Embark on your Adventure to Tech Brilliance'}
        title={'Membership Plans 2024'}
        sx={{ minHeight: '60vh', backgroundColor: '#fff' }}
      >
        <Box sx={{ ...styles.box, marginBottom: '20px' }}>
          <Typography textAlign='center'>
            Click on any of the programs below to discover more about how our
            memberships can empower your child to transcend the ordinary and
            embrace the extraordinary
          </Typography>
        </Box>

        <Box
          sx={{
            ...styles.box,
            width: '90%',
            display: 'grid',
            gridTemplateColumns: largeScreen
              ? 'repeat(5, minmax(0, 1fr))'
              : null,
            justifyContent: 'center',
            gridGap: '10px'
          }}
        >
          {MEMBERSHIP_PLANS.map((plan, index) => {
            return <MembershipPlanCard plan={plan} index={index} key={index} />
          })}
        </Box>
      </Section>
      <LocationSection styles={styles} locations={locations} type='membership' mobile={mobile} title='Explore the Exciting Locations for Rolling Robots Programs' />
      <BlogSection styles={styles} />
    </>
  )
}

export default MembershipLanding
