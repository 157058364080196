import * as React from 'react'
import getCourseImages from '../utils/getCourseImages'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import useMediaQuery from '@mui/material/useMediaQuery'

const ImageSelector = ({ handleChange, handleClose }) => {
  let imageNames = getCourseImages()

  const styles = {
    tempWeb: {}
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  return (
    <Dialog fullScreen open onClose={() => handleClose(false)} style={styles.tempWeb}>
      <DialogTitle>Select an Icon</DialogTitle>
      <DialogContent>
        <ImageList cols={2} rowHeight={164}>
          {imageNames.map((item) => (
            <div key={item}>
              <ImageListItem onClick={() => { handleChange(item) }}>
                <img
                  src={require(`../images/courseIcons/${item}`)}
                  alt={item}
                  loading='lazy'
                />
                <ImageListItemBar
                  title={item}
                />
              </ImageListItem>
            </div>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose(false) }}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImageSelector
