import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  Button,
  Grid,
  Divider,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment
} from '@mui/material'
import dateHandler from '../../utils/dateHandler'
import LaunchIcon from '@mui/icons-material/Launch'
import CancelIcon from '@mui/icons-material/Cancel'
import Close from '@mui/icons-material/Close'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import { cancelWorkshop, getWorkshopCharge } from '../../api/admin'
import { setSelectedUser } from '../../store/userSlice'
import { updateWorkshop } from '../../store/workshopSlice'
import ChangeCourse from './edit/changeCourse'
import moment from 'moment'
import LoadingComp from '../util/loading'
import AlternatePaymentModal from '../adminViews/alternatePaymentModal'
import WorkshopModal from '../workshop/workshopModal'
import { getProduct } from '../../api/products'
import AchPaymentStatus from '../adminViews/achPaymentStatus'

const CourseBox = ({
  courses,
  courseData,
  permissions,
  styles,
  student,
  studentID,
  past,
  handleStatus
}) => {
  const uID = useSelector((state) => state.login.user._id)
  const selectedUser = useSelector((state) => state.user.selectedUser)
  const masterList = useSelector((state) => state.workshop.masterList)
  const dispatch = useDispatch()
  let isStaff = false
  if (permissions > 3) {
    isStaff = true
  }

  const [showConfirmCancel, setShowConfirmCancel] = useState(false)
  const [workshopToCancel, setWorkshopToCancel] = useState(null)
  const [loading, setLoading] = useState(false)
  const [courseToChange, setCourseToChange] = useState(null)
  const [openChangeCourse, setOpenChangeCourse] = useState(false)

  const [paymentIntent, setPaymentIntent] = useState(null)
  const [charge, setCharge] = useState(null)
  const [discount, setDiscount] = useState(null)
  const [refundAmount, setRefundAmount] = useState(null)
  const [lessThanZero, setLessThanZero] = useState(false)
  const [error, setError] = useState(false)

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showWorkshopModal, setShowWorkshopModal] = useState(false)

  //Measure to avoid read only error
  let tmpCourses = [...courses]

  useEffect(() => {
    //let number = Number(refundAmount)
    if (
      isNaN(refundAmount) ||
      refundAmount === '' ||
      Number(refundAmount) < 0 ||
      refundAmount === null ||
      (lessThanZero && Number(refundAmount > 0)) ||
      refundAmount % 1 !== 0
    ) {
      setError(true)
    } else {
      setError(false)
    }
  }, [refundAmount])

  const getCourseLink = (id) => {
    if (courseData) {
      let ids = courseData.map((item) => item['_id'])
      let index = ids.indexOf(id)
      if (courseData[index]['lesson']['url']) {
        return courseData[index]['lesson']['url']
      } else {
        return null
      }
    }
  }

  const handleCancelOpen = async (course) => {
    setWorkshopToCancel(course)
    setShowConfirmCancel(true)
    setLoading(true)
    if (!course.credit) {
      let resp = await getWorkshopCharge({
        uID: uID,
        prodID: course.prodID,
        studentID: studentID
      })
      if (resp.success) {
        setPaymentIntent(resp.data.paymentIntent)
        setDiscount(resp.data.discount)
        setRefundAmount(resp.data.refundAmount)
        setCharge(resp.data.chargeID)
        setLessThanZero(resp.data.lessThanZero)
        setLoading(false)
      } else {
        alert(resp)
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  const handleCancelClose = () => {
    setWorkshopToCancel(null)
    setLoading(false)
    setShowConfirmCancel(false)
  }

  const handleChangeCourse = (course) => {
    setCourseToChange(course)
    setOpenChangeCourse(true)
  }

  const handleChangeClose = () => {
    setCourseToChange(null)
    setOpenChangeCourse(false)
  }

  const handleRefundChange = (e) => {
    setRefundAmount(e.target.value.trim())
  }

  const updateCourses = (newCourse) => {
    //Update Account info page
    let tmpUser = { ...selectedUser }
    let tmpStudents = [...tmpUser.students]
    let studentIndex = tmpStudents.findIndex(
      (element) => element._id === studentID
    )
    let tmpStudent = tmpStudents.filter((student) => student._id === studentID)
    tmpStudent = { ...tmpStudent[0] }
    let tmpCourseInfo = [...tmpStudent.courseInfo]
    let courseIndex = tmpCourseInfo.findIndex(
      (element) => element.prodID === courseToChange.prodID
    )
    if (newCourse.product !== null) {
      let prodInfo = { ...newCourse.product.metadata }
      prodInfo['prodID'] = newCourse.product.id
      tmpCourseInfo[courseIndex] = prodInfo
    } else {
      tmpCourseInfo.splice(courseIndex, 1)
    }
    tmpStudent.courseInfo = tmpCourseInfo
    tmpStudents.splice(studentIndex, 1, tmpStudent)
    tmpUser.students = tmpStudents
    dispatch(setSelectedUser(tmpUser))

    if (masterList.length < 0) {
      //Update workshops enrollment
      let prevWkshp = moment(newCourse.oldWorkshop.metadata.startDate)
      let prevMon = prevWkshp.clone().weekday(1).format('MM/DD/YYYY')
      let prevSun = prevWkshp.clone().weekday(7).format('MM/DD/YYYY')
      let oldBucketName = prevMon + ' - ' + prevSun

      let newWkshp = moment(newCourse.newWorkshop.metadata.startDate)
      let newMon = newWkshp.clone().weekday(1).format('MM/DD/YYYY')
      let newSun = newWkshp.clone().weekday(7).format('MM/DD/YYYY')
      let newBucketName = newMon + ' - ' + newSun

      dispatch(
        updateWorkshop({
          oldBucket: oldBucketName,
          newBucket: newBucketName,
          oldWorkshop: newCourse.oldWorkshop,
          newWorkshop: newCourse.newWorkshop
        })
      )
    }
    handleChangeClose()
  }

  const handleConfirmCancel = async () => {
    try {
      if (!error) {
        setLoading(true)
        let resp = await cancelWorkshop({
          uID: uID,
          workshop: workshopToCancel,
          charge: charge,
          refund: refundAmount,
          studentID: studentID
        })
        if (resp.success) {
          if (resp.data.message) {
            alert(resp.data.message)
          } else {
            let tmpUser = { ...selectedUser }
            let tmpStudents = [...tmpUser.students]
            let studentIndex = tmpStudents.findIndex(
              (element) => element._id === studentID
            )
            let tmpStudent = tmpStudents.filter(
              (student) => student._id === studentID
            )
            tmpStudent = { ...tmpStudent[0] }
            let tmpCourseInfo = [...tmpStudent.courseInfo]
            let courseIndex = tmpCourseInfo.findIndex(
              (element) => element.prodID === workshopToCancel.prodID
            )
            tmpCourseInfo.splice(courseIndex, 1)
            tmpStudent.courseInfo = tmpCourseInfo
            tmpStudents.splice(studentIndex, 1, tmpStudent)
            tmpUser.students = tmpStudents
            dispatch(setSelectedUser(tmpUser))
          }
        } else {
          alert(resp)
        }
        handleCancelClose()
      }
    } catch (err) {
      alert(err)
    }
  }

  const handleOpenProduct = async (course) => {
    let resp = await getProduct({
      params: { id: course.prodID, getInstructors: true }
    })
    if (resp.success) {
      setSelectedProduct(resp.data)
      setShowWorkshopModal(true)
    } else {
      alert('There was an error retrieving product information.')
    }
  }

  const handleCloseProduct = () => {
    setShowWorkshopModal(false)
  }

  return (
    <>
      {tmpCourses.length > 0 ? (
        <>
          {past ? ( //checks if courses are rendering in past courses accordion
            <></>
          ) : (
            <>
              <Typography align='left' variant='h3' sx={styles.title}>
                Courses
              </Typography>
              <Divider style={{ marginBottom: '10px' }} />
            </>
          )}
          {tmpCourses
            .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
            .map((course) => {
              let startDate = dateHandler
                .formatDate(course.startDate)
                .toDateString()
              let endDate = dateHandler
                .formatDate(course.endDate)
                .toDateString()

              let startHour = course.startTime
              if (startHour === '00') {
                startHour = '12'
              }
              let startTime = `${startHour}:${course.startTimeMinute}`

              let endHour = course.endTime
              if (endHour === '00') {
                endHour = '12'
              }
              let endTime = `${endHour}:${course.endTimeMinute}`

              return (
                <div style={{ padding: '5px' }} key={Math.random()}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        variant='h5'
                        display='inline'
                        sx={{ color: 'primary.main' }}
                      >
                        {course.courseName}
                      </Typography>
                      <IconButton
                        color='primary'
                        aria-label='more details link'
                        onClick={() => {
                          permissions >= 3
                            ? handleOpenProduct(course)
                            : window.open(
                                getCourseLink(course.course),
                                '_blank'
                              )
                        }}
                        size='small'
                      >
                        <LaunchIcon />
                      </IconButton>
                      {isStaff ? (
                        <>
                          <Tooltip describeChild title='Switch Workshop'>
                            <IconButton
                              color='primary'
                              aria-label='more details link'
                              onClick={() => handleChangeCourse(course)}
                              size='small'
                            >
                              <ChangeCircleIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip describeChild title='Cancel Workshop'>
                            <IconButton
                              color='primary'
                              aria-label='more details link'
                              onClick={() => handleCancelOpen(course)}
                              size='small'
                            >
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    {isStaff ? (
                      <>
                        <Grid item xs={4}>
                          <Typography variant='body2' align='left'>
                            Course ID
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>{course.course}</Typography>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={4}>
                      <Typography variant='body2' align='left'>
                        Dates{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant='body1'
                        align='left'
                      >{`${startDate} to ${endDate}`}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2' align='left'>
                        Time
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body1' align='left'>
                        {`${dateHandler.toStandardTime(
                          startTime
                        )} - ${dateHandler.toStandardTime(endTime)}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2' align='left'>
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body1' align='left'>
                        {course.locationName}
                      </Typography>
                    </Grid>
                    {course.credit ? (
                      <>
                        <Grid item xs={4}>
                          <Typography variant='body2' align='left'>
                            PO Number
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          lg={4}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography
                            variant='body1'
                            align='left'
                            sx={{ flexGrow: 1 }}
                          >
                            {course.credit.paymentID}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <AlternatePaymentModal
                            selectedCredit={course.credit._id}
                            displayStatus={course.credit.confirmed}
                            handleStatus={handleStatus}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {course.ach ? (
                      <>
                        <Grid item xs={4}>
                          <Typography variant='body2' align='left'>
                            Charge ID
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          lg={4}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography
                            variant='body1'
                            align='left'
                            sx={{ flexGrow: 1 }}
                          >
                            {course.ach.id}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <AchPaymentStatus displayStatus={course.ach.status} />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </div>
              )
            })}
        </>
      ) : (
        <></>
      )}

      <Dialog
        open={showConfirmCancel}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(240, 240, 240, 0.3)'
          },
          '& .MuiPaper-root': { boxShadow: 'none' }
        }}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          Cancel {workshopToCancel?.courseName} for {student.name.fName}{' '}
          {student.name.lName}
          <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={() => {
              handleCancelClose()
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <LoadingComp />
          ) : (
            <>
              {workshopToCancel?.credit ? (
                <Typography>
                  The customer paid through a home school or cash transaction.
                  An automatic refund is not possible.
                </Typography>
              ) : (
                <>
                  {charge === null ? (
                    <>
                      <Typography>
                        The customer's transaction could not be found in our
                        database. You can still go through with the
                        cancellation, but the customer will not be automatically
                        be refunded. Please visit Stripe to refund the customer.
                      </Typography>
                    </>
                  ) : (
                    <>
                      {discount > 0 ? (
                        <>
                          <Typography>
                            This customer received a {discount}% discount on
                            their purchase.
                          </Typography>
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      <Typography>
                        The refund amount equals the current price of the
                        product minus a $25 service fee. If the customer
                        received a discount, the refund has automatically been
                        reduced by the amount of the discount. If you need to
                        edit the amount, please use the box below.
                      </Typography>
                      <br />
                      <TextField
                        value={refundAmount}
                        error={error}
                        helperText={
                          error && !lessThanZero
                            ? 'Refund must be a number without decimals greater than or equal to zero'
                            : ''
                        }
                        onChange={handleRefundChange}
                        label={'Refund'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          )
                        }}
                      />
                      <br />
                      <Typography>
                        To view this transaction on Stripe, use {paymentIntent}
                      </Typography>
                      {lessThanZero ? (
                        <Typography color='red' variant='caption'>
                          This customer paid less than $25 for this transaction.
                          Set refund to zero to continue.
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          <Button
            onClick={() => {
              handleCancelClose()
            }}
          >
            Go Back
          </Button>
          <Button disabled={loading || error} onClick={handleConfirmCancel}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ChangeCourse
        openChangeCourse={openChangeCourse}
        course={courseToChange}
        handleClose={handleChangeClose}
        studentID={studentID}
        updateCourses={updateCourses}
      />
      <WorkshopModal
        workshop={selectedProduct}
        showModal={showWorkshopModal}
        handleModal={handleCloseProduct}
        workshipID={selectedProduct?.stripeID}
        studentList={selectedProduct?.info?.students}
        disableEdit={true}
      />
    </>
  )
}

export default CourseBox
