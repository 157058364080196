import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { notifyCourse } from '../api/admin'
import { getLocation } from '../api/locations'
import { useSelector } from 'react-redux'
import dateHandler from '../utils/dateHandler'
import timeHandler from '../utils/timeHandler'
import { GetTemplates } from '../api/admin'
import TemplateEditor from './adminViews/templateEditor'
import { EditorState } from 'draft-js'
import { convertToHTML } from 'draft-convert'
import { Typography } from '@mui/material'

const EmailModal = ({ studentData, course, handleClose, emailType }) => {
  let adminEmail = useSelector((state) => state.login.user.userName)
  const uID = useSelector((state) => state.login.user._id)
  const [showMsg, setShowMsg] = useState(false)
  const [customTitle, setCustomTitle] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true)

  const [blankTemplate, setBlankTemplate] = useState({
    automated: false,
    content: '',
    type: 'custom'
  })
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  const emails = []
  let ageMin = course.info?.ageMin || course.ageMin
  let ageMax = course.info?.ageMax || course.ageMax

  let devIDs = {
    pre: '640a4dbc3fa728be3705221d',
    post: '640f77c03fa728be37052220'
  }

  const convertWithLinks = () => {
    //TO DO: turn into reusuable hook
    //convert editor state into HTML with a href links
    const html = convertToHTML({
      blockToHTML: (block) => {
        if (block.type === 'PARAGRAPH') {
          return <p />
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>
        }
        return originalText
      }
    })(editorState.getCurrentContent())
    return html
  }

  //use hardcoded IDs to get template from db
  useEffect(() => {
    async function handleAsync() {
      let resp = await GetTemplates()
      if (resp.status === 200) {
        setMessage(
          //TO DO: should this be changed into it's own unique api call?
          // eslint-disable-next-line
          resp.data.filter((option) => option._id == devIDs[emailType])[0][
            'content'
          ]
        )
        setLoading(false)
      } else {
        alert('There was an error retrieving templates')
      }
    }
    if (emailType !== 'custom') {
      handleAsync()
    } else {
      setLoading(false)
    }
  }, [])

  const handleSubmit = async () => {
    //Select emails for bulk send
    studentData.forEach(function (item) {
      emails.push(item.parent.email)
    })

    //Add admin email to emails array
    emails.push(adminEmail)
    let html = convertWithLinks()

    // Get address of class
    let resp = await getLocation({ _id: course.metadata.location }).then(
      (data) => {
        //Send email to parent
        return notifyCourse({
          emails: emails,
          courseName: course.metadata.courseName,
          location: course.metadata.locationName,
          time: timeHandler.getTimeString(course.metadata),
          endDate: dateHandler.toUSDate(course.metadata.endDate),
          startDate: dateHandler.toUSDate(course.metadata.startDate),
          maxAge: ageMax,
          minAge: ageMin,
          emailType: emailType,
          customMsg: html,
          customTitle: customTitle,
          adminEmail: adminEmail,
          address: data.data.address,
          uID: uID
        })
      }
    )
    if (resp.data.MessageId) {
      setShowMsg(true)
    } else {
      alert('Something went wrong.')
    }
  }

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {loading ? (
          <>loading</>
        ) : (
          <>
            <DialogTitle
              id='alert-dialog-title'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              Email Template
            </DialogTitle>
            {showMsg ? (
              <>
                <DialogContent>
                  <p>Message sent</p>
                  <p>(A copy has also been sent to {adminEmail})</p>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    Back
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    {/* <div style={{ whiteSpace: 'pre-line' }}>{message}</div> */}
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                  </div>
                  {emailType === 'custom' ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <TextField
                        id='outlined-basic'
                        label='Email Title'
                        variant='outlined'
                        onChange={(e) => setCustomTitle(e.target.value)}
                        style={{ marginTop: '10px' }}
                      />
                      <Typography>
                        Subject line will send as: "{course.metadata.courseName}
                        : {customTitle == '' ? '[Enter Title]' : customTitle}"
                      </Typography>
                      <TemplateEditor
                        data={blankTemplate}
                        editorState={editorState}
                        setEditorState={setEditorState}
                        convertWithLinks={convertWithLinks}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </DialogContent>
                <DialogActions
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Send Message
                  </Button>
                  <Button
                    onClick={() => {
                      handleClose()
                    }}
                    style={{
                      backgroundColor: 'rgb(200,16,46)',
                      color: 'white'
                    }}
                  >
                    Back
                  </Button>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  )
}

export default EmailModal
