import { useState } from 'react'
import { useSelector } from 'react-redux'
import { IconButton, TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { searchStudents } from '../../../api/tag'
import CloseIcon from '@mui/icons-material/Close'

const StudentSearch = ({ searched, setSearched, setUntaggedStudents, tag }) => {
  const [searchValue, setSearchValue] = useState('')

  const uID = useSelector((state) => state.login.user._id)

  const handleSearch = async () => {
    let resp = await searchStudents({
      params: { searchValue: searchValue, uID: uID }
    })
    if (resp.success) {
      let tmpUntagged = resp.data.users.filter(
        (student) => !tag.students.includes(student._id)
      )
      setUntaggedStudents(tmpUntagged)
      setSearched(true)
    } else {
      alert('There was an error searching for students')
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const changeSearchValue = (value) => {
    setSearchValue(value)
  }

  const handleReset = () => {
    setSearched(false)
    setSearchValue('')
  }
  return (
    <TextField
      fullWidth
      autoFocus
      id='search-user'
      label='Search'
      value={searchValue}
      placeholder={'Name, Email, or Phone'}
      onChange={(e) => {
        changeSearchValue(e.target.value)
      }}
      onKeyDown={(e) => {
        handleKeyDown(e)
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={() => {
                handleSearch()
              }}
              edge='end'
              disabled={searchValue === ''}
            >
              <SearchIcon />
            </IconButton>
            {searched ? (
              <IconButton
                onClick={() => {
                  handleReset()
                }}
                edge='end'
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </InputAdornment>
        )
      }}
    />
  )
}

export default StudentSearch
