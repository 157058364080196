import React, { useState } from 'react'
import { Alert, Button, Grid, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import InviteNotification from '../inviteNotification'
import ParentInfo from './parentInfo'
import StudentInfo from './studentInfo'
import LoadingComp from '../util/loading'
import EditUserInfo from './edit/editUser'
import EditStudentForm from './edit/editStudent'
import { updateUser } from '../../api/admin'
import { setSelectedUser } from '../../store/userSlice'
import { setParentDetails, setStudentDetails } from '../../store/adminSlice'
import { setStudents } from '../../store/parentSlice'
import { recommendForMembership } from '../../api/instructor'
import AlternatePayment from './edit/alternatePayment'
import { authorize, unauthorize } from '../../api/credit'

const AccountView = ({ account, isLoading }) => {
  const dispatch = useDispatch()
  const admin = useSelector((state) => state.admin)
  const parent = useSelector((state) => state.parent)
  const user = useSelector((state) => state.login.user)
  let defaultRegion = useSelector((state) => state.login.defaultRegion)
  const [showEdit, setShowEdit] = useState('x')
  const [editInfo, setEditInfo] = useState(null)
  const [editIndex, setEditIndex] = useState(null)
  const [showAlternate, setShowAlternate] = useState(false)

  const styles = {
    container: {
      width: '95%',
      maxWidth: '1200px',
      margin: '0px auto 50px auto'
    }
  }
  const handleAlternatePayment = () => {
    setShowAlternate(true)
  }

  const updateAlternatePayment = async (currentAuthorization) => {
    let resp
    if (currentAuthorization) {
      //Check if account is already authorized
      resp = await unauthorize({ parentID: account._id, uID: user._id })
    } else {
      resp = await authorize({ parentID: account._id, uID: user._id })
    }
    if (resp.success) {
      setShowAlternate(false)
      let tmpParentObj = { ...account, creditAuthorized: !currentAuthorization }
      dispatch(setSelectedUser(tmpParentObj))
      dispatch(setParentDetails(tmpParentObj))
    } else {
      alert('Error Authorizing')
    }
  }

  const handleEdit = (user, type, index) => {
    setEditInfo(user)
    setEditIndex(index)
    setShowEdit(type)
  }

  const updateInfo = async () => {
    let resp = await updateUser({
      info: editInfo.info,
      userID: editInfo._id
    })

    if (resp.success) {
      setShowEdit(false)
      setEditInfo(null)
      dispatch(setSelectedUser(editInfo))
      if (user.permissions[0] === 4) {
        dispatch(setParentDetails(editInfo))
      }
    } else {
      alert('Error updating')
    }
  }

  const updateStudentInfo = async () => {
    let studentArr = [...account.students]
    studentArr.splice(editIndex, 1, editInfo)
    let parentObj = { ...account, students: studentArr }

    let resp = await updateUser({
      userID: editInfo._id,
      info: editInfo.info,
      pNotes: editInfo.pNotes,
      iNotes: editInfo.iNotes
    }) //updates student in db
    if (resp.success) {
      setShowEdit(false)
      setEditInfo(null)
      dispatch(setSelectedUser(parentObj))
      if (user.permissions[0] === 4) {
        dispatch(setParentDetails(parentObj))
        dispatch(setStudentDetails({ ...editInfo }))
      } else {
        dispatch(setStudents(studentArr))
      }
    } else {
      alert('Error updating')
    }
  }

  const handleSaveRecommendation = async (
    studentToRecommend,
    recommenderNote
  ) => {
    if (recommenderNote.length > 0) {
      let currentDate = new Date()
      //create updated parent object to save in redux store
      let studentArr = [...account.students]
      let studentIndex = studentArr.indexOf(studentToRecommend)
      let studentObj = JSON.parse(JSON.stringify(studentToRecommend))
      if (studentObj.recommendation) {
        studentObj.recommendation.recommenderNote = recommenderNote
        studentObj.recommendation.recommendedDate = currentDate.toISOString()
      } else {
        studentObj.recommendation = {
          recommendedBy: user._id,
          recommenderNote: recommenderNote,
          recommendedDate: currentDate.toISOString()
        }
        studentObj.recommenderInfo = user
      }
      studentArr.splice(studentIndex, 1, studentObj)
      let parentObj = { ...account, students: studentArr }

      //update backend with new note
      let resp = await recommendForMembership({
        studentID: studentToRecommend._id,
        staffID: user._id,
        recommenderNote: recommenderNote,
        date: currentDate
      })
      if (resp.success) {
        dispatch(setSelectedUser(parentObj))
        return 'success' //return to studentInfo.js
      } else {
        console.log(resp.error)
      }
    } else {
      return 'noteError'
    }
  }

  const handleUpdateInfo = (type, value, meta, index) => {
    if (meta === 'misc') {
      setEditInfo((prevState) => ({
        ...prevState,
        misc: {
          ...prevState.misc,
          [type]: value
        }
      }))
    } else if (meta === 'dob') {
      setEditInfo((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          dob: value
        }
      }))
    } else if (meta === 'pNotes' || meta === 'iNotes') {
      setEditInfo((prevState) => ({
        ...prevState,
        [type]: value
      }))
    } else if (meta === 'alt') {
      setEditInfo((prevState) => {
        let tmp = JSON.parse(JSON.stringify({ ...prevState }))
        tmp.info.contact.alt[index][type] = value
        return tmp
      })
    } else {
      setEditInfo((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          [meta]: {
            ...prevState.info[meta],
            [type]: value
          }
        }
      }))
    }
  }

  const sawyerAlert = () => {
    const regionIds = ['65e24510585d915b2db2329b', '65d652aeb3f979b3b01d4769']
    if (regionIds.includes(defaultRegion._id)) {
      return (
        <Grid item xs={12}>
          <Alert severity='info'>
            We are upgrading to a new and improved system to enhance your
            experience. If you are looking for your order information placed at Westlake Village or Oak Park, 
            visit <a href='https://www.hisawyer.com/auth/log-in'>https://www.hisawyer.com/auth/log-in</a>.
          </Alert>
        </Grid>
      )
    }
  }

  return (
    <>
      <Grid container justifyContent='center' sx={styles.container}>
        {sawyerAlert()}
        <Grid
          item
          xs={12}
          md={5}
          sx={{ alignContent: 'flex-start', padding: '10px' }}
        >
          <Typography variant='h4' align='left'>
            Parent Info
          </Typography>
          <ParentInfo
            data={account}
            type={'p'}
            handleEdit={handleEdit}
            handleAlternatePayment={handleAlternatePayment}
            accountInfo={true}
            permissions={user.permissions[0]}
          />

          {account.inviteInfo?.length > 0 ? (
            <Typography variant='h4' align='left'>
              Invites
            </Typography>
          ) : (
            <></>
          )}

          <InviteNotification
            invites={account.inviteInfo}
            accountView={true}
            isAdmin={user.permissions[0] === 4}
          />
        </Grid>

        <Grid item xs={12} md={7} sx={{ padding: '10px' }}>
          <Typography variant='h4' align='left'>
            Roboteers
          </Typography>
          {isLoading ? (
            <LoadingComp />
          ) : (
            <>
              {account.students?.length > 0 ? (
                <>
                  {account.students.map((student, index) => {
                    return (
                      <React.Fragment key={index}>
                        <StudentInfo
                          index={index}
                          data={student}
                          hasMembership={
                            student.memberships?.active?.length > 0
                          }
                          membership={student.membershipInfo || null}
                          type={'s'}
                          parent={account.info}
                          student={student}
                          handleEdit={handleEdit}
                          locations={parent.locations || admin.locations}
                          courseData={parent.courses || admin.courses}
                          membershipsData={
                            admin.memberships || parent.memberships
                          }
                          accountInfo={true}
                          permissions={user.permissions[0]}
                          handleSaveRecommendation={handleSaveRecommendation}
                        />
                      </React.Fragment>
                    )
                  })}
                </>
              ) : (
                <>This user has no roboteers</>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {showAlternate ? (
        <AlternatePayment
          handleClose={() => setShowAlternate(false)}
          updateAlternatePayment={updateAlternatePayment}
          currentAuthorization={account.creditAuthorized}
        />
      ) : (
        <></>
      )}
      {showEdit === 'p' ? (
        <EditUserInfo
          data={editInfo}
          handleNext={updateInfo}
          handleClose={() => {
            setShowEdit(false)
          }}
          setEditInfo={setEditInfo}
        />
      ) : (
        <React.Fragment />
      )}
      {showEdit === 's' ? (
        <EditStudentForm
          index={editIndex}
          data={editInfo}
          handleChange={handleUpdateInfo}
          handleNext={updateStudentInfo}
          handleClose={() => {
            setShowEdit(false)
          }}
          isAdmin={user.permissions[0] === 4}
        />
      ) : (
        <React.Fragment />
      )}
    </>
  )
}

export default AccountView
