import React, { useState, useEffect } from 'react'
import Login from '../component/login'
import validateEmail from '../utils/emailValidation'
import { login } from '../api/login'
import { useDispatch, useSelector } from 'react-redux'
import { loginState } from '../store/loginSlice'
import ForgotPassword from '../component/forgotPassword'
import { forgotPassCall } from '../api/login'
import LS from '../utils/localStorage'
import ReactGA from 'react-ga4'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

const LoginContainer = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [showError, setShowError] = useState(false)
  const [showForgot, setShowForgot] = useState(false) // toggle for showing the dialog of forgot password
  const [forgotEmail, setForgotEmail] = useState('') // the email of the user for reseting the account
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const locationData = useLocation()
  //Prompt Login Route will pass final path as state and redirect user after logging in
  // let redirectToString = '/'
  // if (locationData.state?.redirectTo) {
  //   redirectToString = locationData.state.redirectTo
  // }
  const [redirectToString, setRedirectToString] = useState('/enroll');

  useEffect(() => {
    let mounted = true
    ReactGA.send({ hitType: 'pageview', page: '/login', title: 'Login' })
    ReactPixel.pageView()

    if (mounted && locationData.state?.redirectTo) {
      setRedirectToString(locationData.state.redirectTo)
      alert('You need to be logged in to view this page.')
    }
    return () => mounted = false
  }, [])

  const handleEmail = (value) => {
    setEmail(value)
  }

  const handlePassword = (value) => {
    setPassword(value)
  }

  const handleSubmit = async () => {
    let eCheck = validateEmail(email)

    if (eCheck) {
      let resp = await login({
        userName: email,
        pw: password
      })
      if (resp.success) {
        LS.setItem('rro-JWT', resp.data.jwt)
        dispatch(loginState(resp.data.user))
      } else if (resp.data === '') {
        setShowError(true)
        setErrorMsg('Account does not exist')
      } else {
        setShowError(true)
        setErrorMsg(resp.data.reason)
      }
    } else {
      setShowError(true)
      setErrorMsg('Invalid email')
    }
  }

  const handleForgotPassword = () => {
    setShowForgot(true)
  }

  const handleForgotEmail = (value) => {
    setForgotEmail(value.toLowerCase())
  }

  const closeForgotPassword = () => {
    setForgotEmail('')
    setShowForgot(false)
  }

  const submitReset = async () => {
    forgotPassCall({ email: forgotEmail })
  }

  if (isLoggedIn) {
    //Check if user has been directed from membership invite or direct link
    return <Navigate to={redirectToString} replace />
  } else {
    return (
      <React.Fragment>
        <Login
          email={email}
          password={password}
          handlePassword={handlePassword}
          handleEmail={handleEmail}
          handleSubmit={handleSubmit}
          handleForgot={handleForgotPassword}
          errorMsg={errorMsg}
          showError={showError}
          setShowForgot={setShowForgot}
        />
        {showForgot ? (
          <ForgotPassword
            handleChange={handleForgotEmail}
            handleClose={closeForgotPassword}
            handleSubmit={submitReset}
          />
        ) : (
          <></>
        )}
      </React.Fragment>
    )
  }
}

export default LoginContainer
