const getDayString = (day) => {
  if (day === 'M') {
    return 'Monday'
  } else if (day === 'T') {
    return 'Tuesday'
  } else if (day === 'W') {
    return 'Wednesday'
  } else if (day === 'Thr') {
    return 'Thursday'
  } else if (day === 'F') {
    return 'Friday'
  } else if (day === 'Sat') {
    return 'Saturday'
  } else if (day === 'Sun') {
    return 'Sunday'
  } else {
    return ''
  }
}

export default getDayString
