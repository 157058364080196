import React, { useState, useEffect } from 'react'
import { Typography, Box, IconButton } from '@mui/material/'
import cards from '../component/util/cards.js'
import LaunchIcon from '@mui/icons-material/Launch'
import dateHandler from '../utils/dateHandler.js'

const OrdersView = ({ data, altData }) => {
  let [local, setLocal] = useState([])

  const BlankCard = cards.blankCard

  useEffect(() => {
    //Sort data from stripe and credit
    let tmpArray = data.concat(altData)

    tmpArray.sort(function (a, b) {
      return new Date(b.created) - new Date(a.created)
    })

    setLocal(tmpArray)
  }, [data, altData])

  const COLUMNS = [
    'Order Date',
    'Total',
    'Item',
    'Student',
    'Activity Date',
    'Location'
  ]

  const styles = {
    grid: {
      display: 'inline-grid',
      gridTemplateColumns: `200px 100px repeat(4, minmax(200px, 400px))`,
      borderBottom: '1px solid #e3e3e3',
      borderCollapse: 'separate',
      padding: '10px 0px'
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ padding: '0 5%' }}>
        {local.length === 0 ? (
          <Typography>No orders yet</Typography>
        ) : (
          <BlankCard style={{ overflowX: 'auto' }}>
            <Box id='table-header' sx={{ ...styles.grid }}>
              {COLUMNS.map((col) => {
                return (
                  <Typography variant='h6' key={col}>
                    {col}
                  </Typography>
                )
              })}
            </Box>
            {local.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <Box id={index} sx={{ ...styles.grid }}>
                    <Typography variant='body2'>
                      {row.created}
                      {!row.credit ? (
                        <IconButton
                          aria-label='more details link'
                          size='small'
                          onClick={() => window.open(`${row.receipt}, _blank`)}
                        >
                          <LaunchIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </Typography>
                    <>
                      {row.lineItems.length > 1 ? (
                        <>
                          {row.lineItems.map((item, index) => {
                            let activityDate
                            if (item.type == 'product') {
                              activityDate = `${dateHandler
                                .formatDate(item.startDate)
                                .toDateString()} - ${dateHandler
                                .formatDate(item.endDate)
                                .toDateString()}`
                            } else {
                              activityDate = row.created
                            }
                            return (
                              <React.Fragment key={index}>
                                <Typography style={{ gridColumnStart: 2 }}>
                                  ${item.price}
                                </Typography>
                                <Typography>{item.name}</Typography>
                                <Typography>{item.student}</Typography>{' '}
                                <Typography>{activityDate}</Typography>
                                <Typography>{item.loc}</Typography>
                              </React.Fragment>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          <Typography>
                            {row.amount
                              ? `$${row.amount}`
                              : `${row.lineItems[0].price}`}
                          </Typography>
                          {row.lineItems.map((item, index) => {
                            let activityDate
                            if (item.type == 'product') {
                              activityDate = `${dateHandler
                                .formatDate(item.startDate)
                                .toDateString()} - ${dateHandler
                                .formatDate(item.endDate)
                                .toDateString()}`
                            } else {
                              activityDate = row.created
                            }
                            return (
                              <React.Fragment key={index}>
                                <Typography style={{ gridColumnStart: 3 }}>
                                  {item.name}
                                </Typography>
                                <Typography>{item.student}</Typography>{' '}
                                <Typography>{activityDate}</Typography>
                                <Typography>{item.loc}</Typography>
                              </React.Fragment>
                            )
                          })}
                        </>
                      )}
                    </>
                  </Box>
                </React.Fragment>
              )
            })}
          </BlankCard>
        )}
      </Box>
    </React.Fragment>
  )
}

export default OrdersView
