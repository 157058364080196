import { React, useState } from 'react'
import { Alert } from '@mui/material'

const SawyerAlert = () => {
  const [readMore, setReadMore] = useState(false)
  return (
    <Alert severity='info'>
      Please read this before enrolling in summer camps at this location!{' '}
      {readMore ? (
        <>
          We have partnered with Sawyer for the enrollment of summer camps at
          this location. You must{' '}
          <a
            href='https://www.hisawyer.com/rolling-robots/auth/log-in'
            target='_blank'
            rel='noreferrer'
          >
            create or login to a Sawyer account
          </a>{' '}
          in order to enroll at this location. This is not the same as your
          Rolling Robots account. If you are interested in enrolling in another
          Rolling Robots membership program or summer camp at any other location
          outside of Ventura County, you will need to{' '}
          <a
            href='https://www.rollingrobots.com/login'
            target='_blank'
            rel='noreferrer'
          >
            log in to your Rolling Robots account.
          </a>{' '}
        </>
      ) : (
        <></>
      )}
      <b onClick={() => setReadMore(!readMore)}>
        {readMore ? 'Read Less' : 'Read more'}
      </b>
    </Alert>
  )
}

export default SawyerAlert
