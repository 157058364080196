import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import {
  Typography,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Alert,
  Tooltip
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import { getEmails } from '../../../api/admin'
import { tagStudent, untagStudent, deleteTag } from '../../../api/tag'
import StudentSelect from './studentSelect'
import StudentSearch from './studentSearch'
import DeleteTagDialog from '../../account/deleteTagDialog'

const Tag = ({ tag, students, tags, setTags, all }) => {
  const uID = useSelector((state) => state.login.user._id)

  const [showStudentOptions, setShowStudentOptions] = useState(false)
  const [taggedStudents, setTaggedStudents] = useState([])
  const [untaggedStudents, setUntaggedStudents] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [searched, setSearched] = useState(false)
  const [studentToRemove, setStudentToRemove] = useState(null)
  const [showRemoveStudent, setShowRemoveStudent] = useState(false)

  //Seperate students who have been tagged from those who have not
  useEffect(() => {
    let tmpTagged = students.filter((student) =>
      tag.students.includes(student._id)
    )
    setTaggedStudents(tmpTagged)
    let tmpUntagged = students.filter(
      (student) => !tag.students.includes(student._id)
    )
    setUntaggedStudents(tmpUntagged)
  }, [tag, students])

  const handleShowRemoveStudent = (student) => {
    setStudentToRemove(student)
    setShowRemoveStudent(true)
  }

  const handleCloseRemoveStudent = () => {
    setStudentToRemove(null)
    setShowRemoveStudent(false)
  }

  const selectStudent = async (student) => {
    let resp = await tagStudent({
      tagID: tag._id,
      studentID: student._id,
      uID: uID
    })
    if (resp.success) {
      setTaggedStudents([...taggedStudents, student])
      let tmpUntagged = [...untaggedStudents]
      tmpUntagged = tmpUntagged.filter(
        (untaggedStudent) => untaggedStudent._id !== student._id
      )
      setUntaggedStudents(tmpUntagged)
      setShowStudentOptions(false)
    } else {
      alert('There was an error tagging the student')
    }
  }

  const removeStudent = async () => {
    let resp = await untagStudent({
      tagID: tag._id,
      studentID: studentToRemove._id,
      uID: uID
    })
    if (resp.success) {
      setUntaggedStudents([...untaggedStudents, studentToRemove])
      let tmpTagged = [...taggedStudents]
      tmpTagged = tmpTagged.filter(
        (taggedStudent) => taggedStudent._id !== studentToRemove._id
      )
      setTaggedStudents(tmpTagged)
      handleCloseRemoveStudent()
    } else {
      alert('There was an error untagging the student')
      handleCloseRemoveStudent()
    }
  }

  const removeTag = async () => {
    let resp = await deleteTag({ tagID: tag._id, uID: uID })
    if (resp.success) {
      let tmpTags = [...tags]
      tmpTags = tmpTags.filter((tmpTag) => tag._id !== tmpTag._id)
      setTags(tmpTags)
    } else {
      alert('There was a problem deleting the tag')
    }
    setShowConfirm(false)
  }

  const handleCompose = async (studentIDs) => {
    // Get student objects from IDs
    let studentObjs = students.filter((student) =>
      studentIDs.includes(student._id)
    )
    let parentIDs = []
    //Get parent IDs from students
    studentObjs.forEach((student) => parentIDs.push(student.parents[0]))

    //Get emails and send to GMail
    let emails = await getEmails({ uID: uID, parents: parentIDs })
    if (emails.success) {
      let gmail = `https://mail.google.com/mail/?extsrc=mailto&url=mailto%3Ainfo%40rollingrobots.com%3Fbcc%3D${encodeURIComponent(
        emails.data.toString()
      )}`
      window.open(gmail, '_blank')
    } else {
      alert(emails.error)
    }
  }

  return (
    <Card>
      <CardHeader
        title={tag.name}
        action={
          !showConfirm ? (
            <IconButton
              aria-label='remove-tag'
              color='primary'
              onClick={() => setShowConfirm(true)}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <div style={{ display: 'flex' }}>
              <Tooltip title='Confirm'>
                <IconButton
                  aria-label='remove-tag'
                  color='primary'
                  onClick={removeTag}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title='Cancel'>
                <IconButton
                  aria-label='remove-tag'
                  color='primary'
                  onClick={() => setShowConfirm(false)}
                >
                  <DoDisturbIcon />
                </IconButton>
              </Tooltip>
            </div>
          )
        }
      />
      <CardContent>
        {showStudentOptions ? (
          <>
            {all ? (
              <>
                {searched ? (
                  <>
                    <StudentSelect
                      selectStudent={selectStudent}
                      untaggedStudents={untaggedStudents}
                    />
                    <Button onClick={() => setSearched(false)}>
                      Search Again
                    </Button>
                  </>
                ) : (
                  <StudentSearch
                    searched={searched}
                    setSearched={setSearched}
                    setUntaggedStudents={setUntaggedStudents}
                    tag={tag}
                  />
                )}
              </>
            ) : (
              <>
                {students.length === 0 ? (
                  <Alert severity='error'>
                    There are no students enrolled in this membership.
                  </Alert>
                ) : (
                  <StudentSelect
                    selectStudent={selectStudent}
                    untaggedStudents={untaggedStudents}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {taggedStudents.map((student) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography>{`${student.info.name.fName} ${student.info.name.lName}`}</Typography>
              <IconButton
                aria-label='remove-student'
                color='primary'
                onClick={() => {
                  handleShowRemoveStudent(student)
                }}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </div>
          )
        })}
        {!showStudentOptions ? (
          <Button
            onClick={() => {
              setShowStudentOptions(true)
            }}
          >
            Add Student
          </Button>
        ) : (
          <></>
        )}
      </CardContent>
      <CardActions>
        {tag.students.length > 0 ? (
          <Button onClick={() => handleCompose(tag.students)}>Email</Button>
        ) : (
          <></>
        )}
      </CardActions>
      <DeleteTagDialog
        tag={tag}
        student={studentToRemove}
        showConfirmDeleteTag={showRemoveStudent}
        handleCloseTagDelete={handleCloseRemoveStudent}
        handleConfirm={removeStudent}
      />
    </Card>
  )
}

export default Tag
