import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getUser } from '../api/instructor'
import { setSelectedUser } from '../store/userSlice'
import { Grid, Typography } from '@mui/material'
import EditUserInfo from '../component/account/edit/editUser'
import StaffInfo from '../component/staffInfo'
import { updateUser } from '../api/admin'

const StaffAccountContainer = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.login.user)
  let selectedUser = useSelector((state) => state.user.selectedUser)
  const [isLoading, setIsLoading] = useState(true)
  const [editInfo, setEditInfo] = useState(null)
  const [showEdit, setShowEdit] = useState(false)

  useEffect(() => {
    async function handleAsync() {
      let userInfo
      if (!selectedUser || selectedUser._id !== params.userID) {
        if (user.permissions[0] > 2) {
          let resp = await getUser({
            params: { userID: params.userID }
          })
          if (resp.success) {
            userInfo = resp.data
          }
        } else {
          userInfo = user
        }
        dispatch(setSelectedUser(userInfo))
      } else {
        userInfo = selectedUser
      }
      setIsLoading(false)
    }

    if (user.permissions[0] > 2 || params.userID) {
      handleAsync()
    }
  }, [params])

  const handleEdit = () => {
    setEditInfo(selectedUser)
    setShowEdit(true)
  }

  const updateInfo = async () => {
    let resp = await updateUser({
      info: editInfo.info,
      userID: editInfo._id
    })

    if (resp.success) {
      setShowEdit(false)
      setEditInfo(null)
      dispatch(setSelectedUser(editInfo))
    } else {
      alert('Error updating')
    }
  }

  return (
    <>
    {! isLoading ? 
      <Grid container justifyContent='center'>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ alignContent: 'flex-start', padding: '10px' }}
        >
          <Typography variant='h4' align='left'>
            Staff Info
          </Typography>
          <StaffInfo data={selectedUser} handleEdit={handleEdit} permissions={user.permissions?.[0]}/>
        </Grid>
      </Grid> : <></>}
      {showEdit ? (
        <EditUserInfo
          data={editInfo}
          handleNext={updateInfo}
          handleClose={() => {
            setShowEdit(false)
          }}
          setEditInfo={setEditInfo}
        />
      ) : (
        <React.Fragment />
      )}
    </>
  )
}

export default StaffAccountContainer
