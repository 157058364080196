import React, { useState, useEffect } from 'react'
import { Typography, Box, useMediaQuery, Button, Paper } from '@mui/material'
import WorkshopInfo from './workshop/workshopInfo'
import AddToCartButton from './workshop/addToCartButton'
import { getLocation } from '../api/locations'

const WorkshopLink = ({ course }) => {
  const [inThePast, setInThePast] = useState(false)
  const [displayAdd, setDisplayAdd] = useState(false)
  const [location, setLocation] = useState(null)

  useEffect(() => {
    //Get location data
    async function handleAsync() {
      let resp = await getLocation({ _id: course.metadata.location })
      if (resp.success) {
        setLocation(resp.data)
      } else {
        setLocation(null)
      }
    }

    handleAsync()

    //Check if workshop starts in the past
    let today = new Date()
    let startDate = new Date(course.metadata.startDate)
    //allow 2 days after start to sign up
    startDate.setDate(startDate.getDate() + 2)
    let tmp = today.getTime() - startDate.getTime() > 0
    setInThePast(tmp)
  }, [])

  const checkCapacity = () => {
    if (course.info?.students.length === course.info?.cap) {
      return 'Full'
    } else if (course.info?.students.length >= parseInt(course.info?.cap) - 3) {
      return 'Almost Full'
    } else {
      return 'Enroll'
    }
  }
  const styles = {
    box: { width: '60%', margin: '0 auto', textAlign: 'left' }
  }

  let check = useMediaQuery('(max-width:750px)')
  if (check) {
    styles.box = {
      ...styles.box,
      width: '90%',
      margin: '0 auto'
    }
  }
  return (
    <Box style={styles.box}>
      <Paper sx={{ padding: '20px' }}>
        <WorkshopInfo
          workshop={course}
          showParentDetails={true}
          showLocationDetails={true}
          location={location}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {inThePast || checkCapacity() == 'Full' ? (
            <Button
              id='closed-button'
              variant='contained'
              style={{ backgroundColor: 'grey' }}
            >
              CLOSED
            </Button>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {checkCapacity() == 'Almost Full' ? (
                <Typography>Almost Full</Typography>
              ) : null}
              <AddToCartButton
                course={course}
                displayAdd={displayAdd}
                setDisplayAdd={setDisplayAdd}
              />
            </div>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default WorkshopLink
