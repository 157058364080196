import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

const LoadingComp = () => {
  const styles = {
    image: {
      height: '110px',
      width: 'auto',
      gridColumnStart: '1',
      gridRowStart: '1',
      marginLeft: '20px',
      marginTop: '20px'
    },
    circle: {
      color: '#C80F2D',
      gridColumnStart: '1',
      gridRowStart: '1'
    }
  }

  const LOADING_CONTENT = [
    {
      key: 'robots/robot4.png',
      text: 'Start your child on the path to becoming a tech genius'
    },
    {
      key: 'robots/robot2.png',
      text: 'Learn to code and build cool robots'
    },
    {
      key: 'robots/robot3.png',
      text: 'Become a Robotics Champion today!'
    }
  ]

  const carouselItem = (content) => {
    return (
      <div key={content.key}>
        <div
          style={{
            display: 'grid',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={150} sx={styles.circle} />
          <img
            style={styles.image}
            src={require(`../../images/${content['key']}`)}
          />
        </div>
        <p style={{ color: 'grey', textAlign: 'center' }}>{content['text']}</p>
      </div>
    )
  }

  return (
    <Box>
      <Carousel animation={'fade'} indicators={false} itemsToShow={1}>
        {LOADING_CONTENT.map((content) => {
          return carouselItem(content)
        })}
      </Carousel>
    </Box>
  )
}

export default LoadingComp
