import React from 'react';
import { Snackbar, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CartContainer from '../workshop/CartContainer';
import { clearCart } from '../../store/cartSlice';
import { useDispatch } from 'react-redux';

const AdminCheckOut = ({parent, productType}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleCancel = () => {
        dispatch(clearCart())
        navigate(`/account-view/${parent._id}`)
    }
    
    const snackBarMessage = () => {
        return (
          <Box sx={{ display: 'flex', minWidth: '50vw' }}>
            <Button variant='outlined' onClick={() => handleCancel()}>Cancel</Button>
            <Box sx={{textAlign: 'left', margin: 'auto'}}>
              <Typography>
                <b>Parent: </b>
                {parent.info.name.fName} {parent.info.name.lName}
              </Typography>
              <Typography>
                <b>ID: </b>
                {parent._id}
              </Typography>
            </Box>
            {productType === 'workshop' ? <CartContainer purchaseByAdmin={true}/> : <></>}
          </Box>
        )
      }
    return (
        <Snackbar
        sx={{zIndex: 1000}} //puts snackbar under dialogs (shopping cart, etc)
        ContentProps={{ sx: { backgroundColor: '#fff'} }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={true}
        message={snackBarMessage()}
      />
    );
}

export default AdminCheckOut;
