import React from 'react'

const getNameString = (subscription) => {
  let name = 'Membership'
  if (subscription) {
    if (subscription.name.includes('Membership-')) {
      //cleans up name string
      let tmp = subscription.name.split('-')
      name = tmp[1]
    } else {
      name = subscription.name
    }
  }
  return name
}

export default getNameString
