import axios from 'axios'

//Gets all workshops with complete details (instructor, course, etc)
//Accepts location, season, and date parameter
//Use admin.js > GetAllWorkshopsBasicInfo for workshops WITHOUT complete details
export const GetAllWorkshopsFullInfo = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/product/get-all-workshops-full-info',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const GetProductsById = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/product/byIDs',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getProductsByInstructor = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/product/byInstructor',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const deleteProduct = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/product/delete',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getProduct = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/product/get-product',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getPrice = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/product/get-price',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const assignInstructor = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/product/assign-instructor',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}
