import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CONSTANTS from '../../../constants'
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import dateHandler from '../../../utils/dateHandler'
import {
  setSelectedActivity,
  setSelectedSubscription
} from '../../../store/subscriptionSlice'
import { useNavigate } from 'react-router-dom'

const ExpandedSubscriptionGrid = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filteredActivities = useSelector(
    (state) => state.subscription.filteredActivities
  )
  const subscriptions = useSelector(
    (state) => state.subscription.filteredSubscriptions
  )

  let headers = ['Sat', 'Sun', 'M', 'T', 'W', 'Thr', 'F']
  let startTimes = [
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00'
  ]
  const [rows, setRows] = useState([])

  useEffect(() => {
    let tmpRows = []
    startTimes.forEach((startTime) => {
      let row = []
      headers.forEach((header) => {
        let activities = filteredActivities.filter(
          (filteredActivity) =>
            filteredActivity.instance.startTime === startTime &&
            filteredActivity.instance.day === header
        )
        row.push(activities)
      })
      tmpRows.push(row)
    })
    setRows(tmpRows)
  }, [filteredActivities, subscriptions])

  let today = new Date().getDay() + 1

  const handleClick = (activity) => {
    let subscription = subscriptions.find(
      (sub) => activity.instance.subscriptionID === sub._id
    )
    dispatch(setSelectedSubscription(subscription))
    dispatch(setSelectedActivity(activity))
    navigate(
      `/admin-memberships/enrollment/${activity.instance.subscriptionID}`
    )
  }

  const getSubscriptionName = (activity) => {
    let subscription = subscriptions.find(
      (sub) => activity.instance.subscriptionID === sub._id
    )

    return `${subscription?.name}`
  }

  return (
    <>
      <Table style={{ marginTop: '10px' }} stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((header, headerIndex) => {
              return (
                <TableCell>
                  <Typography
                    variant='h4'
                    sx={{
                      color: today === headerIndex ? 'primary.main' : null,
                      borderBottom:
                        today === headerIndex ? '5px solid' : '1px solid',
                      borderColor:
                        today === headerIndex ? 'primary.main' : '#a6a9aa'
                    }}
                  >
                    {CONSTANTS.DAYS_SHORT_TO_LONG[header]}
                  </Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow>
                {row.map((activities) => {
                  if (activities.length > 0) {
                    return (
                      <TableCell
                        style={{
                          border: 'none',
                          verticalAlign: 'top'
                        }}
                        align='center'
                      >
                        {activities.map((activity) => {
                          return (
                            <Card
                              sx={{ padding: '10px', margin: '10px' }}
                              onClick={() => handleClick(activity)}
                              style={{ cursor: 'pointer' }}
                            >
                              <Typography variant='body2' align='left'>
                                {activity.course.name} {activity.instance.day}
                              </Typography>
                              <Typography align='left'>
                                {getSubscriptionName(activity)}
                              </Typography>
                              <Typography align='left'>
                                {dateHandler.toAbbreviatedTime(
                                  activity.instance.startTime
                                )}{' '}
                                -{' '}
                                {dateHandler.toAbbreviatedTime(
                                  activity.instance.endTime
                                )}
                              </Typography>
                              <Typography align='left'>
                                Enrolled: {activity.instance.students.length}
                              </Typography>
                            </Card>
                          )
                        })}
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell style={{ border: 'none' }}>{null}</TableCell>
                    )
                  }
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default ExpandedSubscriptionGrid
