import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cards from '../../util/cards'
import LoadingComp from '../../util/loading'
import {
  setEmailFilter,
  setRecipientOptions,
  setFilterApplied
} from '../../../store/adminSlice'
import { getCourses, getStudentsById, getAllWorkshopsBasicInfo } from '../../../api/admin'
import Filter from './filter'

const WorkshopFilters = () => {
  const dispatch = useDispatch()
  const BlankCard = cards.blankCard

  const permissions = useSelector((state) => state.login.user.permissions[0])
  const uID = useSelector((state) => state.login.user._id)
  const locations = useSelector((state) => state.user.locations)
  const filter = useSelector((state) => state.admin.emailFilter)

  const baseFilter = {
    Location: null,
    Season: null,
    Course: null,
    Workshop: null
  }

  const [products, setProducts] = useState([])
  const [courses, setCourses] = useState([])
  const [fetchingStudents, setFetchingStudents] = useState(false)

  //Hardcoded for the time being
  const seasons = [
    { name: 'Summer 2023', id: 'summer_2023' },
    { name: 'Holiday 2023', id: 'holiday_2023' },
    { name: 'Spring 2023', id: 'spring_2023' },
    { name: 'Summer 2024', id: 'summer_2024' },
    { name: 'Holiday 2024', id: 'holiday_2024' },
    { name: 'Spring 2024', id: 'spring_2024' }
  ]
  const [filteredProducts, setFilteredProducts] = useState(null)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getAllWorkshopsBasicInfo({ params: { uID: uID } })
      if (resp.success) {
        setProducts(resp.data)
      } else {
        alert('There was an error retrieving products. Please reload')
      }

      resp = await getCourses({ params: { uID: uID } })
      if (resp.data) {
        setCourses(resp.data)
      } else {
        alert('There was an error retrieving courses. Please reload')
      }
    }
    if (products.length === 0) {
      handleAsync()
    }
    dispatch(setEmailFilter(baseFilter))
    dispatch(setRecipientOptions([]))
  }, [])

  //Filter products
  useEffect(() => {
    let tmpProducts = products
    if (filter['Location']) {
      tmpProducts = tmpProducts.filter(
        (product) => product?.metadata?.location === filter['Location']?._id
      )
    }
    if (filter['Course']) {
      tmpProducts = tmpProducts.filter(
        (product) => product?.metadata?.course === filter['Course']?._id
      )
    }
    if (filter['Season']) {
      tmpProducts = tmpProducts.filter(
        (product) => product?.metadata?.workshop === filter['Season']
      )
    }
    setFilteredProducts(tmpProducts)
  }, [filter])

  const handleFilter = (value, type) => {
    let tmpFilter = { ...filter }
    tmpFilter[type] = value
    dispatch(setEmailFilter(tmpFilter))
  }

  const resetFilter = () => {
    dispatch(setEmailFilter(baseFilter))
    dispatch(setRecipientOptions([]))
    dispatch(setFilterApplied(false))
  }

  const applyFilter = async () => {
    setFetchingStudents(true)
    let studentIDs = []
    if (Object.values(filter).every((value) => value == '')) {
      alert('Filter selection can not be empty.')
    } else {
      //Put individual studentIDs in array
      filteredProducts.forEach((filteredProduct) => {
        filteredProduct?.info?.students?.forEach((studentID) => {
          if (!studentIDs.includes(studentID)) {
            studentIDs.push(studentID)
          }
        })
      })
      //Get all student Objects using IDs
      let filteredStudents = []
      let resp = await getStudentsById({
        params: { uID: uID, studentIDs: studentIDs }
      })
      if (resp.success) {
        filteredStudents = resp.data
        setFetchingStudents(false)
      } else {
        setFetchingStudents(false)
        alert('There was a problem retrieving students.')
      }

      //Format student Objects
      filteredStudents = filteredStudents.map((student) => {
        //The details array is used by memberships to check which activity a student is enrolled in
        return { student: student, details: [] }
      })
      dispatch(setFilterApplied(true))
      dispatch(setRecipientOptions(filteredStudents))
    }
  }

  return (
    <>
      {products.length > 0 ? (
        <>
          <Typography align='left' variant='h4'>
            Filters
          </Typography>
          <BlankCard
            box={{ padding: '0px 0px' }}
            style={{}}
            id='filter-recipients'
          >
            <Grid container sx={{ padding: '10px 10px 0px 10px' }}>
              <Filter
                options={seasons}
                type='Season'
                handleFilter={handleFilter}
              />
              <Filter
                options={locations}
                type='Location'
                handleFilter={handleFilter}
              />
              <Filter
                options={courses}
                type='Course'
                handleFilter={handleFilter}
              />
              <Filter
                options={filteredProducts || products}
                type='Workshop'
                handleFilter={handleFilter}
              />
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => resetFilter()}>Reset</Button>
              {fetchingStudents ? (
                <CircularProgress />
              ) : (
                <Button onClick={() => applyFilter()}>Apply Filter</Button>
              )}
            </Box>
          </BlankCard>
        </>
      ) : (
        <LoadingComp />
      )}
    </>
  )
}
export default WorkshopFilters
