import React, { useState } from 'react'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct } from '../../store/cartSlice'
import ItemAdded from './itemAdd'
import dateHandler from '../../utils/dateHandler'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

const AddToCartButton = ({ course, displayAdd, setDisplayAdd }) => {
  const dispatch = useDispatch()

  const handleAdd = () => {
    ReactGA.event({
      category: 'button',
      action: 'Cart Add'
    })

    ReactPixel.track('AddToCart', {
      content_category: 'Add_To_Cart'
    })

    setDisplayAdd(true)
    dispatch(addProduct(course))
    // }
  }

  const handleClose = () => {
    setDisplayAdd(!displayAdd)
  }

  const styles = {
    button: {
      backgroundColor: '#C80F2D'
    }
  }

  return (
    <>
      <Button
        id='add-to-cart'
        variant='contained'
        style={styles.button}
        onClick={() => {
          handleAdd(course)
        }}
      >
        Enroll
        {/*dateHandler.isFourDays(course.metadata.startDate, course.metadata.endDate) ? '*' : null */}
      </Button>
      {displayAdd ? (
        <ItemAdded handleClose={handleClose} course={course} />
      ) : (
        <></>
      )}
    </>
  )
}

export default AddToCartButton
