import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getOrders, getOrdersPI } from '../../api/admin'
import LoadingComp from '../../component/util/loading'
import AdminOrderTable from '../../component/adminViews/ordersTable'
import OrderFilter from '../../component/adminViews/orderFilter'
import SecondaryNav from '../../component/util/secondaryNav'
import Report from '../../component/adminViews/financialReport/report'
import MembershipReport from '../../component/adminViews/membershipReport/membershipReport'
import AlternatePaymentsTable from '../../component/adminViews/alternatePaymentsTable'
import { Alert } from '@mui/material'

const AdminOrdersContainer = () => {
  let user = useSelector((state) => state.login.user)
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [filteredOrders, setFilteredOrders] = useState(null)
  const [currTab, setCurrTab] = useState(0)
  const [pagination, setPagination] = useState(10);
  const [pageOrders, setPageOrders] = useState([]);

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      setIsLoading(true)
      if(pagination > 0 && orders[pagination]) { 
        setPageOrders(orders.slice(pagination - 10, pagination))
      } else {
        let startingAfter = null
        if (orders.length > 0) {
          startingAfter = orders[pagination-11].paymentID
        }// - 11 because pagination sets last index of upcoming, we want to find the next 10
        let resp = await getOrdersPI({
          params: { uID: user._id, search: searchValue, startingAfter: startingAfter }
        })
        if (resp.success) {
          setOrders((prev) => {
            let tmp = [...prev, ...resp.data.orders]
            return tmp})
          setPageOrders(resp.data.orders) //This could have errors
        } else {
          alert('There was a problem retrieving orders')
        }
      }
      setIsLoading(false)
    }
    if(mounted) {
      handleAsync()
    }
    return () => {
      mounted = false
    };
  }, [pagination]);

  const handleSearch = async () => {
    setIsLoading(true)
    let resp = await getOrders({
      params: { uID: user._id, search: searchValue }
    })
    if (resp.success) {
      setOrders(resp.data)
    } else {
      alert('There was a problem retrieving orders')
    }

    setIsLoading(false)
  }
  const handleChange = (value) => {
    setSearchValue(value)
  }
  const handleReset = () => {
    setFilteredOrders(null)
    setSearchValue('')
  }

  const handlePageChange = (direction) => {
    if (direction === 'back') {
      setPagination((prev) => prev - 10)
    } else {
      setPagination((prev) => prev + 10)
    }
  }

  const TABS = [
    {
      label: 'Overview',
      content: (
        <>
          {/* <OrderFilter
            changeSearchValue={handleChange}
            handleSearch={handleSearch}
            searchValue={searchValue}
            handleReset={handleReset}
            filteredItems={filteredOrders}
          /> */}
          {isLoading ? (
            <LoadingComp />
          ) : (
            <>
            <Alert severity='warning'>This page is currently under development and may not be perfectly accurate. Please cross check with Stripe.</Alert>
            <AdminOrderTable
              data={filteredOrders ? filteredOrders : orders}
              handlePageChange={handlePageChange}
              pagination={pagination}
              pageOrders={pageOrders}
            />
            </>
          )}
        </>
      )
    },
    { label: 'Stripe Reports', content: <Report /> },
    { label: 'Membership Reports', content: <MembershipReport /> },
    { label: 'Alternate Payments', content: <AlternatePaymentsTable /> }
  ]
  return (
    <React.Fragment>
      <SecondaryNav
        title='Orders'
        currTab={currTab}
        setCurrTab={setCurrTab}
        tabs={TABS}
      />
      {TABS[currTab].content}
    </React.Fragment>
  )
}

export default AdminOrdersContainer
