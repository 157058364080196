import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography, TextField, IconButton, Tooltip } from '@mui/material'
import dateHandler from '../../../utils/dateHandler'
import { setDate } from '../../../store/workshopSlice'
import RestoreIcon from '@mui/icons-material/Restore'
import ClearIcon from '@mui/icons-material/Clear'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const ProductDate = () => {
  const dispatch = useDispatch()

  const date = useSelector((state) => state.workshop.date)

  const [newDate, setNewDate] = useState('')
  const [change, setChange] = useState(false)

  const changeDate = () => {
    setChange(true)
  }

  const handleChange = (e) => {
    setNewDate(e.target.value)
  }

  const handleBack = () => {
    setChange(false)
  }

  const handleSubmit = () => {
    dispatch(setDate(newDate))
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
      }}
    >
      {change ? (
        <>
          <TextField
            type='date'
            value={newDate}
            onChange={(e) => handleChange(e)}
          />

          <Tooltip title='Submit'>
            <IconButton onClick={handleSubmit}>
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Back'>
            <IconButton onClick={handleBack}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Typography variant='h4'>
            Workshops created after{' '}
            {dateHandler.getMonthDayYear(new Date(date))}
          </Typography>

          <Tooltip title='Change Date'>
            <IconButton onClick={changeDate}>
              <RestoreIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  )
}

export default ProductDate
