import React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box
} from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useSelector } from 'react-redux'

import '../../css/workshop.css'

const CourseFilter = ({
  handleChange,
  courseFilter,
  location,
  age,
  course,
  time,
  handleWeek,
  week,
  dates,
  isCourseMode,
  ageFilter,
  weekFilter,
  handleView,
  view
}) => {
  let permissions = useSelector((state) => state.login?.user?.permissions?.[0])

  const sortObjAlpha = (a, b) => {
    if (a.name && b.name) {
      let textA = a.name.toUpperCase()
      let textB = b.name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    } else return 0
  }

  const allWorkshops = useSelector((state) => state.workshop.masterList)
  let set = []
  let list = [].concat //only get courses for this session and location
    .apply([], Object.values(allWorkshops))
    .forEach((item) => {
      let course = { id: item.metadata.course, name: item.metadata.courseName }
      if (!set.map((item) => item.id).includes(course.id)) {
        set.push(course)
      }
    })

  let sortedCourses = set.sort((a, b) => sortObjAlpha(a, b))

  const genAgeRange = () => {
    let arr = Array(17 - 5 + 1)
      .fill()
      .map((_, idx) => 5 + idx)
    arr.unshift('All')
    return arr
  }

  const StyledSelect = (props) => {
    return (
      <FormControl fullWidth sx={{ ...props.sx }}>
        <InputLabel sx={{ '&.Mui-focused': { color: 'secondary.dark' } }}>
          {props.label}
        </InputLabel>
        <Select
          value={props.value || 'All'}
          label={props.label}
          inputlabelprops={{
            sx: { '& .Mui-focused': { color: 'secondary.dark' } }
          }}
          MenuProps={{
            sx: {
              '&& .Mui-selected': {
                backgroundColor: 'secondary.dark',
                color: '#fff'
              }
            }
          }}
          onChange={(e) => {
            handleChange(props.type, e.target.value)
          }}
          sx={{
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'secondary.dark',
              color: 'secondary.dark'
            }
          }}
        >
          {props.children}
        </Select>
      </FormControl>
    )
  }

  return (
    <div
      style={{
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: '20% auto auto',
        width: '100%',
        maxWidth: '1000px'
      }}
    >
      <Box sx={{ gridColumn: '1/4' }}>
        <ToggleButtonGroup
          fullWidth
          exclusive
          value={view}
          onChange={(e) => {
            handleView(e.target.value)
          }}
        >
          <ToggleButton value={1}>Week View</ToggleButton>
          <ToggleButton value={2}>Grid View</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {view === 1 ? (
        <>
          <StyledSelect
            type='age'
            label='Age'
            value={ageFilter}
            sx={{ gridColumn: '1/2' }}
          >
            {genAgeRange().map((option) => {
              return (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              )
            })}
          </StyledSelect>

          <StyledSelect
            type='course'
            label='Course'
            value={courseFilter}
            sx={{ gridColumn: '2/3' }}
          >
            <MenuItem value={'All'} key={'All'}>
              {'All'}
            </MenuItem>
            {sortedCourses.map((option, index) => {
              return (
                <MenuItem value={option.id} key={option.id}>
                  {option.name}
                </MenuItem>
              )
            })}
          </StyledSelect>

          <StyledSelect
            type='week'
            label='Week'
            value={weekFilter}
            sx={{ gridColumn: '3/4' }}
          >
            <MenuItem value={'All'}>All</MenuItem>
            {permissions > 3 ? <MenuItem value={'Past'}>Past</MenuItem> : null}
            {dates.slice(week).map((date) => {
              return (
                <MenuItem value={date} key={date}>
                  {date}
                </MenuItem>
              )
            })}
          </StyledSelect>
        </>
      ) : (
        <></>
      )}

      {/* <FormControl>
        <InputLabel>Time</InputLabel>
        <Select
          label='Time'
          IconComponent={null}
          onChange={(e) => {
            handleChange('time', e.target.value)
          }}
          style={styles.select}
          sx={{
            ':before': { borderBottomColor: '#ECECEC' },
            ':after': { borderBottomColor: '#ECECEC' }
          }}
        >
          <MenuItem selected={time === ''} value=''>
            ALL
          </MenuItem>
          <MenuItem selected={time === 'am'} value='am'>
            AM
          </MenuItem>
          <MenuItem selected={time === 'pm'} value='pm'>
            PM
          </MenuItem>
        </Select>
      </FormControl> */}
      {/* {!isCourseMode ? (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '15% auto 15%'
            }}
          >
            {dates ? (
              <>
                {week !== 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                      onClick={() => {
                        handleWeek(-1)
                      }}
                    >
                      <ArrowLeftIcon fontSize={'large'} />
                    </IconButton>
                  </div>
                ) : (
                  <></>
                )}
                <p
                  style={{
                    gridColumnStart: '2',
                    paddingTop: '7px',
                    margin: '0px',
                    color: '#3790CB'
                  }}
                >
                  {dates[week]}
                </p>
                {dates.length - 1 !== week ? (
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <IconButton
                      onClick={() => {
                        handleWeek(1)
                      }}
                    >
                      <ArrowRightIcon fontSize={'large'} />
                    </IconButton>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )} */}
    </div>
  )
}

export default CourseFilter
