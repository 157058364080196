import React, { Fragment } from 'react'
import { Grid, Typography, Divider, MenuList, MenuItem } from '@mui/material'
import { Person, Info } from '@mui/icons-material'
import generateUserInfo from '../utils/genUserInfo'
import cards from './util/cards'

const StaffInfo = (props) => {
  let data = props.data
  let permissions = props.permissions
  let handleEdit = props.handleEdit
  let index = props.index
  let type = props.type

  let BigCard = cards.bigCard
  const checkAddress = () => {
    let fields = ['lineOne', 'city', 'state', 'postal']
    return fields.every((field) => data.info.address[field] !== '')
  } //checks for users that moved from previous site with missing info

  const styles = {
    title: {
      marginTop: '15px'
    }
  }

  const editMenuContent = () => {
    return (
      <MenuList>
        <MenuItem key='edit' onClick={() => handleEdit(data, type, index)}>
          Edit
        </MenuItem>
      </MenuList>
    )
  }

  return (
    <>
      <BigCard
        icon={Person}
        title={
          data.info.name.fName
            ? generateUserInfo.generateName(data.info.name)
            : 'N/A'
        }
        id='staff-info'
        editMenu={permissions !== 3 ? editMenuContent() : null} //Instructors cannot edit user info
      >
        <Grid container>
          {permissions >= 3 ? (
            <>
              <Grid item xs={4}>
                <Typography align='left' fontWeight='bold'>
                  User ID
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography align='left'>{data._id} </Typography>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={4}>
            <Typography align='left' fontWeight='bold'>
              Address
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align='left'>
              {checkAddress() ? (
                <>
                  <span>
                    {generateUserInfo.generateAddrOne(data.info.address)}
                  </span>
                  <br />
                  <span>
                    {generateUserInfo.generateAddrTwo(data.info.address)}
                  </span>
                </>
              ) : (
                <>N/A</>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography align='left' variant='h3' style={styles.title}>
              Primary Contact
            </Typography>
            <Divider sx={{ marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={4}>
            <Typography align='left' fontWeight='bold'>
              Email
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align='left'>
              {data.info.contact.email || data.userName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align='left' fontWeight='bold'>
              Phone
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align='left'>
              {data.info.contact.phone || 'N/A'}
            </Typography>
          </Grid>

          {/*Temporary workaround for empty alt objects */}
          {data.info.contact.alt.length >= 1 &&
          Object.values(data.info.contact.alt[0]).some(
            (item) => item !== ''
          ) ? (
            <Fragment>
              <Grid item xs={12}>
                <Typography align='left' variant='h3' sx={styles.title}>
                  Secondary Contact
                  {data.info.contact.alt.length > 1 ? 's' : null}
                </Typography>
                <Divider sx={{ marginBottom: '10px' }} />
              </Grid>
              {data.info.contact.alt.map((item, i) => {
                return (
                  <Fragment key={'secondary_' + i}>
                    {/*check for secondary contact name */}
                    {item.fName && item.fName !== '' ? (
                      <>
                        <Grid item xs={4}>
                          <Typography align='left' fontWeight='bold'>
                            Name
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>
                            {item.fName} {item.lName ? item.lName : null}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                    {/*check for secondary email */}
                    {item.email && item.email !== '' ? (
                      <>
                        <Grid item xs={4}>
                          <Typography align='left' fontWeight='bold'>
                            Email
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>{item.email}</Typography>
                        </Grid>
                      </>
                    ) : null}
                    {/*check for secondary phone */}
                    {item.phone && item.phone !== '' ? (
                      <>
                        <Grid item xs={4}>
                          <Typography align='left' fontWeight='bold'>
                            Phone
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>{item.phone}</Typography>
                        </Grid>
                      </>
                    ) : null}
                    {data.info.contact.alt.length - 1 === i ? null : (
                      <Grid item xs={12}>
                        <Divider sx={{ margin: '5px 0px' }} />
                      </Grid>
                    )}
                  </Fragment>
                )
              })}
            </Fragment>
          ) : null}
        </Grid>
      </BigCard>
    </>
  )
}

export default StaffInfo
