import axios from 'axios'

export const getReport = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/finance/financial-report',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getAllReports = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/finance/get-financial-reports',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const requestReport = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/finance/request-report',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}
