import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material'
import { setSelectedSubscription } from '../../store/subscriptionSlice'
import {
  setToggleBack,
  setMemberships,
  setActivities
} from '../../store/instructorSlice'
import InstructorAPI from '../../api/instructor'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const MembershipCard = ({ membership }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let membershipName = membership.name
  if (membershipName.includes('-')) {
    let tmpName = membership.name.split('-')
    membershipName = tmpName[1]
  }
  const user = useSelector((state) => state.login.user)
  const memberships = useSelector((state) => state.instructor.memberships)
  const activities = useSelector((state) => state.instructor.activities)
  const courses = useSelector((state) => state.instructor.courses)
  const [membershipActivities, setMembershipActivities] = useState([])
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    if (!updated) {
      let tmpActivities = activities.filter(
        (activity) =>
          activity.subscriptionID === membership._id &&
          activity.instructors.includes(user._id)
      )
      setMembershipActivities(tmpActivities)
    } else {
      setUpdated(false)
    }
  }, [activities])

  const getCourse = (activity) => {
    let tmpCourses = courses.filter(
      (course) => course._id === activity.courseID
    )
    return tmpCourses[0]
  }

  const handleClick = () => {
    dispatch(setToggleBack(true))
    dispatch(setSelectedSubscription(membership))
    navigate(`/admin-memberships/all/${membership._id}`)
  }

  const handleRemove = async (activity) => {
    let resp = await InstructorAPI.removeMembership({
      uID: user._id,
      activityID: activity._id
    })
    if (resp.success) {
      setUpdated(true)
      let tmpActivities = [...activities]

      let index = tmpActivities.findIndex(
        (tmpActivity) => tmpActivity._id === resp.data.activity._id
      )

      tmpActivities.splice(index, 1)

      dispatch(setActivities([...tmpActivities]))
      let check = tmpActivities.filter(
        (tmpActivity) =>
          tmpActivity.subscriptionID === resp.data.activity.subscriptionID
      )

      if (check.length === 0) {
        let tmpMemberships = [...memberships]
        index = tmpMemberships.findIndex(
          (tmpMembership) =>
            tmpMembership._id === resp.data.activity.subscriptionID
        )
        tmpMemberships.splice(index, 1)
        dispatch(setMemberships([...tmpMemberships]))
      }
    } else {
      alert(resp)
    }
  }

  return (
    <Card
      style={{
        maxWidth: '400px',
        minWidth: '300px',
        margin: '5px'
        //cursor: 'pointer'
      }}
    >
      <CardHeader
        title={<Typography variant='h6'>{membershipName}</Typography>}
        subheader={membership.locationName}
        avatar={
          <img
            src={require(`../../images/courseIcons/${
              membership.icon || 'robie.png'
            }`)}
            style={{ width: '75px' }}
          />
        }
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='body2' align='left'>
              Activities:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {membershipActivities.map((activity) => {
              let activityCourse = getCourse(activity)
              return (
                <div style={{ display: 'flex' }}>
                  <Typography align='left'>
                    {activity.startTime} - {activity.day} -{' '}
                    {activityCourse.name}
                  </Typography>
                  <Tooltip describeChild title='Remove Activity'>
                    <IconButton
                      color='primary'
                      aria-label='delete'
                      onClick={() => handleRemove(activity)}
                      size='small'
                      style={{ marginTop: '-5px' }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MembershipCard
