import dateHandler from './dateHandler'
import { updateProduct } from '../api/admin'
import moment from 'moment'

const formatProduct = (item) => {
  let hold = item.metadata
  let tmp = Object.assign(
    {
      price: (item.priceInfo.unit_amount / 100).toString(),
      prodId: item.id,
      priceId: item.priceInfo.id,
      cap: item.info.cap
    },
    hold
  )

  // set hours
  let dateObj = new Date()
  tmp.startTime = dateObj.setHours(
    item.metadata.startTime,
    item.metadata.startTimeMinute,
    0
  )
  tmp.endTime = dateObj.setHours(
    item.metadata.endTime,
    item.metadata.endTimeMinute,
    0
  )
  // set date
  if (!item.info.selectDates || item.info.selectDates.length == 0) {
    tmp.selectDates = dateHandler.getConsecutiveDates(item.metadata.startDate, item.metadata.endDate)
  } else {
    tmp.selectDates = item.info.selectDates.map((date) => new moment(date).format('MMM DD, YYYY'))
  }
  tmp.startDate = dateHandler.formatDate(item.metadata.startDate)

  tmp.endDate = dateHandler.formatDate(item.metadata.endDate)
  tmp.ageMin = item.info.ageMin
  tmp.ageMax = item.info.ageMax

  //product form needs instructor Ids only
  tmp.instructors = item.instructors.map((instructor) => {
    return instructor._id
  })

  //Product form needs to check if students have been enrolled
  tmp.students = item.info.students

  return tmp
}

const update = async (product, course, uID) => {
  let tmpProduct = { ...product }
  tmpProduct.startTime = new Date(tmpProduct.startTime).toISOString()
  tmpProduct.endTime = new Date(tmpProduct.endTime).toISOString()

  tmpProduct.price = (tmpProduct.price * 100).toFixed(0)
  tmpProduct.uID = uID
  tmpProduct.ageMin = parseInt(course.age.min)
  tmpProduct.ageMax = parseInt(course.age.max)
  let resp = await updateProduct(tmpProduct)
  if (resp.success) {
    resp.data.priceInfo = resp.data.price
    delete resp.data.price
    return { success: true, data: resp.data }
  } else {
    return { error: true }
  }
}

const productHandler = { formatProduct, update }

export default productHandler
