import React from 'react'
import { Box, Chip, Typography } from '@mui/material'

const Info = ({ student, details }) => {
  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant='h5'>{`${student.info.name.fName} ${student.info.name.lName}`}</Typography>
        {details.map((item, index) => {
          return (
            <React.Fragment
              key={`${student._id}-${item.subscription._id}-${index}`}
            >
              <Typography>{`${item.subscription.locationName} - ${item.subscription.name}`}</Typography>
              <ul style={{ margin: '0px 0px' }}>
                {item.activities.map((activity, index) => {
                  let tags = []
                  if (activity.instance.tags) {
                    tags = Object.keys(activity.instance.tags).filter((tag) =>
                      activity.instance.tags[tag].includes(student._id)
                    )
                  }
                  return (
                    <li key={`${activity.instance._id}-${index}`}>
                      <Typography display='inline'>{`${activity.instance.day} ${activity.course.name} ${activity.instance.startTime}-${activity.instance.endTime}`}</Typography>
                      {tags.length > 0 ? (
                        tags.map((tag) => <Chip label={tag} key={tag} />)
                      ) : (
                        <></>
                      )}
                    </li>
                  )
                })}
              </ul>
            </React.Fragment>
          )
        })}
      </Box>
    </>
  )
}

export default Info
