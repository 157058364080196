import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

const StudentSelect = ({ selectStudent, untaggedStudents }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Select Student</InputLabel>
      <Select
        labelId='select-student'
        id='select-student'
        label='student'
        onChange={(e) => {
          selectStudent(e.target.value)
        }}
      >
        {untaggedStudents.map((student) => {
          return (
            <MenuItem key={student._id} value={student}>
              {`${student.info.name.fName} ${student.info.name.lName}`}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default StudentSelect
