import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  TextField, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Switch,
  useMediaQuery,
  IconButton
} from '@mui/material'
import LessonSelector from './lessonSelector'
import CloseIcon from '@mui/icons-material/Close'

const CourseForm = ({ data, edit, handleClose, handleSave, handleChange, handleUpdate, selectIcon, addLesson, lessonChange, errors }) => {
  const [focus, setFocus] = useState(null)
  const lessons = useSelector((state) => state.admin.lessons)

  const styles = {
    fields: {
      marginTop: '1vh',
      marginBottom: '1vh',
      age: {
        width: '100%'
      }
    },
    ageHelperText: {
      color: '#d32f2f',
      fontSize: 12
    },
    tempWeb: {}
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const CATEGORIES = [
    'programming/coding',
    'robotics',
    'inventing',
    'game design',
    'membership'
  ]

  const PRODUCT_FIELDS = [
    {
      'label': 'Name',
      'key': 'name',
      'isRequired': true
    },
    {
      'label': 'Age',
      'key': 'age',
      'isRequired': true
    },
    {
      'label': 'Description',
      'key': 'description',
      'isRequired': true
    },
    {
      'label': 'Categories',
      'key': 'categories',
      'isRequired': true,
      'data': CATEGORIES
    }
  ]

  const submit = () => {
    if (edit) {
      handleUpdate()
    } else {
      handleSave()
    }
  }

  const standardField = (item) => {
    return (
      <div style={styles.fields} key={item.key}>
        <TextField
          required={item.isRequired}
          value={data[item.key]}
          margin='dense'
          id={item.type}
          key={item.type}
          error={errors.includes(item.key)}
          label={item.label}
          type={item.type}
          multiline={(item.key === 'description')}
          onFocus={() => { setFocus(item.key) }}
          helperText={focus === item.key ? item.helper || null : null}
          fullWidth
          onChange={(e) => { handleChange(item.key, e.target.value) }}
          variant='standard'
        />
      </div>
    )
  }

  const ageField = (item) => {
    return (
      <span style={styles.fields.age} key={item.key}>
        <TextField
          style={{ width: '50%' }}
          required={item.isRequired}
          value={data.minAge}
          margin='dense'
          id={item.type}
          key={item.type}
          error={errors.includes('rangeError') || errors.includes('minAge') ? true : false}
          label='Min Age'
          type='number'
          onFocus={() => { setFocus(item.key) }}
          onChange={(e) => { handleChange('minAge', e.target.value) }}
          variant='standard'
        />
        <TextField
          required={item.isRequired}
          value={data.maxAge}
          style={{ width: '50%' }}
          margin='dense'
          id={item.type}
          key={item.type}
          error={errors.includes('rangeError') || errors.includes('maxAge') ? true : false}
          label='Max Age'
          type='number'
          onFocus={() => { setFocus(item.key) }}
          onChange={(e) => { handleChange('maxAge', e.target.value) }}
          variant='standard'
        />
        <div style={styles.ageHelperText}>{errors.includes('rangeError') ? 'Max age cannot be less than min age' : null}</div>

      </span>
    )
  }

  const selectField = (item) => {
    return (
      <div style={styles.fields} key={item.key}>
        <FormControl
          fullWidth
          error={errors.includes(item.key)} >
          <InputLabel>{item.label}*</InputLabel>
          <Select
            multiple
            value={data.categories}
            label={item.label}
            onChange={(e) => { handleChange(item.key, e.target.value) }}
          >
            {item.data.map((option) => {
              return (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  return (
    <React.Fragment>
      {/* {edit ?      <Dialog fullScreen open style={styles.tempWeb}> */}
      <DialogTitle>Enter the Course Information
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={() => {
            handleClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {PRODUCT_FIELDS.map((item) => {
          if (item.key === 'age') {
            return ageField(item)
          } else if (item.key === 'categories') {
            return selectField(item)
          } else {
            return standardField(item)
          }
        })}
        <Button onClick={() => { selectIcon(true) }}>Select an Icon</Button><br />
        {data.icon
          ? <img
            src={require(`../../images/courseIcons/${data.icon}`)}
            loading='lazy'
            alt='course icon'
          />
          : <React.Fragment />
        }
        <FormControlLabel
          control={
            <Switch
              checked={data.isURL}
              onChange={(e) => {
                handleChange('isURL', e.target.checked)
              }} />}
          label='URL lesson or custom' />

        {data.isURL
          ? <TextField
            value={data.lessonURL}
            margin='dense'
            id='lessonURL'
            key='lessonURL'
            error={errors.includes('lessonURL')}
            label='URL to lesson plan'
            onFocus={() => { setFocus('lessonURL') }}
            // helperText='The url to the outside lesson plan.'
            fullWidth
            onChange={(e) => { handleChange('lessonURL', e.target.value) }}
            variant='standard'
          />
          : <LessonSelector handleChange={lessonChange} lessons={data.lessons} addLesson={addLesson} options={lessons} errors={errors} />
        }        </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose() }}>Cancel</Button>
        <Button onClick={() => { submit() }}>{edit ? 'Update' : 'Save'}</Button>
      </DialogActions>

      {/* {showIconSelect
        ? <ImageSelector handleChange={handleImageSelect} handleClose={setShowIconSelect} />
        : <React.Fragment />
      } */}
    </React.Fragment>
  )
}

export default CourseForm
