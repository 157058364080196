const getStartDate = (data) => {
  let startDate = null

  if (data.trial_end) {
    startDate = data.trial_end
  } else {
    startDate = data.start_date
  }

  return new Date(startDate * 1000).toDateString()
}

const getBillDue = (data) => {
  return new Date(data.current_period_end * 1000).toDateString()
}

const getPaymentPlan = (data) => {
  let amount = Number(data.plan.amount / 100).toLocaleString('en-US')
  let interval = data.plan.interval
  let period = Number(data.plan.interval_count)
  if (period > 1) {
    interval = interval + 's'
  }
  return amount + ' every ' + period + ' ' + interval
}

const stripeData = { getStartDate, getBillDue, getPaymentPlan }

export default stripeData
