import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRegions } from '../../store/adminSlice'
import { getRegions } from '../../api/locations'
import LocationsView from '../../component/adminViews/locations/locationsView'
import LocationView from '../../component/adminViews/location/locationView'

const AdminLocationsContainer = () => {
  const dispatch = useDispatch()

  const view = useSelector((state) => state.location.view)
  const uID = useSelector((state) => state.login.user._id)
  const regions = useSelector((state) => state.admin.regions)

  useEffect(() => {
    async function handleAsync() {
      if (regions.length === 0) {
        let resp = await getRegions({ params: { uID: uID } })
        if (resp.data) {
          dispatch(setRegions(resp.data))
        }
      }
    }
    handleAsync()
  }, [])

  return (
    <>
      {view === 0 ? <LocationsView /> : <></>}
      {view === 1 ? <LocationView /> : <></>}
    </>
  )
}

export default AdminLocationsContainer
