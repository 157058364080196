import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComp from '../component/util/loading'
import StudentForm from '../component/studentForm'
import SecondaryNav from '../component/util/secondaryNav'
import OrdersContainer from './orders'
import ModelHandler from '../models/modelHandler'
import generateEmail from '../utils/generateFakeEmail'
import { createUser } from '../api/userApi'
import { addStudent } from '../store/parentSlice'
import { updateUserChildren } from '../store/loginSlice'
import { setSelectedUser } from '../store/userSlice'
import ParentAPI from '../api/parentApi'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { clearCart } from '../store/cartSlice'
import ReactPixel from 'react-facebook-pixel'
import AccountView from '../component/account/accountView'
import { useParams } from 'react-router-dom'
import { getUser } from '../api/instructor'
import OrdersStripeContainer from './ordersStripe'

const AccountContainer = ({ back, isInstructor }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.login.user)
  let selectedUser = useSelector((state) => state.user.selectedUser)
  //checks if being redirected from checkout success and clears cart if true
  const locState = useLocation().state
  if (locState?.checkoutSuccess) {
    dispatch(clearCart())
  }

  const [newChild, setNewChild] = useState({})
  const [loadingNewChild, setLoadingNewChild] = useState(false);
  const [currTab, setCurrTab] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [charges, setCharges] = useState([])

  const handleBack = () => {
    back()
  }

  useEffect(() => {
    async function handleAsync() {
      let userInfo
      if (!selectedUser) {
        if (user.permissions[0] > 2) {
          let resp = await getUser({
            params: { userID: params.userID }
          })
          if (resp.success) {
            userInfo = resp.data
          }
        } else {
          userInfo = user
        }
        dispatch(setSelectedUser(userInfo))
      } else {
        userInfo = selectedUser
      }
      if (userInfo) {
        if (!Object.keys(userInfo).includes('students')) {
          let resp = await ParentAPI.getAccountData({
            params: {
              parentID: userInfo._id
            }
          })
          if (resp.success) {
            let updatedUser = {
              ...userInfo,
              students: resp.data.students
            }
            dispatch(setSelectedUser(updatedUser))
          } else {
            alert(resp)
          }
        }
        setIsLoading(false)
      }
    }

    if (user.permissions[0] > 2 || params.userID) {
      handleAsync()
    }
  }, [params])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/account', title: 'Account' })
    ReactPixel.pageView()
  }, [])

  const handleNewUser = (type, value) => {
    setNewChild((prevState) => ({
      ...prevState,
      [type]: value
    }))
  }

  /**Student form logic:
   * The only other place the student form is used is in the cart view. In that component,
   * the handleNext function is slightly different*/
  const handleNext = async () => {
    setLoadingNewChild(true)
    var tmp = newChild
    tmp.parentID = selectedUser._id
    tmp.addrCheck = true // temp work around for not adding extra fields
    if (tmp.addrCheck === undefined || tmp.addrCheck === true) {
      tmp.address = selectedUser.info.address
    }
    let errors = ModelHandler.checkStudentData(tmp, true)
    if (errors.length === 0) {
      if (!('email' in tmp) || tmp.email === '') {
        tmp.email = generateEmail(tmp)
      }
      let data = ModelHandler.student(tmp)
      let resp = await createUser(data)
      if (resp.success) {
        let tmpStudents = [...selectedUser.students]
        tmpStudents.push(resp.data)
        dispatch(
          setSelectedUser({
            _id: selectedUser._id,
            permissions: selectedUser.permissions,
            userName: selectedUser.userName,
            pwHash: selectedUser.pwHash,
            active: selectedUser.active,
            createdAt: selectedUser.createdAt,
            updatedAt: selectedUser.updatedAt,
            info: selectedUser.info,
            eula: selectedUser.eula,
            hasPaymentIssue: selectedUser.hasPaymentIssue,
            paymentMeta: selectedUser.paymentMeta,
            children: selectedUser.children,
            __v: selectedUser.__v,
            memberships: selectedUser.memberships,
            students: tmpStudents,
            inviteInfo: selectedUser.inviteInfo
          })
        )
        setNewChild({})
        setLoadingNewChild(false)
        dispatch(addStudent(resp.data))
        if (user.permissions[0] == 1) {
          dispatch(updateUserChildren(resp.data._id))
        }
        setCurrTab(0)
      } else {
        setLoadingNewChild(false)
        alert('There was an error creating a new roboteer. Please try again')
      }
    } else {
      // handle errors
      //setStudentErr(errors)
    }
  }

  const TABS = [
    {
      label: 'Overview',
      content: <AccountView account={selectedUser} isLoading={isLoading} />
    },
    {
      label: 'Add Child',
      content: (
        <StudentForm
          accountInfo={true}
          data={newChild}
          handleChange={handleNewUser}
          handleNext={handleNext}
          loading={loadingNewChild}
        />
      )
    },
    {
      label: 'Orders',
      content: (
        <OrdersContainer
          orders={selectedUser?.paymentMeta?.stripe?.transactions}
          altOrders={selectedUser?.paymentMeta?.credit?.transactions}
          students={selectedUser?.students}
        />
      )
    }
  ]
  if (user.permissions[0] == 4) {
    TABS.push({
      label: 'Stripe',
      content: <OrdersStripeContainer/>
    })
  }

  return (
    <>
      <SecondaryNav
        title={'Account Info'}
        tabs={isInstructor ? TABS.slice(0, 1) : TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
        back={isInstructor ? handleBack : null}
      />
      {selectedUser ? TABS[currTab].content : <LoadingComp />}
    </>
  )
}

export default AccountContainer
