import axios from 'axios';

export const GetLocations = async () => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/locations/get-locations'
    );
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data };
    } else {
      return resp;
    }
  } catch (err) {
    return err;
  }
};

export const getLocation = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/locations/get-location',
      {
        params: {
          _id: data._id
        }
      }
    );
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data };
    } else {
      return resp;
    }
  } catch (err) {
    return err;
  }
};

export const getRegion = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/locations/get-region',
      data
    );
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data };
    } else {
      return resp;
    }
  } catch (err) {
    return err;
  }
};

export const getRegions = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/locations/get-regions',
      data
    );
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data };
    } else {
      return resp;
    }
  } catch (err) {
    return err;
  }
};
