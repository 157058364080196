import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'

const styles = {
  message: {
    padding: '1vh',
    color: '#fff',
    backgroundColor: 'secondary.dark'
  }
}

const MessageView = ({ activeMsgs }) => {
  let pathname = useLocation().pathname
  if (pathname == '/') {
    pathname = 0
  } else {
    pathname = pathname.slice(1, pathname.length) //remove backslash from pathname
  }

  //workaround for outdated site location values and router refactor
  let siteLocations = {
    'summer-camps': 3,
    login: 19,
    0: 99, //landing
    memberships: 12,
    'account-view': 1,
    'holiday-camps': 13
  }
  return (
    <>
      <Box sx={styles.message}>
        <Carousel
          indicators={false}
          itemsToShow={1}
          duration={'4000'}
          interval={'5000'}
        >
          {activeMsgs.map((item) => {
            if (item.siteLocation.includes(siteLocations[pathname])) {
              return <div key={item._id}>{item.message}</div>
            }
          })}
        </Carousel>
      </Box>
    </>
  )
}

export default MessageView
