import React, { useState } from 'react'
import { MobileStepper, Typography, Box, IconButton } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

const PhotoStepper = (props) => {
  let photos = props.photos
  const [activeStep, setActiveStep] = useState(0)
  const handleNext = () => {
    if (activeStep === photos.length - 1) {
      setActiveStep(0)
    } else {
      setActiveStep((prev) => prev + 1)
    }
  }
  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(photos.length - 1)
    } else {
      setActiveStep((prev) => prev - 1)
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '300px',
        margin: 'auto auto',
        position: 'relative'
      }}
    >
      <Box
        component='img'
        src={require(`../../images/fbLandingImages/${
          props.yellow ? 'Asset_2.png' : 'Asset_18.png'
        }`)}
        sx={{ width: '550px', left: '-150px', position: 'absolute' }}
      />
      <Box
        component='img'
        src={require(`../../images/fbLandingImages/${photos[activeStep].image}`)}
        sx={{ maxWidth: '200px' }}
      />

      <Typography variant='h5'>{photos[activeStep].name}</Typography>
      <Typography
        sx={{ fontStyle: 'italic', fontWeight: 500, color: '#EE4239' }}
      >
        {photos[activeStep].position}
      </Typography>
      <Box
        sx={{
          borderTop: '5px solid #3e3e3e',
          width: '50px',
          marginBottom: '20px',
          marginTop: '10px'
        }}
      ></Box>
      <Typography sx={{ height: '100px' }}>
        {photos[activeStep].details}
      </Typography>
      <MobileStepper
        variant='dots'
        steps={photos.length}
        activeStep={activeStep}
        position='static'
        sx={{ maxWidth: 400, flexGrow: 1, background: 'rgba(0, 0, 0, 0)' }}
        nextButton={
          <IconButton size='small' onClick={handleNext}>
            <KeyboardArrowRight />
          </IconButton>
        }
        backButton={
          <IconButton size='small' onClick={handleBack}>
            <KeyboardArrowLeft />
          </IconButton>
        }
      />
    </Box>
  )
}

export default PhotoStepper
