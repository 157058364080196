import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Checkbox, Button, CircularProgress } from '@mui/material'
import { switchPayment } from '../../../api/admin'
import { setStudents } from '../../../store/subscriptionSlice'
import { setSelectedUser } from '../../../store/userSlice'

const PaymentSelect = ({
  updateAccount,
  close,
  setShowSuccess,
  setShowPayment
}) => {
  const dispatch = useDispatch()
  //get data
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const students = useSelector((state) => state.subscription.students)
  const selectedStudent = useSelector((state) => state.subscription.student)
  const selectedUser = useSelector((state) => state.user.selectedUser)
  const uID = useSelector((state) => state.login.user._id)

  //Set price options
  let prices = [...subscription.altPrices]
  prices.push({
    id: subscription.priceID,
    period: 'month',
    price: subscription.price
  })

  const [selectedPrice, setSelectedPrice] = useState(
    selectedStudent.stripe.plan.id
  )
  const [loading, setLoading] = useState(false)

  const isSelected = (priceID) => {
    if (selectedPrice === priceID) {
      return true
    } else {
      return false
    }
  }

  const handlePriceSelect = (priceID) => {
    setSelectedPrice(priceID)
  }

  const handleSubmit = async () => {
    setLoading(true)
    let resp = await switchPayment({
      uID: uID,
      studentID: selectedStudent._id,
      subID: selectedStudent.stripe.id,
      priceID: selectedPrice
    })
    if (resp.success) {
      if (updateAccount) {
        //Update Redux store for account info page
        let tmpUser = { ...selectedUser }
        let tmpStudent = { ...selectedStudent }
        //Swap old stripe data with new stripe data
        tmpStudent.stripe = resp.data.stripe
        let tmpSubs = [...tmpStudent.subscriptionInfo]
        let subIndex = tmpSubs.findIndex((sub) => sub._id === subscription._id)
        let tmpSub = { ...tmpSubs[subIndex] }
        //place new stripe data in subscription object
        tmpSub['stripe'] = resp.data.updatedStripe
        tmpSubs.splice(subIndex, 1, tmpSub)
        tmpStudent['subscriptionInfo'] = tmpSubs
        let tmpStudents = [...tmpUser.students]
        let studentIndex = tmpStudents.findIndex(
          (element) => element._id === tmpStudent._id
        )
        tmpStudents.splice(studentIndex, 1, tmpStudent)
        tmpUser.students = tmpStudents
        dispatch(setSelectedUser(tmpUser))

        setLoading(false)
        close()
      } else {
        //Update redux store for membership page
        let tmpStudent = { ...selectedStudent }
        tmpStudent.stripe = resp.data.updatedStripe
        let tmpStudents = [...students]
        let studentIndex = tmpStudents.findIndex(
          (element) => element._id === tmpStudent._id
        )
        tmpStudents.splice(studentIndex, 1, tmpStudent)
        dispatch(setStudents(tmpStudents))
        setShowPayment(false)
        setShowSuccess(true)
        setLoading(false)
      }
    } else {
      setLoading(false)
      alert(resp.data.error.message)
    }
  }

  return (
    <>
      {subscription.altPrices.length > 0 ? (
        <>
          {prices.map((price) => {
            let currentPlan = price.id === selectedStudent.stripe.plan.id
            let checked = isSelected(price.id)
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                key={price.id}
              >
                <Checkbox
                  onChange={() => handlePriceSelect(price.id)}
                  checked={checked}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '300px'
                  }}
                >
                  <Typography variant='body2'>
                    ${Number(price.price).toLocaleString('en-US')} &nbsp;
                  </Typography>
                  <Typography variant='body2'>
                    per {price.period} &nbsp;
                  </Typography>

                  {currentPlan ? (
                    <Typography variant='body2' style={{ color: 'red' }}>
                      (current plan)
                    </Typography>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )
          })}
          {selectedStudent.stripe.plan.id !== selectedPrice ? (
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant='contained' onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Typography variant='h5'>
          This membership does not have any alternative prices
        </Typography>
      )}
    </>
  )
}

export default PaymentSelect
