import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    parents: [],
    students: [],
    instructors: [],
    memberships: [],
    products: [],
    lessons: [],
    memOrders: [],
    messages: [],
    emailFilter: {},
    recipientOptions: [],
    selectedRecipients: [],
    filterApplied: false,
    regions: [] //TO DO: Decide if this should be moved out of admin slice
  },
  reducers: {
    setLessons: (state, action) => {
      state.lessons = action.payload
    },
    setParents: (state, action) => {
      state.parents = action.payload
    },
    setParentDetails: (state, action) => {
      state.parents.splice(
        state.parents.findIndex(function (i) {
          //replaces parent data at id index
          return i._id === action.payload._id
        }),
        1,
        action.payload
      )
    },
    setStudents: (state, action) => {
      state.students = action.payload
    },
    setStudentDetails: (state, action) => {
      state.students.splice(
        state.students.findIndex(function (i) {
          return i._id === action.payload._id
        }),
        1,
        action.payload
      )
    },
    setMemberships: (state, action) => {
      state.memberships = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setUpdatedMessages: (state, action) => {
      state.messages.splice(
        state.messages.findIndex(function (i) {
          //replaces parent data at id index
          return i._id === action.payload._id
        }),
        1,
        action.payload
      )
    },
    setEmailFilter: (state, action) => {
      state.emailFilter = action.payload
    },
    removeProduct: (state, action) => {
      state.products.splice(
        state.products.findIndex(function (i) {
          return i.id === action.payload.id
        }),
        1
      )
    },
    setInstructors: (state, action) => {
      state.instructors = action.payload
    },
    setRecipientOptions: (state, action) => {
      state.recipientOptions = action.payload
    },
    setSelectedRecipients: (state, action) => {
      state.selectedRecipients = action.payload
    },
    setFilterApplied: (state, action) => {
      state.filterApplied = action.payload
    },
    setRegions: (state, action) => {
      state.regions = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  // addToCourse,
  // updateCourse,
  // setCourses,
  setParents,
  setParentDetails,
  setStudents,
  setStudentDetails,
  setMemberships,
  setProducts,
  setLessons,
  setMessages,
  setUpdatedMessages,
  setEmailFilter,
  removeProduct,
  setInstructors,
  setRecipientOptions,
  setSelectedRecipients,
  setFilterApplied,
  setRegions
} = adminSlice.actions

export default adminSlice.reducer
