import React from 'react'
import AlternateCheckOut from '../component/workshop/alternateCheckOut'
import SecondaryNav from '../component/util/secondaryNav'

const AlternateCheckOutContainer = () => {
  //for workshops
  return (
    <>
      <SecondaryNav title='Alternate Check Out' noTabs />
      <AlternateCheckOut />
    </>
  )
}

export default AlternateCheckOutContainer
