import axios from 'axios'

export const getSubscriptionsByLocation = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/location',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getSubscriptionActivities = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/activities',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createCheckout = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL +
        '/api/services/create-subscription-checkout',
      data
    )
    if (resp.status === 200 && resp.data) {
      window.location = resp.data.url
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createAltCheckout = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/subscription-checkout',
      data
    )
    if (resp.status === 200 && resp.data) {
      window.location = resp.data.url
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const checkEnrollment = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/isEnrolled',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const pauseSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/pause',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const resumeSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/resume',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const editPause = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/edit-pause',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const getInstructorSubs = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/instructor-subs',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const assignInstructorActivity = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/assign-instructor',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getStudentAttendance = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/student-attendance',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}
