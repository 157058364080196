import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, Paper } from '@mui/material'
import MessageForm from '../../component/adminViews/messageForm'
import MessageTable from '../../component/adminViews/messageTable'
import { createMessage } from '../../api/admin'
import { getMessages } from '../../api/admin'
import { updateMessages } from '../../api/admin'
import SecondaryNav from '../../component/util/secondaryNav'
import { setMessages, setUpdatedMessages } from '../../store/adminSlice'


const AdminMessageContainer = () => {
  const [currTab, setCurrTab] = useState(0)

  const styles = {
    buttonStyle: {
      margin: ' 0 auto',
      color: '#FFFFFF',
      backgroundColor: '#C80F2D',
    },
    space: {
      margin: '15px auto',
    },
    toggleButton: {
      color: 'white',
      backgroundColor: '#3596CE',
    },
  }

  const baseMessage = {
    title: null,
    categories: [],
    siteLocation: [],
    message: null,
    startDate: new Date(),
    endDate: null,
    disabled: false
  }

  const checkMessage = {
    title: false,
    categories: false,
    siteLocation: false,
    message: false,
    startDate: false,
    endDate: false,
    helpText: {
      blank: "End date cannot be blank",
      timeTravel: "End date cannot be before Start Date"
    }
  }

  const dispatch = useDispatch()
  const [message, setMessage] = useState(baseMessage)
  const [showForm, setShowForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const uID = useSelector((state) => state.login.user._id)
  const [errors, setErrors] = React.useState([])
  const [currentEdit, setCurrentEdit] = useState(null)
  const messages = useSelector((state) => state.admin.messages)

  // const [messages, setMessages] = useState([])

  useEffect(() => {
    async function handleAsync() {
      let msgTmp = await getMessages({ uID })
      if (msgTmp.data) {
        dispatch(setMessages(msgTmp.data))
      }

    }
    handleAsync()
  }, [])

  const handleClose = () => {
    setErrors([])
    setCurrTab(0)
    setMessage(baseMessage)
    setShowForm(false)
    setIsEdit(false)
  }

  const handleSave = async () => {
    let errorCheck = await handleErrors()
    if (errorCheck.length >= 1) {
      setErrors(errorCheck)
    } else {
      let newMessage = message
      newMessage.uID = uID
      let resp = await createMessage(newMessage)
      if (resp.success) {
        setErrors(['Saved Message'])
        handleClose()
      } else {
        alert('failed creation')
      }
      return resp
    }

  }

  const handleErrors = () => {
    let errTmp = []
    for (const key in message) {
      if (key === 'title' || key === 'message') {
        if (message[key] === null || message[key] === '') {
          errTmp.push(key)
        }
      } else if (key === 'categories' || key == 'siteLocation') {
        if (message[key].length == 0) {
          errTmp.push(key)
        }
      } else if (key === 'startDate' || key === 'endDate') {
        if (message[key] === null) {
          errTmp.push(key)
        }
      }
    }
    if (message.endDate - message.startDate < 0) {
      errTmp.push('rangeError')
    }
    let today = new Date()
    if (isEdit) {
      if (today - message.endDate > 0) {
        errTmp.push('editInThePast')
      }
    } else {
      if (today - message.startDate > 0) {
        errTmp.push('inThePast')
      }
    }

    return errTmp

  }


  const handleUpdate = async () => {
    let errorCheck = await handleErrors()
    if (errorCheck.length >= 1) {
      setErrors(errorCheck)
    } else {
      let updates = {
        'data': {
          'id': message.id,
          'title': message.title,
          'categories': message.categories,
          'siteLocation': message.siteLocation,
          'message': message.message,
          'startDate': message.startDate,
          'endDate': message.endDate,
          'disabled': message.disabled
        }
      }

      updates.uID = uID
      setCurrentEdit(updates.data.id)

      let resp = await updateMessages(updates)
      if (resp.success) {
        dispatch(setUpdatedMessages(resp.data))
        setErrors(['Updated Message'])
        handleClose()
      } else {
        alert('failed update')
      }
      return resp
    }


  }


  const handleChange = (type, value) => {
    if (type === 'categories') {
      setMessage(prevState => ({
        ...prevState,
        [type]: [...value]
      }))
    } else {
      setMessage(prevState => ({
        ...prevState,
        [type]: value
      }))
    }
  }

  const createNew = () => {
    setIsEdit(false)
    setShowForm(true)
  }

  const onEdit = (item) => {

    let tmp = {
      id: item['_id'],
      title: item.title,
      categories: item.categories,
      siteLocation: item.siteLocation,
      message: item.message,
      startDate: new Date(item.startDate),
      endDate: new Date(item.endDate),
      disabled: item.disabled 
    }

    setIsEdit(true)
    setMessage(tmp)
    setShowForm(true)
    // setCurrTab(1)
  }


  const TABS = [
    {
      label: 'Table View',
      content: <MessageTable
        message={message}
        currentEdit={currentEdit}
        messages={messages}
        onEdit={onEdit}
      />
    },
    {
      label: 'Add Message',
      content: <Paper sx={{ margin: '0px auto 100px auto', width: '90%' }}>
        <MessageForm data={message} edit={isEdit} handleClose={handleClose} handleSave={handleSave} handleChange={handleChange} handleUpdate={handleUpdate} errors={errors} />
      </Paper>
    }
  ]

  return (
    <React.Fragment>
      <SecondaryNav title={'Admin Messages'} tabs={TABS} currTab={currTab} setCurrTab={setCurrTab} />
      {showForm ?
        <>
          <Dialog open onClose={() => {
            handleClose()
          }}>
            <MessageForm data={message} edit={isEdit} handleClose={handleClose} handleSave={handleSave} handleChange={handleChange} handleUpdate={handleUpdate} errors={errors} />
          </Dialog>
        </> : <></>}
      {TABS[currTab].content}
    </React.Fragment>
  )
}

export default AdminMessageContainer
