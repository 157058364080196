import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'
import LoadingComp from '../util/loading'
import cards from '../util/cards'
import { Grid } from '@mui/material'
import MessageIcon from '@mui/icons-material/Message'

function Row({ row, type, rUsers, onEdit }) {
  const styles = {
    buttonStyle: {
      margin: ' 0 auto',
      color: '#FFFFFF',
      backgroundColor: '#C80F2D',
    },
    space: {
      margin: '15px auto',
    },
    toggleButton: {
      color: 'white',
      backgroundColor: '#3596CE',
    },
  }

  const [open, setOpen] = React.useState(false)
  const [showChild, setShowChild] = React.useState(null)
  const handleCloseChild = () => {
    setShowChild(null)
  }
  const start = new Date(row.startDate)
  const end = new Date(row.endDate)
  const dateRange = start.toDateString() + ', ' + start.toLocaleTimeString() + ' - ' + end.toDateString() + ', ' + end.toLocaleTimeString()

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

        <TableCell align='center'><Typography variant='h5'>{row.title === null ? 'Message ' + start.toDateString() : row.title}</Typography></TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Button variant='contained' onClick={() => { onEdit(row) }} style={styles.buttonStyle}>Edit</Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5} >
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>

              <Typography variant='subtitle2' gutterBottom component='div'>
                Message Info:
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableRow>
                  <TableCell><Typography variant='body2' display='inline'>Date Range:</Typography> <Typography display='inline'>{dateRange}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><Typography variant='body2' display='inline'>Message:</Typography> <Typography display='inline'>{row.message}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant='body2'>Categories:</Typography>
                    <ul>
                      {row.categories.map((order) => {
                        return (
                          <>
                            <li>
                              <Typography>
                                {order == 1 ? 'Coupons' : 'Seasonal'}
                              </Typography>
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant='body2'>Site Locations:</Typography>
                    <ul>
                      {row.siteLocation.map((order) => {
                        let loc
                        if (order == 3) {
                          loc = 'Summer Camp'
                        } else if (order == 12) {
                          loc = 'Memberships'
                        } else if (order == 19) {
                          loc = 'Login'
                        } else if (order == 99) {
                          loc = 'Landing'
                        } else if (order == 1) {
                          loc = 'Roboteers'
                        } else {
                          loc = order
                        }
                        return (
                          <>
                            <li>
                              <Typography>
                                {loc}
                              </Typography>
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'toggle', label: '' },
  { id: 'edit', label: '' }
]

const MessageTable = ({ currentEdit, messages, onEdit }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const SmallCard = cards.smallCard
  useEffect(() => {
    setPage(0)
  }, [messages])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <Grid container sx={{ padding: { xs: '0px 3% 0px 3%', md: '0px 10% 0px 10%' } }}>
         <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={messages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        {messages
          ? messages
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              const start = new Date(row.startDate)
              const end = new Date(row.endDate)
              const dateRange = start.toDateString() + ' - ' + end.toDateString()
              const today = new Date()
              let active = false
              if (today < end && !row.disabled) {
                active = true
              }
              const Icon = () => {
                if (active) {
                  return (
                    <MessageIcon color='primary' sx={{ margin: '0px 15px' }} />
                  )
                } else {
                  return (
                    <MessageIcon sx={{ color: '#808080', margin: '0px 15px' }} />
                  )
                }
              }
              return (
                <Grid item xs={12}>
                  <SmallCard
                    title={row.title}
                    subtitleOne={dateRange}
                    icon={Icon}
                    titleStyle={{color: active ? null : '#808080'}}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant='body2' align='left'>Message</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography align='left'>{row.message}</Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant='body2' align='left'>Start Date</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography align='left'>{start.toDateString() + ', ' + start.toLocaleTimeString()}</Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant='body2' align='left'>End Date</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography align='left'>{end.toDateString() + ', ' + end.toLocaleTimeString()}</Typography>
                      </Grid>


                      <Grid item xs={4}>
                        <Typography variant='body2' align='left'>Categories</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <ul>
                          {row.categories.map((order) => {
                            return (
                              <>
                                <li>
                                  <Typography align='left'>
                                    {order == 1 ? 'Coupons' : 'Seasonal'}
                                  </Typography>
                                </li>
                              </>
                            )
                          })}
                        </ul>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant='body2' align='left'>Site Locations</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <ul>
                          {row.siteLocation.map((order) => {
                            let loc
                            if (order == 3) {
                              loc = 'Summer Camp'
                            } else if (order == 12) {
                              loc = 'Memberships'
                            } else if (order == 19) {
                              loc = 'Login'
                            } else if (order == 99) {
                              loc = 'Landing'
                            } else if (order == 1) {
                              loc = 'Roboteers'
                            } else {
                              loc = order
                            }
                            return (
                              <>
                                <li>
                                  <Typography align='left'>
                                    {loc}
                                  </Typography>
                                </li>
                              </>
                            )
                          })}
                        </ul>
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='contained' onClick={() => { onEdit(row) }}>Edit</Button>
                      </Grid>
                    </Grid>


                  </SmallCard>
                </Grid >
              )
            })
          : <LoadingComp />
        }
      </Grid >
    </>
  )
}

export default MessageTable
