const CONSTANTS = {
  EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD_REGEX: /^(?=.*\d)(?=.*[~!@#$%^_&*-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  PHONE_REGEX: /^\d{10}$/,
  DAYS: {
    'Sunday': false,
    'Monday': false,
    'Tuesday': false,
    'Wednesday': false,
    'Thursday': false,
    'Friday': false,
    'Saturday': false
  },
  DAY_INDEX: {
    'Monday': 0,
    'Tuesday': 1,
    'Wednesday': 2,
    'Thursday': 3,
    'Friday': 4,
    'Saturday': 5,
    'Sunday': 6

  },
  SHORT_DAY_INDEX: {
    'M': 0,
    'T': 1,
    'W': 2,
    'Thr': 3,
    'F': 4,
    'Sat': 5,
    'Sun': 6
  },
  RED_HEX: '#C8102E',
  BLUE_HEX: '#037CFF',
  DAYS_OF_WEEK: [
    {
      long: 'Sunday',
      short: 'Sun'
    },
    {
      long: 'Monday',
      short: 'M'
    },
    {
      long: 'Tuesday',
      short: 'T'
    },
    {
      long: 'Wednesday',
      short: 'W'
    },
    {
      long: 'Thursday',
      short: 'Thr'
    },
    {
      long: 'Friday',
      short: 'F'
    },
    {
      long: 'Saturday',
      short: 'Sat'
    }
  ],
  DAYS_SHORT_TO_LONG: {
    Sun: 'Sunday',
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    Thr: 'Thursday',
    F: 'Friday',
    Sat: 'Saturday'
  },
  LOCATION_LINKS: {
    '61e9c5fee04b587cd7b520e1': 'https://goo.gl/maps/vt1G1LQEThak7jDK8', //Palos Verdes
    '61f2f983e04b587cd7b520f2': 'https://goo.gl/maps/FGRJPrhniAcuYq9b9', //West LA
    '61f2f9c7e04b587cd7b520f4': 'https://goo.gl/maps/md8dNYGP1ZniXZiEA', //Glendale
    '6236290f63fe1d9b3cc94bd0': 'https://goo.gl/maps/ejLNmrVYx2g3fPz97', //La Crescenta
    '6259a7ccecc19a67a441161a': 'https://goo.gl/maps/679ZTaQ7y8x84eAm9', //Hermosa Beach
    '62cb9c1bdda69fed263b6aad': 'https://goo.gl/maps/kM6CfbmZZLScnkkZ8' //Irvine
  },
  SEASONS: ['summer', 'spring', 'holiday', 'other']
}

export default CONSTANTS
