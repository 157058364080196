import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid
} from '@mui/material'
import { LocalizationProvider, MobileDatePicker } from '@mui/lab'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CloseIcon from '@mui/icons-material/Close'
import LoadingComp from '../util/loading'

const PauseSubscription = ({
  open,
  handleClose,
  error,
  confirm,
  subscription,
  handleReturnDateChange,
  handleStartDateChange,
  startDate,
  returnDate,
  handleCancel,
  handleSubmit,
  loading
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(240, 240, 240, 0.3)'
        },
        '& .MuiPaper-root': { boxShadow: 'none' }
      }}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        Pause Subscription
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error ? (
          <Typography style={{ color: 'red', textAlign: 'center' }}>
            Pause must be two weeks or longer
          </Typography>
        ) : (
          <></>
        )}
        {!confirm ? (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div>
                <Typography variant={'h6'} style={{ textAlign: 'center' }}>
                  Select Start Date
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      lable='returnDate'
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <Typography variant={'h6'} style={{ textAlign: 'center' }}>
                  Select End Date
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      lable='returnDate'
                      value={returnDate}
                      onChange={handleReturnDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
          <div>
            {loading ? (
              <>
                <LoadingComp />
                <Typography>
                  Please be patient. This may take a moment.
                </Typography>
              </>
            ) : (
              <Typography>
                Pause {subscription.name} from {startDate} to {returnDate}?
              </Typography>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {confirm ? (
          <>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
              <div>
                <Button onClick={handleCancel}>Back</Button>
                <Button onClick={handleSubmit}>Confirm</Button>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default PauseSubscription
