import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import SecondaryNav from '../../util/secondaryNav'
import StudentList from './studentList'
import AttendanceReport from './attendanceReport'
import {
  setSelectedSubscription,
  setSelectedActivity
} from '../../../store/subscriptionSlice'
import { useParams } from 'react-router-dom'

const EnrollmentView = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currTab, setCurrTab] = useState(0)
  const params = useParams()

  const activity = useSelector((state) => state.subscription.selectedActivity)
  const allActivities = useSelector((state) => state.subscription.allActivities)
  const allSubscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )
  const toggleBack = useSelector((state) => state.instructor.toggleBack)

  useEffect(() => {
    if (!activity) {
      //if selected activity is not in redux store, use search params
      let activity = allActivities.filter(
        (obj) => obj.instance._id === params.activityID
      )[0]
      dispatch(setSelectedActivity(activity))
      let subscription = allSubscriptions.filter(
        (obj) => obj._id == activity.instance.subscriptionID
      )[0]
      dispatch(setSelectedSubscription(subscription))
    }
  }, [allActivities])

  const handleBack = () => {
    if (toggleBack) {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  //pass today's date to student list
  const todaysDate = new Date()
  todaysDate.setHours(0, 0, 0, 0)

  const TABS = [
    {
      label: 'Enrollment',
      content: <StudentList date={todaysDate} />
    },
    {
      label: 'Attendance Report',
      content: <AttendanceReport handleStudent={props.handleStudent} />
    }
  ]

  return (
    <>
      {!activity ? (
        <>Loading...</>
      ) : (
        <>
          <SecondaryNav
            title={`${activity.course.name} ${activity.instance.day} ${activity.instance.startTime} - ${activity.instance.endTime} Enrollment`}
            tabs={TABS}
            currTab={currTab}
            setCurrTab={setCurrTab}
            back={handleBack}
          />
          <>{TABS[currTab].content}</>
        </>
      )}
    </>
  )
}

export default EnrollmentView
