import { createSlice } from '@reduxjs/toolkit'

export const instructorSlice = createSlice({
  name: 'instructorCourses',
  initialState: {
    courses: [],
    memberships: [],
    activities: [],
    currMemView: '',
    classes: [],
    selectedCourse: {},
    toggleBack: false
  },
  reducers: {
    setCourses: (state, action) => {
      state.courses = action.payload
    },
    rmCourses: (state) => {
      state.courses = {}
    },
    setMemberships: (state, action) => {
      state.memberships = action.payload
    },
    rmMemberships: (state) => {
      state.memberships = {}
    },
    setCurrMemView: (state, action) => {
      state.currMemView = action.payload
    },
    rmCurrMemView: (state) => {
      state.currMemView = {}
    },
    setClasses: (state, action) => {
      state.classes = action.payload
    },
    setSelectedCourse: (state, action) => {
      state.selectedCourse = action.payload
    },
    setActivities: (state, action) => {
      state.activities = action.payload
    },
    setToggleBack: (state, action) => {
      state.toggleBack = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setCourses,
  rmCourses,
  setMemberships,
  rmMemberships,
  setCurrMemView,
  rmCurrMemView,
  setClasses,
  setSelectedCourse,
  setActivities,
  setToggleBack
} = instructorSlice.actions

export default instructorSlice.reducer
