import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ParentAPI from '../api/parentApi'
import { setSelectedSubscription } from '../store/subscriptionSlice'
import SelectDetails from '../component/subscription/selectDetails'
import LoadingComp from '../component/util/loading'

const SubscriptionInvite = () => {
  const dispatch = useDispatch()
  const [validLink, setValidLink] = useState(false)
  const [done, setDone] = useState(false)

  //Get subscription and student ID from url parameters
  let queryString = window.location.search
  queryString = queryString.split('&')
  const subID = queryString[0].substring(4)
  let studentName = queryString[1].substring(5)
  if (studentName.includes('%20')) {
    studentName = studentName.replace('%20', ' ')
  }

  //get parent
  const parent = useSelector((state) => state.login.user)

  //Get invited subscription
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )

  useEffect(() => {
    async function handleAsync() {
      //Check if invite link is still valid
      let check = await ParentAPI.checkInvite({
        params: {
          parentID: parent._id,
          childName: studentName,
          subscriptionID: subID
        }
      })

      if (check.success) {
        setValidLink(check.data.status)
        //retrieve information about subscription if link is still valid
        if (check.data.status) {
          let resp = await ParentAPI.getSubByID({
            params: { subscriptionID: subID }
          })
          if (resp.success) {
            dispatch(setSelectedSubscription(resp.data))
          } else {
            alert('Could not retrieve invite information at this time')
          }
        }
      } else {
        alert('Could not retrieve invite information at this time')
      }
      setDone(true)
    }
    handleAsync()
  }, [])

  return (
    <>
      {subscription && done ? (
        <SelectDetails source={'invite'} childName={studentName} />
      ) : (
        <>
          {!validLink && done ? (
            <h1>This invitation is no longer valid</h1>
          ) : (
            <LoadingComp />
          )}
        </>
      )}
    </>
  )
}

export default SubscriptionInvite
