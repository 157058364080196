import React from 'react'
import {
  Box,
  Autocomplete,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Typography
} from '@mui/material'

const CompetitionEventsFilter = (props) => {
const { loading, teamSelectOptions, setSelectedTeam, programFilter, setProgramFilter } = props

  return (
    <>
    <Typography sx={{ marginBottom: '20px', textAlign: 'left' }}>
    Select a team number to view events. Refresh the page to update with
    newly added events.
    <br /> More details can be found on{' '}
    <a
      href='https://www.robotevents.com/'
      target='_blank'
      rel='noreferrer'
    >
      robotevents.com
    </a>
  </Typography>
    <Box sx={{ display: 'flex' }}>
      <Autocomplete
        disableClearable
        autoHighlight
        sx={{ flexGrow: 1 }}
        loading={loading}
        id='select-team-number'
        options={teamSelectOptions}
        renderInput={(params) => <TextField {...params} label='Team Number' />}
        renderOption={(props, option) => {
          return (
            <Box
              component='li'
              {...props}
              key={`${option}`}
            >
              {option}
            </Box>
          )
        }}
        onChange={(e, value) => {
          setSelectedTeam(value)
        }}
      />
      <ToggleButtonGroup
        value={programFilter}
        onChange={(e) => setProgramFilter(e.target.value)}
      >
        <ToggleButton value='V5RC'>VRC</ToggleButton>
        <ToggleButton value='VIQRC'>IQ</ToggleButton>
        <ToggleButton value='AI'>AI</ToggleButton>
      </ToggleButtonGroup>
    </Box>
    </>
  )
}

export default CompetitionEventsFilter
