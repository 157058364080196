import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getActivities } from '../../../api/admin'
import {
  setSubscriptionActivities,
  setSelectedActivity
} from '../../../store/subscriptionSlice'
import LoadingComp from '../../util/loading'
import { Button, Grid, Typography, Chip, Box } from '@mui/material'
import cards from '../../util/cards'
import { useNavigate } from 'react-router-dom'
import ReadMore from '../../../utils/readMore'

const SubscriptionDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const SmallCard = cards.smallCard

  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )

  const activities = useSelector(
    (state) => state.subscription.subscriptionActivities
  )

  const uID = useSelector((state) => state.login.user._id)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let resp = await getActivities({
        params: { uID: uID, activityIDs: subscription.activities }
      })
      if (mounted) {
        if (resp.success) {
          dispatch(setSubscriptionActivities(resp.data))
          setLoading(false)
        } else {
          alert('There was an error retrieving activities')
        }
      }
    }
    handleAsync()
    return () => (mounted = false)
  }, [])

  const handleEnrollment = (activity) => {
    dispatch(setSelectedActivity(activity))
    navigate(`${activity.instance._id}`)
  }

  const getCapacity = (activity) => {
    if (activity.instance.capacity) {
      return `${activity.instance.students.length}/${activity.instance.capacity} Capacity`
    } else {
      return `${activity.instance.students.length} Enrolled`
    }
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          {activities.length > 0 ? (
            <Grid
              container
              spacing={2}
              sx={{ margin: '0px auto 100px auto', width: '90%' }}
            >
              {activities.map((activity) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ marginTop: '10px' }}
                    key={activity.instance._id}
                  >
                    <SmallCard
                      title={activity.course.name}
                      subtitleOne={getCapacity(activity)}
                      subtitleThree={
                        activity.instance.linkedActivity
                          ? '(includes students from another membership)'
                          : ''
                      }
                      box={{ padding: '0px 0px' }}
                      style={{ marginBottom: '0px' }}
                    >
                      <Grid container sx={{ padding: '20px 40px' }}>
                        <Grid item xs={4}>
                          {activity.course.description ? (
                            <Typography variant='body2' align='left'>
                              Description
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          <ReadMore>{activity.course.description}</ReadMore>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='body2' align='left'>
                            Days
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>
                            {activity.instance.day}{' '}
                            {activity.instance.startTime} -{' '}
                            {activity.instance.endTime}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          {activity.instructors.length > 0 ? (
                            <Typography variant='body2' align='left'>
                              Instructors
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          {activity.instructors.length > 0 ? (
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'left',
                                gap: 2
                              }}
                            >
                              {activity.instructors.map((instructor) => {
                                return (
                                  <Chip
                                    label={`${instructor.info.name.fName} ${instructor.info.name.lName} `}
                                    size='small'
                                    key={activity._id + instructor._id}
                                  />
                                )
                              })}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Button onClick={() => handleEnrollment(activity)}>
                          Enrollment
                        </Button>
                      </Grid>
                    </SmallCard>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <h1>There are no activities for this subscription</h1>
          )}
        </>
      )}
    </>
  )
}

export default SubscriptionDetails
