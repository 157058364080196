import { useSelector } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material'
import {
  Done,
  Remove,
  Clear,
  Edit,
  QuestionMark,
  DoNotDisturb
} from '@mui/icons-material'
import { pink, green, grey } from '@mui/material/colors'
import dateHandler from '../../../utils/dateHandler'

const AttendanceTable = ({ headerCells, rowCells, handleEdit }) => {
  let today = headerCells.findIndex(
    (header, index) =>
      index !== 0 &&
      header.toLocaleDateString() == new Date().toLocaleDateString()
  )

  const user = useSelector((state) => state.login.user)

  return (
    <TableContainer
      component={Paper}
      sx={{
        overflow: 'hidden'
      }}
    >
      <Table sx={{ miWidth: 650 }} arial-lable='enrollment table'>
        <TableHead>
          <TableRow>
            {headerCells.map((header, index) => {
              let text = ''
              if (index == 0) {
                text = header
              } else {
                text = header.toLocaleDateString()
              }
              return (
                <TableCell
                  key={`${text} ${index}`}
                  sx={{
                    backgroundColor: today == index ? 'secondary.main' : null
                  }}
                >
                  <span>
                    <Tooltip
                      title={dateHandler.getDayName(header)}
                      placement='top'
                      arrow
                    >
                      <Typography
                        sx={{ color: today == index ? 'white' : null }}
                        display='inline'
                      >
                        {text}
                      </Typography>
                    </Tooltip>
                    {/**Edit icon will show in header row if it passes three checks:
                     * 1: The cell is not in the most left column
                     * 2: The date has already passed
                     * 3: The user is an admin
                     */}
                    {index !== 0 &&
                    new Date(headerCells[index]) <= new Date() &&
                    user.permissions[0] > 3 ? (
                      <>
                        <IconButton
                          sx={{ color: today == index ? 'white' : null }}
                          onClick={() => handleEdit(index)}
                        >
                          <Edit />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowCells.map((row, columnIndex) => {
            return (
              <TableRow key={columnIndex}>
                {row.map((details, rowIndex) => {
                  if (rowIndex === 0) {
                    return (
                      <TableCell key={rowIndex}>
                        {details.info.name.fName} {details.info.name.lName}
                      </TableCell>
                    )
                  } else {
                    let icon = () => {
                      if (new Date(headerCells[rowIndex]) > new Date()) {
                        return null
                      } else if (details === 'Not Taken') {
                        return (
                          <Tooltip title='Not Taken'>
                            <Remove sx={{ color: grey[500] }} />
                          </Tooltip>
                        )
                      } else if (details === 'Not Joined') {
                        return (
                          <Tooltip title='Not yet enrolled'>
                            <DoNotDisturb sx={{ color: grey[500] }} />
                          </Tooltip>
                        )
                      } else if (details === 'Absent') {
                        return (
                          <Tooltip title='Absent'>
                            <Clear sx={{ color: pink[600] }} />
                          </Tooltip>
                        )
                      } else if (details === 'Present') {
                        return (
                          <Tooltip title='Present'>
                            <Done sx={{ color: green[600] }} />
                          </Tooltip>
                        )
                      } else {
                        return (
                          <Tooltip title='Start date unknown'>
                            <QuestionMark sx={{ color: grey[500] }} />
                          </Tooltip>
                        )
                      }
                    }
                    return (
                      <TableCell
                        key={rowIndex}
                        sx={{
                          backgroundColor:
                            today == rowIndex ? 'secondary.light' : null
                        }}
                      >
                        {icon()}
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AttendanceTable
