import React, { useState, useEffect } from 'react'
import { LocationOn, Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogTitle,
  Typography,
  Button,
  DialogContent,
  Divider,
  IconButton,
  useMediaQuery
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { GetLocations, getRegions } from '../../api/locations'
import { setLocations } from '../../store/userSlice'
import {
  setDefaultLocation,
  setDefaultRegion,
  updateUser
} from '../../store/loginSlice'
import { saveDefaultLocation } from '../../api/parentApi'
import NavBarButton from './../util/navBarButton'
import { setRegions } from '../../store/adminSlice'
import ParentAPI from '../../api/parentApi'

const DefaultRegionButton = () => {
  const [showDialog, setShowDialog] = useState(false)
  const dispatch = useDispatch()
  const locations = useSelector((state) => state.user.locations)
  const regions = useSelector((state) => state.admin.regions)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)
  const user = useSelector((state) => state.login.user)
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    async function handleAsync() {
      if (showDialog) {
        if (regions.length === 0) {
          let resp = await getRegions()
          if (resp.data) {
            let tmpRegions = [...resp.data]
            //Remove 'All' option from everyone except admins
            if (
              !user.permissions ||
              (user.permissions && user.permissions[0] !== 4)
            ) {
              tmpRegions = tmpRegions.filter((region) => region.name !== 'All')
            }
            dispatch(setRegions(tmpRegions))
          }
        }
        if (locations.length === 0) {
          let resp = await GetLocations()
          if (resp.data) {
            dispatch(setLocations(resp.data))
          }
        }
      }
    }
    handleAsync()
  }, [showDialog])

  const handleClose = () => {
    setShowDialog(false)
  }

  const currentRegion = (region) => {
    if (defaultRegion._id === region._id) {
      return true
    } else {
      return false
    }
  }
  const handleSetRegion = async (region) => {
    if (isLoggedIn) {
      let resp = await ParentAPI.saveDefaultRegion({
        uID: user._id,
        regionID: region._id
      })     
      if (resp.success) {
        dispatch(updateUser(resp.data))
      } else {
        alert('Error updating')
      }
    }
    dispatch(setDefaultRegion(region))
    handleClose()
  }

  const regionLocations = (region) => {
    let filteredLocations = []
    if (region.name === 'All') {
      filteredLocations = [...locations]
    } else {
      filteredLocations = locations.filter(
        (location) => location.region === region._id
      )
    }

    return filteredLocations.map((location, index) => (
      <Typography sx={{ fontSize: '14px' }} display='inline' key={location._id}>
        {location.name}
        {index !== filteredLocations.length - 1 ? ', ' : null}
      </Typography>
    ))
  }
  return (
    <>
      <Dialog open={showDialog} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          Select Region
          <IconButton
            onClick={() => {
              handleClose()
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {regions.length > 0 && locations.length > 0 ? (
            regions.map((region) => (
              <React.Fragment key={region._id}>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='h6'>{region.name}</Typography>
                    {regionLocations(region)}
                  </Box>
                  <Button
                    variant='contained'
                    sx={{ alignSelf: 'flex-end' }}
                    disabled={currentRegion(region)}
                    onClick={() => handleSetRegion(region)}
                  >
                    {currentRegion(region) ? 'Your Region' : 'Set Region'}
                  </Button>
                </Box>
                <Divider sx={{ margin: '10px 0px' }} />
              </React.Fragment>
            ))
          ) : (
            <>Loading...</>
          )}
        </DialogContent>
      </Dialog>
      <NavBarButton
        startIcon={<LocationOn />}
        onClick={() => setShowDialog(true)}
        mobile={mobile}
      >
        {defaultRegion.name ? defaultRegion.name : 'Set Region'}
      </NavBarButton>
    </>
  )
}

export default DefaultRegionButton
