import { useSelector, useDispatch } from 'react-redux'
import { setSelectedSubscription } from '../../../store/subscriptionSlice'
import { useState, useEffect } from 'react'
import SecondaryNav from '../../util/secondaryNav'
import SubscriptionDetails from './subscriptionDetails'
import SubscriptionInvite from './subscriptionInvite'
import SubscriptionStudents from './subscriptionStudents'
import Tags from '../tags/tags'
import { setEmailFilter } from '../../../store/adminSlice'
import { useNavigate, useParams } from 'react-router-dom'

const SubscriptionView = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [currTab, setCurrTab] = useState(0)
  const uID = useSelector((state) => state.login.user._id)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const allSubscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )
  const locations = useSelector((state) => state.user.locations)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!subscription || subscription._id !== params.membershipID) {
      //if selected subscription is not in redux store, use search params
      let sub = allSubscriptions.filter(
        (obj) => obj._id === params.membershipID
      )[0]
      dispatch(setSelectedSubscription(sub))
    }
  }, [allSubscriptions])

  const TABS = [
    {
      label: 'Subscription',
      content: <SubscriptionDetails />
    },
    { label: 'Send Invite', content: <SubscriptionInvite /> },
    {
      label: 'Email Membership',
      content: <>Sending to Email Tool...</>
    },
    {
      label: 'Students',
      content: <SubscriptionStudents />
    },
    {
      label: 'Tags',
      content: <Tags all={false} />
    }
  ]

  useEffect(() => {
    if (locations.length > 0 && allSubscriptions.length > 0) {
      setLoading(false)
    }
  }, [locations, allSubscriptions])

  useEffect(() => {
    async function handleAsync() {
      window.history.replaceState({ tab: 0 }, '')
      dispatch(
        setEmailFilter({
          Location: locations.filter(
            (loc) => loc._id === subscription.locationID
          )[0],
          Subscription: subscription,
          Activity: '',
          Tag: ''
        })
      )
      navigate('/email')
    }
    if (!loading && currTab === 2) {
      handleAsync()
    }
  })

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <>
      {!subscription ? (
        <>Loading...</>
      ) : (
        <>
          {' '}
          <SecondaryNav
            title={`${subscription.name} Activities`}
            tabs={TABS}
            currTab={currTab}
            setCurrTab={setCurrTab}
            back={handleBack}
          />
          <>{TABS[currTab].content}</>
        </>
      )}
    </>
  )
}

export default SubscriptionView
