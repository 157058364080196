import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSubscription } from '../store/subscriptionSlice'
import WorkshopLink from '../component/workshopLink'
import SelectDetails from '../component/subscription/selectDetails'
import ParentAPI from '../api/parentApi'
import { getProduct } from '../api/products'
import LoadingComp from '../component/util/loading'
import { getSubscriptionActivities } from '../api/subscription'
import { useNavigate } from 'react-router-dom'

const DirectLink = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //Get type of link and ID from url parameters
  let queryArr = window.location.search.split('&')
  let type = queryArr[0].substring(6)
  const id = queryArr[1].substring(3)

  const [loading, setLoading] = useState(true)
  const [course, setCourse] = useState(null)
  const [activities, setActivities] = useState(null)
  const [children, setChildren] = useState([])

  let user = useSelector((state) => state.login.user)

  useEffect(() => {
    async function handleMembership() {
      let tmpSelectedSubscription = null
      let resp = await ParentAPI.getSubByID({ params: { subscriptionID: id } })
      if (resp.success) {
        tmpSelectedSubscription = resp.data
        dispatch(setSelectedSubscription(resp.data))
      } else {
        alert(resp)
      }

      let activityResp = await getSubscriptionActivities({
        params: { activityIDs: tmpSelectedSubscription.activities }
      })
      if (activityResp.success) {
        setActivities(activityResp.data)
      } else {
        alert('There was a problem getting the activity list. Please try again')
      }

      if (user) {
        //get children
        let parentResp = await ParentAPI.getStudents({
          children: user.children
        })
        if (parentResp.success) {
          const createNewChild = {
            _id: 'create',
            info: { name: { fName: 'Add', lName: 'Roboteer' } }
          }
          let tmpChildren = parentResp.data
          tmpChildren.push(createNewChild)
          setChildren(tmpChildren)
        } else {
          alert('There was an error retrieving children')
        }
      }
      setLoading(false)
    }

    async function handleWorkshop() {
      let resp = await getProduct({ params: { id: id } })
      if (resp.success) {
        setCourse(resp.data)
      } else {
        alert(resp)
      }
      setLoading(false)
    }
    if (user.permissions?.[0] >= 3) {
      alert(
        'Direct links are only accessible by parent accounts. Please log out and try again. Press OK to be redirected to the home page.'
      )
      navigate('/')
    } else {
      if (type === 'membership') {
        handleMembership()
      } else {
        handleWorkshop()
      }
    }
  }, [])

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <div style={{ marginTop: '20px' }}>
          {type === 'membership' ? (
            <SelectDetails
              source={'link'}
              activities={activities}
              children={children}
              setChildren={setChildren}
            />
          ) : (
            <WorkshopLink course={course} />
          )}
        </div>
      )}
    </>
  )
}

export default DirectLink
