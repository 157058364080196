import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SecondaryNav from '../../component/util/secondaryNav'
import Instructors from '../../component/adminViews/staff/instructors'
import AddInstructor from '../../component/adminViews/staff/addInstructor'
import { getInstructors } from '../../api/admin'
import { setInstructors } from '../../store/adminSlice'

const AdminStaffContainer = () => {
  const dispatch = useDispatch()
  const [currTab, setCurrTab] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  let user = useSelector((state) => state.login.user)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getInstructors({ params: { uID: user._id } })
      if (resp.success) {
        dispatch(setInstructors(resp.data.instructors))
      } else {
        alert(resp)
      }
      setIsLoading(false)
    }
    handleAsync()
  }, [])

  const TABS = [
    {
      label: 'Instructors',
      content: <Instructors isLoading={isLoading} />
    },
    { label: 'Add Instructor', content: <AddInstructor /> }
  ]

  return (
    <>
      <SecondaryNav
        title='Staff'
        currTab={currTab}
        setCurrTab={setCurrTab}
        tabs={TABS}
      />
      {TABS[currTab].content}
    </>
  )
}

export default AdminStaffContainer
