import { Star, Info } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import React from 'react'

const ListPriorityToolTip = () => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#fff',
            boxShadow: 1
          }
        }
      }}
      title={
        <>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '3px'
            }}
          >
            <Info
              sx={{
                color: '#5c5c5c',
                fontSize: '15px',
                marginRight: '3px'
              }}
            />
            <Typography
              display='inline'
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '15px'
              }}
            >
              List Priority
            </Typography>
          </span>
          <Typography sx={{ fontSize: '14px' }}>
            This location will be prioritized in the locations list on the
            landing page. Locations are sorted by priority then by alphabetical
            order. Edit the location to change this.
          </Typography>
        </>
      }
    >
      <Star />
    </Tooltip>
  )
}

export default ListPriorityToolTip
