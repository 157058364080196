import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { Typography, useMediaQuery } from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import CartView from './cartView'
import LS from '../../utils/localStorage'
import CartApi from '../../api/cartAPI.js'
import CONSTANTS from '../../constants'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: theme.palette.background.paper,
    backgroundColor: CONSTANTS.RED_HEX
  }
}))

const CartContainer = ({ purchaseByAdmin }) => {
  const windowLocation = useLocation()
  const navigate = useNavigate()

  const cartInv = useSelector((state) => state.cart.products)
  const pID = useSelector((state) => state.login.user._id)
  const stripeCustID = useSelector(
    (state) => state.login.user?.paymentMeta?.stripe?.stripeID
  )
  const customerUN = useSelector((state) => state.login.user?.userName)

  const [numInCart, setNumInCart] = useState(0)
  const [showCart, setShowCart] = useState(false)
  const [total, setTotal] = useState(0)
  const [useAlternate, setUseAlternate] = useState(purchaseByAdmin || false)
  const [termsCheck, setTermsCheck] = useState(false)
  const [isCurrentPage, setIsCurrentPage] = useState(false)
  const [coupon, setCoupon] = useState('')
  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState('card')

  useEffect(() => {
    if (windowLocation.pathname === '/alternate-check-out') {
      setIsCurrentPage(true)
    } else {
      setIsCurrentPage(false)
    }
  }, [windowLocation])

  const [errors, setErrors] = useState({})
  const [achAuthorized, setAchAuthorized] = useState(true);

  const styles = {
    cart: {
      padding: '0px 10px',
      color: isCurrentPage ? '#fff' : null
    }
  }

  const updateTotal = (items) => {
    let tmp = 0
    items.forEach((item) => {
      tmp += item.priceInfo.unit_amount
    })
    setTotal(tmp / 100)
  }

  useEffect(() => {
    setNumInCart(cartInv.length)
    LS.setItem('cart', cartInv)
    updateTotal(cartInv)
    if (cartInv.length === 0) {
      setErrors({})
    }
  }, [cartInv])

  const handleAlternate = () => {
    setUseAlternate((prev) => !prev)
  }

  const handleCoupon = (e) => {
    setCoupon(e.target.value)
  }

  const handleErrors =() => {
    let tmp = {}

    cartInv.forEach((item) => {
      if (item.student === 'default') {
        tmp[item['cartID']] = 'defaultSelected'
        setLoading(false)
      } else if (
        cartInv.filter(
          (elem) => elem.student === item.student && elem.id === item.id
        ).length > 1
      ) {
        tmp[item['cartID']] = 'doubleStudent'
        setLoading(false)
      }
    })
    if(cartInv.length === 1 && new moment(cartInv[0].metadata.startDate).subtract(2, 'days') < new moment()) {
      setAchAuthorized(false)
    } else {
      setAchAuthorized(true)
    }
    return tmp;
  }
  const handleSubmit = async () => {
    setLoading(true)
    ReactGA.event({
      category: 'button',
      action: 'Cart Checkout'
    })

    ReactPixel.track('InitiateCheckout', {
      content_category: 'Cart_Checkout'
    })

    let errors = handleErrors()
    setErrors(errors)

    if (cartInv.length !== 0 && Object.keys(errors).length === 0) {
      if (useAlternate) {
        setShowCart(false)
        setLoading(false)
        navigate('/alternate-check-out')
      } else {
        let newTmp = {}
        // temp bug fix of passing the un instead of contact email, need to update DB
        let resp = await CartApi.createCheckout({
          cart: cartInv,
          p: { rID: pID, sID: stripeCustID, email: customerUN },
          coupon: coupon,
          paymentType: paymentType
        })
        if (resp.status === 'fail') {
          setLoading(false)
          if (resp.reason === 'invalidCoupon') {
            newTmp.coupon = true
          } else {
            cartInv.forEach((item) => {
              if (resp.taken.indexOf(item.id) > -1) {
                newTmp[item['cartID']] = 'capacityFull'
              } else if (
                resp.enrolled.filter((enroll) => enroll['id'] === item.id)[0]
              ) {
                newTmp[item['cartID']] = 'alreadyEnrolled'
              }
            })
          }
          setErrors(newTmp)
        } else {
          setLoading(false)
          setErrors({})
        }
      }
    }
  }

  const handleClick = () => {
    setShowCart(true)
  }

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const handleClose = () => {
    setShowCart(false)
    setCoupon('')
    setLoading(false)
    setTermsCheck(false)
  }

  return (
    <>
      <IconButton
        aria-label='cart'
        onClick={isCurrentPage ? null : handleClick}
        sx={{
          color: '#000',
          backgroundColor: isCurrentPage ? 'secondary.main' : null,
          borderRadius: '10px',
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: '10px'
          },
          '&:hover': {
            backgroundColor: isCurrentPage ? 'secondary.main' : null
          }
        }}
      >
        <StyledBadge
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          badgeContent={numInCart}
        >
          <ShoppingCartIcon
            sx={{ color: isCurrentPage ? '#fff' : 'secondary.main' }}
            fontSize='small'
          />
        </StyledBadge>
        {mobile ? (
          <></>
        ) : (
          <Typography variant='body1' sx={styles.cart}>
            Cart
          </Typography>
        )}
      </IconButton>

      {showCart ? (
        <CartView
          purchaseByAdmin={purchaseByAdmin}
          useAlternate={useAlternate}
          setTermsCheck={setTermsCheck}
          termsCheck={termsCheck}
          handleAlternate={handleAlternate}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          total={total}
          errors={errors}
          setErrors={setErrors}
          handleCoupon={handleCoupon}
          loading={loading}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          handleErrors={handleErrors}
          achAuthorized={achAuthorized}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default CartContainer
