import React from 'react'
import { createTheme, responsiveFontSizes } from '@mui/material'
import { ThemeContext } from '@emotion/react'

let rrTheme = createTheme()
rrTheme = createTheme(rrTheme, {
    palette: {
        type: 'light',
        primary: {
            main: '#c8102e',
            light: '#F04856',
        },
        secondary: {
            main: '#3596ce',
            light: '#d6eaf5',
            dark: '#0072ce',
            contrastText: '#fff',
        },
    },
    typography: {
        h1: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '2rem'
        },
        h2: {
            fontFamily: 'Montserrat',
            fontSize: '1.8rem',
            fontWeight: 600,
        },
        h3: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: 1.8
        },
        h4: {
            fontFamily: 'Montserrat',
            fontSize: '1.5rem',
            lineHeight: 1.8
        },
        h5: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1.3rem'
        },
        h6: {
            fontFamily: 'Montserrat',
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        subtitle1: {
            fontFamily: 'Montserrat',
            fontSize: '1.5rem',
            lineHeight: 1.36,
        },
        subtitle2: {
            fontFamily: 'Montserrat',
            fontSize: '1.1rem'
        },
        body1: {
            fontSize: '1rem',
            color: '#5c5c5c',
            lineHeight: 1.6
            // fontWeight: 600,
            // letterSpacing: '0.00938em'
        },
        body2: {
            fontSize: '1rem',
            color: '#5c5c5c',
            fontWeight: 500,
            lineHeight: 1.6
            // letterSpacing: '0.00938em'
        },
    },
    components: {
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: "#fff",
              elevation: '1',
              color: '#000'
            }
          }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    color: '#000',
                    height: '1rem'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontWeight: '400'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    m: '10px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    '&:hover': {
                        backgroundColor: '#b10e28',
                    }
                }
            }
        }
      }
})
rrTheme = responsiveFontSizes(rrTheme)

export default rrTheme
