import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  TextField
} from '@mui/material'
import { getMemberships, inviteToMembershipFromCourseCard } from '../api/admin'
import { useSelector } from 'react-redux'

const InviteModal = ({ handleClose, uID, invitedStudents }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [memberships, setMemberships] = useState([])
  const [selectedMembership, setSelectedMembership] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const adminEmail = useSelector((state) => state.login.user.userName)
  const [confirmInvite, setConfirmInvite] = useState(false)

  const handleSearchTerm = (value) => {
    setSearchTerm(value)
  }

  const handleSearch = async () => {
    setMemberships([])
    if (searchTerm !== '') {
      let resp = await getMemberships({
        searchTerm: searchTerm.trim(),
        uID: uID
      })
      if (resp.success) {
        setMemberships(resp.data)
      }
    }
  }

  const handleMembershipSelect = (membership) => {
    setSelectedMembership(membership)
    setShowConfirm(true)
  }

  const handleInvite = async () => {
    let resp = await inviteToMembershipFromCourseCard({
      studentIds: invitedStudents,
      membership: selectedMembership,
      uID: uID,
      adminEmail: adminEmail
    })
    if (resp.success) {
      setConfirmInvite(true)
    } else {
      alert(resp)
    }
  }

  const handleBack = () => {
    setShowConfirm(false)
  }

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <h4>Membership Search</h4>
      </DialogTitle>
      <DialogContent>
        {!showConfirm ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <TextField
                id='search'
                label='Membership Search'
                onChange={(e) => handleSearchTerm(e.target.value)}
                value={searchTerm}
                style={{ margin: '5px' }}
              ></TextField>
              <Button onClick={handleSearch}>Search</Button>
            </div>
            <div>
              {memberships.length > 0 ? (
                <div>
                  <Typography
                    varianth='body1'
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Select One Membership
                  </Typography>
                  {memberships.map((membership, index) => {
                    let membershipString = membership.name.split('-')
                    let membershipName = membershipString[1]
                    let locationName = membershipString[2]
                    return (
                      <div
                        key={index + membership.name}
                        style={{
                          marginBottom: '10px',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleMembershipSelect(membership)}
                      >
                        <div>
                          <span style={{ fontWeight: 'bold' }}>Name: </span>
                          {membershipName}
                        </div>
                        <div>
                          <span style={{ fontWeight: 'bold' }}>Location: </span>
                          {locationName}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            {!confirmInvite ? (
              <Typography variant='body1'>
                Invite {invitedStudents.length}{' '}
                {invitedStudents.length === 1 ? 'student' : 'students'} to{' '}
                {selectedMembership.name.split('-')[1]} at{' '}
                {selectedMembership.name.split('-')[2]}
              </Typography>
            ) : (
              <Typography variant='body1' style={{ textAlign: 'center' }}>
                Invite sent
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {showConfirm ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button onClick={handleBack}>Back</Button>
            {!confirmInvite ? (
              <Button onClick={handleInvite}>Invite</Button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default InviteModal
