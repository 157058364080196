import axios from 'axios'

export const authorize = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/authorize',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const unauthorize = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/unauthorize',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}
export const updateConfirmation = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/update-confirmation',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const getAllUnconfirmed = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/get-all-unconfirmed',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const getMoreCredits = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/get-more',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const getParentCredits = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/parent-credits',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const getCreditsByID = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/get-credits-by-ID',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const notifyAdmin = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/notify-admin',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}
