import { useState } from 'react'
import CancelSub from './cancelSub'
import SecondaryNav from '../../util/secondaryNav'
import { useNavigate } from 'react-router-dom'

const CancelView = () => {
  const navigate = useNavigate()
  const [currTab, setCurrTab] = useState(0)

  const handleBack = () => {
    navigate(-1)
  }

  const TABS = [
    {
      label: 'Cancel',
      content: <CancelSub close={handleBack} />
    }
  ]

  return (
    <>
      <SecondaryNav
        title={'Cancel Subscription'}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
        back={handleBack}
      />
      <>{TABS[currTab].content}</>
    </>
  )
}

export default CancelView
