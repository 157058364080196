import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress
} from '@mui/material'
import { setInstructors } from '../../../store/adminSlice'
import { removeInstructor } from '../../../api/admin'

const RemoveConfirm = ({ handleClose, open, instructor }) => {
  const dispatch = useDispatch()
  let user = useSelector((state) => state.login.user)
  const instructors = useSelector((state) => state.admin.instructors)

  const [loading, setLoading] = useState(false)

  const handleRemove = async (id) => {
    setLoading(true)
    let resp = await removeInstructor({ uID: user._id, instructorID: id })
    if (resp.success) {
      let tmpInstructors = [...instructors]
      let instructorIndex = tmpInstructors.findIndex(
        (element) => element._id === id
      )
      setLoading(false)
      tmpInstructors.splice(instructorIndex, 1)
      dispatch(setInstructors(tmpInstructors))
    } else {
      setLoading(false)
      alert(resp.data.error.message)
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        Remove instructor permission from {instructor?.info?.name?.fName}
      </DialogTitle>
      <DialogActions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={() => handleRemove(instructor._id)}>Remove</Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default RemoveConfirm
