import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const DeleteTagDialog = ({
  tag,
  student,
  showConfirmDeleteTag,
  handleCloseTagDelete,
  handleConfirm
}) => {
  return (
    <Dialog
      open={showConfirmDeleteTag}
      onClose={handleCloseTagDelete}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(240, 240, 240, 0.3)'
        },
        '& .MuiPaper-root': { boxShadow: 'none' }
      }}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        Remove Tag
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={() => {
            handleCloseTagDelete()
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Remove {student?.info?.name?.fName} {student?.info?.name?.lName} from{' '}
          {tag?.name}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Confirm</Button>
        <Button onClick={handleCloseTagDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteTagDialog
