import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Card,
  CardContent,
  CardActions
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import ageHandler from '../../utils/ageFormatting'
import { acceptInviteRequest, declineInviteRequest } from '../../api/admin'

const InviteRequestModal = ({ handleClose, requests, setRequests, uID }) => {
  const adminEmail = useSelector((state) => state.login.user.userName)

  const styles = {
    tempWeb: {},
    header: { fontWeight: 'bold', textAlign: 'left' },
    details: { textAlign: 'left' },
    button: { margin: '5px' }
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const handleAccept = async (request) => {
    let resp = await acceptInviteRequest({
      uID: uID,
      request: request,
      adminEmail: adminEmail
    })
    if (resp.success) {
      updateNotifications(request)
    } else {
      alert('There was an error responding to this request')
    }
  }

  const handleDecline = async (request) => {
    let resp = await declineInviteRequest({ uID: uID, request: request })
    if (resp.success) {
      updateNotifications(request._id)
    } else {
      alert('There was an error responding to this request')
    }
  }

  const updateNotifications = (requestID) => {
    if (requests.length === 1) {
      setRequests([])
    } else {
      let tmpArr = [...requests]

      tmpArr = tmpArr.filter((item) => item._id !== requestID)

      setRequests(tmpArr)
    }
  }

  return (
    <Dialog
      open
      fullWidth
      onClose={() => handleClose(false)}
      style={styles.tempWeb}
    >
      <DialogTitle>
        Requested Invitations
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={() => {
            handleClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <>
          {requests.length > 0 ? (
            <>
              {requests.map((request) => {
                const subscriptionName = request.subscription.name
                const childName = `${request.child.name.fName} ${request.child.name.lName}`
                const parentName = `${request.parent.name.fName} ${request.parent.name.lName}`
                return (
                  <Card variant='outlined' style={{ marginBottom: '5px' }}>
                    <CardContent>
                      <Typography style={styles.header}>
                        Subscription:
                      </Typography>
                      <Typography style={styles.details}>
                        {subscriptionName}
                      </Typography>
                      <Typography style={styles.header}>Location:</Typography>
                      <Typography style={styles.details}>
                        {request.location.locationName}
                      </Typography>
                      <Typography style={styles.header}>Student:</Typography>
                      <Typography style={styles.details}>
                        {childName}
                      </Typography>
                      <Typography style={styles.header}>Age: </Typography>
                      <Typography style={styles.details}>
                        {ageHandler.getAge(request.child.dob)}
                      </Typography>
                      <Typography style={styles.header}>
                        Requested By:{' '}
                      </Typography>
                      <Typography style={styles.details}>
                        {parentName}
                      </Typography>
                      <div>
                        <Button
                          variant='outlined'
                          style={styles.button}
                          onClick={() => {
                            handleAccept(request)
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant='outlined'
                          color='error'
                          style={styles.button}
                          onClick={() => {
                            handleDecline(request)
                          }}
                        >
                          Decline
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                )
              })}
            </>
          ) : (
            <Typography>There are no Invite Requests</Typography>
          )}
        </>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default InviteRequestModal
