import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoggedIn: false,
    user: {},
    defaultLocation: {},
    defaultRegion: {}
  },
  reducers: {
    loginState: (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.user = {}
    },
    setDefaultLocation: (state, action) => {
      state.defaultLocation = action.payload
    },
    setDefaultRegion: (state, action) => {
      state.defaultRegion = action.payload
    },
    updateUserChildren: (state, action) => {
      state.user.children.push(action.payload)
    },
    updateUser: (state, action) => {
      state.user = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { loginState, logout, setDefaultLocation, setDefaultRegion, updateUserChildren, updateUser } = loginSlice.actions

export default loginSlice.reducer
