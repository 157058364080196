import React from 'react'
import StatusAlert from '../util/statusAlert'


const AchPaymentStatus = ({
  displayStatus,
  sx,
  iconOnly
}) => {

  const Status = () => {
    let color
    let text
    let icon
    //TO DO: Update this to include all possible outcomes.
    switch (displayStatus) {
      case 'failed':
        color = 'red'
        text = 'ACH Payment was rejected'
        break
      case 'succeeded':
        color = 'blue'
        icon = 'success'
        text = 'ACH Payment has succeeded'
        break
      case 'requires_action':
        color = 'orange'
        text = 'ACH Requires Action'
        break
      default:
        color = 'orange'
        text = 'ACH Payment is pending'
        break

      //Add some kind of informational message here
    }
      return (
        <StatusAlert
          color={color}
          icon={icon}
          sx={{ ...sx }}
          iconOnly={iconOnly}
          text={text}
/>
      )
  }

  return (
    <>
    <Status />
    </>
  )
}

export default AchPaymentStatus
