import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Card, Typography, CardContent, TextField } from '@mui/material'
import { getMembershipReport, getAllSubscriptions } from '../../../api/admin'
import ReportDetails from './reportDetails'

const MembershipReport = () => {
  const uID = useSelector((state) => state.login.user._id)
  const [memberships, setMemberships] = useState([])
  const [filteredMemberships, setFilteredMemberships] = useState([])
  const [selectedMembership, setSelectedMembership] = useState(null)
  const [membershipData, setMembershipData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    async function handleAsync() {
      let resp = await getAllSubscriptions({ params: { uID: uID } })
      if (resp.success) {
        setMemberships(resp.data)
        setFilteredMemberships(resp.data)
      } else {
        alert('Error')
      }
    }
    handleAsync()
  }, [])

  const handleClick = async (membership) => {
    setLoading(true)
    setSelectedMembership(membership)
    let resp = await getMembershipReport({
      params: { uID: uID, productID: membership.productID }
    })
    if (resp.success) {
      setLoading(false)
      setMembershipData(resp.data.subs)
    } else {
      setLoading(false)
      alert('Error retrieving membership data')
    }
  }

  const handleSearch = (value) => {
    setSearchTerm(value)

    //Filter memberships
    let tmpMemberships = memberships.filter((membership) => {
      let tmpName = membership.name.toLowerCase()
      if (tmpName.includes(value.toLowerCase())) {
        return true
      } else {
        return false
      }
    })

    setFilteredMemberships(tmpMemberships)
  }

  return (
    <Grid container style={{ margin: '10px' }}>
      <Grid item xs={4}>
        <Typography variant='h6'>Memberships</Typography>
        <TextField
          variant='outlined'
          label='Search'
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {filteredMemberships.map((membership) => {
          return (
            <Card
              style={{ margin: '5px', cursor: 'pointer' }}
              onClick={() => handleClick(membership)}
              key={membership._id}
            >
              <CardContent>
                <Typography>{membership.name}</Typography>
                <Typography>{membership.locationName}</Typography>
              </CardContent>
            </Card>
          )
        })}
      </Grid>
      <Grid item xs={8}>
        {selectedMembership !== null ? (
          <ReportDetails
            membership={selectedMembership}
            data={membershipData}
            loading={loading}
          />
        ) : (
          <Typography>No Membership Selected</Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default MembershipReport
