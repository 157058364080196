import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useNavigate } from 'react-router-dom'

import validatePassword from '../utils/passwordValidation'
import { updatePassword } from '../api/login'

const PasswordResetRoute = ({ handleChange, handleClose, handleSubmit }) => {
  const [open, setOpen] = useState(true)
  const [newPass, setNewPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [token, setToken] = useState(null)
  const [hasErr, setHasErr] = useState(false)
  const [error, setError] = useState('')
  const [updated, setUpdated] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)

  const navigate = useNavigate()

  const handleState = async () => {
    setHasErr(false)
    if (newPass === confirmPass) {
      let check = validatePassword(newPass)
      if (check) {
        let reset = await updatePassword({ pw: newPass, code: token })
        if (reset.data.status === 'success') {
          setUpdated(true)
        } else {
          setHasErr(true)
          setError(reset.data.msg)
        }
      } else {
        setHasErr(true)
        setError('Password does not meet criteria')
      }
    } else {
      setHasErr(true)
      setError('Passwords Do not match')
    }
  }

  const close = () => {
    navigate('/login')
    // window.history.pushState({}, null, window.location.origin)
    setOpen(false)
  }

  useEffect(() => {
    if (token === null) {
      const queryString = window.location.search
      setToken(queryString.substring(3))
    }
  }, [])

  return (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title' style={{ display: 'flex', justifyContent: 'center' }}>
              Reset Password
            </DialogTitle>
            <DialogContent>
              {updated
                ? <p>Password has succesfully been updated!</p>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {hasErr
                    ? <p style={{ textAlign: 'center' }}>{error}</p>
                    : <></>
                  }
                  <p>Enter your new Password</p>
                  <TextField
                    id='outlined-password-input'
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      setNewPass(e.target.value)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => {
                              setShowPassword(!showPassword)
                            }}
                            onMouseDown={() => {
                              setShowPassword(!showPassword)
                            }}
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <p>Confirm</p>
                  <TextField
                    id='outlined-password-input'
                    type={showConfPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      setConfirmPass(e.target.value)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => {
                              setShowConfPassword(!showConfPassword)
                            }}
                            onMouseDown={() => {
                              setShowConfPassword(!showConfPassword)
                            }}
                          >
                            {showConfPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <p style={{maxWidth: '60%', margin: 'auto'}}>1 Capital, 1 Number, be 8 characters long and have a special character "~!@#$%^_&*-"</p>
                </div>
              }
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
              {updated
                ? <Button onClick={() => { close() }}>
                Continue
                </Button>
                : <Button onClick={() => { handleState() }}>
                Submit
                </Button>
              }
            </DialogActions>
          </Dialog>
        </>
  )
}

export default PasswordResetRoute
