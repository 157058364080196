import React from 'react';
import {Box, Typography} from '@mui/material'

const Section = (props) => {
    return (
      <Box
        sx={{
          padding: '30px 0px',
          width: '100vw',
          overflow: 'hidden',
          ...props.sx
        }}
      >
        {props.tagline ? (
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto auto',
              padding: '10px 0px'
            }}
          >
            <Typography
              sx={{ textTransform: 'uppercase', textAlign: 'center' }}
              variant='h5'
            >
              {props.tagline}
            </Typography>
          </Box>
        ) : null}
        {props.title ? (
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto auto',
              paddingTop: props.tagline ? null : '30px',
              zIndex: 10
            }}
          >
            <Typography
              variant='h1'
              sx={{
                textTransform: props.capitalCase ? null : 'uppercase',
                paddingBottom: '50px',
                textAlign: 'center',
                zIndex: 10
              }}
            >
              {props.title}
            </Typography>
          </Box>
        ) : null}
        {props.children}
      </Box>
    )
  }

export default Section;
