import React, { useState } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { Error, KeyboardArrowRight, TaskAlt } from '@mui/icons-material'
import { orange, red, lightBlue } from '@mui/material/colors'

const StatusAlert = (props) => {
  let iconOnly = props.iconOnly
  let bgColor
  let iconColor
  switch (props.color) {
    case 'orange':
      bgColor = orange
      iconColor = orange[900]
      break
    case 'blue':
      bgColor = lightBlue
      iconColor = lightBlue[900]
      break
    default:
      bgColor = red
      iconColor = red[900]
  }

  let icon
  switch (props.icon) {
    case 'error':
      icon = <Error sx={{ color: iconColor }} />
      break
    case 'success':
      icon = <TaskAlt sx={{ color: iconColor }} />
      break
    default:
      icon = <Error sx={{ color: iconColor }} />
  }

  const [hover, setHover] = useState(false)

  return (
    <Tooltip title={props.text} disableHoverListener={!iconOnly}>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: hover ? bgColor[100] : bgColor[50],
          transition: '0.2s',
          borderRadius: '5px',
          padding: '3px',
          cursor: hover ? 'pointer' : null,
          ...props.sx
        }}
        onMouseEnter={() => (props.onClick ? setHover(true) : null)}
        onMouseLeave={() => (props.onClick ? setHover(false) : null)}
        onClick={props.onClick}
      >
        {icon}
        {iconOnly ? null : (
          <>
            <Typography
              sx={{
                color: iconColor,
                marginLeft: '3px',
                flexGrow: 1,
                fontSize: '14px'
              }}
            >
              {props.children || props.text}
            </Typography>
            {props.onClick ? (
              <KeyboardArrowRight
                sx={{
                  color: iconColor,
                  transition: '0.2s',
                  marginRight: hover ? '0px' : '5px'
                }}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </Tooltip>
  )
}

export default StatusAlert
