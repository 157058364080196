import React from 'react'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputMask from 'react-input-mask'
import ModelHandler from '../../../models/modelHandler'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Add, Delete } from '@mui/icons-material'

const EditUserInfo = ({
  handleClose,
  handleNext,
  // handleChange,
  data,
  // addContactInfo,
  // deleteContact,
  setEditInfo
}) => {
  const styles = {
    tempWeb: {}
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const [error, setError] = React.useState([])

  const handleChange = (type, value, meta, index) => {
    if (meta === 'misc') {
      setEditInfo((prevState) => ({
        ...prevState,
        misc: {
          ...prevState.misc,
          [type]: value
        }
      }))
    } else if (meta === 'dob') {
      setEditInfo((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          dob: value
        }
      }))
    } else if (meta === 'pNotes' || meta === 'iNotes') {
      setEditInfo((prevState) => ({
        ...prevState,
        [type]: value
      }))
    } else if (meta === 'alt') {
      setEditInfo((prevState) => {
        let tmp = JSON.parse(JSON.stringify({ ...prevState }))
        tmp.info.contact.alt[index][type] = value
        return tmp
      })
    } else {
      setEditInfo((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          [meta]: {
            ...prevState.info[meta],
            [type]: value
          }
        }
      }))
    }
  }

  const addContactInfo = () => {
    setEditInfo((prevState) => {
      if (prevState.info.contact.alt) {
        let tmp = [...prevState.info.contact.alt]
        tmp.push({})
        return {
          ...prevState,
          info: {
            ...prevState.info,
            contact: { ...prevState.info.contact, alt: tmp }
          }
        }
      } else {
        return {
          ...prevState,
          info: {
            ...prevState.info,
            contact: { ...prevState.info.contact, alt: [{}] }
          }
        }
      }
    })
  }

  const deleteContact = (index) => {
    setEditInfo((prevState) => {
      let tmp = [...prevState.info.contact.alt]
      tmp.splice(index, 1)
      return {
        ...prevState,
        info: {
          ...prevState.info,
          contact: { ...prevState.info.contact, alt: tmp }
        }
      }
    })
  }

  const next = () => {
    let tmp = {
      fName: data.info.name.fName,
      lName: data.info.name.lName,
      email: data.info.contact.email,
      lineOne: data.info.address.lineOne,
      city: data.info.address.city,
      postal: data.info.address.postal,
      country: data.info.address.country,
      phone: data.info.contact.phone,
      state: data.info.address.state,
      alt: data.info.contact.alt
    }
    let errors = ModelHandler.checkParentData(tmp, true)
    if (errors.length >= 1) {
      setError(errors)
    } else {
      handleNext(data)
    }
  }

  const PARENT_FIELDS = [
    {
      type: 'fName',
      key: 'fName',
      meta: 'name',
      label: 'First Name',
      value: data.info.name.fName,
      isRequired: true
    },
    {
      type: 'lName',
      key: 'lName',
      meta: 'name',
      label: 'Last Name',
      value: data.info.name.lName,
      isRequired: true
    },
    {
      type: 'mName',
      key: 'mName',
      meta: 'name',
      label: 'Middle Name',
      value: data.info.name.mName,
      isRequired: false
    },
    {
      type: 'email',
      key: 'email',
      meta: 'contact',
      label: 'Email',
      value: data.info.contact.email,
      isRequired: true
    },
    {
      type: 'phone',
      key: 'phone',
      meta: 'contact',
      label: 'Phone',
      value: data.info.contact.phone,
      isRequired: true
    },
    {
      type: 'lineOne',
      key: 'lineOne',
      label: 'Street',
      meta: 'address',
      value: data.info.address.lineOne,
      isRequired: true
    },
    {
      type: 'lineTwo',
      key: 'lineTwo',
      meta: 'address',
      label: 'Street Line Two',
      value: data.info.address.lineTwo,
      isRequired: false
    },
    {
      type: 'city',
      key: 'city',
      meta: 'address',
      label: 'City',
      value: data.info.address.city,
      isRequired: true
    },
    {
      type: 'state',
      key: 'state',
      meta: 'address',
      label: 'State',
      value: data.info.address.state,
      isRequired: true
    },
    {
      type: 'postal',
      key: 'postal',
      meta: 'address',
      label: 'Postal',
      value: data.info.address.postal,
      isRequired: true
    },
    {
      type: 'country',
      key: 'country',
      meta: 'address',
      label: 'Country',
      value: data.info.address.country,
      isRequired: true
    }
  ]

  const SECONDARY_FIELDS = [
    {
      type: 'fName',
      key: 'secondaryFName',
      label: 'First Name',
      // isRequired: true,
      meta: 'alt'
    },
    {
      type: 'lName',
      key: 'secondaryLName',
      label: 'Last Name',
      // isRequired: true,
      meta: 'alt'
    },
    {
      type: 'phone',
      key: 'phone',
      label: 'Secondary Phone',
      // isRequired: true,
      meta: 'alt'
    },
    {
      type: 'email',
      key: 'email',
      label: 'Secondary Email',
      // isRequired: true,
      meta: 'alt'
    }
  ]

  const standardField = (item) => {
    return (
      <TextField
        required={item.isRequired}
        margin='dense'
        id={item.type}
        key={item.key}
        error={
          error.includes(item.type) ||
          error.includes(`${item.type}-${item.index}`)
        }
        label={item.label}
        type={item.type}
        defaultValue={item.value}
        fullWidth
        onChange={(e) => {
          if (typeof item.index) {
            handleChange(item.type, e.target.value, item.meta, item.index)
          } else {
            handleChange(item.key, e.target.value, item.meta)
          }
        }}
        variant='standard'
      />
    )
  }

  const phoneField = (item) => {
    return (
      <InputMask
        mask='(999)999-9999'
        onChange={(e) => {
          if (typeof item.index) {
            handleChange(item.key, e.target.value, item.meta, item.index)
          } else {
            handleChange(item.key, e.target.value, item.meta)
          }
        }}
        defaultValue={item.value}
      >
        {() => (
          <TextField
            required={item.isRequired}
            margin='dense'
            fullWidth
            error={
              error.includes(item.type) ||
              error.includes(`${item.type}-${item.index}`)
            }
            id={item.type}
            key={item.key}
            label={item.label}
            type={item.type}
            variant='standard'
          />
        )}
      </InputMask>
    )
  }

  return (
    <React.Fragment>
      <Dialog
        open
        onClose={() => {
          handleClose()
        }}
        // style={styles.tempWeb}
      >
        <DialogTitle>
          Edit information
          <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {PARENT_FIELDS.map((item) => {
            if (item.type === 'phone') {
              return phoneField(item)
            } else {
              return standardField(item)
            }
          })}
          {data.info.contact.alt?.length > 0 ? (
            <>
              {data.info.contact.alt.map((alt, index) => {
                return (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px'
                      }}
                    >
                      <Typography display='inline' variant='h6'>
                        Secondary Contact {index + 1}
                      </Typography>
                      <IconButton
                        size='small'
                        onClick={() => deleteContact(index)}
                      >
                        <Delete />
                      </IconButton>
                    </span>
                    {SECONDARY_FIELDS.map((item) => {
                      if (item.type === 'phone') {
                        return phoneField({
                          ...item,
                          index: index,
                          value: data.info.contact.alt[index].phone
                        })
                      } else {
                        return standardField({
                          ...item,
                          index: index,
                          value: data.info.contact.alt[index][item.type]
                        })
                      }
                    })}
                  </React.Fragment>
                )
              })}
            </>
          ) : (
            <></>
          )}
          <Button onClick={() => addContactInfo()} startIcon={<Add />}>
            Add additional contact information
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              next(data)
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default EditUserInfo
