import React from 'react'
import { useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'

const Filter = ({ options, type, handleFilter }) => {
  const filter = useSelector((state) => state.admin.emailFilter)
  return (
    <React.Fragment key={type}>
      <Grid item xs={6} sx={{ padding: '5px 5px' }}>
        <FormControl fullWidth>
          <InputLabel id={`${type}-select-label`}>{type}</InputLabel>
          <Select
            value={filter[type]}
            onChange={(e) => handleFilter(e.target.value, type)}
            labelId={`${type}-select-label`}
            label={type}
          >
            {options.length === 0 ? <MenuItem disabled>None</MenuItem> : null}
            {options.map((option, index) => {
              let name
              let value
              if (type === 'Activity') {
                name = `${option.instance.day} ${option.course.name} ${option.instance.startTime} - ${option.instance.endTime}`
                value = option
              } else if (type === 'Tag') {
                name = option.name
                value = option
              } else if (type === 'Season') {
                name = option.name
                value = option.id
              } else {
                name = option.name
                value = option
              }
              return (
                <MenuItem value={value} key={`${name}-${index}`}>
                  {name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  )
}

export default Filter
