import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Paper,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions
} from '@mui/material'
import { searchUsers, createInstructor } from '../../../api/admin'
import { setInstructors } from '../../../store/adminSlice'

const AddInstructor = () => {
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [users, setUsers] = useState([])
  const [error, setError] = useState(null)
  const [confirmMessage, setConfirmMessage] = useState(false)
  const uID = useSelector((state) => state.login.user._id)
  const instructors = useSelector((state) => state.admin.instructors)

  const handleFirstNameChange = (value) => {
    setFirstName(value)
    setConfirmMessage(false)
  }

  const handleLastNameChange = (value) => {
    setLastName(value)
    setConfirmMessage(false)
  }

  const handleSearch = async () => {
    let resp = await searchUsers({
      fName: firstName.trim(),
      lName: lastName.trim(),
      uID: uID
    })
    if (resp.success) {
      if (resp.data !== '') {
        setUsers(resp.data)
        setError('')
      } else {
        setError('No users found')
      }
      setFirstName('')
      setLastName('')
    } else {
      setError('An error occurred while searching. Please try again')
    }
  }

  const handleAdd = async (user) => {
    let resp = await createInstructor({ uID: uID, instructorID: user._id })
    if (resp.success) {
      let tmpInstructors = [...instructors]
      tmpInstructors.push(user)
      dispatch(setInstructors(tmpInstructors))
      setConfirmMessage(true)
    } else {
      alert(resp)
    }
  }

  const handleClose = () => {
    setConfirmMessage(false)
  }

  const handleClear = () => {
    setUsers([])
  }

  return (
    <Paper sx={{ margin: '0px auto 100px auto', width: '90%' }}>
      <div style={{ margin: '5px', display: 'flex', justifyContent: 'center' }}>
        <TextField
          id='firstName'
          label='First Name'
          onChange={(e) => handleFirstNameChange(e.target.value)}
          value={firstName}
          style={{ margin: '5px' }}
        ></TextField>
        <TextField
          id='lastName'
          label='Last Name'
          onChange={(e) => handleLastNameChange(e.target.value)}
          value={lastName}
          style={{ margin: '5px' }}
        ></TextField>
        {error ? (
          <Typography style={{ color: 'red' }}>{error}</Typography>
        ) : (
          <></>
        )}
        <Button
          onClick={handleSearch}
          style={{
            color: '#FFFFFF',
            backgroundColor: '#C80F2D',
            height: '40px',
            margin: 'auto 0'
          }}
        >
          Search
        </Button>
      </div>

      <div>
        {users.length > 0 ? (
          <div>
            <Typography variant='h6'>Select Instructors</Typography>
            {users.map((user) => {
              return (
                <div
                  key={user._id}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      key={user._id + user.info.name.fName}
                      style={{ marginRight: '10px' }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Name: </span>
                      {`${user.info.name.fName} ${user.info.name.lName}`}
                    </div>
                    <div key={user._id + user.userName}>
                      <span style={{ fontWeight: 'bold' }}>Email: </span>
                      {user.userName}
                    </div>
                    <Button
                      size='small'
                      style={{
                        color: '#FFFFFF',
                        backgroundColor: '#C80F2D',
                        margin: '5px'
                      }}
                      onClick={() => {
                        handleAdd(user)
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <></>
        )}
      </div>

      {users.length > 0 ? <Button onClick={handleClear}>Clear</Button> : <></>}

      <Dialog
        open={confirmMessage}
        onClose={handleClose}
        style={{ margin: 'auto' }}
      >
        <DialogTitle>Instructor Added</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='contained'
            style={{ width: '100px', margin: 'auto' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default AddInstructor
