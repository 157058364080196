import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Button,
  Typography,
  IconButton
} from '@mui/material'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

const EmailList = ({ emails, open, close }) => {
  const [copied, setCopied] = useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(emails.toString())
    setCopied(true)
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        Emails{' '}
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={() => {
            close()
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!copied ? (
          <Button onClick={handleClick}>Copy to Clipboard</Button>
        ) : (
          <Typography>Done!</Typography>
        )}

        <List>
          {emails.map((email) => {
            return <ListItem>{email}</ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default EmailList
