import React, { useState } from 'react'
import { Info } from '@mui/icons-material'
import { ClickAwayListener, Tooltip, Typography } from '@mui/material'

const AlternatePaymentToolTip = () => {
  const [showToolTip, setShowToolTip] = useState(false)
  return (
    <>
      <ClickAwayListener onClickAway={() => setShowToolTip(false)}>
        <Tooltip
          onClose={() => setShowToolTip(false)}
          open={showToolTip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                boxShadow: 1
              }
            }
          }}
          title={
            <>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '3px'
                }}
              >
                <Info
                  sx={{
                    color: '#5c5c5c',
                    fontSize: '15px',
                    marginRight: '3px'
                  }}
                />
                <Typography
                  display='inline'
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: '15px'
                  }}
                >
                  Alternate Payment Info
                </Typography>
              </span>
              <Typography sx={{ fontSize: '14px' }}>
                Select this option if you are:
              </Typography>
              <ul
                style={{
                  margin: '0px 0px',
                  paddingLeft: '20px',
                  color: '#5c5c5c',
                  fontSize: '14px'
                }}
              >
                <li>Enrolling a homeschool student</li>
                <li>Paying with check</li>
              </ul>
            </>
          }
        >
          <Info
            onClick={() => {
              setShowToolTip(true)
            }}
            sx={{
              color: '#5c5c5c',
              fontSize: '15px',
              margin: '0px 5px',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          />
        </Tooltip>
      </ClickAwayListener>
    </>
  )
}

export default AlternatePaymentToolTip
