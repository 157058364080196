import React, { useState, useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import Section from './section'

const HolidayCampsSection = ({ styles, locations }) => {
  const PARAGRAPH = '20px'
  const [filteredLocations, setfilteredLocations] = useState([])
  useEffect(() => {
    let mounted = true

    async function handleAsync() {
      let tmp = locations.filter((loc) =>
        loc.workshopSeasons.includes('holiday')
      )
      setfilteredLocations(tmp)
    }
    if (mounted && locations?.length > 0) {
      handleAsync()
    }
    return () => {
      mounted = false
    }
  }, [locations])

  const handleLocationNav = (location) => {
    let urlParam = location.name.toLowerCase().replaceAll(' ', '-')
    window.open('/holiday-camps/' + urlParam)
  }
  return (
    <Section sx={{ minHeight: '60vh', backgroundColor: styles.darkBlue }}>
      {' '}
      <Typography
        variant='h1'
        sx={{
          textTransform: 'uppercase',
          paddingBottom: '20px',
          textAlign: 'center',
          zIndex: 10,
          color: 'white'
        }}
      >
        Holiday Camps
      </Typography>
      <Box sx={{ ...styles.box }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '100%', md: '50% auto' },
            gridGap: {xs: '0px', md: '30px'},
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <picture style={{ marginBottom: '20px' }}>
            <source
              type='image/webp'
              srcset={require('../../images/Holiday_Camps.webp')}
              media='(min-width:600px)'
            />
            <Box
              sx={{
                backgroundImage: `url(${require('../../images/Holiday_Camps.png')})`,
                backgroundSize: 'cover',
                backgroundPositionX: 'center',
                WebkitBoxShadow: `inset 0 0 0 6px ${styles.lightBlue}`,
                boxShadow: `inset 0 0 0 6px ${styles.lightBlue}`,
                borderRadius: '20px',
                minHeight: { xs: '500px', md: '400px' },
                gridRowStart: { xs: 1, md: null },
                gridColumn: { xs: '1/3', md: '1/2' }
              }}
            />
          </picture>
          <Box sx={{ gridColumn: { xs: '1/3', md: '2/3' } }}>
            <Typography sx={{ marginBottom: PARAGRAPH, color: 'white' }}>
              Rolling Robots Holiday Robotics Camps will help your child learn
              robotics and coding in a fun way during their holiday break times.
            </Typography>
            <Typography sx={{ marginBottom: PARAGRAPH, color: 'white' }}>
              Our holiday Camps offer our most popular camps such as Jr. Robot
              Builders, 3D Printing for Holiday Gift, Battle Bots, Python, and
              C++ Coding.
            </Typography>
            <Typography sx={{ marginBottom: PARAGRAPH, color: 'white' }}>
              <ul style={{ listStyleType: 'none' }}>
                <li>November 25 - 27</li>
                <li>December 23, 26, 27</li>
                <li>December 30, January 2, 3</li>
              </ul>
            </Typography>
            <Typography
              sx={{
                marginBottom: PARAGRAPH,
                fontStyle: 'italic',
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: 'white'
              }}
            >
              Don't wait! Let's set your child on the path to tech genius today!
            </Typography>
            <Typography sx={{ marginBottom: PARAGRAPH, color: 'white' }}>
              Select a location below to view camp availability.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-evenly',
            margin: { xs: '40px 0px', sm: '0px 0px' }
          }}
        >
          {filteredLocations.length > 0 ? (
            <>
              {filteredLocations.map((loc) => (
                <Button
                  sx={{
                    backgroundColor: styles.lightBlue,
                    color: 'black',
                    marginBottom: {xs: '20px', sm: '0px'}
                  }}
                  onClick={() => handleLocationNav(loc)}
                >
                  {loc.name}
                </Button>
              ))}
            </>
          ) : (
            <>Loading...</>
          )}
        </Box>
      </Box>
    </Section>
  )
}

export default HolidayCampsSection
