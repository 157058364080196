import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { clearCart, removeProduct } from '../../store/cartSlice'
import { useSelector, useDispatch } from 'react-redux'
import { assignStudent } from '../../store/cartSlice'
import cards from '../util/cards'
import CartApi from '../../api/cartAPI'
import CartCard from './cartCard'
import { useNavigate } from 'react-router-dom'

const AlternateCheckOut = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cartInv = useSelector((state) => state.cart.products)
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const students = useSelector((state) => state.parent.students)
  const loggedInUser = useSelector((state) => state.login.user)
  const permission = useSelector((state) => state.login.user.permissions[0])
  let user = useSelector((state) => {
    if (
      state.login.user.permissions &&
      state.login?.user?.permissions[0] === 4
    ) {
      return state.user.selectedUser
    } else {
      return state.login.user
    }
  })

  const handleRemove = (workshop) => {
    if (cartInv.length == 1) {
      if (
        window.confirm(
          'Are you sure you want to remove this workshop from your cart? Your cart will be empty and you will be redirected to the summer camp page.'
        )
      ) {
        dispatch(removeProduct(workshop))
        navigate('/summer-camps')
      }
    } else {
      dispatch(removeProduct(workshop))
    }
  }

  const BlankCard = cards.blankCard

  const [alternatePaymentType, setAlternatePaymentType] = useState('')
  const [alternatePaymentDetails, setAlternatePaymentDetails] = useState({})
  const [note, setNote] = useState('')
  const [errors, setErrors] = useState({})
  const [isAdmin, setIsAdmin] = useState(false)

  //Checks if person checking out is admin or not
  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (loggedInUser.permissions[0] === 4) {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    }
    return () => (mounted = false)
  }, [loggedInUser])

  const handleSelectStudent = (student, product) => {
    dispatch(assignStudent({ student, product }))
  }

  const checkEnrollmentFields = () => {
    let check = true
    if (isLoggedIn) {
      if (alternatePaymentType == 'free') {
        /* If free option is selected, check whether the total is actually zero
        Non-zero totals will be prohibited from checking out
        The function alternatePaymentFields will show an error message.
        */
        if (total() > 0) {
          check = false
        }
      } else {
        if (typeof selectedChild == 'string') {
          check = false
        }
        if (alternatePaymentType === '') {
          check = false
        } else {
          FIELDS.forEach((field) => {
            if (!alternatePaymentDetails[field.id]) {
              check = false
            }
          })
        }
      }
    } else {
      check = false
    }
    return check
  }

  const FIELDS = [{ label: 'Payment ID', type: 'text', id: 'paymentID' }]

  const alternatePaymentFields = () => {
    if (alternatePaymentType !== '') {
      let paymentIDLabel = 'PO Number'
      if (alternatePaymentType == 'check') {
        paymentIDLabel = 'Check Number'
      }
      //If 'free' is selected, check whether or not the products are actually free
      if (alternatePaymentType == 'free') {
        if (total() > 0) {
          return (
            <Box>
              The workshops selected are not available for free checkout. Please
              remove any non-$0 workshops to proceed.
            </Box>
          )
        }
      } else {
        return (
          <>
            <Typography variant='h4'>Payment Details</Typography>
            <BlankCard>
              {FIELDS.map((item) => (
                <TextField
                  sx={{ marginTop: '10px' }}
                  fullWidth
                  key={item.id}
                  id={item.id}
                  label={item.id == 'paymentID' ? paymentIDLabel : item.label}
                  type={item.type}
                  variant='outlined'
                  InputLabelProps={
                    item.type === 'date' ? { shrink: true } : null
                  }
                  onChange={(e) =>
                    setAlternatePaymentDetails((prev) => {
                      let tmp = { ...prev }
                      tmp[item.id] = e.target.value
                      return tmp
                    })
                  }
                />
              ))}
            </BlankCard>
            <Typography variant='h4'>Additional Information</Typography>
            <BlankCard>
              {isAdmin ? (
                <Typography>
                  Leave an optional note to go along with the transaction.
                </Typography>
              ) : (
                <Typography variant='body2'>
                  If you need to add more details to your order, leave a short
                  note in the box below.
                </Typography>
              )}
              <TextField
                fullWidth
                placeholder='Note'
                multiline
                minRows={2}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></TextField>
            </BlankCard>
          </>
        )
      }
    } else {
      return <></>
    }
  }

  //Handle issues with enrolling student in course
  const handleError = (data) => {
    let tmp = {}
    cartInv.forEach((cartItem) => {
      if (data.taken.indexOf(cartItem.id) > -1) {
        tmp[cartItem['cartID']] = 'capacityFull'
      } else if (
        data.enrolled.filter((enroll) => enroll['id'] == cartItem.id)[0]
      ) {
        tmp[cartItem['cartID']] = 'alreadyEnrolled'
      }
    })
    setErrors(tmp)
  }

  const handleSubmit = async () => {
    // ReactGA.event({
    //   category: 'button',
    //   action: 'Cart Checkout'
    // })

    // ReactPixel.track('InitiateCheckout', {
    //   content_category: 'Cart_Checkout'
    // })

    let tmp = {}

    cartInv.forEach((item) => {
      if (item.student === 'default') {
        tmp[item['cartID']] = 'defaultSelected'
      } else if (
        cartInv.filter(
          (elem) => elem.student === item.student && elem.id === item.id
        ).length > 1
      ) {
        tmp[item['cartID']] = 'doubleStudent'
      }
    })

    setErrors(tmp)
    if (cartInv.length !== 0 && Object.keys(tmp).length === 0) {
      //Free workshops will use Stripe information to checkout
      let stripeCustID = user.paymentMeta?.stripe?.stripeID
      let pID = user._id
      let customerUN = user.userName
      let resp =
        alternatePaymentType !== 'free'
          ? await CartApi.createAltCheckout({
              cart: cartInv,
              paymentType: alternatePaymentType,
              details: alternatePaymentDetails,
              parentID: user._id,
              note: note,
              isAdmin: isAdmin
            })
          : await CartApi.createCheckout({
              cart: cartInv,
              p: { rID: pID, sID: stripeCustID, email: customerUN }
            })
      if (resp.status === 'fail') {
        handleError(resp)
      } else {
        dispatch(clearCart())
        setErrors({})
        /*Alternate checkout will return a checkout success url.
        Normal checkout (for free workshops) will redirect to a Stripe session
        */
        if (resp.url) {
          window.location = resp.url
        }
      }
    }
  }
  const total = () => {
    let tmp = 0
    cartInv.forEach((item) => {
      tmp += item.priceInfo.unit_amount
    })
    return tmp / 100
  }

  return (
    <Box
      sx={{
        width: '80%',
        margin: 'auto auto 100px auto',
        display: 'grid',
        gridTemplateColumns: '50% auto',
        gridGap: '20px'
      }}
    >
      <Box>
        {cartInv.map((course, index) => {
          return (
            <div style={{ marginBottom: '10px' }} key={`${course.id}_${index}`}>
              <CartCard
                children={students}
                workshop={course}
                handleSelectStudent={handleSelectStudent}
                handleRemove={handleRemove}
                errors={errors?.[course['cartID']]}
              />
            </div>
          )
        })}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px'
          }}
        >
          <Typography variant='h6'>
            Total: <b>${total()}</b>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant={'h4'} sx={{ paddingTop: '10px' }}>
          Select Alternate Payment Type
        </Typography>
        <BlankCard>
          <FormControl fullWidth>
            <InputLabel>Select an option</InputLabel>
            <Select
              label='Select an option'
              value={alternatePaymentType}
              onChange={(e) => setAlternatePaymentType(e.target.value)}
            >
              <MenuItem value='homeschool-blue-ridge'>Blue Ridge Academy</MenuItem>
              <MenuItem value='homeschool-suncoast'>Suncoast</MenuItem>
              <MenuItem value='check'>Check</MenuItem>
              {permission === 4 ? <MenuItem value='free'>Free</MenuItem> : null}
            </Select>
          </FormControl>
        </BlankCard>
        {alternatePaymentFields()}
        {checkEnrollmentFields() ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant='contained' onClick={() => handleSubmit()}>
                Place Order
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default AlternateCheckOut
