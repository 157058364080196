import * as React from 'react'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

const LessonSelector = ({ handleChange, lessons, addLesson, options, errors }) => {
  const styles = {
    helpText: {
      color: '#d32f2f',
      fontSize: 12,
    }
  }

  const selectField = (item, index) => {
    return (
      <FormControl fullWidth>
        <Select
          value={lessons[index]}
          label={item.label}
          onChange={(e) => { handleChange(index, e.target.value) }}
          error={errors.includes('lessons')}
        >
          {options.map((option) => {
            return (
              <MenuItem value={option._id} key={option._id}>
                {option.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  return (
    <div>
        {lessons ? Object.keys(lessons).map((item, index) => {
          return (
            selectField(item, index + 1)
          )
        }) : <></>}
        {errors.includes('lessons') ? <div style={styles.helpText}>Custom lessons cannot be blank</div> : <></>}
      <Button onClick={() => { addLesson(lessons) }}>Add Lesson</Button>
    </div>
  )
}

export default LessonSelector
