
import React, { useState } from 'react'
import { TextField, FormGroup, Button } from '@mui/material'
const MailChimpInput = () => {
  const [emailValue, setEmailValue] = useState('')

  return (
    <form
      action='https://rollingrobots.us7.list-manage.com/subscribe/post?u=7cc0604a55abc294835f2b77c&amp;id=9b4dc2375e&amp;f_id=00dac2e1f0'
      method='POST'
      id='mc-embedded-subscribe-form'
    >
      <input type='hidden' name='u' value='7cc0604a55abc294835f2b77c' readOnly={true}></input>
      <input type='hidden' name='id' value='9b4dc2375e' readOnly={true}></input>
      <div hidden={true}>
        <input type='hidden' name='tags' value='15317' readOnly={true} />
      </div>
      <div id='mce-responses'>
        <div id='mce-error-response' style={{ display: 'none' }}></div>
        <div id='mce-success-response' style={{ display: 'none' }}></div>
      </div>
      {/*  real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div
        className='field-shift'
        aria-label='Please leave the following three fields empty'
        aria-hidden='true'
        style={{position: 'absolute', left: '-5000px'}}
      >
        <label htmlFor='b_name'>Name: </label>
        <input
          type='text'
          name='b_name'
          tabIndex='-1'
          value=''
          readOnly={true}
          placeholder='Freddie'
          id='b_name'
        />

        <label htmlFor='b_email'>Email: </label>
        <input
          type='email'
          name='b_email'
          tabIndex='-1'
          value=''
          readOnly={true}
          placeholder='youremail@gmail.com'
          id='b_email'
        />

        <label htmlFor='b_comment'>Comment: </label>
        <textarea
          name='b_comment'
          tabIndex='-1'
          placeholder='Please comment'
          id='b_comment'
        ></textarea>
      </div>
      <FormGroup>
        <TextField
          type='email'
          name='EMAIL'
          id='mce-EMAIL'
          value={emailValue}
          onChange={(e) => {
            setEmailValue(e.target.value)
          }}
          placeholder='Enter Your Email'
        />

        <Button
          sx={{ width: '120px', marginTop: '10px' }}
          type='submit'
          variant='contained'
          value='Subscribe'
          name='subscribe'
          id='mc-embedded-subscribe'
        >
          Submit
        </Button>
      </FormGroup>
    </form>
  )
}

export default MailChimpInput

