import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSelectedLocation,
  setEdit,
  // updateLoc,
  addLocation
} from '../../../store/locationSlice'
import { updateLoc } from '../../../store/userSlice'
import {
  Grid,
  TextField,
  Typography,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Switch,
  FormControlLabel,
  FormHelperText
} from '@mui/material'
import { getAdmins, updateLocation, createLocation } from '../../../api/admin'
import cards from '../../util/cards'
import LoadingComp from '../../util/loading'
import CONSTANTS from '../../../constants'

const EditLocation = ({ setCurrTab }) => {
  const dispatch = useDispatch()
  const BlankCard = cards.blankCard

  const location = useSelector((state) => state.location.selectedLocation)
  const isEdit = useSelector((state) => state.location.edit)
  const uID = useSelector((state) => state.login.user._id)
  const regions = useSelector((state) => state.admin.regions)

  const [loading, setLoading] = useState(true)
  const [admins, setAdmins] = useState([])

  useEffect(() => {
    async function handleAsync() {
      let resp = await getAdmins({ params: { uID: uID } })
      if (resp.success) {
        setAdmins(resp.data)
      } else {
        alert(resp)
      }
      setLoading(false)
    }
    handleAsync()
  }, [])

  const LOCATION_FIELDS_ADDRESS = [
    {
      id: 'lineOne',
      label: 'Line One',
      type: 'text'
    },
    {
      id: 'lineTwo',
      label: 'Line Two',
      type: 'text'
    },
    {
      id: 'city',
      label: 'City',
      type: 'text'
    },
    {
      id: 'state',
      label: 'State',
      type: 'text'
    },
    {
      id: 'postal',
      label: 'Postal',
      type: 'text'
    },
    {
      id: 'country',
      label: 'Country',
      type: 'text'
    }
  ]

  const LOCATION_FIELDS_OTHER = [
    {
      id: 'name',
      label: 'Location Name',
      type: 'text'
    },
    {
      id: 'email',
      label: 'Email',
      type: 'text'
    },
    {
      id: 'phone',
      label: 'Phone',
      type: 'text'
    },
    {
      id: 'manager',
      label: 'Manager',
      type: 'text'
    },
    {
      id: 'info',
      label: 'Info',
      type: 'text',
      fullWidth: true
    },
    {
      id: 'workshop',
      label: 'Workshop Seasons',
      type: 'array',
      fullWidth: true
    },
    { id: 'region', label: 'Region', type: 'text', fullWidth: true }
  ]

  let initialAddressValues = null
  let initialOtherValues = null

  if (isEdit) {
    initialAddressValues = {
      lineOne: location.address.lineOne,
      lineTwo: location.address.lineTwo,
      city: location.address.city,
      postal: location.address.postal,
      state: location.address.state,
      country: location.address.country
    }
    initialOtherValues = {
      name: location.name,
      email: location.email,
      phone: location.phone,
      manager: location.manager?._id,
      info: location.info,
      workshopSeasons: location.workshopSeasons || [],
      region: location.region,
      listPriority: location.listPriority || false
    }
  } else {
    initialAddressValues = {
      lineOne: '',
      lineTwo: '',
      city: '',
      postal: '',
      state: '',
      country: ''
    }
    initialOtherValues = {
      name: '',
      email: '',
      phone: '',
      manager: '',
      info: '',
      workshopSeasons: [],
      region: '',
      listPriority: false
    }
  }

  const [addressValues, setAddressValues] = useState(initialAddressValues)
  const [otherValues, setOtherValues] = useState(initialOtherValues)
  const [errorList, setErrorList] = useState([])
  const [showSubmit, setShowSubmit] = useState(false)

  const handleAddressChange = (e) => {
    setShowSubmit(true)
    const { name, value } = e.target
    setAddressValues({
      ...addressValues,
      [name]: value
    })
  }

  const handleOtherChange = (e) => {
    setShowSubmit(true)
    const { name, value, checked } = e.target
    if (name == 'listPriority') {
      setOtherValues({ ...otherValues, [name]: checked })
    } else {
      setOtherValues({ ...otherValues, [name]: value })
    }
  }

  const handleCancel = () => {
    dispatch(setEdit(false))
    dispatch(setSelectedLocation(null))
    setCurrTab(0)
  }

  const checkData = () => {
    let tmpErr = []

    Object.keys(addressValues).forEach((key) => {
      if (addressValues[key] === '' || addressValues[key] === undefined) {
        if (key !== 'lineTwo') {
          tmpErr.push(key)
        }
      }
    })

    Object.keys(otherValues).forEach((key) => {
      if (otherValues[key] === '' || otherValues[key] === undefined) {
        if (key !== 'info') {
          tmpErr.push(key)
        }
      }
    })

    return tmpErr
  }

  const handleSubmit = async () => {
    let errorCheck = checkData()
    
    if (errorCheck.length > 0) {
      setErrorList(errorCheck)
    } else {
      if (isEdit) {
        let resp = await updateLocation({
          uID: uID,
          locationID: location._id,
          addressValues: addressValues,
          otherValues: otherValues
        })
        if (resp.success) {
          dispatch(updateLoc(resp.data))
          dispatch(setEdit(false))
          dispatch(setSelectedLocation(null))
          setCurrTab(0)
        } else {
          alert(resp)
        }
      } else {
        let resp = await createLocation({
          uID: uID,
          addressValues: addressValues,
          otherValues: otherValues
        })
        if (resp.success) {
          dispatch(addLocation(resp.data))
          dispatch(setSelectedLocation(null))
          setCurrTab(0)
        } else {
          alert(resp)
        }
      }
    }
  }

  let SEASONS = CONSTANTS.SEASONS

  const SelectField = ({ field }) => {
    let items = () => {
      if (field.id === 'manager') {
        return admins.map((admin) => {
          return (
            <MenuItem key={admin._id} value={admin._id}>
              {`${admin.info.name.fName} ${admin.info.name.lName}`}
            </MenuItem>
          )
        })
      } else {
        return regions.map((region) => {
          return (
            <MenuItem key={region._id} value={region._id}>
              {region.name}
            </MenuItem>
          )
        })
      }
    }
    return (
      <>
        <FormControl fullWidth>
          <InputLabel>{field.label}</InputLabel>
          <Select
            labelId={`select-${field.id}`}
            id={`select-${field.id}`}
            value={otherValues[field.id]}
            label={field.label}
            error={errorList.includes(field.id)}
            onChange={handleOtherChange}
            name={field.id}
          >
            {items()}
          </Select>
        </FormControl>
      </>
    )
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <div style={{ margin: '0px auto 100px auto', width: '90%' }}>
          <BlankCard>
            <Grid container spacing={1} justifyContent='center'>
              <Grid item xs={12} align='left'>
                <Typography>Address</Typography>
              </Grid>
              {LOCATION_FIELDS_ADDRESS.map((field) => {
                return (
                  <Grid item key={field.id} xs={6}>
                    <TextField
                      value={addressValues[field.id]}
                      name={field.id}
                      margin='dense'
                      id={field.id}
                      label={field.label}
                      type={field.type}
                      error={errorList.includes(field.id)}
                      fullWidth
                      onChange={handleAddressChange}
                      variant='standard'
                    />
                  </Grid>
                )
              })}
              <Grid item xs={12} align='left'>
                <Typography>General Information</Typography>
              </Grid>
              {LOCATION_FIELDS_OTHER.map((field) => {
                return (
                  <Grid item key={field.id} xs={field.fullWidth ? 12 : 6}>
                    {field.id === 'manager' || field.id === 'region' ? (
                      <SelectField field={field} />
                    ) : (
                      <>
                        {field.id === 'workshop' ? (
                          <FormControl fullWidth>
                            <InputLabel>{field.label}</InputLabel>
                            <Select
                              labelId='select-season'
                              id='select-season'
                              value={otherValues.workshopSeasons}
                              label={field.label}
                              error={errorList.includes('workshop')}
                              onChange={(e) => {
                                handleOtherChange(e, field.id)
                              }}
                              name='workshopSeasons'
                              multiple
                              fullWidth
                              renderValue={(selected) => selected.join(', ')}
                            >
                              {SEASONS.map((season) => {
                                return (
                                  <MenuItem key={season} value={season}>
                                    <Checkbox
                                      checked={
                                        otherValues.workshopSeasons.indexOf(
                                          season
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={season} />
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            value={otherValues[field.id]}
                            name={field.id}
                            margin='dense'
                            id={field.id}
                            label={field.label}
                            type={field.type}
                            error={errorList.includes(field.id)}
                            fullWidth
                            onChange={handleOtherChange}
                            variant='standard'
                            multiline={field.id == 'info' ? true : false}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                )
              })}
              <FormControl fullWidth sx={{ textAlign: 'left' }}>
                <FormHelperText>
                  Turning on List Priority on will prioritize this location in
                  the list of locations on the landing page. Locations are
                  sorted by priority then by alphabetical order.
                </FormHelperText>
                <FormControlLabel
                  sx={{ justifyContent: 'flex-end' }}
                  labelPlacement='start'
                  control={
                    <Switch
                      name='listPriority'
                      checked={otherValues.listPriority}
                      onChange={handleOtherChange}
                    />
                  }
                  label='List Priority'
                />
              </FormControl>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleCancel}>Cancel</Button>
              {showSubmit ? (
                <Button onClick={handleSubmit}>Submit</Button>
              ) : (
                <></>
              )}
            </div>
          </BlankCard>
        </div>
      )}
    </>
  )
}

export default EditLocation
