import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  AppBar,
  useMediaQuery,
  Typography
} from '@mui/material'
import { Logout, Menu, Person } from '@mui/icons-material'
import CONSTANTS from '../constants'
import LoginButton from './loginButton'
import AdminNotification from './adminViews/AdminNotification'
import CartContainer from './workshop/CartContainer'
import { useLocation } from 'react-router-dom'
import DefaultLocationButton from './defaultLocationButton'
import DefaultRegionButton from './adminViews/defaultRegionButton'

const NavigationBar = ({
  items,
  handleViewState,
  handleLogOut,
  handleLogIn
}) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const user = useSelector((state) => state.login.user)
  const [openDrawer, setOpenDrawer] = useState(false)
  const dispatch = useDispatch()
  let pathname = useLocation().pathname
  if (pathname == '/') {
    pathname = 0
  } else {
    pathname = pathname.split('/')[1] //remove backslash from pathname
  }

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }
  const styles = {
    item: {
      display: 'flex',
      flex: 1,
      padding: '1vh',
      button: {
        margin: 'auto',
        color: CONSTANTS.RED_HEX
      }
    },
    image: {
      height: { xs: 30, sm: 40 },
      margin: '0.5rem',
      justifySelf: 'center',
      gridColumn: '2/3'
    },

    appbar: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 33%)',
      gridTemplateRows: 'auto auto',
      alignItems: 'center'
      // backgroundColor: '#fff'
    }
  }
  const [open, setOpen] = React.useState(false)

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    prevOpen.current = open
  }, [open])

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role='presentation'
      onClick={() => {
        toggleDrawer()
      }}
    >
      <List
        sx={{
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'secondary.main',
            '&, & .MuiListItemIcon-root': {
              color: 'secondary.contrastText'
            }
          }
        }}
      >
        {isLoggedIn ? (
          <>
            <Typography variant='h5' sx={{ padding: '10px 10px' }}>
              Hello, {user.info.name.fName}
            </Typography>
            <Divider />
          </>
        ) : (
          <ListItemButton key='Sign In' onClick={() => handleLogIn()}>
            <ListItemIcon sx={{ color: 'secondary.main' }}>
              <Person />
            </ListItemIcon>
            <ListItemText
              primary='Sign In'
              primaryTypographyProps={{ variant: 'h5' }}
            />
          </ListItemButton>
        )}

        {items.map((item, index) => (
          <ListItemButton
            selected={item.id === pathname}
            key={item.value}
            onClick={() => {
              item.value !== 'Sign Out' ? handleViewState(item) : handleLogOut()
            }}
          >
            <ListItemIcon sx={{ color: 'secondary.main' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.value}
              primaryTypographyProps={{ variant: 'h5' }}
            />
          </ListItemButton>
        ))}

        {isLoggedIn ? (
          <ListItemButton key='sign-out' onClick={() => handleLogOut()}>
            <ListItemIcon sx={{ color: 'secondary.main' }}>
              <Logout />
            </ListItemIcon>
            <ListItemText
              primary='Sign Out'
              primaryTypographyProps={{ variant: 'h5' }}
            />
          </ListItemButton>
        ) : (
          <></>
        )}
      </List>
      <Divider />
    </Box>
  )

  return (
    <>
      <AppBar position='static' style={styles.appbar}>
        {mobile ? (
          <span
            key={'menu'}
            style={{ justifySelf: 'start', gridRowStart: 1, gridColumn: '1/2' }}
          >
            <IconButton
              aria-label='open drawer'
              onClick={() => {
                toggleDrawer()
              }}
              edge='start'
              color='secondary'
              style={{ marginLeft: '10px' }}
            >
              <Menu />
            </IconButton>
            <Drawer
              open={openDrawer}
              onClose={() => {
                toggleDrawer()
              }}
            >
              {list()}
            </Drawer>
            {/* TO DO: Make mobile vs desktop view less redudant*/}

            <DefaultRegionButton />
          </span>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifySelf: 'start',
                gridColumn: '1/2'
              }}
            >
              <DefaultRegionButton />
            </Box>
            <span
              style={{
                gridRowStart: 2,
                gridColumn: '1/4',
                borderTop: '1px solid #d3d3d3',
                display: 'inline-flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
            >
              {items.map((item) => {
                let itemPath = item.id
                if (item.id === 0) {
                  itemPath = 0
                } else if (item?.id?.includes('/')) {
                  itemPath = item.id.split('/')[0]
                }
                return (
                  <Typography
                    key={item.value}
                    onClick={() => {
                      item.value !== 'Sign Out'
                        ? handleViewState(item)
                        : handleLogOut()
                    }}
                    sx={{
                      padding: '5px 7px',
                      '&:hover': {
                        backgroundColor:
                          itemPath == pathname ? '#f5f5f5' : null,
                        cursor: 'pointer'
                      },
                      transition: '0.2s',
                      backgroundColor:
                        itemPath == pathname ? 'secondary.main' : null,
                      color: itemPath == pathname ? '#fff' : null
                    }}
                  >
                    {item.value}
                  </Typography>
                  // </Button>
                )
              })}
            </span>
          </>
        )}
        <Box
          component='img'
          sx={{
            ...styles.image,
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          alt='Rolling Robots Header Logo'
          src={require(`../images/RR-logo-no-robie.png`)}
          onClick={() => {
            window.location.pathname = '/'
          }}
        />
        <Box sx={{ justifySelf: 'end', display: 'flex', gridColumn: '3/4' }}>
          {!mobile ? (
            <LoginButton
              handleLogOut={handleLogOut}
              handleLogIn={handleLogIn}
            />
          ) : (
            <></>
          )}
          {isLoggedIn && user?.permissions[0] === 4 ? (
            <AdminNotification />
          ) : (
            <></>
          )}
          {(isLoggedIn && user?.permissions[0] === 1) || !isLoggedIn ? (
            <CartContainer />
          ) : (
            <></>
          )}
        </Box>
      </AppBar>
    </>
  )
}

export default NavigationBar
