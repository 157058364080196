import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button, Grid, Box } from '@mui/material'
import {
  setSelectedSubscription,
  setEdit
} from '../../../store/subscriptionSlice'
import cards from '../../util/cards'
import { useNavigate } from 'react-router-dom'

const SubscriptionCard = ({ subscription, setCurrTab }) => {
  const SmallCard = cards.smallCard
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const permissions = useSelector((state) => state.login.user.permissions)

  let siteURL =
    process.env.REACT_APP_MODE === 'DEVELOP'
      ? 'http://localhost:3000/'
      : 'https://www.rollingrobots.net/'

  let subscriptionPrice = subscription.price
  if (subscriptionPrice > 1000) {
    subscriptionPrice = subscriptionPrice / 100
  }

  const handleClick = () => {
    dispatch(setSelectedSubscription(subscription))
    navigate(`${subscription._id}`)
  }

  const handleEdit = () => {
    dispatch(setSelectedSubscription(subscription))
    dispatch(setEdit(true))
    setCurrTab(2)
  }

  const Icon = () => {
    return (
      <Box
        component='img'
        alt='Subscription Icon'
        sx={{
          width: '100px',
          padding: '10px 10px',
          marginRight: '10px',
          filter: subscription.active ? null : 'grayscale(100%)'
        }}
        src={require(`../../../images/courseIcons/${
          subscription.icon || 'robie.png'
        }`)}
      />
    )
  }
  let name
  if (subscription.name.includes('Membership-')) {
    //cleans up name string
    let tmp = subscription.name.split('-')
    name = tmp[1]
  } else {
    name = subscription.name
  }

  return (
    <SmallCard
      icon={Icon}
      title={name}
      subtitleOne={`${subscription.locationName} - ${subscription.students.length} Enrolled`}
      box={{ padding: '0px 0px' }}
      style={{ marginBottom: '0px' }}
      titleStyle={{ color: subscription.active ? null : '#808080' }}
    >
      <Grid container sx={{ padding: '20px 40px' }}>
        <Grid item xs={4}>
          <Typography variant='body2' align='left'>
            StripeID:
          </Typography>
          <Typography variant='body2' align='left'>
            Price:
          </Typography>
          {subscription.altPrices.map((altPrice, index) => {
            return <br key={index} />
          })}
          <Typography variant='body2' align='left'>
            Link:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography align='left'>{subscription.productID}</Typography>
          <Typography align='left'>${subscriptionPrice} / month</Typography>
          {subscription.altPrices.map((altPrice, index) => {
            return (
              <Typography align='left' key={index}>
                ${altPrice.price} / {altPrice.period}
              </Typography>
            )
          })}
          <Typography align='left'>{`${siteURL}direct/?type=membership&id=${subscription._id}`}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: permissions.includes(4) ? 'space-between' : 'flex-end'
        }}
      >
        {permissions.includes(4) ? (
          <>
            <Button onClick={handleEdit}>Edit</Button>
          </>
        ) : (
          <></>
        )}
        <Button onClick={handleClick}>View</Button>
      </Grid>
    </SmallCard>
  )
}

export default SubscriptionCard
