import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import SubscriptionSwitch from '../../adminViews/subscriptionSwitch/subscriptionSwitch'
import LoadingComp from '../../util/loading'
import { getAllSubscriptions, getActivities } from '../../../api/admin'
import {
  setAllSubscriptions,
  setSubscriptionActivities,
  setStudent
} from '../../../store/subscriptionSlice'

const ChangeSubscription = ({ open, handleCloseSub }) => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const student = useSelector((state) => state.subscription.student)
  const uID = useSelector((state) => state.login.user._id)

  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false)
  const [activitiesLoaded, setActivitiesLoaded] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function handleAsync() {
      if (subscriptions.length === 0) {
        let resp = await getAllSubscriptions({ params: { uID: uID } })
        if (resp.success) {
          dispatch(setAllSubscriptions(resp.data))
          setSubscriptionsLoaded(true)
        } else {
          alert('There was an error retrieving subscriptions. Please reload')
        }
      } else {
        setSubscriptionsLoaded(true)
      }

      let resp = await getActivities({
        params: { uID: uID, activityIDs: subscription?.activities }
      })
      if (resp.success) {
        dispatch(setSubscriptionActivities(resp.data))
        setActivitiesLoaded(true)
      } else {
        alert('There was an error retrieving activities. Please reload')
      }
    }

    if (open) {
      //Move subscription info to selectedStudent (needed placement for switch component)
      if (subscription.hasOwnProperty('stripe')) {
        let tmpStudent = { ...student }
        tmpStudent['stripe'] = subscription.stripe
        dispatch(setStudent(tmpStudent))
      }

      //get subscription data
      handleAsync()
    }
  }, [open])

  useEffect(() => {
    if (subscriptionsLoaded && activitiesLoaded) {
      setLoading(false)
    }
  }, [subscriptionsLoaded, activitiesLoaded])

  const close = () => {
    handleCloseSub()
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle style={{ margin: 'auto' }}>Switch Membership</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingComp />
        ) : (
          <SubscriptionSwitch updateAccount={true} close={close} />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ChangeSubscription
