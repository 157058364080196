import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import SecondaryNav from '../component/util/secondaryNav'
import SelectLocation from '../component/subscription/selectLocation'
import SelectSubscription from '../component/subscription/selectSubscription'
import SelectDetails from '../component/subscription/selectDetails'
import ConfirmDetails from '../component/subscription/confirmDetails'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { useParams, useNavigate } from 'react-router-dom'
import {
  setSelectedLocation,
  setSelectedSubscription
} from '../store/subscriptionSlice'
import {
  getSubscriptionsByLocation,
  getSubscriptionActivities
} from '../api/subscription'
import LoadingComp from '../component/util/loading'
import ParentAPI from '../api/parentApi'
import getNameString from '../utils/nameString'
import { useLocation } from 'react-router-dom'
import AdminCheckOut from '../component/adminViews/adminCheckOut'

const SubscriptionsContainer = () => {
  const dispatch = useDispatch()
  const windowLocation = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/membership_home',
      title: 'Membership Home'
    })
    ReactPixel.pageView()
  }, [])
  const [purchaseByAdmin, setPurchaseByAdmin] = useState(false)
  useEffect(() => {
    if (windowLocation.state && windowLocation.state?.purchaseByAdmin) {
      setPurchaseByAdmin(windowLocation.state.purchaseByAdmin)
    }
  }, [windowLocation])

  const selectedLocation = useSelector(
    (state) => state.subscription.selectedLocation
  )
  const allLocations = useSelector((state) => state.user.locations)
  //Checks if user is admin or parent
  let parent = useSelector((state) => {
    if (
      state.login.user.permissions &&
      state.login?.user?.permissions[0] === 4
    ) {
      return state.user.selectedUser
    } else {
      return state.login.user
    }
  })
  const selectedSubscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const [currTab, setCurrTab] = useState(0)

  const [loading, setLoading] = useState(true)
  const [children, setChildren] = useState([])

  const locationSubscriptions = useRef([])

  const [activities, setActivities] = useState(null)

  useEffect(() => {
    //uses information from params to set selected location and subscription
    async function handleDetails() {
      if (
        //Location subscription list has not been populated yet
        locationSubscriptions.current.length == 0 ||
        //Location has not been selected yet
        !selectedLocation ||
        //If the location param is a name string, check that it's not the name of the selected location
        (params.locationParam.includes('-') &&
          selectedLocation.name?.toLowerCase().replaceAll(' ', '-') !==
            params.locationParam) ||
        //If the location param is an id string, check that it's not the same as the selected location ID
        (!params.locationParam.includes('-') &&
          selectedLocation._id !== params.locationParam)
      ) {
        setLoading(true)
        //Check location param type when filtering for location object
        let tmpSelectedLocation = allLocations.filter((obj) => {
          if (params.locationParam.includes('-')) {
            return (
              obj.name.toLowerCase().replaceAll(' ', '-') ==
              params.locationParam
            )
          } else {
            return obj.name.toLowerCase() == params.locationParam.toLowerCase()
          }
        })[0]
        dispatch(setSelectedLocation(tmpSelectedLocation))
        let subscriptionResp = await getSubscriptionsByLocation({
          params: { locationID: tmpSelectedLocation._id }
        })
        if (subscriptionResp.success) {
          locationSubscriptions.current = subscriptionResp.data
        } else {
          alert(
            'There was a problem getting the subscription list. Please try again'
          )
        }
      }

      if (params.membershipID) {
        setCurrTab(2)
        if (
          activities == null ||
          !selectedSubscription ||
          selectedSubscription._id !== params.membershipID
        ) {
          setLoading(true)
          let tmpSelectedSubscription = locationSubscriptions.current.filter(
            (sub) => sub._id == params.membershipID
          )[0]
          dispatch(setSelectedSubscription(tmpSelectedSubscription))
          //get activities
          let activityResp = await getSubscriptionActivities({
            params: { activityIDs: tmpSelectedSubscription.activities }
          })
          if (activityResp.success) {
            setActivities(activityResp.data)
          } else {
            alert(
              'There was a problem getting the activity list. Please try again'
            )
          }
        }

        if (parent) {
          //get children
          let parentResp = await ParentAPI.getStudents({
            children: parent.children
          })
          if (parentResp.success) {
            const createNewChild = {
              _id: 'create',
              info: { name: { fName: 'Add', lName: 'Roboteer' } }
            }
            let tmpChildren = parentResp.data
            tmpChildren.push(createNewChild)
            setChildren(tmpChildren)
          } else {
            alert('There was an error retrieving children')
          }
        }
      } else {
        setCurrTab(1)
      }
      setLoading(false)
    }

    if (allLocations.length > 0) {
      if (params.locationParam) {
        handleDetails()
      } else {
        setCurrTab(0)
        setLoading(false)
      }
    }
  }, [allLocations, params])

  const TABS = [
    {
      label: 'Locations',
      content: <SelectLocation />
    },
    {
      label: selectedLocation?.name || 'Select Subscription',
      content: (
        <SelectSubscription
          setCurrTab={setCurrTab}
          subscriptions={locationSubscriptions.current}
        />
      )
    },
    {
      label: getNameString(selectedSubscription) || 'Select Details',
      content: (
        <SelectDetails
          purchaseByAdmin={purchaseByAdmin}
          setCurrTab={setCurrTab}
          source={'catalog'}
          activities={activities}
          children={children}
          setChildren={setChildren}
        />
      )
    },
    {
      label: 'Order Details',
      content: <ConfirmDetails setCurrTab={setCurrTab} />
    }
  ]
  return (
    <>
      <SecondaryNav
        title={'Memberships'}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
        isDynamic={true}
      />
      {purchaseByAdmin ? <AdminCheckOut parent={parent} /> : null}
      {loading ? <LoadingComp /> : <>{TABS[currTab].content}</>}
    </>
  )
}

export default SubscriptionsContainer
