import React, { useState } from 'react'
import UserTable from './userTable'
import LoadingComp from '../util/loading'
import SecondaryNav from '../util/secondaryNav'
import { Grid } from '@mui/material'

const UserView = ({ viewType, isLoading }) => {
  const [currTab, setCurrTab] = useState(0)

  const styles = {
    container: {
      padding: {
        xs: '0px 5% 50px 5%',
        sm: '0px 5% 50px 5%',
        md: '0px 10% 50px 10%'
      }
    },

    title: {
      color: '#C80F2D'
    },
    paper: {
      padding: '5px'
    }
  }

  const SearchView = () => {
    return (
      <>
        <Grid container justifyContent='center' sx={styles.container}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ alignContent: 'flex-start', padding: '10px' }}
          >
            <UserTable type={viewType} />
          </Grid>
        </Grid>
      </>
    )
  }

  const TABS = [
    {
      label: 'Search',
      content: <SearchView />
    }
  ]

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingComp />
      ) : (
        <>
          <SecondaryNav
            title={viewType === 1 ? 'Parents' : 'Students'}
            tabs={TABS}
            currTab={currTab}
            setCurrTab={setCurrTab}
          />
          {TABS[currTab].content}
        </>
      )}
    </React.Fragment>
  )
}

export default UserView
