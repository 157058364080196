import { createTheme, responsiveFontSizes } from '@mui/material'

let fbLandingTheme = createTheme()
fbLandingTheme = createTheme(fbLandingTheme, {
  palette: {
    primary: {
      main: '#EE4239'
    },
    yellow: {
        main: '#F3C34B'
    }
  },
  typography: {
        h1: {
            fontFamily: 'Montserrat',
            fontWeight: 800,
            fontSize: '2.5rem',
            color: '#000'
        },
        h2: {
            fontFamily: 'Montserrat',
            fontSize: '1.8rem',
            fontWeight: 600,
        },
        h3: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: 1.8
        },
        h4: {
            fontFamily: 'Montserrat',
            fontSize: '1.5rem',
            lineHeight: 1.8
        },
        h5: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1.3rem'
        },
        h6: {
            fontFamily: 'Montserrat',
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        subtitle1: {
            fontFamily: 'Montserrat',
            fontSize: '1.5rem',
            lineHeight: 1.36,
        },
        subtitle2: {
            fontFamily: 'Montserrat',
            fontSize: '1.1rem'
        },
        body1: {
            fontFamily: 'Montserrat',
            fontSize: '0.9rem',
            fontWeight: 500
        },
        body2: {
            fontSize: '1rem',
            color: '#5c5c5c',
            fontWeight: 500,
            lineHeight: 1.6
        },
  },
  components: {
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                boxShadow: '0px 0px',
                borderRadius: '25px',
                padding: '10px 30px',
                backgroundColor: '#EE4239',
                '&:hover': {
                    backgroundColor: '#cd2f32'
                },
                color: '#fff',
                fontFamily: 'Montserrat',
                fontWeight: 'bold'
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            root: {
                color: '#000',
                fontFamily: 'Montserrat',
            }
        }
    }
  }
})

fbLandingTheme = responsiveFontSizes(fbLandingTheme)

export default fbLandingTheme
