import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import Button from '@mui/material/Button'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ItemAdded = ({ course, handleClose}) => {
  const styles = {
    actions: {
      display: 'flex',
      justifyContent: 'center'
    },
    button: {
      backgroundColor: '#C80F2D',
      color: 'white'
    }
  }

  return (
    <div>
      <Dialog
        open
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>Added {course.metadata.courseName} to the cart!</DialogTitle>
        <DialogActions style={styles.actions}>
          <Button onClick={() => { handleClose() }} style={styles.button}>Continue</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ItemAdded
