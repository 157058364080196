import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Paper,
  Divider
} from '@mui/material'
import cards from '../../util/cards'
import { getInstructorAssignments } from '../../../api/admin'
import timeHandler from '../../../utils/timeHandler'
import RemoveConfirm from './removeConfirm'

const Instructor = ({ instructor }) => {
  let user = useSelector((state) => state.login.user)
  const SmallCard = cards.smallCard

  const [assignmentLoading, setAssignmentLoading] = useState(false)
  const [showAssignments, setShowAssignments] = useState(false)
  const [workshops, setWorkshops] = useState([])
  const [memberships, setMemberships] = useState([])
  const [activities, setActivities] = useState([])
  const [instructorToRemove, setInstructorToRemove] = useState(null)
  const [confirmRemove, setConfirmRemove] = useState(false)

  const handleOpenRemove = (instructor) => {
    setConfirmRemove(true)
    setInstructorToRemove(instructor)
  }

  const handleCloseRemove = () => {
    setConfirmRemove(false)
    setInstructorToRemove(null)
  }

  const handleView = async () => {
    setAssignmentLoading(true)
    let resp = await getInstructorAssignments({
      params: { uID: user._id, instructorID: instructor._id }
    })
    if (resp.success) {
      setWorkshops(resp.data.workshops)
      setMemberships(resp.data.memberships)
      setActivities(resp.data.activities)
      setAssignmentLoading(false)
      setShowAssignments(true)
    } else {
      alert(resp)
      setAssignmentLoading(false)
    }
  }

  const handleHide = () => {
    setShowAssignments(false)
  }

  const getTitle = () => {
    let title = `${instructor.info.name.fName} ${instructor.info.name.lName}`
    if (instructor.permissions.includes(4)) {
      title = title + ' (admin)'
    }
    return title
  }

  return (
    <SmallCard title={getTitle()} subtitleOne={`${instructor.userName}`}>
      {showAssignments ? (
        <>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant='h6'>Workshops</Typography>
              {workshops.length > 0 ? (
                <>
                  {workshops.map((workshop) => {
                    let start = new Date(
                      workshop.metadata.startDate
                    ).toLocaleDateString()
                    let end = new Date(
                      workshop.metadata.endDate
                    ).toLocaleDateString()
                    let startTime = workshop.metadata.startTime
                    let endTime = workshop.metadata.endTime
                    let startMinute = workshop.metadata.startTimeMinute
                    let endMinute = workshop.metadata.endTimeMinute
                    return (
                      <Paper
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '5px'
                        }}
                        key={workshop.id}
                      >
                        <Typography>{workshop.metadata.courseName}</Typography>
                        <Typography variant='caption'>
                          {workshop.metadata.locationName}
                        </Typography>
                        <Typography variant='caption'>
                          {timeHandler.getTimeString({
                            startTime,
                            endTime,
                            startMinute,
                            endMinute
                          })}
                        </Typography>
                        <Typography variant='caption'>{`${start} - ${end}`}</Typography>
                      </Paper>
                    )
                  })}
                </>
              ) : (
                <Typography>No Workshops Assigned</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6'>Memberships</Typography>
              {memberships.length > 0 ? (
                <>
                  {memberships.map((membership) => {
                    let memActivities = activities.filter(
                      (activity) =>
                        activity.instance.subscriptionID === membership._id
                    )
                    return (
                      <Paper
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '5px'
                        }}
                        key={membership._id}
                      >
                        <Typography>{membership.name}</Typography>
                        <Typography variant='caption'>
                          {membership.locationName}
                        </Typography>
                        <Divider />
                        {memActivities.map((memActivity) => {
                          return (
                            <>
                              <Typography variant='caption'>
                                {`${memActivity.course.name}, ${memActivity.instance.day}, ${memActivity.instance.startTime}-${memActivity.instance.endTime}`}
                              </Typography>
                            </>
                          )
                        })}
                      </Paper>
                    )
                  })}
                </>
              ) : (
                <Typography>No Memberships Assigned</Typography>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px'
        }}
      >
        {showAssignments ? (
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              handleHide()
            }}
          >
            Hide Assignments
          </Button>
        ) : (
          <>
            {assignmentLoading ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                size='small'
                onClick={() => {
                  handleView()
                }}
              >
                View Assignments
              </Button>
            )}
          </>
        )}

        {instructor.permissions.includes(4) ? (
          <></>
        ) : (
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              handleOpenRemove(instructor)
            }}
          >
            Remove Instructor
          </Button>
        )}
      </div>
      <RemoveConfirm
        open={confirmRemove}
        handleClose={handleCloseRemove}
        instructor={instructorToRemove}
      />
    </SmallCard>
  )
}

export default Instructor
