const getMonthlyRate = (priceObj) => {
  let price = priceObj.price
  let period = 0
  if (priceObj.period === 'quarter') {
    period = 3
  } else if (priceObj.period === '9 months') {
    period = 9
  } else {
    period = 12
  }

  let monthlyRate = price / period
  return monthlyRate.toFixed(2)
}

export default getMonthlyRate
