import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Section from './section'
import TextStepper from '../util/textStepper'

const LearningContentSection = ({ 
  styles 
}) => { 

  const LEARNING_CONTENT = [
    {
      image: 'Asset_35.png',
      title: 'Robotics',
      detailsOne: ['Toys', 'LEGO', 'Battle Bots'],
      detailsTwo: ['VEX GO', 'IQ', 'V5', 'Dash']
    },
    {
      image: 'Asset_37.png',
      title: 'Coding',
      detailsOne: ['Scratch', 'Python', 'AI'],
      detailsTwo: ['Unity', 'C#', 'C++']
    },
    {
      image: 'Asset_36.png',
      title: 'Design',
      detailsOne: ['3D Design', '3D Printing'],
      detailsTwo: ['Minecraft', 'MOD', 'Roblox']
    }
  ]


  return (
      <Section
        id='learning-content'
        title={'What Your Kids Will Be Learning'}
        capitalCase
        sx={{ minHeight: null }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'auto',
              md: 'repeat(3, minmax(0, 1fr))'
            },
            ...styles.box,
            marginBottom: '50px',
            gridGap: '30px'
          }}
        >
          {LEARNING_CONTENT.map((item) => {
            return (
              <Box sx={{ textAlign: 'center' }} key={item.title}>
                <picture>
                  <source
                    type='image/webp'
                    srcset={require(`../../images/fbLandingImages/${item.image}`)}
                    media='(min-width:300px)'
                  />
                  <Box
                    component='img'
                    sx={{
                      backgroundImage: `url(${require(`../../images/fbLandingImages/${item.image}`)})`,
                      height: '200px',
                      width: '100%',
                      maxWidth: '300px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      WebkitBoxShadow: `inset 0 0 0 6px ${styles.red}`,
                      boxShadow: `inset 0 0 0 6px ${styles.red}`,
                      borderRadius: '20px'
                    }}
                  />
                </picture>
                <Typography
                  // variant='h5'
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '26px'
                  }}
                >
                  {item.title}:
                </Typography>

                <Typography sx={{ fontSize: '18px' }}>
                  {item.detailsOne.map((detail, index) => (
                    <span key={detail}>
                      {detail}
                      {index < item.detailsOne.length - 1 ? ' - ' : null}
                    </span>
                  ))}
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>
                  {item.detailsTwo.map((detail, index) => (
                    <span key={detail}>
                      {detail}
                      {index < item.detailsTwo.length - 1 ? ' - ' : null}
                    </span>
                  ))}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Section>
  )
}
export default LearningContentSection
