import React, { useState } from 'react'
import {
  IconButton,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const ForgotPassword = ({ handleChange, handleClose, handleSubmit }) => {
  const [showMsg, setShowMsg] = useState(false)

  const handleState = () => {
    setShowMsg(true)
    handleSubmit()
  }

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          Forgot Password
          <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {showMsg ? (
            <p>
              If an account with that email is found a reset link will be sent.
            </p>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <p>Enter your account email/username</p>
              <TextField
                id='outlined-basic'
                variant='outlined'
                onChange={(e) => {
                  handleChange(e.target.value)
                }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          {showMsg ? (
            <Button
              onClick={() => {
                handleClose()
              }}
            >
              Close
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleState()
              }}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ForgotPassword
