import React, { useState } from 'react'
import {
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel
} from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import ModelHandler from '../models/modelHandler'
import { createUser } from '../api/userApi'
import CONSTANTS from '../constants'
import InputMask from 'react-input-mask'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import LS from '../utils/localStorage'
import { useDispatch } from 'react-redux'
import { loginState } from '../store/loginSlice'
import { useNavigate } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

const CreateAccount = ({ setShowForgot }) => {
  const [showCreate, setShowCreate] = useState(false)

  const [info, setInfo] = useState({})
  const [showSuccess, setShowSuccess] = useState(false)
  const [focus, setFocus] = useState(null)
  const [error, setError] = React.useState([])
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    setShowCreate(false)
    setInfo({})
    setError([])
  }

  const handleChange = (type, value, index) => {
    if (
      type == 'secondaryPhone' ||
      type == 'secondaryEmail' ||
      type == 'secondaryFName' ||
      type == 'secondaryLName'
    ) {
      let tmp = info
      tmp['alt'][index][type] = value
      setInfo(tmp)
    } else {
      setInfo((prevState) => ({
        ...prevState,
        [type]: value
      }))
    }
  }

  const createAccount = async () => {
    let errors = ModelHandler.checkParentData(info)
    if (!info.eula) {
      errors.push('eula')
      // alert('Please accept the EULA')
      // return
    }
    if (errors.length >= 1) {
      setError(errors)
    } else {
      ReactPixel.track('Lead', {
        content_category: 'Account_Create'
      })
      let data = ModelHandler.parent(info)
      let resp = await createUser(data)
      if (resp.status === 200) {
        if (!resp.data.success) {
          setError(resp.data.reason)
        } else {
          LS.setItem('rro-JWT', resp.data.jwt)
          dispatch(loginState(resp.data.user))
          navigate('/account-view')
        }
      } else {
        alert('An error occurred creating the user. Please try again')
      }
    }
  }

  const PARENT_FIELDS = [
    {
      type: 'standard',
      key: 'fName',
      label: 'First Name',
      isRequired: true
    },
    {
      type: 'standard',
      key: 'lName',
      label: 'Last Name',
      isRequired: true
    },
    {
      type: 'standard',
      key: 'mName',
      label: 'Middle Name',
      isRequired: false
    },
    {
      type: 'standard',
      key: 'email',
      label: 'Email',
      isRequired: true
    },
    {
      type: 'phone',
      key: 'phone',
      label: 'Phone',
      isRequired: true
    },
    {
      type: 'password',
      key: 'password1',
      label: 'Password',
      isRequired: true
    },
    {
      type: 'password',
      key: 'password2',
      label: 'Password Confirm',
      isRequired: true
    },
    {
      type: 'standard',
      key: 'lineOne',
      label: 'Street',
      isRequired: true
    },
    {
      type: 'standard',
      key: 'lineTwo',
      label: 'Street Line Two',
      isRequired: false
    },
    {
      type: 'standard',
      key: 'city',
      label: 'City',
      isRequired: true
    },
    {
      type: 'standard',
      key: 'state',
      label: 'State',
      isRequired: true
    },
    {
      type: 'standard',
      key: 'postal',
      label: 'Postal',
      isRequired: true
    },
    {
      type: 'standard',
      key: 'country',
      label: 'Country',
      isRequired: false
    },
    {
      type: 'dropdown',
      key: 'survey',
      label: 'How did you hear about us?',
      isRequired: false,
      options: [
        'Search Engine (Google, Yahoo, etc)',
        'Recommended by a friend',
        'Social Media',
        'Events (camp fair, etc)',
        'Other'
      ]
    }
  ]

  const SECONDARY_FIELDS = [
    {
      type: 'standard',
      key: 'secondaryFName',
      label: 'First Name'
      // isRequired: true
    },
    {
      type: 'standard',
      key: 'secondaryLName',
      label: 'Last Name'
      // isRequired: true
    },
    {
      type: 'phone',
      key: 'secondaryPhone',
      label: 'Secondary Phone'
      // isRequired: true
    },
    {
      type: 'standard',
      key: 'secondaryEmail',
      label: 'Secondary Email'
      // isRequired: true
    }
  ]

  const standardField = (item) => {
    return (
      <TextField
        required={item.isRequired}
        margin='dense'
        id={item.key} 
        key={item.key}
        error={
          error.includes(item.key) ||
          error.includes(`${item.key}-${item.index}`)
        }
        label={item.label}
        onFocus={() => {
          setFocus(item.key)
        }}
        helperText={focus === item.key ? item.helper : null}
        fullWidth
        onChange={(e) => {
          if (typeof item.index) {
            handleChange(item.key, e.target.value, item.index)
          } else {
            handleChange(item.key, e.target.value)
          }
        }}
        variant='standard'
      />
    )
  }

  const passwordField = (item) => {
    return (
      <React.Fragment key={item.label}>
        <TextField
          required={item.isRequired}
          id={item.label}
          label={item.label}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          onChange={(e) => {
            handleChange(item.key, e.target.value)
          }}
          variant='standard'
          error={error.includes(item.key)}
          onFocus={() => {
            setFocus(item.key)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                  onMouseDown={() => {
                    setShowPassword(!showPassword)
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {item.key == 'password1' ? (
          focus === item.key || error.includes(item.key) ? (
            <span style={{ fontSize: 12, color: 'grey' }}>
              <p style={{ margin: 0 }}>Password requirements</p>
              <ul style={{ margin: 0 }}>
                <li>Minimum 8 characters long</li>
                <li>One lowercase letter</li>
                <li>One uppercase letter</li>
                <li>One special character</li>
                <li>One number</li>
              </ul>
            </span>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </React.Fragment>
    )
  }

  const dropdownField = (item) => {
    return (
      <FormControl fullWidth sx={{ marginTop: '10px' }}>
        <InputLabel id='dropdown-label'>{item.label}</InputLabel>
        <Select
          labelId='dropdown-label'
          id={`${item.key}-dropdown`}
          label={item.label}
          defaultValue=''
          onChange={(e) => handleChange(item.key, e.target.value)}
        >
          {item.options.map((option) => {
            return <MenuItem value={option}>{option}</MenuItem>
          })}
        </Select>
      </FormControl>
    )
  }

  const styles = {
    tempWeb: {}
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const phoneField = (item) => {
    return (
      <InputMask
        key={item.key}
        mask='(999)999-9999'
        onChange={(e) => {
          if (typeof item.index) {
            handleChange(item.key, e.target.value, item.index)
          } else {
            handleChange(item.key, e.target.value)
          }
        }}
      >
        {() => (
          <TextField
            required={item.isRequired}
            margin='dense'
            fullWidth
            id={item.key}
            error={
              error.includes(item.key) ||
              error.includes(`${item.key}-${item.index}`)
            }
            label={item.label}
            variant='standard'
          />
        )}
      </InputMask>
    )
  }

  const addContactInfo = () => {
    setInfo((prevState) => {
      if (prevState.alt) {
        let tmp = prevState.alt
        tmp.push({})
        return { ...prevState, alt: tmp }
      } else {
        return { ...prevState, alt: [{}] }
      }
    })
  }

  const deleteContact = (index) => {
    setInfo((prevState) => {
      let tmp = prevState.alt
      tmp.splice(index, 1)
      return { ...prevState, alt: tmp }
    })
  }

  return (
    <React.Fragment>
      {showSuccess ? (
        <Dialog
          open
          onClose={() => {
            setShowSuccess(false)
          }}
        >
          <DialogTitle>Account Created</DialogTitle>
        </Dialog>
      ) : (
        <React.Fragment />
      )}
      {showCreate ? (
        <Dialog open>
          <DialogTitle>
            Enter your information
            <IconButton
              style={{ position: 'absolute', right: '20px', top: '12px' }}
              onClick={() => {
                handleClose()
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {PARENT_FIELDS.map((item) => {
              if (item.type === 'password') {
                return passwordField(item)
              } else if (item.type === 'phone') {
                return phoneField(item)
              } else if (item.type === 'dropdown') {
                return dropdownField(item)
              } else {
                return standardField(item)
              }
            })}
            {/* <Typography variant='h5' sx={{ marginTop: '10px' }}>Secondary Contact Information</Typography> */}
            {info.alt ? (
              <>
                {info.alt.map((alt, index) => {
                  return (
                    <React.Fragment key={index}>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '10px'
                        }}
                      >
                        <Typography display='inline' variant='h6'>
                          Secondary Contact {index + 1}
                        </Typography>
                        <IconButton
                          size='small'
                          onClick={() => deleteContact(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                      {SECONDARY_FIELDS.map((item) => {
                        if (item.type === 'phone') {
                          return phoneField({ ...item, index: index })
                        } else {
                          return standardField({ ...item, index: index })
                        }
                      })}
                    </React.Fragment>
                  )
                })}
              </>
            ) : (
              <></>
            )}
            <Button onClick={() => addContactInfo()} startIcon={<AddIcon />}>
              Add additional contact information
            </Button>
            <FormGroup>
              {/* <FormControlLabel
                control={<Checkbox onChange={(e) => { handleChange('vax', e.target.checked) }} />}
                label='Vaccinated'
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleChange('eula', e.target.checked)
                    }}
                  />
                }
                label={
                  <p style={{ fontSize: '12px' }}>
                    I agree to the{' '}
                    <a target='_blank' href='/terms'>
                      Terms and Conditions
                    </a>
                  </p>
                }
              />
              {error.includes('eula') ? (
                <p>
                  Please accept our Terms and Conditions to create an account.
                </p>
              ) : (
                <></>
              )}
              {error.includes('Email already in use') ? (
                <p>
                  Email already in use.{' '}
                  <span
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => {
                      setShowForgot(true)
                    }}
                  >
                    Reset password
                  </span>
                </p>
              ) : (
                <></>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    createAccount()
                  }}
                >
                  Create
                </Button>
              </DialogActions>
            </FormGroup>
          </DialogContent>
        </Dialog>
      ) : (
        <p
          onClick={() => {
            setShowCreate(true)
          }}
          style={{ fontSize: '12px', color: CONSTANTS.BLUE_HEX }}
        >
          Create New Account
        </p>
      )}
    </React.Fragment>
  )
}

export default CreateAccount
