import { useSelector } from 'react-redux'
import { useState } from 'react'
import SecondaryNav from '../../util/secondaryNav'
import SubscriptionSwitch from './subscriptionSwitch'
import { useNavigate } from 'react-router-dom'

const SwitchView = () => {
  const navigate = useNavigate()
  const [currTab, setCurrTab] = useState(0)

  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )

  const TABS = [
    { label: 'Switch Subscription', content: <SubscriptionSwitch /> }
  ]

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <>
      <SecondaryNav
        title={`${subscription.name} Activities`}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
        back={handleBack}
      />
      <>{TABS[currTab].content}</>
    </>
  )
}

export default SwitchView
