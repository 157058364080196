import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { clearCart } from '../store/cartSlice'

const CheckoutSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/checkout_success',
      title: 'Checkout Success'
    })

    ReactPixel.track('Purchase', {
      content_category: 'Checkout_Success'
    })

    ReactPixel.pageView()
  }, [])

  useEffect(() => {
    if (cart.products?.length > 0) {
      dispatch(clearCart())
    }
  }, [cart])

  const handleHome = () => {
    navigate(`/`)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Typography variant='h1'>Thank you for your purchase!</Typography>
      <Typography>
        A verification email has been sent to the address on file
      </Typography>

      <Button
        variant='contained'
        style={{ marginTop: '10px' }}
        onClick={handleHome}
      >
        Home
      </Button>
    </div>
  )
}

export default CheckoutSuccess
