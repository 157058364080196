import { CSVLink } from 'react-csv'
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Tooltip,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material/'
import dateHandler from '../../utils/dateHandler'
import { red, orange, teal, indigo, deepOrange } from '@mui/material/colors'
import { GetAllWorkshopsFullInfo } from '../../api/products'
import { setFilteredWorkshops, setWorkshops } from '../../store/workshopSlice'

const WorkshopEnrollmentOverview = (props) => {
  const dispatch = useDispatch()
  const allWorkshops = useSelector((state) => state.workshop.workshops)
  const filteredWorkshops = useSelector((state) => state.workshop.filteredWorkshops)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)
  const locations = useSelector((state) => state.user.locations)
  // const [allWorkshops, setAllWorkshops] = useState([])
  const [hasWorkshops, setHasWorkshops] = useState(true)
  const [csvArray, setCsvArray] = useState([])
  const [viewGroup, setViewGroup] = useState('All')
  const [allExpanded, setAllExpanded] = useState(false)
  const [expanded, setExpanded] = useState([])
  const [dataType, setDataType] = useState('All')

  //data for when all groups are selected
  const [allLocationsGridData, setAllLocationsGridData] = useState(null)
  const [locationTotals, setLocationTotals] = useState(null)
  const [allLocationsHeaders, setAllLocationsHeaders] = useState([])

  //data for group that is currently selected
  const [currentGridData, setCurrentGridData] = useState([])
  const [currentHeaders, setCurrentHeaders] = useState([])

  //data for different group selections
  const [groupHeaders, setGroupHeaders] = useState([])
  const [groupData, setGroupData] = useState([])

  let GRID_BORDER = '#dfdfdf'
  const LOCATION_SORT = [
    //order of locations using production values
    { id: '61f2f9c7e04b587cd7b520f4', groupId: 0 }, //Glendale
    { id: '6236290f63fe1d9b3cc94bd0', groupId: 0 }, //La Cresenta
    { id: '640bcbc208da234010058bc2', groupId: 0 }, //La Canada
    { id: '641262d0ce0ea509da15fa9e', groupId: 0 }, //Pasadena
    { id: '61e9c5fee04b587cd7b520e1', groupId: 1 }, //Palos Verdes
    { id: '6259a7ccecc19a67a441161a', groupId: 1 }, //Hermosa Beach
    { id: '640bc5ec08da234010058b0e', groupId: 1 }, //Manhattan Beach Cornerstone Church
    { id: '63d91b8b130490ef8293c88d', groupId: 1 }, //MHCC
    { id: '61f2f983e04b587cd7b520f2', groupId: 2 }, //West LA
    { id: '63ed2c07ed82558160a5519c', groupId: 2 }, //Santa Monica
    { id: '62cb9c1bdda69fed263b6aad', groupId: 3 }, //Irvine
    { id: '640bc00a08da2340100587a1', groupId: 3 }, //Tustin
    { id: '63daf952130490ef829455b6', groupId: 3 }, //Newport Beach Church
    { id: '640bca5908da234010058bbc', groupId: 3 } //Newport Seashore
  ]

  const GROUPS = [
    { id: 0, name: 'North LA', color: 'red' },
    { id: 1, name: 'South Bay', color: 'blue' },
    { id: 2, name: 'Westside', color: 'purple' },
    { id: 3, name: 'Orange County', color: 'orange' }
  ]
  //Use these values when testing on dev
  // const LOCATION_SORT = [
  //   { id: '63e9541c6409fe45e75c1095', groupId: 1 }, //MT
  //   { id: '61843a2dbad25f0e9600541f', groupId: 1 }, //PV
  //   { id: '61bd211948f87e7e775bdedb', groupId: 2 } //Some Place
  // ]
  const getTotalsColumn = (chunkData) => {
    let completeChunk = [...chunkData]
    for (let i = 0; i < completeChunk.length; i++) {
      let enrollmentTotal = null
      let capacityTotal = null
      let currentRow = completeChunk[i]
      for (let y = 0; y < currentRow.length; y++) {
        if (
          currentRow[y].id == undefined &&
          currentRow[y].startDate == undefined
        ) {
          //[column(location)][am, pm]{enrollment, capacity}
          if (currentRow[y][0]) {
            //check AM
            enrollmentTotal += currentRow[y][0]['enrollment']
            capacityTotal += currentRow[y][0]['capacity']
          }
          if (currentRow[y][1]) {
            //check PM
            enrollmentTotal += currentRow[y][1]['enrollment']
            capacityTotal += currentRow[y][1]['capacity']
          }
        }
      }
      completeChunk[i].push({
        enrollment: enrollmentTotal,
        capacity: capacityTotal
      })
    }
    return completeChunk
  }

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let tmpWorkshops = allWorkshops
      if (defaultRegion._id) {
        let regionLocations = locations
          .filter((loc) => loc.region === defaultRegion._id)
          .map((filteredLoc) => filteredLoc._id)
        let filteredWorkshops = tmpWorkshops.filter((workshop) =>
          regionLocations.includes(workshop.metadata.location)
        )
        dispatch(setFilteredWorkshops(filteredWorkshops))
      } else {
        dispatch(setFilteredWorkshops(tmpWorkshops))
      }
    }
    if (mounted && allWorkshops?.length > 0) {
      handleAsync()
    }
    return () => (mounted = false)
  }, [defaultRegion, allWorkshops])

  // let locationRow = []
  useEffect(() => {
    if (filteredWorkshops.length > 0) {
      //Wait until all products (workshops) have loaded in redux store
      //Get all workshops for selected season
      let seasonWorkshops = filteredWorkshops.filter(
        (workshop) => workshop.metadata.workshop == props.season
      )
      //Check if workshops exist for this season
      if (seasonWorkshops.length > 0) {
        let locations = []
        let allStartDates = []

        seasonWorkshops.forEach((workshop) => {
          //Get all locations that workshops exist at
          if (
            //Make sure location has not already been added to array
            locations.filter(
              (locObj) => locObj.location == workshop.metadata.location
            ).length == 0
          ) {
            //Add it to the array
            locations.push({
              location: workshop.metadata.location,
              locationName: workshop.metadata.locationName
            })
          }

          //Get all possible workshop dates
          let date = new Date(workshop.metadata.startDate)
          let day = date.getDay()
          let diff = date.getDate() - day + 1
          let monday = new Date(date.setDate(diff))
          monday.setHours(0, 0, 0, 0)
          if (allStartDates.includes(monday.toLocaleDateString())) {
          } else {
            allStartDates.push(monday.toLocaleDateString())
          }
        })

        //Sort the dates and get the end dates
        allStartDates.sort((a, b) => new Date(a) - new Date(b))
        for (let i = 0; i < allStartDates.length; i++) {
          let rangeEndDate = new Date(allStartDates[i])
          rangeEndDate.setDate(new Date(allStartDates[i]).getDate() + 4)
          allStartDates[i] = {
            startDate: allStartDates[i],
            endDate: rangeEndDate.toLocaleDateString()
          }
        }

        //Get all the workshops for a given week
        let workshopsByWeek = []
        allStartDates.forEach((dateObj, index) => {
          let workshopsForWeek = seasonWorkshops.filter((workshop) => {
            let valid = true
            if (
              //Check that workshop starts after given week
              dateHandler.formatDate(workshop.metadata.startDate) >=
              new Date(dateObj.startDate)
            ) {
              if (index !== allStartDates.length - 1) {
                if (
                  //Check that workshop does not start after following given week, unless it's the last workshop
                  dateHandler.formatDate(workshop.metadata.startDate) >=
                  new Date(allStartDates[index + 1].startDate)
                ) {
                  valid = false
                }
              }
            } else {
              valid = false
            }
            return valid
          })
          workshopsByWeek.push({ key: dateObj, value: workshopsForWeek })
        })
        //sort locations using the sorting list
        let sortedLocations = locations.sort((a, b) => {
          let aPosition = LOCATION_SORT.findIndex(
            (sort) => sort.id == a.location
          )
          let bPosition = LOCATION_SORT.findIndex(
            (sort) => sort.id == b.location
          )
          if (aPosition == -1) {
            //if a location is not on the sorting list, it gets placed at the end
            aPosition = 100
          }
          if (bPosition == -1) {
            bPosition = 100
          }
          return aPosition - bPosition
        })
        let locationRow = ['', ...sortedLocations] //Create location row for viewing all locations

        let dataArray = []
        workshopsByWeek.forEach((weekWorkshops) => {
          let workshopRows = []
          //Create all workshop rows
          weekWorkshops.value.forEach((workshopInstance) => {
            let tmpWorkshopRow = []
            //Populate with blank columns
            for (let i in locationRow) {
              tmpWorkshopRow.push([null, null])
            }
            //Check if workshop COURSE doesn't have a row yet and create one if so
            if (
              workshopRows.filter(
                (row) => row[0].id == workshopInstance.metadata.course
              ).length == 0
            ) {
              tmpWorkshopRow[0] = {
                id: workshopInstance.metadata.course,
                name: workshopInstance.metadata.courseName
              }
              workshopRows.push(tmpWorkshopRow)
            }
            //Get enrollment details for this workshop instance
            let rowIndex = workshopRows.findIndex(
              (row) => row[0].id == workshopInstance.metadata.course
            )
            let locationIndex = locationRow.findIndex(
              (loc) => loc.location == workshopInstance.metadata.location
            )
            let hour = 0
            if (workshopInstance.metadata.startTime > 12) {
              hour = 1
            }
            //Update the correct spot in array with the enrollment
            let capacity = 8
            if (typeof workshopInstance.info.cap == 'number') {
              capacity = workshopInstance.info.cap
            }
            workshopRows[rowIndex][locationIndex][hour] = {
              enrollment: workshopInstance.info.students?.length,
              capacity: capacity,
              instructor: workshopInstance.instructor
            }
          })

          //Create week row - row with enrollment totals for the week
          let tmpWeekRow = []
          //Calculate total enrollments for each week of workshops
          for (let i = 0; i < locationRow.length; i++) {
            let amEnrollmentTotal = null
            let amCapacityTotal = null
            let pmEnrollmentTotal = null
            let pmCapacityTotal = null
            for (let y = 0; y < workshopRows.length; y++) {
              //[row(workshop)][column(location)][am, pm]{enrollment:, capacity:, instructor}
              if (workshopRows[y][i].length) {
                if (workshopRows[y][i][0]) {
                  amEnrollmentTotal += workshopRows[y][i][0]['enrollment']
                  amCapacityTotal += workshopRows[y][i][0]['capacity']
                }
                if (workshopRows[y][i][1]) {
                  pmEnrollmentTotal += workshopRows[y][i][1]['enrollment']
                  pmCapacityTotal += workshopRows[y][i][1]['capacity']
                }
              }
            }
            let tmpArray = [null, null]
            if (typeof amCapacityTotal == 'number') {
              tmpArray[0] = {
                enrollment: amEnrollmentTotal,
                capacity: amCapacityTotal
              }
            }
            if (typeof pmCapacityTotal == 'number') {
              tmpArray[1] = {
                enrollment: pmEnrollmentTotal,
                capacity: pmCapacityTotal
              }
            }
            tmpWeekRow.push(tmpArray)
          }
          tmpWeekRow[0] = weekWorkshops.key

          let allRows = [tmpWeekRow, ...workshopRows]
          //get sum of enrollments for the week
          let completeChunk = getTotalsColumn(allRows)
          dataArray.push(completeChunk)
        })

        // //get total enrollments over entire season for each location's AM and PM
        let tmpCumulativeLocationRow = []
        let enrollmentTotal = null
        let capacityTotal = null
        for (let y = 0; y < dataArray[0][0].length; y++) {
          //iterate thru columns
          let amEnrollmentTotal = null
          let amCapacityTotal = null
          let pmEnrollmentTotal = null
          let pmCapacityTotal = null
          for (let i = 0; i < dataArray.length; i++) {
            let cumulativeRow = dataArray[i][0] //[week of workshops][first line]
            //iterate thru weeks(rows/chunks)
            if (y == dataArray[0][0].length - 1) {
              //last column
              enrollmentTotal += cumulativeRow[y]['enrollment']
              capacityTotal += cumulativeRow[y]['capacity']
            } else {
              //[column(location)][AM, PM]
              if (cumulativeRow[y][0]) {
                //check am
                amEnrollmentTotal += cumulativeRow[y][0]['enrollment']
                amCapacityTotal += cumulativeRow[y][0]['capacity']
              }
              if (cumulativeRow[y][1]) {
                //check pm
                pmEnrollmentTotal += cumulativeRow[y][1]['enrollment']
                pmCapacityTotal += cumulativeRow[y][1]['capacity']
              }
            }
          }
          let tmpArray = [null, null]
          if (typeof amCapacityTotal == 'number') {
            tmpArray[0] = {
              enrollment: amEnrollmentTotal,
              capacity: amCapacityTotal
            }
          }
          if (typeof pmCapacityTotal == 'number') {
            tmpArray[1] = {
              enrollment: pmEnrollmentTotal,
              capacity: pmCapacityTotal
            }
          }
          tmpCumulativeLocationRow.push(tmpArray)
        }
        tmpCumulativeLocationRow[dataArray[0][0].length - 1] = {
          enrollment: enrollmentTotal,
          capacity: capacityTotal
        }
        tmpCumulativeLocationRow[0] = 'Total'

        setAllLocationsHeaders(locations)
        setAllLocationsGridData(dataArray)
        setCurrentHeaders(locations)
        setCurrentGridData(dataArray)
        setLocationTotals(tmpCumulativeLocationRow)
      } else {
        //if there are no workshops...
        setHasWorkshops(false)
        setAllLocationsGridData([])
      }
    }
  }, [props.season, filteredWorkshops])

  useEffect(() => {
    if (allLocationsGridData) {
      //generate data for groups
      let tmpGroupData = []
      let tmpGroupHeaders = []

      let locationRow = ['', ...allLocationsHeaders] //Use this to reference which column to get data from
      GROUPS.forEach((group) => {
        //Get data for each group
        let groupIndexes = []
        LOCATION_SORT.forEach((obj) => {
          if (obj.groupId == group.id) {
            let index = locationRow.findIndex((col) => col.location == obj.id)
            if (index >= 0) {
              groupIndexes.push(index)
            }
          }
        })
        if (groupIndexes.length > 0) {
          let newData = []
          allLocationsGridData.forEach((chunk) => {
            let newChunk = []
            chunk.forEach((row, rowIndex) => {
              let newRow = []
              let hasInstances = false
              groupIndexes.forEach((groupIndex) => {
                if (rowIndex == 0 || row[groupIndex][0] || row[groupIndex][1]) {
                  newRow.push(row[groupIndex])
                  hasInstances = true
                } else {
                  newRow.push([null, null])
                }
              })
              if (rowIndex == 0 || hasInstances) {
                newRow.unshift(row[0])
                newChunk.push(newRow)
              }
            })
            let completeChunk = getTotalsColumn(newChunk)
            newData.push(completeChunk)
          })
          tmpGroupData.push(newData)

          let newHeaders = []
          groupIndexes.forEach((index) => newHeaders.push(locationRow[index]))
          tmpGroupHeaders.push(newHeaders)
        } else {
          tmpGroupData.push([])
          tmpGroupHeaders.push([])
        }
      })
      setGroupData(tmpGroupData)
      setGroupHeaders(tmpGroupHeaders)
    }
  }, [props.season, allLocationsGridData])

  useEffect(() => {
    if (allLocationsGridData?.length > 0) {
      //Generate CSV array
      let firstCsvRow = ['Locations']
      let secondCsvRow = ['Times']
      let thirdCsvRow = ['Type']
      //loop through locations to fill top three throws
      allLocationsHeaders.forEach((location) => {
        for (let i = 0; i < 4; i++) {
          firstCsvRow.push(location.locationName)
          if (i < 2) {
            secondCsvRow.push('AM')
          } else {
            secondCsvRow.push('PM')
          }
          if (i % 2 == 0) {
            thirdCsvRow.push('Enrollment')
          } else {
            thirdCsvRow.push('Capacity')
          }
        }
      })
      thirdCsvRow.push('Total Enrollment', 'Total Capacity')
      //get the csv body data by un-nesting the data array
      let csvRows = allLocationsGridData.flat()
      csvRows.push(locationTotals) //add the total row to the end
      let flattenedArray = []
      csvRows.forEach((row) => {
        let flattenedRow = []
        let instructorRow = ['Instructor']
        row.slice(1, row.length - 1).forEach((item) => {
          item.forEach((timeSlot) => {
            //check if there is an AM or PM value
            if (timeSlot) {
              flattenedRow.push(timeSlot.enrollment)
              flattenedRow.push(timeSlot.capacity)
              if (timeSlot.instructor) {
                //check if there is an assigned instructor
                instructorRow.push(timeSlot.instructor.info.name.fName)
                instructorRow.push(null)
              } else {
                instructorRow.push('N/A')
                instructorRow.push(null)
              }
            } else {
              //if not, push null values - one for enrollment, one for capacity
              flattenedRow.push(null)
              flattenedRow.push(null)
              instructorRow.push(null)
              instructorRow.push(null)
            }
          })
        })
        let firstCell = ''
        if (row[0].name) {
          firstCell = row[0].name
        } else if (row[0].startDate) {
          firstCell = `${row[0].startDate} - ${row[0].endDate}`
          instructorRow = null //remove instructor row for overview row
        } else {
          firstCell = row[0]
          instructorRow = null //remove instructor for for totals row
        }
        flattenedRow.unshift(firstCell) //add First Cell to row
        //add last totals column values to row
        flattenedRow.push(row[row.length - 1].enrollment)
        flattenedRow.push(row[row.length - 1].capacity)
        flattenedArray.push(flattenedRow) //add to array
        if (instructorRow) {
          instructorRow.push(null)
          instructorRow.push(null)
          flattenedArray.push(instructorRow)
        }
      })
      setCsvArray([firstCsvRow, secondCsvRow, thirdCsvRow, ...flattenedArray])
    }
  }, [allLocationsGridData])

  //Create second row - AMs and PMs
  const [secondRow, setSecondRow] = useState([])

  useEffect(() => {
    let tmpSecondRow = []
    for (let i in currentHeaders) {
      tmpSecondRow.push(['AM', 'PM'])
    }
    tmpSecondRow.push('Total')
    tmpSecondRow.unshift('')
    setSecondRow(tmpSecondRow)
  }, [currentHeaders])

  const GridBox = (props) => {
    let gridTemplate = `auto 10% repeat(${
      currentHeaders.length * 2 + 1
    }, minmax(0, 1fr))`
    if (props.accordionSummary) {
      gridTemplate = `10% repeat(${
        currentHeaders.length * 2 + 1
      }, minmax(0, 1fr)) `
    }

    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: gridTemplate,
          ...props.style
        }}
      >
        {props.accordionSummary ? <></> : <Box sx={{ width: '1.4em' }}></Box>}{' '}
        {/*Placeholder to account for expand icon */}
        {props.children}
      </Box>
    )
  }

  const enrollmentColor = (percentage) => {
    let textColor = 'inherit'
    let bgColor = 'inherit'
    if (percentage !== null && percentage <= 20) {
      textColor = red['A700']
      bgColor = red[50]
      // return 'red'
    } else if (percentage > 20 && percentage <= 50) {
      textColor = deepOrange[500]
      bgColor = orange[50]
    } else if (percentage > 50 && percentage <= 80) {
      textColor = teal[700]
    } else if (percentage > 80) {
      textColor = indigo[700]
    } else {
    }
    return { text: textColor, bg: bgColor }
  }

  const Cell = ({ col, index, accordionSummary, bold }) => {
    const dataTypeCell = (value) => {
      let string = null
      let percentage = null
      let colorsObj = {}
      if (value !== null) {
        if (value.enrollment !== null) {
          if (value.capacity !== 0) {
            percentage = Math.floor(
              (parseInt(value.enrollment) / parseInt(value.capacity)) * 100
            )
          }

          if (dataType == 'Enrollment') {
            string = `${value.enrollment}`
          } else if (dataType == 'Capacity') {
            string = `${value.capacity}`
          } else if (dataType == 'Percentage') {
            if (percentage !== null) {
              string = `${percentage}%`
            } else {
              string = null
            }
          } else {
            //'All'
            string = `${value.enrollment} / ${value.capacity}`
          }
        }
      }
      colorsObj = enrollmentColor(percentage)
      return (
        <Box
          key={Math.random()}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: `1px solid ${GRID_BORDER}`,
            borderBottom: accordionSummary ? null : `1px solid ${GRID_BORDER}`,
            backgroundColor: dataType == 'Capacity' ? null : colorsObj.bg
          }}
        >
          <Typography
            sx={{
              color: dataType == 'Capacity' ? null : colorsObj.text,
              fontSize: '0.9rem',
              fontWeight: bold ? 'bold' : null
            }}
          >
            {string}
          </Typography>
          {value?.instructor ? (
            <Typography sx={{ fontSize: '0.9rem' }}>
              {value.instructor.info.name.fName}
            </Typography>
          ) : null}
        </Box>
      )
    }
    if (col.id) {
      return (
        <Typography
          key={col.id}
          sx={{
            gridColumn: '2/3',
            borderBottom: accordionSummary ? null : `1px solid ${GRID_BORDER}`
          }}
        >
          {col.name}
        </Typography>
      )
    } else if (col.startDate) {
      return (
        <Typography
          key={col.startDate}
          sx={{
            fontWeight: bold ? 'bold' : null
          }}
        >
          {col.startDate} - {col.endDate}
        </Typography>
      )
    } else {
      if (index <= currentHeaders.length) {
        if (col.length > 0) {
          return col.map((value) => dataTypeCell(value))
        } else {
          console.log('error', col)
          return <>error</>
        }
      } else {
        return dataTypeCell(col)
      }
    }
  }

  const abbreviateLocation = (locationName) => {
    let split = locationName.split(' (')[0].split(' ')
    let abbreviation = ''
    split.forEach((word) => {
      if (word.length > 2) {
        abbreviation += word[0]
      } else {
        abbreviation += word
      }
    })
    return abbreviation
  }

  const handleGroupSelect = (groupId) => {
    setViewGroup(groupId)
    if (groupId === 'All') {
      setCurrentGridData(allLocationsGridData)
      setCurrentHeaders(allLocationsHeaders)
    } else {
      let selectedData = groupData[groupId]
      let selectedHeaders = groupHeaders[groupId]
      setCurrentGridData(selectedData)
      setCurrentHeaders(selectedHeaders)
    }
  }

  const empty = (chunkSlice) => {
    let test = true
    chunkSlice.flat().forEach((item) => {
      if (item.length) {
        item.forEach((value) => {
          if (value !== null) {
            test = false
          }
        })
      }
    })
    return test
  }

  useEffect(() => {
    let tmp = currentGridData.map((item, index) => index)
    if (expanded.length == tmp.length) {
      setAllExpanded(true)
    } else {
      setAllExpanded(false)
    }
  }, [expanded, currentGridData])

  const handleExpandAll = () => {
    let tmp = currentGridData.map((item, index) => index)
    if (expanded.length == tmp.length) {
      setExpanded([])
    } else {
      setExpanded(tmp)
    }
  }

  const handleDataType = (value) => {
    setDataType(value)
  }

  const handleExpand = (accordionIndex) => {
    if (expanded.includes(accordionIndex)) {
      let arrayIndex = expanded.findIndex((value) => value == accordionIndex)
      setExpanded((prev) => {
        let tmp = [...prev]
        tmp.splice(arrayIndex, 1)
        return tmp
      })
    } else {
      setExpanded((prev) => {
        let tmp = [...prev, accordionIndex]
        return tmp
      })
    }
  }

  const getColor = (locationId) => {
    let color = '#000'
    let sortObj = LOCATION_SORT.filter((obj) => obj.id == locationId)[0]
    if (sortObj) {
      let groupObj = GROUPS.filter((obj) => obj.id == sortObj.groupId)[0]
      color = groupObj.color
    }
    return color
  }
  return (
    <>
      {allLocationsGridData?.length > 0 ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <FormControl fullWidth>
              <InputLabel id='location-group-select-label'>
                Location Group
              </InputLabel>
              <Select
                labelId='location-group-select-label'
                id='location-group-select'
                value={viewGroup}
                label='Location Group'
                onChange={(e) => handleGroupSelect(e.target.value)}
              >
                <MenuItem value={'All'}>All</MenuItem>
                {GROUPS.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <ToggleButtonGroup
              value={dataType}
              exclusive
              onClick={(e) => handleDataType(e.target.value)}
            >
              <ToggleButton value='All'>All</ToggleButton>
              <ToggleButton value='Enrollment'>Enrollment</ToggleButton>
              <ToggleButton value='Capacity'>Capacity</ToggleButton>
              <ToggleButton value='Percentage'>Percentage</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {currentHeaders.length == 0 ? (
            'There are no workshops for this location group.'
          ) : (
            <Paper
              sx={{ width: '100%', margin: 'auto auto', paddingTop: '10px' }}
            >
              <GridBox>
                <Box key='blank'></Box>
                {currentHeaders.map((col) => {
                  return (
                    <Tooltip
                      title={col.locationName}
                      key={col.location}
                      placement='top'
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: getColor(col.location),
                            '& .MuiTooltip-arrow': {
                              color: getColor(col.location)
                            }
                          }
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          gridColumn: col ? 'auto / span 2' : null,
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          borderRight: `1px solid ${GRID_BORDER}`,
                          color: getColor(col.location)
                        }}
                      >
                        {viewGroup == 'All'
                          ? abbreviateLocation(col.locationName)
                          : col.locationName}
                      </Typography>
                    </Tooltip>
                  )
                })}
              </GridBox>
              <GridBox>
                {secondRow.map((col, index) => {
                  if (index === 0) {
                    return (
                      <Button
                        key={`AM/PM-${index}`}
                        aria-label='open-all'
                        onClick={() => handleExpandAll()}
                        disableRipple
                        sx={{
                          fontSize: 10,
                          width: '100%',
                          left: 0,
                          '&:hover': {
                            backgroundColor: 'transparent'
                          }
                        }}
                      >
                        {allExpanded ? (
                          <>
                            <KeyboardArrowUp
                              sx={{ position: 'absolute', left: '-0.5em' }}
                            />
                            Close all
                          </>
                        ) : (
                          <>
                            <KeyboardArrowDown
                              sx={{ position: 'absolute', left: '-0.5em' }}
                            />
                            Open all
                          </>
                        )}
                      </Button>
                    )
                  } else if (typeof col == 'object') {
                    return (
                      <React.Fragment key={`AM/PM-${index}`}>
                        <Typography>{col[0]}</Typography>
                        <Typography
                          sx={{ borderRight: `1px solid ${GRID_BORDER}` }}
                        >
                          {col[1]}
                        </Typography>
                      </React.Fragment>
                    )
                  } else if (col.length > 1) {
                    return <Typography key='total'>{col}</Typography>
                  } else {
                    return <Box key='blank' sx={{ gridColumn: '2/3' }}></Box>
                  }
                })}
              </GridBox>

              {currentGridData.map((chunk, index) => {
                //Each chunk is a collection of workshops for a given week
                return (
                  <Accordion
                    expanded={
                      !empty(chunk.slice(1)) && expanded.includes(index)
                    }
                    onChange={() => handleExpand(index)}
                    disableGutters
                    sx={{ margin: '0px 0px' }}
                    key={index}
                  >
                    <AccordionSummary
                      disabled={empty(chunk.slice(1))}
                      expandIcon={<ExpandMore />}
                      sx={{
                        flexDirection: 'row-reverse',
                        borderBottom: `2px solid ${GRID_BORDER}`,
                        '&.MuiAccordionSummary-root': {
                          padding: '0px 0px',
                          margin: '0px 0px',
                          minHeight: '0px'
                        },
                        '& .MuiAccordionSummary-content': {
                          margin: '0px 0px'
                        }
                      }}
                    >
                      <GridBox
                        accordionSummary={true}
                        style={{ width: '100%' }}
                      >
                        {chunk[0].map((col, index) => (
                          <Cell
                            col={col}
                            index={index}
                            bold={true}
                            accordionSummary={true}
                            key={Math.random()}
                          />
                        ))}
                      </GridBox>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: '0px 0px',
                        borderBottom: `2px solid ${GRID_BORDER}`,
                        marginBottom: '10px'
                      }}
                    >
                      <GridBox>
                        {chunk.slice(1).map((row) => {
                          return row.map((col, index) => (
                            <Cell col={col} key={Math.random()} index={index} />
                          ))
                        })}
                      </GridBox>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
              {viewGroup == 'All' ? (
                <GridBox>
                  {locationTotals.map((col, index) => {
                    if (typeof col == 'string') {
                      return <Typography key='total'>{col}</Typography>
                    } else {
                      return (
                        <Cell
                          col={col}
                          key={Math.random()}
                          bold={true}
                          index={index}
                        />
                      )
                    }
                  })}
                </GridBox>
              ) : (
                <></>
              )}
            </Paper>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px'
            }}
          >
            {csvArray.length > 0 && viewGroup == 'All' ? (
              <CSVLink data={csvArray} style={{ textDecoration: 'none' }}>
                <Button variant='contained'>Download as CSV</Button>
              </CSVLink>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      ) : (
        <>{hasWorkshops ? <>Loading Enrollment...</> : <>No Workshops</>}</>
      )}
    </>
  )
}

export default WorkshopEnrollmentOverview
