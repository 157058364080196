import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import CreateAccount from './createAccount'
import CONSTANTS from '../constants'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// TODO change the blue to correct blue on create new acct and forgot pw
const Login = ({
  handleEmail,
  handlePassword,
  handleSubmit,
  email,
  password,
  handleForgot,
  errorMsg,
  showError,
  setShowForgot
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const styles = {
    container: {
      height: '100%',
      width: '100vw',
      marginTop: '40px',
      maxWidth: '400px' // TEMP WEB
    },
    box: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column'
    },
    item: {
      display: 'flex',
      flex: 1,
      padding: '1vh',
      marginTop: '10px',
      button: {
        margin: 'auto',
        width: '95%',
        backgroundColor: CONSTANTS.RED_HEX
      }
    }
  }
  return (
    <React.Fragment>
      <Container style={styles.container}>
        <div style={{ width: '100%' }}>
          <img
            src={require('../images/rolling_robots_logo_small_transparent.png')}
            height='100%'
            width='100%'
          />
        </div>
        <Box styles={styles.box}>
          <TextField
            style={styles.item}
            id='outlined-email-input'
            label='Email'
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            onChange={(e) => {
              handleEmail(e.target.value)
            }}
          />
          <TextField
            style={styles.item}
            id='outlined-password-input'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => {
              handlePassword(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                    onMouseDown={() => {
                      setShowPassword(!showPassword)
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%', cursor: 'pointer' }}>
              <CreateAccount setShowForgot={setShowForgot} />
            </div>
            <div style={{ width: '50%', cursor: 'pointer' }}>
              <p
                style={{
                  fontSize: '12px',
                  color: CONSTANTS.BLUE_HEX
                }}
                onClick={() => {
                  handleForgot()
                }}
              >
                Forgot Password?
              </p>
            </div>
          </span>
          <Button
            variant='contained'
            style={styles.item.button}
            onClick={() => handleSubmit()}
          >
            Login
          </Button>
          {showError ? <p>{errorMsg}</p> : <></>}
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default Login
