import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setLessons
} from '../../store/adminSlice'
import { setCourses } from '../../store/parentSlice'
import CourseForm from '../../component/adminViews/courseForm'
import CoursesTable from '../../component/adminViews/coursesTable'
import { getCourses, getLessons } from '../../api/admin'
import ImageSelector from '../../component/imageSelector'
import COURSE_API from '../../api/course'
import SecondaryNav from '../../component/util/secondaryNav'
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
} from '@mui/material'
import { Search, Close } from '@mui/icons-material'
import cards from '../../component/util/cards'
import listHandler from '../../utils/listHandler'

const AdminCoursesContainer = () => {
  const styles = {
    buttonStyle: {
      margin: ' 0 auto',
      color: '#FFFFFF',
      backgroundColor: '#C80F2D'
    },
    space: {
      margin: '15px auto'
    },
    toggleButton: {
      color: 'white',
      backgroundColor: '#3596CE'
    }
  }

  const baseCourse = {
    // published: false,
    categories: [],
    isURL: true,
    lessons: {
      1: ''
    },
    lessonURL: '',
    maxAge: '',
    minAge: '',
    name: '',
    description: ''
  }

  const BlankCard = cards.blankCard

  const dispatch = useDispatch()
  const [course, setCourse] = useState(baseCourse)
  const [showForm, setShowForm] = useState(false)
  const [showIconSelect, setShowIconSelect] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const courses = useSelector((state) => state.parent.courses) //previously state.admin.courses, changed to parent slice for route refactor
  const [errors, setErrors] = React.useState([])
  const [currTab, setCurrTab] = useState(0)

  useEffect(() => {
    async function handleAsync() {
      if (courses.length === 0) {
        let resp = await getCourses()
        if (resp.data) {
          dispatch(setCourses(resp.data))
        }
      }

      let lessons = await getLessons()
      if (lessons.data) {
        dispatch(setLessons(lessons.data))
      }
    }
    handleAsync()
  }, [])

  const handleClose = () => {
    setErrors([])
    setShowForm(false)
    setCourse(baseCourse)
    setIsEdit(false)
  }

  const handleSave = async () => {
    if (course['isURL']) {
      //prevents courses from having two types of lesson data (url/custom)
      course['lessons'] = { 1: '' }
    } else {
      course['lessonURL'] = ''
    }
    let errorCheck = await handleErrors()
    if (errorCheck.length >= 1) {
      setErrors(errorCheck)
    } else {
      let resp = await COURSE_API.createCourse(course)
      if (resp.success) {
        resp.data.priceInfo = resp.data.price
        delete resp.data.price
        let tmp = [...courses]
        tmp.unshift(resp.data)
        dispatch(setCourses(tmp))
        handleClose()
      } else {
        alert('failed creation')
      }
      return resp
    }
  }

  const handleErrors = () => {
    let errTmp = []
    for (const key in course) {
      if (key === 'categories') {
        if (course[key].length == 0) {
          errTmp.push(key)
        }
      } else if (course[key] === null || course[key] === '') {
        //all fields except lessons are required
        if (key !== 'lessonURL' && key !== 'lessons') {
          errTmp.push(key)
        }
      }

      if (course.maxAge - course.minAge < 0) {
        errTmp.push('rangeError')
      }
    }
    return errTmp
  }

  const handleUpdate = async () => {
    let errorCheck = await handleErrors()
    if (errorCheck.length >= 1) {
      setErrors(errorCheck)
    } else {
      let resp = await COURSE_API.updateCourse(course)
      if (resp.success) {
        let tmp = listHandler.updateObjInArr(resp.data, [...courses])
        dispatch(setCourses(tmp))
        if (filteredCourses) {
          let tmpFiltered = listHandler.updateObjInArr(resp.data, [...filteredCourses])
          setFilteredCourses(tmpFiltered)
        }
        handleClose()
      } else {
        alert('failed creation')
      }
      return resp
    }
  }

  const handleChange = (type, value) => {
    if (type === 'categories') {
      setCourse((prevState) => ({
        ...prevState,
        [type]: [...value]
      }))
    } else {
      setCourse((prevState) => ({
        ...prevState,
        [type]: value
      }))
    }
  }

  const createNew = () => {
    setIsEdit(false)
    setShowForm(true)
  }

  const onEdit = (item) => {
    let tmp = Object.assign(
      {
        minAge: item.age.min,
        maxAge: item.age.max,
        isURL: item.lesson.isURL,
        lessonURL: item.lesson.url,
        lessons: item.lesson.lessons
      },
      item
    )
    setIsEdit(true)
    setCourse(tmp)
    setShowForm(true)
  }

  const handleImageSelect = (value) => {
    setCourse((prevState) => ({
      ...prevState,
      icon: value
    }))
    setShowIconSelect(false)
  }

  const addLesson = (value) => {
    //TO DO: REFACTOR
    let tmp
    if (value) {
      let a = Object.keys(value).length + 1
      tmp = { ...value }
      tmp[a] = ''
    } else {
      tmp = { 1: '' }
    }
    setCourse((prevState) => ({
      ...prevState,
      lessons: tmp
    }))
  }

  const handleLessonChange = (index, value) => {
    setCourse((prevState) => ({
      ...prevState,
      lessons: {
        ...prevState.lessons,
        [index]: value
      }
    }))
  }
  const [searchValue, setSearchValue] = useState('')
  const [filteredCourses, setFilteredCourses] = useState(null)

  const handleSearch = () => {
    if (searchValue === '') {
      setFilteredCourses(null)
    } else {
      let regex = new RegExp(searchValue.toLowerCase().replace(/\s*/g, ''), 'i')

      let fCourses = courses.filter((obj) => regex.test(obj.name))
      setFilteredCourses(fCourses)
    }
  }

  const changeSearchValue = (value) => {
    setSearchValue(value)
  }

  const handleReset = () => {
    setFilteredCourses(null)
    setSearchValue('')
  }

  const searchFilter = () => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSearch()
      }
    }
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}
      >
        <TextField
          fullWidth
          id='search-course'
          label='Search'
          value={searchValue}
          onChange={(e) => {
            changeSearchValue(e.target.value)
          }}
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
          InputProps={
            !filteredCourses
              ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          handleSearch()
                        }}
                        edge='end'
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              : {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          handleReset()
                        }}
                        edge='end'
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  )
                }
          }
        />
      </Box>
    )
  }

  const TABS = [
    {
      label: 'Search',
      content: (
        <>
          <div style={{ width: '90%', margin: 'auto auto 100px auto' }}>
            {searchFilter()}
            <CoursesTable
              courses={filteredCourses ? filteredCourses : courses}
              onEdit={onEdit}
            />
          </div>
        </>
      )
    },
    {
      label: 'Add Course',
      content: (
        <>
          <BlankCard style={{ width: '90%', margin: 'auto auto 100px auto' }}>
            <CourseForm
              data={course}
              edit={isEdit}
              handleClose={handleClose}
              handleSave={handleSave}
              handleChange={handleChange}
              handleUpdate={handleUpdate}
              selectIcon={setShowIconSelect}
              addLesson={addLesson}
              lessonChange={handleLessonChange}
              errors={errors}
            />
          </BlankCard>
        </>
      )
    }
  ]

  return (
    <React.Fragment>
      <SecondaryNav
        title={'Admin Courses'}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
      />
      {TABS[currTab].content}
      {showForm ? (
        <>
          <Dialog
            open
            onClose={() => {
              handleClose()
            }}
          >
            <CourseForm
              data={course}
              edit={isEdit}
              handleClose={handleClose}
              handleSave={handleSave}
              handleChange={handleChange}
              handleUpdate={handleUpdate}
              selectIcon={setShowIconSelect}
              addLesson={addLesson}
              lessonChange={handleLessonChange}
              errors={errors}
            />
          </Dialog>
        </>
      ) : (
        <></>
      )}
      {showIconSelect ? (
        <ImageSelector
          handleChange={handleImageSelect}
          handleClose={setShowIconSelect}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  )
}

export default AdminCoursesContainer
