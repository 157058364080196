import { React, useEffect } from 'react'
import {
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const SecondaryNav = ({
  title,
  tabs,
  currTab,
  setCurrTab,
  back,
  isDynamic,
  toggleCustomer,
  setToggleCustomer,
  noTabs
}) => {
  const windowLocation = useLocation()
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    //When clicking tab, use tab value to update URL and save tab value to state
    //Replaces window in window history stack
    //This is so the back button will use the whole container instead of the tab in the stack
    let keys = windowLocation.pathname
      .slice(1, windowLocation.pathname.length)
      .split('/')
    let newPath = ''
    keys.slice(0, newValue + 1).forEach((key) => {
      newPath += `/${key}`
    })
    //Special case for customer viewing memberships and camps. Path will be replaced
    const specialPaths = [
      //Public paths
      '/memberships',
      '/summer-camps',
      '/holiday-camps',
      '/spring-camps',
      '/upcoming-camps',
      //Admin paths
      '/summer-overview',
      '/holiday-overview',
      '/spring-overview',
      '/upcoming-overview'
    ]
    if (specialPaths.some((specialPath) => newPath.includes(specialPath))) {
      navigate(newPath, { replace: true, state: { tab: newValue } })
    } else {
      navigate(null, { replace: true, state: { tab: newValue } })
    }
  }

  //Uses setCurrTab from container to update tab when windowLocation changes
  useEffect(() => {
    if (typeof windowLocation.state?.tab == 'number') {
      setCurrTab(windowLocation.state.tab)
    }
  }, [windowLocation])

  return (
    <>
      {/* secondary navigation bar at top */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          margin: '10px 0px 30px 0px'
        }}
      >
        <Typography
          variant='h1'
          align='left'
          sx={{
            marginLeft: '15px',
            color: 'primary.main',
            marginBottom: noTabs ? '5px' : 0
          }}
        >
          {title}
        </Typography>
        {noTabs ? (
          <></>
        ) : (
          <div id='tabs-bar' style={{ display: 'flex', width: '95vw' }}>
            {back ? (
              <IconButton aria-label='back' onClick={() => back()}>
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <></>
            )}
            <Tabs
              variant='scrollable'
              value={currTab}
              onChange={handleChange}
              aria-label='navigation tabs'
              sx={{ display: 'flex', width: '100%' }}
            >
              {tabs.map((tab, index) => {
                if (isDynamic) {
                  if (index <= currTab) {
                    return <Tab label={tab.label} key={tab.label} />
                  }
                } else {
                  return <Tab label={tab.label} key={tab.label} />
                }
              })}
              {typeof setToggleCustomer !== 'undefined' ? ( //checks if viewing admin workshops as admin or previewing as customer
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    marginRight: '5%'
                  }}
                >
                  <Typography sx={{ marginRight: '10px' }}>
                    View as:{' '}
                  </Typography>
                  <ToggleButtonGroup
                    onChange={(e) => setToggleCustomer(e.target.value)}
                    value={toggleCustomer}
                    size='small'
                  >
                    <ToggleButton value='Admin'>Admin</ToggleButton>
                    <ToggleButton value='Customer'>Customer</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              ) : null}
            </Tabs>
          </div>
        )}
      </Box>
    </>
  )
}

export default SecondaryNav
