import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Box, TextField } from '@mui/material'
import LoadingComp from '../../util/loading'
import Instructor from './instructor'

const Instructors = ({ isLoading }) => {
  const instructors = useSelector((state) => state.admin.instructors)
  const [filteredInstructors, setFilteredInstructors] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    setFilteredInstructors(instructors)
  }, [instructors])

  const handleSearch = (e) => {
    setSearch(e.target.value)

    let tmpArr = [...instructors]
    //Filter instructors based on first name, last name, and user name
    let newArr = tmpArr.filter(
      (tmp) =>
        tmp.info.name.fName
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        tmp.info.name.lName
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        tmp.userName.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setFilteredInstructors(newArr)
  }

  return (
    <>
      {isLoading ? (
        <LoadingComp />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {instructors?.length === 0 ? (
            <Typography>No instructors have been assigned yet</Typography>
          ) : (
            <Box sx={{ width: '40%' }}>
              <Typography variant='h2'>Instructors</Typography>
              <TextField
                sx={{ width: 1 / 2, margin: 2 }}
                variant='outlined'
                placeholder='Search'
                value={search}
                onChange={(e) => handleSearch(e)}
              />
              {filteredInstructors.map((instructor) => {
                return (
                  <Instructor instructor={instructor} key={instructor._id} />
                )
              })}
            </Box>
          )}
        </div>
      )}
    </>
  )
}

export default Instructors
