import axios from 'axios'

const getCourses = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/courses/instructor',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getMemberships = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/courses/memberships',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const checkInMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/courses/check-in-member',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const checkOutMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/courses/remove-check-in-member',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getStudentsInfo = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/courses/students-mem',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const recommendForMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL +
        '/api/courses/membership-recommendation',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getUser = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/courses/get-user',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const removeMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/subscription/remove-instructor',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const removeCourse = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/product/remove-instructor',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

const InstructorAPI = {
  getCourses,
  getMemberships,
  checkInMembership,
  getStudentsInfo,
  checkOutMembership,
  recommendForMembership,
  getUser,
  removeMembership,
  removeCourse
}

export default InstructorAPI
