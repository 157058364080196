import { IconButton, Button } from '@mui/material'
import { styled } from '@mui/system'

const styles = ({ theme }) => ({
  '& .MuiButton-startIcon': {
    color: theme.palette.secondary.main
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.main
  },
  textTransform: 'capitalize',
  borderRadius: '10px',
  '& .MuiTouchRipple-root .MuiTouchRipple-child': {
    borderRadius: '10px'
  },
  ...theme.typography.body1
})

const DesktopButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(styles)
const MobileButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'startIcon' && prop !== 'mobile'
})(styles)
const NavBarButton = (props) => {
  if (props.mobile) {
    return <MobileButton {...props}>{props.startIcon}</MobileButton>
  } else {
    return <DesktopButton {...props} />
  }
}

export default NavBarButton
