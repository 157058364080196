import { green, amber, lightBlue, purple } from '@mui/material/colors'

let levelOne =  amber[600]//5-9
let levelTwo = green[500] //7-11
let levelThree = lightBlue[500] //8-12
let levelFour = purple[500] //10-14

const formatRange = (min, max) => {
  return 'Age ' + min + '-' + max
}
const getAge = (dateString) => {
  let today = new Date()
  let birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  let month = today.getMonth() - birthDate.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

const ageGroupColor = (min, max) => {
  if (min >= 10) {
    return levelFour
  } else if (min >= 8) {
    return levelThree
  } else if (min >= 7) {
      return levelTwo
  } else {
    return levelOne
  }
}
const ageHandler = {
  formatRange,
  getAge,
  ageGroupColor
}

export default ageHandler
