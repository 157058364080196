import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import SecondaryNav from '../../util/secondaryNav'
import LocationsList from './locationsList'
import EditLocation from './editLocation'

const LocationsView = () => {
  const [currTab, setCurrTab] = useState(0)
  const edit = useSelector((state) => state.location.edit)
  const [msg, setMsg] = useState('Add Location')

  useEffect(() => {
    if (edit) {
      setMsg('Edit Location')
    } else {
      setMsg('Add Location')
    }
  }, [edit])

  const TABS = [
    { label: 'Locations', content: <LocationsList setCurrTab={setCurrTab} /> },
    { label: msg, content: <EditLocation setCurrTab={setCurrTab} /> }
  ]
  return (
    <>
      <SecondaryNav
        title={'Admin Locations'}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
      />
      <>{TABS[currTab].content}</>
    </>
  )
}

export default LocationsView
