import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setAllSubscriptions,
  setAllActivities
} from '../../store/subscriptionSlice'
import { getAllSubscriptions, getAllActivities } from '../../api/admin'
import { Outlet } from 'react-router-dom'

const AdminSubscriptionContainer = () => {
  const dispatch = useDispatch()
  const uID = useSelector((state) => state.login.user._id)
  const allSubscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )
  const allActivities = useSelector((state) => state.subscription.allActivities)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getAllSubscriptions({ params: { uID: uID } })
      if (resp.success) {
        dispatch(setAllSubscriptions(resp.data))
      } else {
        alert('There was an error retrieving subscriptions. Please reload')
      }
      let activities = await getAllActivities({ params: { uID: uID } })
      if (activities.success) {
        dispatch(setAllActivities(activities.data))
      } else {
        alert('There was an error retrieving activities. Please reload')
      }
    }
    if (allSubscriptions.length === 0 || allActivities.length === 0) {
      handleAsync()
    }
  }, [])

  return (
    <>
      <Outlet />
    </>
  )
}

export default AdminSubscriptionContainer
