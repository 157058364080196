import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  Chip,
  Box,
  Button,
  DialogActions,
  DialogTitle
} from '@mui/material'
import CONSTANTS from '../constants'

const SeasonEdit = ({ handleClose, selectedSeasons, handleSubmit }) => {
  const [seasonChange, setSeasonChange] = useState(selectedSeasons)
  
  const handleChange = (value) => {
    let index = seasonChange.indexOf(value)
    let tmp = [...seasonChange]
    if (index == -1) {
      tmp.push(value)
    } else {
      tmp.splice(index, 1)
    }
    setSeasonChange(tmp)
  }

  return (
    <Dialog
      open
      fullScreen
      sx={{ width: '250px', height: '300px', margin: 'auto auto' }}
    >
        <DialogTitle>
            Select Seasons
        </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          {CONSTANTS.SEASONS.map((season) => {
            let selected = false
            if (seasonChange.includes(season)) {
              selected = true
            }
            return (
              <Chip
                label={season}
                sx={{
                  margin: '2px 0px',
                  backgroundColor: selected ? '#3596ce' : null,
                  color: selected ? '#fff' : null,
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                    color: '#fff'
                  }
                }}
                onClick={() => handleChange(season)}
              />
            )
          })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button onClick={()=> handleSubmit(seasonChange)}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SeasonEdit
