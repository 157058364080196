import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
  Box,
  Typography,
  IconButton,
  Button
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LoadingComp from '../util/loading'

function Row({ row, type, rUsers, onEdit }) {
  const styles = {
    buttonStyle: {
      margin: ' 0 auto',
      color: '#FFFFFF',
      backgroundColor: '#C80F2D'
    },
    space: {
      margin: '15px auto'
    },
    toggleButton: {
      color: 'white',
      backgroundColor: '#3596CE'
    }
  }

  const [open, setOpen] = React.useState(false)
  const [showChild, setShowChild] = React.useState(null)
  const lessons = useSelector((state) => state.admin.lessons)

  const handleCloseChild = () => {
    setShowChild(null)
  }
  return (
    <React.Fragment key={row['_id']}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align='center'>{row.name}</TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Button
            variant='contained'
            onClick={() => {
              onEdit(row)
            }}
            style={styles.buttonStyle}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Course Info:
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                <TableRow>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Age: {row.age?.min + '-' + row.age?.max}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Categories:{' '}
                    {row.categories.map((item, index) => {
                      if (index == 0) {
                        return <React.Fragment key={item + index}>{item}</React.Fragment>
                      } else {
                        return  <React.Fragment key={item + index}>, {item}</React.Fragment>
                      }
                    })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {row.lesson ? ( //check to compensate for inconsistent dev data
                    row.lesson.isURL ? (
                      <TableCell>
                        URL:{' '}
                        <a
                          href={`${row.lesson.url}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {row.lesson.url}
                        </a>
                      </TableCell>
                    ) : (
                      <ol>
                        {Object.keys(row.lesson.lessons).map((order) => {
                          //Convert lesson ID to name
                          return (
                            <li>
                              {Object.keys(lessons).map((item) => {
                                if (
                                  row.lesson.lessons[order] ==
                                  lessons[item]['_id']
                                ) {
                                  return lessons[item]['name']
                                }
                              })}
                            </li>
                          )
                        })}
                      </ol>
                    )
                  ) : (
                    <></>
                  )}
                </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'toggle', label: '' },
  { id: 'edit', label: '' }
]

const CoursesTable = ({ courses, onEdit }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  useEffect(() => {
    setPage(0)
  }, [courses])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{}}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ textAlign: 'center' }}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {courses ? (
              courses
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return <Row key={row['_id']} row={row} onEdit={onEdit} />
                })
            ) : (
              <LoadingComp />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={courses.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default CoursesTable
