import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const PromptLoginRoute = ({children}) => {
    const signedIn = useSelector((state) => state.login.isLoggedIn)
    const locationData = useLocation()
    if (signedIn) {
        return children
      } else {
        let redirectToString = `${locationData.pathname}${locationData.search}`
        return <Navigate to={`/login`} state={{redirectTo: redirectToString}} replace={true} />
      }
}

export default PromptLoginRoute;
