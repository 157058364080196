import { Box } from '@mui/material'
import React, { useState } from 'react'
import YouTube from 'react-youtube'

const YoutubePlayer = ({ playerImage }) => {
  const [player, setPlayer] = useState(null)
  const [showPlayerImg, setShowPlayerImg] = useState(100)
  const onReady = (event) => {
    event.target.pauseVideo()
    setPlayer(event.target) //when youtube API loads, set the player
  }

  const play = () => {
    player.playVideo() //starts youtube player
    setShowPlayerImg(-10) //hides player image
  }
  return (
    <Box
      sx={{
        paddingBottom: { xs: '80%', sm: '60%', md: '100%' },
        position: 'relative',
        width: { xs: '80%', sm: '60%', md: '100%' }
      }}
    >
      {/*Player image*/}
      <Box
        onClick={() => play()}
        sx={{
          borderRadius: '50%',
          WebkitBorderRadius: '50%',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      >
        <picture>
          <source
            type='image/webp'
            srcset={require(`../images/${playerImage.replace(/(png|jpg|jpeg)/, 'webp')}`)}
            media='(min-width:300px)'
          />
          <Box
            component='img'
            alt='youtube-preview'
            src={require(`../images/${playerImage}`)}
            sx={{
              position: 'absolute',
              zIndex: showPlayerImg,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: '100%',
              width: '100%',
              borderRadius: '50%',
              WebkitBorderRadius: '50%',

              overflow: 'hidden',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          />
        </picture>
        <Box
          sx={{
            borderRadius: '50%',
            WebkitBorderRadius: '50%',
            WebkitBoxShadow: `inset 0 0 0 10px #ffffff`,
            display: 'block',
            boxShadow: `inset 0 0 0 10px #ffffff`,
            zIndex: `${showPlayerImg + 10}`,
            height: '100%',
            width: '100%',
            position: 'absolute'
          }}
        />
        <Box
          sx={{
            backgroundColor: '#EE4239',
            width: '22%',
            height: '15%',
            position: 'absolute',
            zIndex: `${showPlayerImg + 5}`,
            top: '42.5%',
            left: '39%',
            borderRadius: '12px',
            WebkitBorderRadius: '12px'
          }}
        >
          <Box
            sx={{
              width: 0,
              height: 0,
              paddingTop: '25%',
              paddingBottom: '25%',
              paddingLeft: '30%',
              overflow: 'hidden',
              top: '12%',
              left: '37%',
              position: 'absolute'
            }}
          >
            <Box
              sx={{
                width: 0,
                height: 0,
                marginTop: '-300px',
                borderTop: '300px solid transparent',
                borderBottom: '300px solid transparent',
                display: 'block',
                borderLeft: '500px solid #ffffff',
                marginLeft: '-500px'
              }}
            />
          </Box>
        </Box>
      </Box>
      {/*Player*/}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: '50%',
          WebkitBorderRadius: '50%',
          clipPath: 'circle(50%)',
          overflow: 'hidden',
          border: '6px solid #fff'
        }}
      >
        <YouTube
          videoId='foTB7QHvdNg'
          opts={{
            height: '100%',
            width: '180%',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
              loop: 1,
              playlist: 'foTB7QHvdNg'
            }
          }}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '-40%',
            width: '100%'
          }}
          onReady={(event) => onReady(event)}
        />
      </Box>
    </Box>
  )
}

export default YoutubePlayer
