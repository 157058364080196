import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    selectedUser: null,
    userView: 0,
    locations: []
  },
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    },
    setUserView: (state, action) => {
      state.userView = action.payload
    },
    setLocations: (state, action) => {
      state.locations = action.payload.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    updateLoc: (state, action) => {
      state.locations.splice(
        state.locations.findIndex(function (i) {
          return i._id === action.payload._id
        }),
        1
      )
      state.locations.unshift(action.payload)
    },
  }
})

export const { setSelectedUser, setUserView, setLocations, updateLoc } = userSlice.actions

export default userSlice.reducer
