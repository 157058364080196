import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { useSelector } from 'react-redux'

const SubscriptionSelect = ({ subscriptionID, handleSubscriptionSelect }) => {
  const subscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )
  const student = useSelector((state) => state.subscription.student)

  return (
    <>
      <Typography variant='h6' style={{ textAlign: 'center' }}>
        Select subscription to move{' '}
        {`${student.info.name.fName} ${student.info.name.lName}`} into
      </Typography>
      <FormControl fullWidth>
        <InputLabel id='subscription-dropdown'>Subscriptions</InputLabel>
        <Select
          labelId='subscription-dropdown'
          id='subscription-dropdown-select'
          label='subscription'
          onChange={handleSubscriptionSelect}
        >
          {subscriptions.map((subscription) => {
            let color = 'black'

            if (subscription._id === subscriptionID) {
              color = 'red'
            }

            return (
              <MenuItem
                value={subscription._id}
                key={subscription._id}
                style={{ color: color }}
              >
                {subscription.name}{' '}
                {subscription._id === subscriptionID ? '(enrolled)' : ''}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  )
}

export default SubscriptionSelect
