import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Typography, Grid, Button } from '@mui/material'
import { setClasses } from '../../store/instructorSlice.js'
import dateHandler from '../../utils/dateHandler.js'
import { useSelector } from 'react-redux'
import ClassCard from './classCard.js'
import ActivityCard from './activityCard.js'
import MembershipCard from './membershipCard.js'
import InstructorAPI from '../../api/instructor.js'
import LoadingComp from '../util/loading.js'
import WorkshopModal from '../workshop/workshopModal.js'

const ClassOverview = (props) => {
  const dispatch = useDispatch()

  const classes = useSelector((state) => state.instructor.classes)
  const activities = useSelector((state) => state.instructor.activities)
  const memberships = useSelector((state) => state.instructor.memberships)
  let uID = useSelector((state) => state.login.user._id)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showModal, setShowModal] = useState(false)

  let todaysDate = new Date()
  let upcomingDates = []
  for (let i = 1; i < 7; i++) {
    let nextDate = new Date()
    nextDate.setDate(todaysDate.getDate() + i)
    upcomingDates.push(nextDate)
  }
  const [todaysClasses, setTodaysClasses] = useState([])
  const [todaysActivities, setTodaysActivities] = useState([])
  const [upcomingClasses, setUpcomingClasses] = useState(new Map())
  const [hasUpcomingClasses, setHasUpcomingClasses] = useState(false)

  useEffect(() => {
    function handleSort() {
      let todaysClassesTmp = []
      let classFilter = []
      if (Object.keys(classes).length > 0) {
        classFilter = classes?.filter((item) => {
          let classStartDate = new Date(item.metadata.startDate)
          return (
            classStartDate.getYear() === todaysDate.getYear() &&
            classStartDate.getMonth() === todaysDate.getMonth()
          )
        })
      }

      let upcomingClassesTmp = new Map()
      upcomingDates.forEach((date) => {
        upcomingClassesTmp.set(date.getDate(), [])
      })

      //TO DO: improve wording for date, day, class etc
      classFilter.forEach((item) => {
        //get today's classes
        let startDay = new Date(item.metadata.startDate).getDate()
        let endDay = new Date(item.metadata.endDate).getDate()
        let todaysDay = todaysDate.getDate()
        if (startDay <= todaysDay && todaysDay <= endDay) {
          todaysClassesTmp.push(item)
        }
        //get upcoming classes
        upcomingDates.forEach((date) => {
          let upcomingDay = date.getDate()
          if (startDay <= upcomingDay && upcomingDay <= endDay) {
            setHasUpcomingClasses(true)
            upcomingClassesTmp.set(
              upcomingDay,
              upcomingClassesTmp.get(upcomingDay).concat([item])
            )
          }
        })
      })
      setTodaysClasses(todaysClassesTmp)
      setUpcomingClasses(upcomingClassesTmp)

      //add activities
      let tmpActivities = activities.filter(
        (activity) =>
          activity.day === dateHandler.getShortenedDay(todaysDate.getDay())
      )
      setTodaysActivities(tmpActivities)
    }
    if (!props.loading) {
      handleSort()
    }
  }, [props.loading, classes, activities, memberships])

  const handleShowDetails = (item) => {
    setSelectedProduct(item)
    setShowModal(true)
  }

  const handleAddAssignment = () => {
    props.setCurrTab(1)
  }

  const handleRemoveCourse = async (course) => {
    let resp = await InstructorAPI.removeCourse({
      uID: uID,
      prodID: course.stripeID
    })
    if (resp.success) {
      let tmpClasses = [...classes]
      let index = tmpClasses.findIndex(
        (tmpClass) => tmpClass.stripeID === course.stripeID
      )
      if (index !== -1) {
        tmpClasses.splice(index, 1)
        dispatch(setClasses(tmpClasses))
      }
    } else {
      alert(resp.data.error.message)
    }
  }

  const handleModal = () => {
    setShowModal(false)
  }

  return (
    <Grid container spacing={2} sx={{ width: '90%', margin: 'auto' }}>
      <Grid item xs={12} sm={6}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant='h2'>Today</Typography>
          <Typography variant='h4'>
            {dateHandler.formatWithWeekday(todaysDate)}
          </Typography>
          {props.loading ? (
            <LoadingComp />
          ) : (
            <Grid container spacing={2}>
              {todaysClasses.length === 0 && todaysActivities.length === 0 ? (
                <Grid item>
                  <Typography variant='h2'>No Assignments today</Typography>
                </Grid>
              ) : (
                <>
                  {todaysClasses.map((item, index) => {
                    return (
                      <Grid item>
                        <ClassCard
                          item={item}
                          index={index}
                          handleShowDetails={handleShowDetails}
                          handleRemove={handleRemoveCourse}
                        />
                      </Grid>
                    )
                  })}
                  {todaysActivities.map((activity) => {
                    return (
                      <Grid item>
                        <ActivityCard activity={activity} />
                      </Grid>
                    )
                  })}
                </>
              )}
            </Grid>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container>
          {hasUpcomingClasses ? (
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'left' }}>
                <Typography variant='h2'>Upcoming Workshops</Typography>
                {upcomingDates.map((date) => {
                  let upcomingDay = date.getDate()
                  if (upcomingClasses.get(upcomingDay)?.length > 0) {
                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant='h4' key={upcomingDay}>
                            {dateHandler.formatWithWeekday(date)}
                          </Typography>
                        </Grid>

                        {upcomingClasses.get(upcomingDay).map((item, index) => {
                          return (
                            <Grid item xs={12}>
                              <ClassCard
                                item={item}
                                index={index}
                                handleShowDetails={handleShowDetails}
                                handleRemove={handleRemoveCourse}
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    )
                  } else {
                    return <></>
                  }
                })}
              </Box>
            </Grid>
          ) : (
            <></>
          )}
          {memberships.length > 0 ? (
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'left' }}>
                <Grid container>
                  <Typography variant='h2'>Memberships</Typography>
                  {memberships.map((membership) => {
                    return (
                      <Grid item xs={12}>
                        <MembershipCard membership={membership} />
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Grid>
          ) : (
            <></>
          )}

          {memberships.length === 0 && !hasUpcomingClasses ? (
            <Grid item xs={12}>
              <Typography variant='h2'>No Assignments</Typography>
              <Button
                variant='contained'
                style={{ marginTop: '10px' }}
                onClick={handleAddAssignment}
              >
                Add Assignment
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <WorkshopModal
        workshop={selectedProduct}
        showModal={showModal}
        handleModal={handleModal}
        workshipID={selectedProduct?.stripeID}
        studentList={selectedProduct?.students}
        toggleCustomer={false}
      />
    </Grid>
  )
}

export default ClassOverview
