import cards from './util/cards'
import EmailIcon from '@mui/icons-material/Email'

const InviteNotification = ({ invites, isAdmin }) => {
  const handleViewInvite = (invite) => {
    const link = `https://www.rollingrobots.net/subscription-invite/?id=${invite.subscription._id}&name=${invite.student.info.name.fName}`
    window.open(link, '_self')
  }

  const SmallCard = cards.smallCard

  return (
    <>
      {invites?.length > 0 ? (
        invites.map((invite) => {
          const addProps = {
            onClick: () => handleViewInvite(invite)
          }

          return (
            <>
              {isAdmin ? (
                <SmallCard
                  key={`${invite.subscription._id}_${invite.student.info.name.fName}`}
                  subtitleOne={`${invite.student.info.name.fName} 
                      ${invite.student.info.name.lName} invited to 
                      ${invite.subscription.name} at ${invite.subscription.locationName}`}
                />
              ) : (
                <SmallCard
                  key={`${invite.subscription._id}_${invite.student.info.name.fName}`}
                  addProps={addProps}
                  style={{
                    '&:hover': {
                      backgroundColor: '#fafafa',
                      boxShadow: '0px 2px 8px rgb(0,0,0,0.25)'
                    }
                  }}
                  title={`Invite For ${invite.student.info.name.fName}`}
                  subtitleOne={'Click to View'}
                  icon={EmailIcon}
                ></SmallCard>
              )}
            </>
          )
        })
      ) : (
        <></>
      )}
    </>
  )
}

export default InviteNotification
