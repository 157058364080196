import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Button,
  TextField
} from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { addTag } from '../../../api/tag'

const AddTag = ({ tags, setTags }) => {
  const [newTag, setNewTag] = useState('')
  const [showTagOptions, setShowTagOptions] = useState(false)

  const uID = useSelector((state) => state.login.user._id)

  const addNewTag = async () => {
    let resp = await addTag({
      uID: uID,
      name: newTag
    })
    if (resp.success) {
      setTags([...tags, resp.data])
      setShowTagOptions(false)
      setNewTag('')
    } else {
      alert(resp)
    }
  }

  return (
    <Card>
      <CardHeader title={'Add Tag'}></CardHeader>
      <CardContent>
        {!showTagOptions ? (
          <IconButton
            aria-label='add-student'
            color='primary'
            onClick={() => {
              setShowTagOptions(true)
            }}
          >
            <AddBoxIcon fontSize='large' />
          </IconButton>
        ) : (
          <div>
            <TextField
              label='Tag'
              onChange={(e) => setNewTag(e.target.value)}
            />
            <Button onClick={addNewTag}>Submit</Button>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default AddTag
