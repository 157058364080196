import React from 'react'
import WorkshopInfo from './workshopInfo'
import {
  Card,
  Button,
  useMediaQuery,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import { removeProduct } from '../../store/cartSlice'

const CartCard = ({
  workshop,
  handleSelectStudent,
  children,
  // errorCheck,
  errors,
  handleRemove
}) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)

  const styles = {
    tempWeb: {
      maxHeight: 200,
      overflow: 'auto'
    },
    title: {
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'space-between ',
      // margin: ' 0 auto',
      // color: '#3890CB',
      // width: '100%',
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '15px auto 10% 10% 10%',
      width: '100%'
    }
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const helpTextOptions = {
    defaultSelected: 'Please ensure a student is selected for each camp.',
    doubleStudent: 'Students cannot be enrolled in the same camp twice.',
    capacityFull: 'This class has reached capacity.',
    alreadyEnrolled: 'This student is already enrolled for this class.'
  }

  return (
    <>
      <Card
        sx={{
          height: '100%',
          padding: '10px 10px',
          display: 'flex',
          flexDirection: 'column',
          border: errors ? '1px solid red' : null
        }}
      >
        <WorkshopInfo workshop={workshop} />

        {isLoggedIn ? (
          <FormControl fullWidth sx={{ marginTop: '10px', textAlign: 'left' }}>
            <InputLabel id='roboteer-select-label'>Select Student</InputLabel>
            <Select
              fullWidth
              value={workshop.student == 'default' ? '' : workshop.student}
              onChange={(e) => {
                handleSelectStudent(e.target.value, workshop.cartID)
              }}
              labelId='roboteer-select-label'
              label='Select Student'
            >
              {children.map((item) => {
                return (
                  <MenuItem key={item._id} value={item._id}>
                    {item.info.name.fName}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        ) : null}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              //removing products while on alternate checkout page will redirect user to summer camp page if the cart is empty
              if (typeof handleRemove === 'function') {
                handleRemove(workshop)
              } else {
                dispatch(removeProduct(workshop))
              }
            }}
            sx={{ padding: '0px 0px' }}
          >
            Remove
          </Button>
        </Box>
      </Card>
      <div
        style={{
          color: '#d32f2f',
          fontSize: 12
        }}
      >
        {helpTextOptions[errors]}
      </div>
    </>
  )
}

export default CartCard
