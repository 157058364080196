import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import Section from './section'
import TextStepper from '../util/textStepper'


const testimonialsSection = ({ 
  styles 
}) => {

  let TESTIMONIALS = [
    {
      name: 'E C.',
      subtitle: 'Glendale',
      details:
        "Amazing things can happen when like-minded kids get together and are guided by passionate and caring teachers! This is a true Maker Space, where it's chaotic and energetic, and where the kids feel completely comfortable and free to create and build and make a mess."
    },
    {
      name: 'Brandon W.',
      subtitle: 'Palos Verdes',
      details:
        'My 8-yr old son has attended the summer robotics camp for the past two years. He absolutely enjoys his time branching out and exploring into the world of Robotics. I recommend Rolling Robots to all young minds!'
    },
    {
      name: 'Danielle S.',
      subtitle: 'Los Angeles',
      details:
        'My seven year old son loves Rolling Robots! He goes to almost every Minecraft night and he looks forward to it all week. He likes that he gets to socialize with other Minecraft players and also with the employees who are super friendly and patient.'
    },
    {
      name: 'Talia P.',
      subtitle: 'Los Angeles',
      details:
        " If you are looking for coding classes, you will be very happy with the curriculum at Rolling Robots. My son looks forward to his classes, and when I pick him up he's never ready to go home, completely captivated with his work."
    }
  ]

  return (
    <Section
        id='testimonials'
        sx={{
          backgroundColor: styles.lightBlue,
          padding: '50px 0px 20px 0px',
          display: 'flex',
          flexDirection: 'justify-content'
        }}
      >
        <Box sx={{ ...styles.box, textAlign: 'center' }}>
          <TextStepper yellow={true} textOptions={TESTIMONIALS} />
        </Box>
      </Section>
  )

}
export default testimonialsSection
