import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import {
  Paper,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  useMediaQuery,
  Switch,
  FormControlLabel
} from '@mui/material'
import DatePicker from "react-datepicker"
import CloseIcon from '@mui/icons-material/Close'

import "react-datepicker/dist/react-datepicker.css"

const MessageForm = ({ data, edit, handleClose, handleSave, handleChange, handleUpdate, errors }) => {
  const [focus, setFocus] = useState(null)

  const styles = {
    fields: {
      marginTop: '1vh',
      marginBottom: '1vh',
      age: {
        width: '100%'
      }
    },

    dateTextError: {
      color: '#d32f2f'
    },

    dateBoxError: {
      color: '#d32f2f',
      border: '1px solid #d32f2f',
      borderRadius: '5px',
      width: 'max-content'
    },

    dateHelpText: {
      color: '#d32f2f',
      fontSize: 12,
    },
    tempWeb: {}
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const CATEGORIES = [
    { label: 'Coupons', id: 1 },
    { label: 'Seasonal', id: 2 },
  ]

  const SITE_LOCATION = [
    { label: 'Workshops', id: 3 },
    { label: 'Login ', id: 19 },
    { label: 'Memberships', id: 12 },
    { label: 'Landing Page', id: 99 },
    { label: 'Roboteers', id: 1 },
    { label: 'Holiday Camps', id: 13 }
  ]

  const PRODUCT_FIELDS = [
    {
      'label': 'Title',
      'key': 'title',
      'isRequired': true
    },
    {
      'label': 'Date',
      'key': 'date',
      'isRequired': true
    },
    {
      'label': 'Message',
      'key': 'message',
      'isRequired': true
    },
    {
      'label': 'Categories',
      'key': 'categories',
      'isRequired': true,
      'data': CATEGORIES
    },
    {
      'label': 'Site Location',
      'key': 'siteLocation',
      'isRequired': true,
      'data': SITE_LOCATION
    }
  ]

  const submit = () => {
    if (edit) {
      handleUpdate()
    } else {
      handleSave()
      console.log("save!")
    }
  }

  const standardField = (item) => {
    return (
      <div style={styles.fields}>
        <TextField
          required={item.isRequired}
          defaultValue={data[item.key]}
          margin='dense'
          id={item.type}
          key={item.type}
          error={errors.includes(item.key)}
          label={item.label}
          type={item.type}
          multiline={(item.key === 'message')}
          // onFocus={() => { setFocus(item.key) }}
          fullWidth
          onChange={(e) => { handleChange(item.key, e.target.value) }}
          variant='standard'
        />
      </div>
    )
  }

  const dateField = (item) => {
    return (
      <>
        <div style={errors.includes('startDate') || errors.includes('inThePast') ? styles.dateTextError : null}>Start Date {errors.includes('startDate') || errors.includes('inThePast') ? '*' : ''}</div>
        <div style={errors.includes('startDate') || errors.includes('inThePast') ? styles.dateBoxError : null}>
          <DatePicker
            minDate={new Date()}
            selected={data.startDate}
            onChange={(date) => { handleChange('startDate', date) }}
            showTimeSelect
            dateFormat="Pp"
          />
        </div>
        <div style={styles.dateHelpText} >{errors.includes('startDate') ? 'Start date cannot be blank' : null}</div>
        <div style={styles.dateHelpText} >{errors.includes('inThePast') ? 'Start date cannot be in the past' : null}</div>
        <div style={errors.includes('endDate') || errors.includes('rangeError') || errors.includes('editInThePast') ? styles.dateTextError : null}>End Date {errors.includes('endDate') || errors.includes('rangeError') || errors.includes('editInThePast') ? '*' : ''}</div>
        <div style={errors.includes('startDate') || errors.includes('rangeError') || errors.includes('editInThePast') ? styles.dateBoxError : null}>
          <DatePicker
            minDate={data.startDate}
            selected={data.endDate}
            onChange={(date) => { handleChange('endDate', date) }}
            showTimeSelect
            dateFormat="Pp"
          />
        </div>
        <div style={styles.dateHelpText} >{errors.includes('endDate') ? 'End date cannot be blank' : null}</div>
        <div style={styles.dateHelpText} >{errors.includes('rangeError') ? 'End date cannot be before Start Date' : null}</div>
        <div style={styles.dateHelpText} >{errors.includes('editInThePast') ? 'End date cannot be in the past' : null}</div>
      </>

    )
  }

  const selectField = (item) => {
    return (
      <div style={styles.fields}>
        <FormControl fullWidth error={errors.includes(item.key)} >
          <InputLabel>{item.label}</InputLabel>
          <Select
            multiple
            value={item.key === 'categories' ? data.categories : data.siteLocation}
            label={item.label}
            onChange={(e) => { handleChange(item.key, e.target.value) }}
          >
            {item.data.map((option) => {
              return (
                <MenuItem value={option.id} >
                  {option.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  return (
    <React.Fragment>
      <DialogTitle style={{ textAlign: 'left' }}>Write your message
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={() => {
            handleClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {PRODUCT_FIELDS.map((item) => {
          if (item.key === 'date') {
            return dateField(item)
          } else if (item.key === 'categories' || item.key === 'siteLocation') {
            return selectField(item)
          } else {
            return standardField(item)
          }
        })}
        <FormControl fullWidth>
          <FormControlLabel control={
            <Switch defaultChecked
              checked={data.disabled ? data.disabled : false}
              onChange={() => {
                handleChange('disabled', !data.disabled)
              }}
            />} label='Disabled' sx={{ margin: 'auto' }} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose() }}>Cancel</Button>
        <Button onClick={() => { submit() }}>{edit ? 'Update' : 'Save'}</Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default MessageForm
