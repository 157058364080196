import { useState, useEffect } from 'react'
import {
  Typography,
  Chip,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import moment from 'moment'
import LoadingComp from '../../util/loading'

const ReportDetails = ({ membership, data, loading }) => {
  const currentDate = new Date()
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth())
  const [monthStudents, setMonthStudents] = useState([])
  const [year, setYear] = useState(2024)

  useEffect(() => {
    if (data) {
      handleMonth(selectedMonth)
      getPayments()
    }
  }, [data])

  const handleMonth = (selection) => {
    //Set month index
    setSelectedMonth(selection)

    //set date to last day of month
    let monthDate = new Date(year, selection + 1, 0)

    //get enrolled students
    let students = data.filter((tmp) => {
      let studentStart = new Date(tmp.startDate)
      let studentEnd = tmp.endDate
      if (studentEnd !== null) {
        studentEnd = new Date(studentEnd)
      }

      //Filter students by their start and end date
      if (
        (studentStart.getTime() < monthDate.getTime() && studentEnd === null) ||
        (studentStart.getTime() < monthDate.getTime() &&
          studentEnd.getTime() > monthDate.getTime())
      ) {
        return tmp
      } else {
        return null
      }
    })

    setMonthStudents(students)
  }

  const handleYearChange = (event) => {
    setYear(event.target.value)
  }

  const isInFuture = (monthIndex) => {
    if (
      year > currentDate.getFullYear() ||
      (year === currentDate.getFullYear() &&
        monthIndex > currentDate.getMonth())
    ) {
      return true
    } else {
      return false
    }
  }

  const getPayments = () => {
    let total = 0
    monthStudents.forEach((monthStudent) => {
      let studentTotal = monthStudent.plan.amount / 100

      //Calculate monthly total
      let intervalCount = 1
      if (monthStudent.plan.interval === 'year') {
        intervalCount = 12
      } else {
        intervalCount = monthStudent.plan.interval_count
      }

      studentTotal = studentTotal / intervalCount

      total = total + studentTotal
    })

    return `$${total.toFixed(2)}`
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '80%',
              margin: 'auto'
            }}
          >
            <Typography variant='h4'>
              {membership.name} - {membership.locationName}
            </Typography>
            <FormControl>
              <InputLabel>Year</InputLabel>
              <Select value={year} label='Year' onChange={handleYearChange}>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              margin: '10px 0'
            }}
          >
            {moment.monthsShort().map((month, index) => {
              return (
                <Chip
                  clickable
                  label={month}
                  variant={selectedMonth === index ? '' : 'outlined'}
                  onClick={() => {
                    handleMonth(index)
                  }}
                  key={month}
                  disabled={isInFuture(index)}
                />
              )
            })}
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '75%',
              margin: 'auto'
            }}
          >
            <Box style={{ width: '50%' }}>
              <Typography variant='h1'>{monthStudents.length}</Typography>
              <Typography variant='h6'>students</Typography>
            </Box>

            <Box style={{ width: '50%' }}>
              <Typography variant='h1'>{getPayments()}</Typography>
              <Typography variant='h6'>in monthly payments</Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default ReportDetails
