import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLocationClasses } from '../../../api/admin'
import LoadingComp from '../../util/loading'
import { Typography, Grid } from '@mui/material'
import cards from '../../util/cards'
import dateHandler from '../../../utils/dateHandler'

const LocationDetails = () => {
  const uID = useSelector((state) => state.login.user._id)
  const location = useSelector((state) => state.location.selectedLocation)

  const [loading, setLoading] = useState(true)
  const [subscriptions, setSubscriptions] = useState([])
  const [workshops, setWorkshops] = useState([])

  const SmallCard = cards.smallCard

  useEffect(() => {
    async function handleAsync() {
      let resp = await getLocationClasses({
        params: { uID: uID, locationID: location._id }
      })
      if (resp.success) {
        setSubscriptions(resp.data.subscriptions)
        setWorkshops(resp.data.workshops)
      } else {
        alert(resp)
      }
      setLoading(false)
    }
    handleAsync()
  }, [])

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container sx={{ padding: '20px 40px' }} spacing={2}>
            <Grid item xs={12} align='left'>
              <Typography variant='h6'>Subscriptions</Typography>
            </Grid>
            {subscriptions.map((subscription) => {
              return (
                <Grid item xs={4}>
                  <SmallCard
                    title={subscription.name}
                    subtitleOne={`Enrollment: ${subscription.students.length}`}
                    subtitleTwo={`Activities: ${subscription.activities.length}`}
                  ></SmallCard>
                </Grid>
              )
            })}
          </Grid>
          <Grid container spacing={2} sx={{ padding: '20px 40px' }}>
            <Grid item xs={12}>
              <Typography variant='h6' align='left'>
                Workshops
              </Typography>
            </Grid>
            {workshops.map((workshop) => {
              let startDate = dateHandler
                .formatDate(workshop.metadata.startDate)
                .toDateString()
              let endDate = dateHandler
                .formatDate(workshop.metadata.endDate)
                .toDateString()
              let startTime = workshop.metadata.startTime
              let startMinute = workshop.metadata.endTime || '00'
              let endTime = workshop.metadata.endTime
              let endMinute = workshop.metadata.endMinute || '00'
              let convertedStartTime = dateHandler.toStandardTime(
                `${startTime}:${startMinute}`
              )
              let convertedEndTime = dateHandler.toStandardTime(
                `${endTime}:${endMinute}`
              )
              return (
                <Grid item xs={4}>
                  <SmallCard
                    title={workshop.metadata.courseName}
                    subtitleOne={`${startDate} - ${endDate}`}
                    subtitleTwo={`${convertedStartTime} - ${convertedEndTime}`}
                    subtitleThree={workshop.metadata.workshop}
                  />
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

export default LocationDetails
