import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LocalizationProvider, MobileDatePicker } from '@mui/lab'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { resumeSubscription, editPause } from '../../api/subscription'
import {
  updateSub,
  setSelectedSubscription
} from '../../store/subscriptionSlice'
import { setSelectedUser } from '../../store/userSlice'
import { updateStudent } from '../../store/parentSlice'

const EditPause = ({ open, handleClose, subscription, student, source }) => {
  const dispatch = useDispatch()
  const selectedUser = useSelector((state) => state.user.selectedUser)

  const [showOptions, setShowOptions] = useState(true)
  const [selectedOption, setSelectedOption] = useState(null)
  const [newEndDate, setNewEndDate] = useState(null)
  const [newStartDate, setNewStartDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (subscription !== null && student !== null) {
      let pauseInfo = subscription?.paused?.filter(
        (subItem) => subItem.studentID === student?._id
      )[0]
      if (pauseInfo) {
        setNewEndDate(pauseInfo.returnDate)
        setNewStartDate(pauseInfo.startDate)
      }
    }
  }, [subscription, student])

  const handleSelectOption = (option) => {
    setShowOptions(false)
    setSelectedOption(option)
  }

  const handleBack = () => {
    setShowOptions(true)
    setSelectedOption(null)
  }

  const handleChangeStartDate = (newDate) => {
    if (newDate !== null) {
      setNewStartDate(newDate.toLocaleDateString('en', 'America/Los_Angeles'))
    }
  }

  const handleChangeEndDate = (newDate) => {
    if (newDate !== null) {
      setNewEndDate(newDate.toLocaleDateString('en', 'America/Los_Angeles'))
    }
  }

  const handleSubmit = async () => {
    //Handle resume membership
    let resp = null
    if (selectedOption === 'resume') {
      setLoading(true)
      //Resume subscription in back end
      resp = await resumeSubscription({
        productID: subscription.productID,
        studentID: student._id,
        subscriptionID: subscription._id
      })
      //Handle changeing pause dates
    } else {
      //Check days are seven days apart
      let tmpStart = new Date(newStartDate)
      let tmpEnd = new Date(newEndDate)
      let startDay = tmpStart.getTime() / (1000 * 3600 * 24)
      let endDay = tmpEnd.getTime() / (1000 * 3600 * 24)
      let daysApart = Number(endDay - startDay)

      //Error check
      if (daysApart < 7) {
        setError(true)
      } else {
        setError(false)
        setLoading(true)

        resp = await editPause({
          productID: subscription.productID,
          studentID: student._id,
          subscriptionID: subscription._id,
          endDate: newEndDate,
          startDate: newStartDate
        })
      }
    }
    //Handle updating redux store
    if (!error && resp.success && resp.data.subscription !== null) {
      if (source === 'membership') {
        //Handle updating subscription students on subscription page
        dispatch(setSelectedSubscription(resp.data.subscription))
        dispatch(updateSub(resp.data.subscription))
      } else {
        //handle updating student on account info page
        let tmpStudent = { ...student }
        let tmpSubInfo = []

        tmpStudent.subscriptionInfo?.forEach((tmpSub) => {
          if (tmpSub._id !== resp.data.subscription._id) {
            tmpSubInfo.push(tmpSub)
          } else {
            tmpSubInfo.push(resp.data.subscription)
          }
        })
        tmpStudent['subscriptionInfo'] = tmpSubInfo
        dispatch(updateStudent(tmpStudent))

        let tmpUser = { ...selectedUser }
        let tmpStudents = []

        tmpUser.students.forEach((student) => {
          if (student._id !== tmpStudent._id) {
            tmpStudents.push(student)
          } else {
            tmpStudents.push(tmpStudent)
          }
        })
        tmpUser['students'] = tmpStudents
        dispatch(setSelectedUser(tmpUser))
      }

      setShowOptions(true)
      setSelectedOption(null)
      setLoading(false)
      handleClose()
    } else {
      alert(resp)
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(240, 240, 240, 0.3)'
        },
        '& .MuiPaper-root': { boxShadow: 'none' }
      }}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        Edit pause for {subscription?.name}
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showOptions ? (
          <>
            <Typography>
              Select an option to edit {student?.info?.name?.fName}'s membership
              pause
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => handleSelectOption('resume')}>
                Resume Membership
              </Button>
              <Button onClick={() => handleSelectOption('edit')}>
                Edit Dates
              </Button>
            </div>
          </>
        ) : (
          <>
            {selectedOption === 'resume' ? (
              <Typography variant={'body1'} style={{ textAlign: 'center' }}>
                Confirm choice to resume {subscription?.name} for{' '}
                {student?.info?.name?.fName}
              </Typography>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label='Start Date'
                      value={newStartDate}
                      onChange={handleChangeStartDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label='end Date'
                      value={newEndDate}
                      onChange={handleChangeEndDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                {error ? (
                  <Typography color={'red'}>
                    Error: Pause length must be at least seven days and start
                    date must be today or later
                  </Typography>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {selectedOption === null ? (
          <></>
        ) : (
          <>
            {!loading ? (
              <>
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handleSubmit}>Confirm</Button>
              </>
            ) : (
              <Typography>Loading...</Typography>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default EditPause
