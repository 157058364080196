import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button
} from '@mui/material'

const AlternatePayment = ({
  handleClose,
  updateAlternatePayment,
  currentAuthorization
}) => {
  return (
    <Dialog open>
      <DialogTitle>Alternate Payments</DialogTitle>
      <DialogContent>
        <Typography sx={{ paddingBottom: '15px' }}>
          Are you sure you want to {currentAuthorization ? 'un' : null}authorize
          this user to make alternate payments?
        </Typography>
        <Typography>
          Admins are able to make alternate payment purchases on behalf of
          parents regardless of their authorization status. Authorizing this user will{' '}
          {currentAuthorization ? 'no longer' : null} allow them to purchase
          memberships and workshops with the following methods:
        </Typography>
        <ul>
          <li>
            <Typography>Homeschool Purchase Orders</Typography>
          </li>
          <li>
            <Typography>Checks</Typography>
          </li>
        </ul>
        <Typography></Typography>
        {/* make note about admins */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => updateAlternatePayment(currentAuthorization)}
          variant='contained'
        >
          {currentAuthorization ? 'Unauthorize' : 'Authorize'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlternatePayment
