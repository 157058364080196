import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resetProduct, setProduct } from '../../store/workshopSlice'
import { Box } from '@mui/material/'
import ProductForm from '../../component/adminViews/products/productForm'
import { createProduct } from '../../api/admin'
import ProductsTable from '../../component/adminViews/products/productsTable'
import SecondaryNav from '../../component/util/secondaryNav'
import productHandler from '../../utils/productHandler'
import {
  setWorkshops,
  setFilteredWorkshops,
  setDate
} from '../../store/workshopSlice'
import { GetAllWorkshopsFullInfo } from '../../api/products'

const AdminProductsContainer = () => {
  const dispatch = useDispatch()
  const product = useSelector((state) => state.workshop.product)
  const [showForm, setShowForm] = useState(false)
  const [type, setType] = useState('save')
  const [loading, setLoading] = useState(true)

  const products = useSelector((state) => state.workshop.workshops)
  const date = useSelector((state) => state.workshop.date)
  const filteredProducts = useSelector(
    (state) => state.workshop.filteredWorkshops
  )
  const uID = useSelector((state) => state.login.user._id)
  const [currTab, setCurrTab] = useState(0)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)
  const locations = useSelector((state) => state.user.locations)

  //First get date six months ago to only retreieve workshops from last six months (on first page load)
  useEffect(() => {
    let tmpDate = new Date()
    tmpDate.setMonth(tmpDate.getMonth() - 6)
    dispatch(setDate(tmpDate.toISOString()))
  }, [])

  //Once the year is determined, the API call will retrieve the workshops
  useEffect(() => {
    async function handleAsync() {
      let allWorkshops
      let resp = await GetAllWorkshopsFullInfo({ date: date })
      if (resp.data) {
        allWorkshops = resp.data
        dispatch(setWorkshops(resp.data))
      }
      let filteredWorkshops = allWorkshops
      if (defaultRegion._id && locations.length > 0) {
        let regionLocations = []
        if (defaultRegion.name === 'All') {
          regionLocations = locations.map((tmpLocation) => tmpLocation._id)
        } else {
          regionLocations = locations
            .filter((loc) => loc.region === defaultRegion._id)
            .map((filteredLoc) => filteredLoc._id)
        }

        filteredWorkshops = allWorkshops.filter((workshop) =>
          regionLocations.includes(workshop.metadata.location)
        )
      }
      dispatch(setFilteredWorkshops(filteredWorkshops))
      setLoading(false)
    }

    if (date && locations.length > 0) {
      handleAsync()
    }
  }, [date, defaultRegion, locations])

  const handleClose = () => {
    setCurrTab(0)
    setType('save')
    setShowForm(false)
    dispatch(resetProduct())
  }

  const handleSeason = (product) => {
    //Uses the startdate to get the year and assign it to the workshop's camp code season
    let startDate = new Date(product.startDate)
    let workshopValue = product.workshop
    let year = startDate.getFullYear()
    //Holiday camps in Jan, Feb assigned to previous year season
    if (workshopValue.includes('holiday') && startDate.getMonth() <= 2) {
      year = year - 1
    }
    if (workshopValue.includes('_')) {
      workshopValue =
        workshopValue.split('_')[0] + '_' + year
    } else {
      workshopValue = workshopValue + '_' + year
    }
    let tmpProduct = {
      ...product,
      workshop: workshopValue
    }
    return tmpProduct
  }

  const handleSave = async () => {
    //Uses the startdate to get the year and assign it to the workshop's camp code season
    let tmpProduct = handleSeason(product)
    tmpProduct.price = (tmpProduct.price * 100).toFixed(0)
    tmpProduct.uID = uID
    tmpProduct.startDate = new Date(tmpProduct.startDate)
    let startH = tmpProduct.startTime.getHours()
    let startM = tmpProduct.startTime.getMinutes()
    tmpProduct.endDate = new Date(tmpProduct.endDate)
    tmpProduct.selectDates = tmpProduct.selectDates.map((date) => {
      let tmp = new Date(date)
      tmp.setHours(startH)
      tmp.setMinutes(startM)
      tmp.setSeconds(0)
      tmp.setMilliseconds(0)
      return tmp})
    let resp = await createProduct(tmpProduct)
    if (resp.success) {
      resp.data.priceInfo = resp.data.price
      delete resp.data.price
      let tmpProducts = [...products]
      tmpProducts.unshift(resp.data)
      dispatch(setWorkshops(tmpProducts))
      handleClose()
      setCurrTab(0)
    } else {
      alert('failed creation')
    }
  }

  const handleUpdate = async (course) => {
    let tmpProduct = handleSeason(product)
    tmpProduct.startDate = new Date(tmpProduct.startDate)
    let startH = new Date(tmpProduct.startTime).getHours()
    let startM = new Date(tmpProduct.startTime).getMinutes()
    tmpProduct.endDate = new Date(tmpProduct.endDate)
    tmpProduct.selectDates = tmpProduct.selectDates.map((date) => {
      let tmp = new Date(date)
      tmp.setHours(startH)
      tmp.setMinutes(startM)
      tmp.setSeconds(0)
      tmp.setMilliseconds(0)
      return tmp})
    let resp = await productHandler.update(tmpProduct, course, uID)
    if (resp.success) {
      //update products
      let index = products.findIndex((prod) => prod.id === resp.data.id)
      let tmpProducts = [...products]
      tmpProducts.splice(index, 1, resp.data)
      dispatch(setWorkshops(tmpProducts))

      //updated filtered products
      tmpProducts = [...filteredProducts]
      index = tmpProducts.findIndex((prod) => prod.id === resp.data.id)
      tmpProducts.splice(index, 1, resp.data)
      dispatch(setFilteredWorkshops(tmpProducts))

      dispatch(resetProduct())
      handleClose()
    } else {
      alert('failed update')
    }
  }

  const setForm = (item, passedType) => {
    let formattedProduct = productHandler.formatProduct(item)
    dispatch(setProduct(formattedProduct))
    setShowForm(true)
    setType(passedType)
  }

  const TABS = [
    {
      label: 'Search',
      content: (
        <>
          <ProductsTable setForm={setForm} loading={loading} />
        </>
      )
    },
    {
      label: 'Add Workshop',
      content: (
        <>
          <Box sx={{ width: '80%', margin: '0px auto 100px auto' }}>
            <ProductForm
              handleClose={handleClose}
              handleSave={handleSave}
              handleUpdate={handleUpdate}
              type={type}
              addWorkshop={true}
            />
          </Box>
        </>
      )
    }
  ]

  return (
    <React.Fragment>
      <SecondaryNav
        title='Admin Workshops'
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
      />
      {showForm ? (
        <>
          <ProductForm
            handleClose={handleClose}
            handleSave={handleSave}
            handleUpdate={handleUpdate}
            type={type}
            addWorkshop={false}
          />
        </>
      ) : (
        <></>
      )}
      {TABS[currTab].content}
    </React.Fragment>
  )
}

export default AdminProductsContainer
