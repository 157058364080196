import AdminSubscriptionContainer from '../containers/admin/subscription'
import SubscriptionView from '../component/adminViews/subscription/subscriptionView'
import SubscriptionsView from '../component/adminViews/subscriptions/subscriptionsView'
import EnrollmentView from '../component/adminViews/activityEnrollment/enrollmentView'
import SwitchView from '../component/adminViews/subscriptionSwitch/switchView'
import CancelView from '../component/adminViews/cancelSubscription/cancelView'
import { useRouteError } from 'react-router-dom'

const ErrorPage = () => {
  const error = useRouteError();
    return (
      <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
    )
}

const membershipRoutes = [
  {
    path: '/admin-memberships',
    element: <AdminSubscriptionContainer />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <SubscriptionsView />
      },
      {
        path: ':locationID',
        element: <SubscriptionsView />
      },
      { path: ':locationID/:membershipID', element: <SubscriptionView /> },
      { path: ':locationID/:membershipID/:activityID', element: <EnrollmentView/> },
      { path: 'switch', element: <SwitchView /> },
      { path: 'cancel', element: <CancelView /> }
    ]
  }
]
export default membershipRoutes
