import React from 'react'

const MapSelector = (props) => {
  let location = props.location
  let width = '250'
  let height = '150'
  if (props.height) {
    height = props.height
  }
  if (props.width) {
    width = props.width
  }

  // //These are the production values
  // const locationMap = {
  //   //Palos Verdes
  //   '61e9c5fee04b587cd7b520e1':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212261.4836345683!2d-118.65830898284912!3d33.77137077205131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd4b9566b22bbd%3A0x646029d5af0e7d99!2sRolling%20Robots%2C%20Inc.!5e0!3m2!1sen!2sus!4v1693257333504!5m2!1sen!2sus',
  //   //West LA
  //   '61f2f983e04b587cd7b520f2':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d211168.07536935268!2d-118.58135267278263!3d34.2102194154248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bba2530808ef%3A0x41fbdb67d72063c1!2sRolling%20Robots!5e0!3m2!1sen!2sus!4v1658527390991!5m2!1sen!2sus',
  //   //Glendale
  //   '61f2f9c7e04b587cd7b520f4':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d211325.41314599998!2d-118.54243061328124!3d34.14737619999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c0ff1cb0cd5f%3A0x170db7c3293ecdc3!2sRolling%20Robots!5e0!3m2!1sen!2sus!4v1693257516150!5m2!1sen!2sus',
  //   //La Crescenta
  //   '6236290f63fe1d9b3cc94bd0':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3299.400404382165!2d-118.24493738485005!3d34.21279328056263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2ea5d6b4e04d5%3A0x6669c4a6ee1f8d8!2s2920%20Honolulu%20Ave%2C%20Glendale%2C%20CA%2091214!5e0!3m2!1sen!2sus!4v1658787640565!5m2!1sen!2sus',
  //   //Hermosa Beach
  //   '6259a7ccecc19a67a441161a':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.6351077685895!2d-118.40192588485921!3d33.87329358065497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b385cf849ded%3A0x7e7b317d3279c0b8!2s2521%20Valley%20Dr%2C%20Hermosa%20Beach%2C%20CA%2090254!5e0!3m2!1sen!2sus!4v1658787793070!5m2!1sen!2sus',
  //   //Irvine
  //   '62cb9c1bdda69fed263b6aad':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d848912.8137377918!2d-118.87999885149382!3d33.78480207219754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcddf743e701c5%3A0xa30599e11bb97bdd!2sRolling%20Robots%2C%20Inc.!5e0!3m2!1sen!2sus!4v1693257714048!5m2!1sen!2sus',
  //   //Manhattan Heights
  //   '63d91b8b130490ef8293c88d':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.1013389845357!2d-118.38700658538451!3d33.88704383386631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b47305e3c56b%3A0x35bdcb038b84d872!2s1600%20Manhattan%20Beach%20Blvd%2C%20Manhattan%20Beach%2C%20CA%2090266!5e0!3m2!1sen!2sus!4v1675459235973!5m2!1sen!2sus',
  //   //Newport Beach (St. James Episcopal Church)
  //   '63daf952130490ef829455b6':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.57633304124!2d-117.92769200000001!3d33.6162955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd20012a4e8457%3A0xdfbe970a794e6cc4!2s3209%20Via%20Lido%2C%20Newport%20Beach%2C%20CA%2092663!5e0!3m2!1sen!2sus!4v1678216073799!5m2!1sen!2sus',
  //   //Santa Monica
  //   '63ed2c07ed82558160a5519c':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.737849159956!2d-118.47559468538044!3d34.02493942661705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb3a165ba837%3A0x47d21cec9421ecc8!2s1715%2021st%20St%2C%20Santa%20Monica%2C%20CA%2090404!5e0!3m2!1sen!2sus!4v1675459396418!5m2!1sen!2sus',
  //   //Newport Seashore Academy
  //   '640bca5908da234010058bbc':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.5952017567206!2d-117.86884494969905!3d33.64173644660456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcde30ab5cf2a1%3A0x105ef2f2677056e!2s1011%20Camelback%20St%2C%20Newport%20Beach%2C%20CA%2092660!5e0!3m2!1sen!2sus!4v1678924354473!5m2!1sen!2sus',
  //   //La Canada Flintridge
  //   '640bcbc208da234010058bc2':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3299.5093680053164!2d-118.21524484968334!3d34.21001021675242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2ea1b6aaa6115%3A0xe8cfb5fd7742e6cc!2s1700%20Foothill%20Blvd%2C%20La%20Ca%C3%B1ada%20Flintridge%2C%20CA%2091011!5e0!3m2!1sen!2sus!4v1678925612180!5m2!1sen!2sus',
  //   //Tustin
  //   '640bc00a08da2340100587a1':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3434.377756635172!2d-117.80941006281627!3d33.75058098588734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdbb8c86d1e49%3A0x1523e915e07561c3!2s1221%20Wass%20St%2C%20Tustin%2C%20CA%2092780!5e0!3m2!1sen!2sus!4v1682613928058!5m2!1sen!2sus',
  //   //Manhattan Beach Cornerstore
  //   '640bc5ec08da234010058b0e':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.3322024602985!2d-118.3850935496926!3d33.88109723408315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b47041409cff%3A0xf03f0651781874b6!2sCornerstone%20Christian%20Fellowship!5e0!3m2!1sen!2sus!4v1678925759333!5m2!1sen!2sus',
  //   //Pasadena Foothills Community Church
  //   '641262d0ce0ea509da15fa9e':
  //     'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.55361582931!2d-118.10005114968487!3d34.15776071951507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2dcba5616c097%3A0x656cfb1173835b1c!2sFoothills%20Community%20Church!5e0!3m2!1sen!2sus!4v1678926610360!5m2!1sen!2sus'
  // }

  let locationString =
    location.address.lineOne +
    ',' +
    location.address.lineTwo +
    ',' +
    location.address.city +
    ',' +
    location.address.postal +
    ' ' +
    location.address.state

  return (
    <iframe
      title={`${location.name} map`}
      src={`https://maps.google.com/maps?q=${locationString}&hl=es;&output=embed`}
      width={width}
      height={height}
      style={{ border: '0' }}
    ></iframe>
  )
  //if (locationMap[location._id]) {
  //   return (
  //     <iframe
  //       title='map'
  //       src={locationMap[location._id]}
  //       width={width}
  //       height={height}
  //       style={{ border: '0' }}
  //     ></iframe>
  //   )
  // } else {
  //   return (
  //     <img
  //       className='locationImg'
  //       src={require(`../images/locations_img.png`)}
  //       alt='map'
  //       style={{ width: width, height: height, objectFit: 'cover' }}
  //     />
  //   )
  // }
}

export default MapSelector
