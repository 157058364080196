import axios from 'axios'

const createCheckout = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/services/create-wkshp-session',
      data
    )
    if (resp.status === 200 && resp.data) {
      if (resp.data.status === 'fail') {
        return resp.data
      } else {
        window.location = resp.data.url
        return { status: 'good' }
      }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const createAltCheckout = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/credit/workshop-checkout',
      data
    )
    if (resp.status === 200 && resp.data) {
      if (resp.data.status === 'fail') {
        return resp.data
      } else {
        return resp.data
      }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const CartApi = {
  createCheckout,
  createAltCheckout
}

export default CartApi
