import React, { useState } from 'react'
import { Info } from '@mui/icons-material'
import { ClickAwayListener, Tooltip, Typography } from '@mui/material'

const PaymentPlanToolTip = () => {
  const [showToolTip, setShowToolTip] = useState(false)
  return (
    <>
      <ClickAwayListener onClickAway={() => setShowToolTip(false)}>
        <Tooltip
          onClose={() => setShowToolTip(false)}
          open={showToolTip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                boxShadow: 1
              }
            }
          }}
          title={
            <>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '3px'
                }}
              >
                <Info
                  sx={{
                    color: '#5c5c5c',
                    fontSize: '15px',
                    marginRight: '3px'
                  }}
                />
                <Typography
                  display='inline'
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    fontSize: '15px'
                  }}
                >
                  Payment Plan Not Found
                </Typography>
              </span>
              <Typography sx={{ fontSize: '14px', lineHeight: 1.3 }}>
                This message may appear if the payment plan data cannot be
                retrieved due to the complexity of the database structure.{' '}
              </Typography>
              <Typography sx={{ fontSize: '14px', lineHeight: 1.3, fontWeight: 'bold' }}>
                It doesn't necessarily indicate an issue with the payment plan
                itself.
              </Typography>
              <Typography sx={{ fontSize: '14px', lineHeight: 1.3 }}>
                To confirm the payment plan's existence, you can either:
              </Typography>
              <ul
                style={{
                  margin: '0px 0px',
                  paddingLeft: '20px',
                  color: '#5c5c5c',
                  fontSize: '14px',
                  lineHeight: 1.3
                }}
              >
                <li>
                  Use the Stripe dashboard to search for the student ID and view
                  the subscription details.
                </li>
                <li>Use the Alternate Payment tab on the Orders page.</li>
              </ul>
            </>
          }
        >
          <Info
            onClick={() => {
              setShowToolTip(true)
            }}
            sx={{
              color: '#5c5c5c',
              fontSize: '15px',
              margin: '0px 5px',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          />
        </Tooltip>
      </ClickAwayListener>
    </>
  )
}

export default PaymentPlanToolTip
