import React from 'react'
import Section from '../component/landing/section'
import { Box, Button } from '@mui/material'
import STYLES from '../component/landing/landingStyles'

const EventsLanding = () => {
  let styles = STYLES

  return (
    <Section sx={{backgroundColor: styles.lightGrey}}>
      <Box
        sx={{
          ...styles.box,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component='img'
          alt='events-image'
          sx={{width: {xs: '90vw',sm: '80vw', md: '70vw'}, maxWidth: '700px'}} //adjust styles as needed
          src={require('../images/girl_powered_workshop_2023.jpg')} //Update this URL with new image
        />
        <Button
          sx={{ display: 'inline-block', margin: '10px 0px' }}
          onClick={() =>
            window.open(
              'https://www.rollingrobots.net/direct/?type=workshop&id=prod_OkhD5otwo1Ndcm'
            )
          } //Update this path with new link
        >
          Register
        </Button>
      </Box>
    </Section>
  )
}

export default EventsLanding
