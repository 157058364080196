import React, { Fragment } from 'react'
import { Grid, Typography, Divider, MenuList, MenuItem } from '@mui/material'
import { Person, Info } from '@mui/icons-material'
import CONSTANTS from '../../constants'
import generateUserInfo from '../../utils/genUserInfo'
import cards from '../util/cards'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearCart } from '../../store/cartSlice'

const ParentInfo = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let data = props.data
  let permissions = props.permissions
  let handleEdit = props.handleEdit
  let handleAlternatePayment = props.handleAlternatePayment
  let index = props.index
  let type = props.type

  let BigCard = cards.bigCard
  const checkAddress = () => {
    let fields = ['lineOne', 'city', 'state', 'postal']
    return fields.every((field) => data.info.address[field] !== '')
  } //checks for users that moved from previous site with missing info

  const styles = {
    button: {
      backgroundColor: CONSTANTS.RED_HEX,
      padding: '5px',
      margin: '5px'
    },
    title: {
      marginTop: '15px'
    },
    paper: {}
  }

  const editMenuContent = () => {
    return (
      <MenuList>
        <MenuItem key='edit' onClick={() => handleEdit(data, type, index)}>
          Edit
        </MenuItem>
        {permissions > 3 //TO DO: Finish Place Membership and Workshop Order
          ? [
              <MenuItem
                key='alternate-payments'
                onClick={() => handleAlternatePayment()}
              >
                {data.creditAuthorized ? 'Unauthorize' : 'Authorize'} Alternate
                Payments
              </MenuItem>,
              <MenuItem
                key='membership-order'
                onClick={() =>
                  navigate('/memberships', { state: { purchaseByAdmin: true } })
                }
              >
                Place Membership Order (Alternate Payment)
              </MenuItem>,
              //TO DO: change this from being hardcoded for summer camps
              <MenuItem
                key='summer-camp-order'
                onClick={() => {
                  dispatch(clearCart())
                  navigate('/summer-camps', {
                    state: { purchaseByAdmin: true }
                  })
                }}
              >
                Place Summer Camp Order (Alternate Payment)
              </MenuItem>,
              <MenuItem
                key='other-workshop-order'
                onClick={() => {
                  dispatch(clearCart())
                  navigate('/upcoming-camps', {
                    state: { purchaseByAdmin: true }
                  })
                }}
              >
                Place Other Workshop Order (Alternate Payment)
              </MenuItem>,
              <MenuItem
                key='holiday-camp-order'
                onClick={() => {
                  dispatch(clearCart())
                  navigate('/holiday-camps', {
                    state: { purchaseByAdmin: true }
                  })
                }}
              >
                Place Holiday Camp Order (Alternate Payment)
              </MenuItem>
            ]
          : null}
      </MenuList>
    )
  }

  return (
    <>
      <BigCard
        icon={Person}
        title={
          data.info.name.fName
            ? generateUserInfo.generateName(data.info.name)
            : 'N/A'
        }
        id='parent-info'
        editMenu={permissions !== 3 ? editMenuContent() : null} //Instructors cannot edit parent info
      >
        <Grid container>
          {permissions >= 3 ? (
            <>
              <Grid item xs={4}>
                <Typography align='left' fontWeight='bold'>
                  Parent ID
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography align='left'>{data._id} </Typography>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={4}>
            <Typography align='left' fontWeight='bold'>
              Address
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align='left'>
              {checkAddress() ? (
                <>
                  <span>
                    {generateUserInfo.generateAddrOne(data.info.address)}
                  </span>
                  <br />
                  <span>
                    {generateUserInfo.generateAddrTwo(data.info.address)}
                  </span>
                </>
              ) : (
                <>N/A</>
              )}
            </Typography>
          </Grid>

          {data.creditAuthorized ? (
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'left',
                display: 'flex'
              }}
            >
              <Info fontSize='10px' color='secondary' />
              <Typography color='secondary' sx={{ fontSize: '12px' }}>
                Authorized to Make Alternate Payments
              </Typography>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12}>
            <Typography align='left' variant='h3' style={styles.title}>
              Primary Contact
            </Typography>
            <Divider sx={{ marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={4}>
            <Typography align='left' fontWeight='bold'>
              Email
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align='left'>
              {data.info.contact.email || data.userName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align='left' fontWeight='bold'>
              Phone
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography align='left'>
              {data.info.contact.phone || 'N/A'}
            </Typography>
          </Grid>

          {/*Temporary workaround for empty alt objects */}
          {data.info.contact.alt.length >= 1 &&
          Object.values(data.info.contact.alt[0]).some(
            (item) => item !== ''
          ) ? (
            <Fragment>
              <Grid item xs={12}>
                <Typography align='left' variant='h3' sx={styles.title}>
                  Secondary Contact
                  {data.info.contact.alt.length > 1 ? 's' : null}
                </Typography>
                <Divider sx={{ marginBottom: '10px' }} />
              </Grid>
              {data.info.contact.alt.map((item, i) => {
                return (
                  <Fragment key={'secondary_' + i}>
                    {/*check for secondary contact name */}
                    {item.fName && item.fName !== '' ? (
                      <>
                        <Grid item xs={4}>
                          <Typography align='left' fontWeight='bold'>
                            Name
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>
                            {item.fName} {item.lName ? item.lName : null}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                    {/*check for secondary email */}
                    {item.email && item.email !== '' ? (
                      <>
                        <Grid item xs={4}>
                          <Typography align='left' fontWeight='bold'>
                            Email
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>{item.email}</Typography>
                        </Grid>
                      </>
                    ) : null}
                    {/*check for secondary phone */}
                    {item.phone && item.phone !== '' ? (
                      <>
                        <Grid item xs={4}>
                          <Typography align='left' fontWeight='bold'>
                            Phone
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align='left'>{item.phone}</Typography>
                        </Grid>
                      </>
                    ) : null}
                    {data.info.contact.alt.length - 1 === i ? null : (
                      <Grid item xs={12}>
                        <Divider sx={{ margin: '5px 0px' }} />
                      </Grid>
                    )}
                  </Fragment>
                )
              })}
            </Fragment>
          ) : null}
        </Grid>
      </BigCard>
    </>
  )
}

export default ParentInfo
