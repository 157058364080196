import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery
} from '@mui/material'
import {
  updateConfirmation,
} from '../../api/credit'
import {
  CheckCircleOutline,
  HighlightOff,
  ArrowDropDown
} from '@mui/icons-material'

const ConfirmAltPayButton = ({ credit, handleStatus }) => {
  const uID = useSelector((state) => state.login.user._id)
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const medium = useMediaQuery((theme) => theme.breakpoints.down('md'))
  let buttonWidth = mobile ? 'auto' : medium ? '100px' : '150px'
  const [status, setStatus] = useState(credit.confirmed)
  const [showMenu, setShowMenu] = useState(false)
  const anchorRef = useRef(null)
  const STATUS_OPTIONS = [
    {
      change: 'Reset',
      label: 'Pending',
      value: 0,
      variant: 'contained',
      color: 'primary',
      icon: ''
    },
    {
      change: 'Confirm',
      label: 'Confirmed',
      value: 1,
      variant: 'outlined',
      color: 'secondary',
      icon: <CheckCircleOutline />
    },
    {
      change: 'Reject',
      label: 'Rejected',
      value: 2,
      variant: 'outlined',
      color: 'warning',
      icon: <HighlightOff />
    }
  ]

  const handleStatusChange = async (value) => {
    updateConfirmation({
      uID: uID,
      creditID: credit._id,
      confirmedValue: value
    })
    setStatus(value)
    //handles any additional changes from the parent container
    handleStatus(value, credit)
    setShowMenu(false)
  }
  let menuItems = [...STATUS_OPTIONS]
  menuItems.splice(status, 1)
  return (
    <>
      <ButtonGroup
        variant={STATUS_OPTIONS[status]['variant']}
        color={STATUS_OPTIONS[status]['color']}
        ref={anchorRef}
        sx={{ width: buttonWidth }}
      >
        <Button
          onClick={() => {
            setShowMenu((prev) => !prev)
          }}
          sx={{ width: '100%' }}
        >
          {mobile
            ? STATUS_OPTIONS[status]['icon']
            : STATUS_OPTIONS[status]['label']}
          {status === 0 ? <ArrowDropDown /> : null}
        </Button>
      </ButtonGroup>
      <Popper
        open={showMenu}
        anchorEl={anchorRef.current}
        sx={{ width: buttonWidth, zIndex: 1500 }} //Modal zIndex is 1300
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setShowMenu(false)}>
            <MenuList>
              {menuItems.map((option) => (
                <MenuItem
                  onClick={(e) => handleStatusChange(e.target.value)}
                  key={option.label}
                  value={option.value}
                >
                  {option.change}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}

export default ConfirmAltPayButton
