import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  List,
  ListItem,
  Tooltip
} from '@mui/material'
import {
  setSelectedActivity,
  setSelectedSubscription
} from '../../../store/subscriptionSlice'
import dateHandler from '../../../utils/dateHandler'
import timeHandler from '../../../utils/timeHandler'
import { useNavigate } from 'react-router-dom'

const CompressedSubscriptionGrid = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const filteredActivities = useSelector(
    (state) => state.subscription.filteredActivities
  )

  const subscriptions = useSelector(
    (state) => state.subscription.filteredSubscriptions
  )

  //set data for table
  let headers = ['Time', 'Sat', 'Sun', 'M', 'T', 'W', 'Thr', 'F']
  let startTimes = [
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00'
  ]
  const [rows, setRows] = useState([])

  useEffect(() => {
    let tmpRows = []
    startTimes.forEach((startTime) => {
      //Add start time to row
      let tmpRow = [startTime]

      //Iterate through headers to check if a class takes place at the day and time
      headers.forEach((header, index) => {
        if (index !== 0) {
          let timeActivities = filteredActivities.filter(
            (filteredActivity) =>
              filteredActivity.instance.day === header &&
              filteredActivity.instance.startTime === startTime
          )
          tmpRow.push(timeActivities)
        }
      })
      tmpRows.push(tmpRow)
    })

    setRows(tmpRows)
  }, [filteredActivities])

  const getSubscriptionName = (activity) => {
    let subscription = subscriptions.find(
      (sub) => activity.instance.subscriptionID === sub._id
    )

    return `${subscription?.name}`
  }

  const handleClick = (activity) => {
    let subscription = subscriptions.find(
      (sub) => activity.instance.subscriptionID === sub._id
    )
    dispatch(setSelectedSubscription(subscription))
    dispatch(setSelectedActivity(activity))
    navigate(
      `/admin-memberships/enrollment/${activity.instance.subscriptionID}`
    )
  }

  return (
    <Box>
      <Table style={{ marginTop: '10px' }} stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((header, headerIndex) => {
              return (
                <TableCell
                  key={header}
                  width='10%'
                  style={{
                    borderWidth: 0,
                    borderWidth: 1,
                    borderColor: 'black',
                    borderStyle: 'solid'
                  }}
                >
                  {headerIndex === 0
                    ? header
                    : dateHandler.getLengthenedDay(header)}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            return (
              <TableRow>
                {row.map((item, index) => {
                  return (
                    <TableCell
                      style={{
                        borderWidth: 0,
                        borderWidth: 1,
                        borderColor: 'gray',
                        borderStyle: 'solid'
                      }}
                    >
                      {index === 0 ? (
                        timeHandler.convertTime(item)
                      ) : (
                        <>
                          {item.length > 0 ? (
                            <List>
                              {item.map((act) => {
                                return (
                                  <ListItem
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleClick(act)}
                                  >
                                    <Tooltip title={getSubscriptionName(act)}>
                                      <Typography variant='caption'>
                                        {act.course.name}:{' '}
                                        {act.instance.students.length}
                                      </Typography>
                                    </Tooltip>
                                  </ListItem>
                                )
                              })}
                            </List>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default CompressedSubscriptionGrid
