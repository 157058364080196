import { createSlice } from '@reduxjs/toolkit'

export const parentSlice = createSlice({
  name: 'parent',
  initialState: {
    hasStudents: false,
    students: [],
    courses: [],
    memberships: []
  },
  reducers: {
    setStudents: (state, action) => {
      state.hasStudents = true
      state.students = action.payload
    },
    updateStudent: (state, action) => {
      let tmpStudents = []
      state.students.forEach((student) => {
        if (student._id !== action.payload._id) {
          tmpStudents.push(student)
        } else {
          tmpStudents.push(action.payload)
        }
      })
      state.students = tmpStudents
    },
    addStudent: (state, action) => {
      state.students.push(action.payload)
    },
    rmStudents: (state) => {
      state.hasStudents = false
      state.students = {}
    },
    setCourses: (state, action) => {
      state.courses = action.payload
    },
    setMemberships: (state, action) => {
      state.memberships = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setStudents,
  rmStudents,
  addStudent,
  setCourses,
  setMemberships,
  updateStudent,
  updateParentLocations
} = parentSlice.actions

export default parentSlice.reducer
