import axios from 'axios'

export const getCustomers = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/customers',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const searchCustomers = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/search-customers',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getStudentsById = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/studentsById',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}
//Gets all workshops without bare minimum details
//Use products.js > GetAllWorkshopsFullInfo for workshops WITHOUT complete details
export const getAllWorkshopsBasicInfo = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL +
        '/api/product/get-all-workshops-basic-info',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getCourses = async () => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/courses'
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getLessons = async () => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/lessons'
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createProduct = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/products/create',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/membership/create',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const updateMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/membership/update',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createMessage = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/message/create',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getMessages = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/message/get-all',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getActiveMessages = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/message/get-active',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const updateMessages = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/message/update',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const updateProduct = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/products/update',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getEnrolledStudents = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/courses/get-students',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getOrders = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/orders',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getOrdersPI = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/orders-PI',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const notifyCourse = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/course-notify',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}
export const sendTest = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/send-test',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const sendReceipt = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/send-receipt',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const emailMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/email-membership',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const emailSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/email-subscription',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getEmails = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-emails',
      data
    )
    if (resp.status === 200 && resp.data && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, error: resp.data.error.message }
    }
  } catch (err) {
    return err
  }
}

export const attendanceAdd = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/attendance-add',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getAttendance = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-attendance',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const attendanceEdit = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/attendance-edit',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getMembershipAttendance = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-membership-attendance',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const updateUser = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/update-user',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const editNote = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/edit-student-note',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const changeCourse = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/change-course',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, error: resp.data.error.message }
    }
  } catch (err) {
    return err
  }
}

export const getStudents = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-students',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const inviteToMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/membership-invite',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getMemberships = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-memberships',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const inviteToMembershipFromCourseCard = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/membership-invite-course',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const changeMembership = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/membership-change',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return { success: false }
    }
  } catch (err) {
    return err
  }
}

export const getInviteRequests = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-invite-requests',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getInvites = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-invites',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const acceptInviteRequest = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/accept-invite-request',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const declineInviteRequest = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/decline-invite-request',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/create-subscription',
      data
    )
    if (resp.data.success) {
      return { success: true, data: resp.data }
    } else {
      return resp.data
    }
  } catch (err) {
    return err
  }
}

export const getAllSubscriptions = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-all-subscriptions',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getSubscription = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-subscription',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getActivities = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-activities',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getAllActivities = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-all-activities',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getActivityStudents = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-activity-students',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getParent = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-parent',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getDaysAttendance = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-days-attendance',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const submitAttendance = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/submit-attendance',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getActivityAttendance = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-activity-attendance',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const changeAttendance = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/change-attendance',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const resumeMembershipBilling = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/resume-billing',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getSubscriptionActivities = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL +
        '/api/admin/get-subscription-activities',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const updateSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/update-subscription',
      data
    )
    if (resp.data.success) {
      return { success: true, data: resp.data }
    } else {
      return resp.data
    }
  } catch (err) {
    return err
  }
}

export const getSubscriptionStudents = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-subscription-students',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const changeSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/change-subscription',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const switchPayment = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/switch-payment',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true, data: resp.data }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const inviteToSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/subscription-invite',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const cancelSubscription = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/subscription-cancel',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getLinkedActivity = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-linked-activity',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const tagStudent = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/tag-student',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

// export const untagStudent = async (data) => {
//   try {
//     let resp = await axios.post(
//       process.env.REACT_APP_LOCAL_URL + '/api/admin/untag-student',
//       data
//     )
//     if (resp.status === 200) {
//       return { success: true, data: resp.data }
//     } else {
//       return resp
//     }
//   } catch (err) {
//     return err
//   }
// }

// export const addTag = async (data) => {
//   try {
//     let resp = await axios.post(
//       process.env.REACT_APP_LOCAL_URL + '/api/admin/add-tag',
//       data
//     )
//     if (resp.status === 200) {
//       return { success: true, data: resp.data }
//     } else {
//       return resp
//     }
//   } catch (err) {
//     return err
//   }
// }

export const removeTag = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/remove-tag',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getLocationsInfo = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/locations-info',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getLocationClasses = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/location-classes',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getAdmins = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/admins',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const updateLocation = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/update-location',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const updateWorkshopSeasons = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/update-workshop-seasons',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createLocation = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/create-location',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getWorkshopCharge = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/workshop-charge',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const cancelWorkshop = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/cancel-workshop',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const GetTemplates = async () => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-templates'
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const updateTemplate = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/update-template',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getInstructors = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/get-instructors',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getInstructorAssignments = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/assignments',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const removeInstructor = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/remove-instructor',
      data
    )
    if (resp.status === 200 && !resp.data.error) {
      return { success: true }
    } else {
      return { success: false, data: resp.data }
    }
  } catch (err) {
    return err
  }
}

export const searchUsers = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/user-search',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const createInstructor = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/create-instructor',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getPayment = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/services/get-payment',
      data
    )
    if (resp.status === 200) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const getMembershipReport = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/admin/membership-report',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}
