import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  Typography,
  Paper,
  Button,
  useMediaQuery,
  Box,
  Chip
} from '@mui/material'
import COURSE_API from '../../api/course'
import LoadingComp from '../util/loading'
import { assignInstructor } from '../../api/products'
import {
  setActivities,
  setCourses,
  setMemberships,
  setClasses
} from '../../store/instructorSlice'
import {
  getSubscriptionsByLocation,
  getSubscriptionActivities,
  assignInstructorActivity
} from '../../api/subscription'

const AddAssignments = ({ setCurrTab }) => {
  const [step, setStep] = useState(0)
  const [type, setType] = useState('')
  const [season, setSeason] = useState('')
  const [location, setLocation] = useState('')
  const [products, setProducts] = useState([])
  const [membershipOptions, setMembershipOptions] = useState([])
  const [product, setProduct] = useState('')
  const [membership, setMembership] = useState('')
  const [activityOptions, setActivityOptions] = useState([])
  const [selectedActivity, setSelectedActivity] = useState('')
  const [loading, setLoading] = useState(false)
  const [instructor, setInstructor] = useState(null)

  const dispatch = useDispatch()
  const user = useSelector((state) => state.login.user)
  const locationOptions = useSelector((state) => state.user.locations)
  const classes = useSelector((state) => state.instructor.classes)
  const instructorMemberships = useSelector(
    (state) => state.instructor.memberships
  )
  const instructorActivities = useSelector(
    (state) => state.instructor.activities
  )
  const instructorCourses = useSelector((state) => state.instructor.courses)

  const styles = {
    select: { margin: 'auto', width: '50%' },
    form: { width: '75%' },
    review: { marginLeft: '-100px', width: '90%' }
  }

  let check = useMediaQuery('(max-width:900px)')
  if (check) {
    styles.select = { margin: 'auto', width: '75%' }
    styles.review = {
      margin: 'auto',
      width: '75%'
    }
  }

  if (step === 6) {
    styles.select = {}
    styles.review = { margin: 'auto', width: '75%', maxWidth: '500px' }
  }

  useEffect(() => {
    async function getProducts() {
      setLoading(true)
      let resp = await COURSE_API.getProductsByLocation({
        params: { location: location, season: season, uID: user._id }
      })
      if (resp.success) {
        setLoading(false)
        setProducts(resp.data)
      } else {
        setLoading(false)
        alert(resp)
      }
    }

    async function getMemberships() {
      setLoading(true)
      let resp = await getSubscriptionsByLocation({
        params: { locationID: location }
      })
      if (resp.success) {
        setLoading(false)
        setMembershipOptions(resp.data)
      } else {
        setLoading(false)
        alert(resp)
      }
    }

    async function getActivities() {
      setLoading(true)
      let tmpMemberships = membershipOptions.filter(
        (tmpMembership) => tmpMembership._id === membership
      )
      let membershipObj = tmpMemberships[0]

      let resp = await getSubscriptionActivities({
        params: { activityIDs: membershipObj.activities }
      })
      if (resp.success) {
        setActivityOptions(resp.data)
        setLoading(false)
      } else {
        setLoading(false)
        alert(resp)
      }
    }

    if (step === 3) {
      getProducts()
    } else if (step === 4) {
      getMemberships()
    } else if (step === 5) {
      getActivities()
    }
  }, [step])

  const handleTypeChange = (e) => {
    setType(e.target.value)
    if (e.target.value === 'workshop') {
      setStep(1)
    } else {
      setStep(2)
    }
  }

  const handleSeasonChange = (e) => {
    setSeason(e.target.value)
    setStep(2)
  }

  const handleLocationChange = (e) => {
    setLocation(e.target.value)
    if (type === 'workshop') {
      setStep(3)
    } else {
      setStep(4)
    }
  }

  const handleProductChange = (e) => {
    setProduct(e.target.value)

    let tmpProducts = products.filter(
      (tmpProduct) => tmpProduct.id === e.target.value
    )

    if (tmpProducts[0].instructor) {
      setInstructor(tmpProducts[0].instructor)
    }

    setStep(6)
  }

  const handleMembershipChange = (e) => {
    setMembership(e.target.value)
    setStep(5)
  }

  const handleActivityChange = (e) => {
    let tmpActivities = activityOptions.filter(
      (tmpActivity) => tmpActivity.instance._id === e.target.value
    )

    setInstructor(tmpActivities[0].instructor)

    setSelectedActivity(e.target.value)
    setStep(6)
  }

  const getLocationName = (locationID) => {
    let tmpLocations = locationOptions.filter(
      (tmpLocation) => tmpLocation._id === locationID
    )
    let locationName = tmpLocations[0].name
    return locationName
  }

  const getMembershipName = (membershipID) => {
    let tmpMemberships = membershipOptions.filter(
      (tmpMembership) => tmpMembership._id === membershipID
    )
    let membershipName = tmpMemberships[0].name

    if (membershipName.includes('-')) {
      membershipName = membershipName.split('-')[1]
    }

    return membershipName
  }

  const getWorkshopName = (productID) => {
    let tmpProducts = products.filter(
      (tmpProduct) => tmpProduct.id === productID
    )
    let workshopName = tmpProducts[0].metadata.courseName

    return workshopName
  }

  const getWorkshopDate = (productID) => {
    let tmpProducts = products.filter(
      (tmpProduct) => tmpProduct.id === productID
    )

    let start = new Date(tmpProducts[0].metadata.startDate).toLocaleDateString()

    let end = new Date(tmpProducts[0].metadata.endDate).toLocaleDateString()

    return `${start} - ${end}`
  }

  const getActivityName = () => {
    let tmpActivities = activityOptions.filter(
      (tmpActivity) => tmpActivity.instance._id === selectedActivity
    )
    return tmpActivities[0].course.name
  }

  const getActivityTime = () => {
    let tmpActivities = activityOptions.filter(
      (tmpActivity) => tmpActivity.instance._id === selectedActivity
    )
    return `${tmpActivities[0].instance.startTime} - ${tmpActivities[0].instance.endTime}, ${tmpActivities[0].instance.day}`
  }

  /**
   * Steps:
   * 1: Select Type (Workshop or Membership)
   * 2: Select Location
   * 3: Select Workshop (skip if type is Membership)
   * 4: Select Membership (skip if type if Workshop)
   * 5: Select Activity (skip if type is Workshop)
   * 6: Confirm
   */

  const handleBack = () => {
    switch (step) {
      case 1:
        setType('')
        setStep(0)
        break
      case 2:
        if (type === 'workshop') {
          setSeason('')
          setStep(1)
        } else {
          setType('')
          setStep(0)
        }
        break
      case 3:
        setLocation('')
        setStep(2)
        break
      case 4:
        setLocation('')
        setStep(2)
        break
      case 5:
        setMembership('')
        setStep(4)
        break
      case 6:
        if (type === 'workshop') {
          setProduct('')
          setInstructor(null)
          setStep(3)
        } else {
          setSelectedActivity('')
          setStep(5)
          setInstructor(null)
        }
        break
    }
  }

  const handleConfirm = async () => {
    if (type === 'workshop') {
      let resp = await assignInstructor({ uID: user._id, prodID: product })
      if (resp.success) {
        let tmpClasses = [...classes]
        tmpClasses.push(resp.data.product)
        dispatch(setClasses(tmpClasses))
        setCurrTab(0)
      } else {
        alert(resp)
      }
    } else {
      let resp = await assignInstructorActivity({
        uID: user._id,
        activityID: selectedActivity
      })
      if (resp.success) {
        let index = instructorCourses.findIndex(
          (elm) => elm._id === resp.data.course._id
        )
        if (index === -1) {
          let tmpCourses = [...instructorCourses]
          tmpCourses.push(resp.data.course)
          dispatch(setCourses(tmpCourses))
        }

        index = instructorMemberships.findIndex(
          (elm) => elm._id === resp.data.subscription._id
        )
        if (index === -1) {
          let tmpMemberships = [...instructorMemberships]
          tmpMemberships.push(resp.data.subscription)
          dispatch(setMemberships(tmpMemberships))
        }

        let tmpActivities = [...instructorActivities]
        tmpActivities.push(resp.data.instance)
        dispatch(setActivities(tmpActivities))

        setCurrTab(0)
      } else {
        alert(resp)
      }
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={step === 6 ? 0 : 8} xs={12}>
        <Paper style={styles.select}>
          <Grid container>
            {step === 0 ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='type'>Select Assignment Type</InputLabel>
                  <Select
                    labelId='type'
                    id='type'
                    value={type}
                    label='type'
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={'workshop'}>Workshop</MenuItem>
                    <MenuItem value={'membership'}>Membership</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}

            {step === 1 ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='season'>Select Season</InputLabel>
                  <Select
                    labelId='season'
                    id='season'
                    value={season}
                    label='season'
                    onChange={handleSeasonChange}
                  >
                    <MenuItem value={'summer'}>Summer</MenuItem>
                    <MenuItem value={'spring'}>Spring</MenuItem>
                    <MenuItem value={'holiday'}>Holiday</MenuItem>
                    <MenuItem value={'other'}>Upcoming</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}

            {step === 2 ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='location'>Select Location</InputLabel>
                  <Select
                    labelId='location'
                    id='location'
                    value={location.name}
                    label='location'
                    onChange={handleLocationChange}
                  >
                    {locationOptions.map((option) => {
                      return (
                        <MenuItem value={option._id}>{option.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}

            {step === 3 ? (
              <Grid item xs={12}>
                {loading ? (
                  <LoadingComp />
                ) : (
                  <FormControl>
                    <FormLabel id='workshops'>Workshops</FormLabel>
                    <RadioGroup
                      aria-labelledby='workshops'
                      name='workshops'
                      value={product.id}
                      onChange={handleProductChange}
                    >
                      {products.map((workshop) => {
                        let startDate = new Date(
                          workshop.metadata.startDate
                        ).toLocaleDateString()
                        let endDate = new Date(
                          workshop.metadata.endDate
                        ).toLocaleDateString()
                        let workshopTitle = `${workshop.metadata.courseName}: ${startDate} - ${endDate}`
                        return (
                          <Box
                            style={{
                              width: '90%',
                              margin: '2px auto',
                              textAlign: 'left',
                              padding: '5px',
                              border: '2px solid gray',
                              borderRadius: '25px'
                            }}
                          >
                            <FormControlLabel
                              value={workshop.id}
                              control={<Radio />}
                              label={
                                <Typography
                                  align='center'
                                  style={{ margin: 'auto' }}
                                >
                                  {workshopTitle}
                                </Typography>
                              }
                            />
                            {workshop.instructors ? (
                              <div style={{ display: 'flex' }}>
                                <Typography color='error'>
                                  Instructors:{' '}
                                </Typography>
                                {workshop.instructors.map((instructor) => (
                                  <Chip
                                    label={`${instructor.info.name.fName}`}
                                    size='small'
                                  />
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </Box>
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                )}
              </Grid>
            ) : (
              <></>
            )}

            {step === 4 ? (
              <Grid item xs={12}>
                {loading ? (
                  <LoadingComp />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel id='location'>Select Membership</InputLabel>
                    <Select
                      labelId='membership'
                      id='membership'
                      value={membership.name}
                      label='location'
                      onChange={handleMembershipChange}
                    >
                      {membershipOptions.map((option) => {
                        return (
                          <MenuItem value={option._id}>{option.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            ) : (
              <></>
            )}

            {step === 5 ? (
              <Grid item xs={12}>
                {loading ? (
                  <LoadingComp />
                ) : (
                  <FormControl>
                    <FormLabel id='workshops'>Activities</FormLabel>
                    <RadioGroup
                      aria-labelledby='activities'
                      name='activities'
                      value={selectedActivity}
                      onChange={handleActivityChange}
                    >
                      {activityOptions.map((activity) => {
                        let activityName = `${activity.course.name}, ${activity.instance.startTime} - ${activity.instance.endTime}, ${activity.instance.day}`
                        return (
                          <Box
                            style={{
                              width: '90%',
                              margin: '2px auto',
                              textAlign: 'left',
                              padding: '5px',
                              border: '2px solid gray',
                              borderRadius: '25px'
                            }}
                          >
                            <FormControlLabel
                              value={activity.instance._id}
                              control={<Radio />}
                              label={activityName}
                            />
                            {activity.instructor ? (
                              <Typography align='center' color='error'>
                                Instructor:{' '}
                                {activity.instructor.info.name.fName}{' '}
                                {activity.instructor.info.name.lName}
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </Box>
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                )}
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {step !== 0 && step !== 6 ? (
            <Grid item xs={12}>
              <Button onClick={handleBack}>Back</Button>
            </Grid>
          ) : (
            <></>
          )}
        </Paper>
      </Grid>
      <Grid item md={step === 6 ? 12 : 4} xs={12}>
        <Paper style={styles.review}>
          <Typography variant='h6'>Selections</Typography>
          {type === '' ? (
            <></>
          ) : (
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Type:</span> {type}
            </Typography>
          )}
          {season === '' ? (
            <></>
          ) : (
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Season:</span> {season}
            </Typography>
          )}
          {location === '' ? (
            <></>
          ) : (
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Location: </span>
              {getLocationName(location)}
            </Typography>
          )}
          {membership === '' ? (
            <></>
          ) : (
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Membership: </span>
              {getMembershipName(membership)}
            </Typography>
          )}
          {product === '' ? (
            <></>
          ) : (
            <>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Workshop: </span>
                {getWorkshopName(product)}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Date: </span>
                {getWorkshopDate(product)}
              </Typography>
              {instructor ? (
                <Typography variant='caption' align='center' color='error'>
                  Selecting this workshop will remove{' '}
                  {instructor?.info?.name?.fName}{' '}
                  {instructor?.info?.name?.lName} from the course.
                </Typography>
              ) : (
                <></>
              )}
            </>
          )}
          {selectedActivity === '' ? (
            <></>
          ) : (
            <>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Activity: </span>
                {getActivityName()}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Time: </span>
                {getActivityTime()}
              </Typography>
              {instructor ? (
                <Typography variant='caption' align='center' color='error'>
                  Selecting this activity will remove{' '}
                  {instructor?.info?.name?.fName}{' '}
                  {instructor?.info?.name?.lName} as the instructor.
                </Typography>
              ) : (
                <></>
              )}
            </>
          )}
          {step === 6 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <Button onClick={handleBack}>Back</Button>
              <Button onClick={handleConfirm}>Confirm</Button>
            </div>
          ) : (
            <></>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AddAssignments
