import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  useMediaQuery,
  Box,
  Chip,
  Typography
} from '@mui/material'
import dateHandler from '../../utils/dateHandler'
import LoadingComp from '../util/loading'
import ProductForm from '../adminViews/products/productForm'
import ageHandler from '../../utils/ageFormatting'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import WorkshopModal from './workshopModal'
import { setProduct, setWorkshops } from '../../store/workshopSlice'
import productHandler from '../../utils/productHandler'

const GridCatalog = ({
  week,
  location,
  isAdmin,
  uID,
  handlePast,
  toggleCustomer
}) => {
  const dispatch = useDispatch()

  const [products, setProducts] = useState(null)
  const [names, setNames] = useState(null)
  const [loading, setLoading] = useState(true)
  const [infoCourse, setInfoCourse] = useState(null)
  const [inThePast, setInThePast] = useState(false)
  //Below is true if at least one workshop is a four day session
  const [hasFourDays, setHasFourDays] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showProductForm, setShowProductForm] = useState(false)

  const gridData = useSelector((state) => state.workshop.gridData)
  const product = useSelector((state) => state.workshop.product)
  const workshops = useSelector((state) => state.workshop.workshops)

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/gridCatalog',
      title: 'Grid Catalog'
    })
    ReactPixel.pageView()
  }, [])

  const Enrolled = (prod) => {
    let enrolled = prod?.info?.students?.length
    if (enrolled == 0) {
      return { ...styles.button, backgroundColor: '#C80F2D' }
    } else if (enrolled >= 1 && enrolled <= 2) {
      return { ...styles.button, backgroundColor: 'orange' }
    } else if (enrolled > 2 && enrolled <= 5) {
      return { ...styles.button, backgroundColor: '#3596CE' }
    } else if (enrolled > 5 && enrolled <= 8) {
      return { ...styles.button, backgroundColor: 'green' }
    } else if (enrolled > 8) {
      return { ...styles.button, backgroundColor: 'purple' }
    } else {
      return { ...styles.button, backgroundColor: '#3596CE' }
    }
  }

  const styles = {
    msg: {
      textAlign: 'center'
    },
    button: {
      padding: 2,
      margin: -3,
      fontSize: 11
    },
    sticky: {
      position: 'sticky',
      backgroundColor: 'inherit',
      zIndex: 2
    },
    cellRoot: {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        padding: 1,
        textAlign: 'center',
        minWidth: 50,
        boxShadow: '1px 0px rgba(224, 224, 224, 1) inset'
      }
    },
    camp: {
      minWidth: 120
    }
  }

  // Checks to center the grid view for different screen sizes
  if (useMediaQuery('(max-width:699px')) {
  }

  if (useMediaQuery('(min-width:700px)')) {
    styles.camp = {
      minWidth: 250
    }
  }

  if (useMediaQuery('(min-width:1300px)')) {
    styles.cellRoot = {
      '& .MuiTableCell-root': {
        ...styles.cellRoot['& .MuiTableCell-root']
      }
    }
    styles.camp = {
      minWidth: 300
    }
  }

  if (useMediaQuery('(min-width:1700px)')) {
    styles.cellRoot = {
      '& .MuiTableCell-root': {
        ...styles.cellRoot['& .MuiTableCell-root'],
        minWidth: 25,
        padding: 1
      }
    }
    styles.button = {
      ...styles.button,
      padding: 0,
      minWidth: 40
    }
  }

  useEffect(() => {
    if (Object.keys(gridData).length > 0) {
      let tmp = {}

      Object.keys(gridData.bucket).forEach((key) => {
        //TODO: Fix this workaround. 
        let setter = gridData.bucket[key]
          .find((x) => !x.hValue) //Finds the first course instance array, stored as [{AM course}, {PM course}]
          .find((y) => y !== null) //Finds the first instance that exists in the array, null is used as placeholder
        if (setter) {
          tmp[setter.metadata.courseName] = {}
          tmp[setter.metadata.courseName]['data'] = toggleCustomer
            ? gridData.bucket[key].slice(week)
            : gridData.bucket[key]
          tmp[setter.metadata.courseName]['age'] = {}
          tmp[setter.metadata.courseName]['age'].min = setter.info.ageMin
          tmp[setter.metadata.courseName]['age'].max = setter.info.ageMax
        }
      })
      let sortedByAge = Object.entries(tmp)
        .sort(([, a], [, b]) => a.age.min - b.age.min + (a.age.max - b.age.max))
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      setProducts(sortedByAge)
      let namesList = toggleCustomer
        ? gridData.names.slice(week)
        : gridData.names
      setNames(namesList)
    }
  }, [gridData])

  const handleInfoOpen = (data, inThePast) => {
    setInThePast(inThePast)
    setInfoCourse(data)
    setShowModal(true)
  }

  const CampButton = ({ prod }) => {
    let inThePast = handlePast(prod.metadata.startDate)
    let isFourDays = dateHandler.isFourDays(
      prod.metadata.startDate,
      prod.metadata.endDate
    )
    if (isFourDays) {
      setHasFourDays(true)
    }
    return (
      <Button
        variant='contained'
        style={
          isAdmin
            ? Enrolled(prod)
            : inThePast
            ? { ...styles.button, backgroundColor: 'grey' }
            : styles.button
        }
        onClick={() => {
          handleInfoOpen(prod, inThePast)
        }}
      >
        {isAdmin ? (
          <>
            {prod?.info?.students?.length} / {prod.info.cap}
          </>
        ) : inThePast ? (
          <>CLOSED</>
        ) : (
          <>INFO{isFourDays ? '*' : null}</>
        )}
      </Button>
    )
  }

  const handleModal = () => {
    setShowModal(false)
  }

  /*Edit Product functions */
  const handleProductForm = (item) => {
    let formattedProduct = productHandler.formatProduct(item)
    dispatch(setProduct(formattedProduct))
    setShowProductForm(true)
  }

  const handleCloseProductForm = () => {
    setShowProductForm(false)
  }

  const handleProductUpdate = async (course) => {
    let resp = await productHandler.update(product, course, uID)
    if (resp.success) {
      //Change the information that is displayed in CourseInfo
      setInfoCourse(resp.data)

      let tmpWorkshops = [...workshops]

      let index = tmpWorkshops.findIndex((prod) => prod.id === resp.data.id)

      tmpWorkshops.splice(index, 1, resp.data)
      dispatch(setWorkshops(tmpWorkshops))

      handleCloseProductForm()
    } else {
      alert('failed creation')
    }
  }
  /*End edit product function */

  return (
    <Box sx={{ marginTop: '30px', width: '100%' }}>
      {loading && products === null ? (
        <div style={styles.msg}>
          <LoadingComp />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            maxHeight: '90vh',
            width: '100%'
          }}
          sx={{
            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: 8,
              height: 8
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0,0,0,.5)',
              boxShadow: '0 0 1px rgba(255,255,255,.5)'
            },
            '& .MuiTableCell-head': {
              color: 'white'
            }
          }}
        >
          <Table sx={styles.cellRoot}>
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 3,
                '&:nth-of-type(even)': { backgroundColor: '#3596CE' },
                '&:nth-of-type(odd)': { backgroundColor: '#FFF' }
              }}
            >
              {/*First Header Row*/}
              <TableRow style={{ backgroundColor: '#3596CE' }}>
                {/*Location Name */}
                <TableCell
                  colSpan={2}
                  style={{
                    ...styles.sticky,
                    left: 0,
                    boxShadow: '-1px 0px rgba(224, 224, 224, 1) inset'
                  }}
                >
                  {location.name}
                </TableCell>
                {/*Dates across the top */}
                {names.map((item) => {
                  return (
                    <TableCell colSpan={2}>
                      {dateHandler.shortenDateSpan(item)}
                    </TableCell>
                  )
                })}
              </TableRow>
              {/* Second Header Row */}
              <TableRow style={{ backgroundColor: '#EEEEEE' }}>
                <>
                  <TableCell
                    style={{
                      ...styles.sticky,
                      left: 0,
                      color: '#666',
                      minWidth: 80,
                      boxShadow: '-1px 0px rgba(224, 224, 224, 1) inset'
                    }}
                  >
                    Age
                  </TableCell>
                  <TableCell
                    style={{
                      ...styles.camp,
                      ...styles.sticky,
                      left: 80,
                      color: '#666',
                      boxShadow: '-1px 0px rgba(224, 224, 224, 1) inset'
                    }}
                  >
                    Camps
                  </TableCell>
                </>
                {names.map((item) => {
                  return (
                    <>
                      <TableCell style={{ color: '#666' }}>AM</TableCell>
                      <TableCell style={{ color: '#666', boxShadow: 'none' }}>
                        PM
                      </TableCell>
                    </>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(products).map((product, index) => {
                return (
                  <TableRow
                    sx={{
                      '&:nth-of-type(even)': { backgroundColor: '#EEEEEE' },
                      '&:nth-of-type(odd)': { backgroundColor: '#FFF' }
                    }}
                  >
                    <TableCell
                      style={{
                        ...styles.sticky,
                        left: 0
                      }}
                    >
                      <Chip
                        sx={{
                          color: '#fff',
                          backgroundColor: ageHandler.ageGroupColor(
                            products[product].age.min,
                            products[product].age.max
                          )
                        }}
                        label={
                          products[product].age.min +
                          '-' +
                          products[product].age.max
                        }
                      />
                    </TableCell>{' '}
                    {/*Ages*/}
                    <TableCell
                      style={{
                        ...styles.sticky,
                        ...styles.camp,
                        left: 80,
                        fontWeight: 700,
                        boxShadow:
                          '-1px 0px rgba(224, 224, 224, 1) inset, 1px 0px rgba(224, 224, 224, 1) inset'
                      }}
                    >
                      {product}
                    </TableCell>{' '}
                    {/*Camp Buttons */}
                    {products[product].data.map((prod) => {
                      //Iterate thru each bucket of camps
                      if (!prod.hValue) {
                        //hValue means its a placeholder object, there are no camp instances that week
                        return prod.map((courseInstance, index) => {
                          //Course instances stored as array, iterate thru array
                          if (courseInstance !== null) {
                            //Null used for AM/PM placeholder
                            return (
                              <TableCell
                                style={{
                                  boxShadow: index !== 0 ? 'none' : null
                                }}
                              >
                                <CampButton
                                  prod={courseInstance}
                                  style={{ color: 'white' }}
                                ></CampButton>
                              </TableCell>
                            )
                          } else {
                            return (
                              <TableCell
                                style={{
                                  boxShadow: index !== 0 ? 'none' : null
                                }}
                              ></TableCell>
                            )
                          }
                        })
                      } else {
                        return (
                          <>
                            <TableCell></TableCell>
                            <TableCell
                              style={{ boxShadow: 'none' }}
                            ></TableCell>
                          </>
                        )
                      }
                    })}
                  </TableRow>
                )
              })}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!loading && products.length === 0 ? (
        <div style={styles.msg}>
          <h3>No availability this week, check another date or time.</h3>
        </div>
      ) : (
        <></>
      )}
      <WorkshopModal
        workshop={infoCourse}
        handleModal={handleModal}
        handleProductForm={handleProductForm}
        inThePast={inThePast}
        showModal={showModal}
        workshopID={infoCourse?.id}
        studentList={infoCourse?.info?.students}
        toggleCustomer={toggleCustomer}
      />
      {showProductForm ? (
        <ProductForm
          handleClose={handleCloseProductForm}
          handleUpdate={handleProductUpdate}
          type={'edit'}
        ></ProductForm>
      ) : (
        <></>
      )}
      {/* show four day message when at least one workshop is a 4 day week */}
      {hasFourDays ? (
        <Typography sx={{ padding: '5px 5px' }}>
          * - indicates camp runs for fewer than 5 days
        </Typography>
      ) : null}
    </Box>
  )
}

export default GridCatalog
