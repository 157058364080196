import './App.css'
import React from 'react'
import { persistor, store } from './store/store'
import { Provider, useSelector } from 'react-redux'
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  RouterProvider,
  useLocation
} from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material'
import rrTheme from './themes/mainTheme'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import CONSTANTS from './constants'

import PasswordResetRoute from './routes/passwordResetRoute'

import NavigationContainer from './containers/navigationContainer'
import LoginContainer from './containers/login'
import LandingPage from './containers/landingPage'
import SubscriptionsContainer from './containers/subscriptions'
import AccountContainer from './containers/account'
import WorkShopsContainer from './containers/workshops'

import membershipRoutes from './routes/membershipRoutes'

import AdminLocationsContainer from './containers/admin/locations'
import AdminProductsContainer from './containers/admin/products'
import AdminStaffContainer from './containers/admin/staff'
import AdminMessageContainer from './containers/admin/messageEdit'
import AdminOrdersContainer from './containers/admin/adminOrders'
import EmailContainer from './containers/admin/emailContainer'
import UserView from './component/adminViews/userView'
import AdminCoursesContainer from './containers/admin/course'
import DirectLink from './containers/directLink'
import SubscriptionInvite from './containers/subscriptionInvite'
import PromptLoginRoute from './routes/promptLoginRoute'
import FBlandingPage from './containers/FBlandingPage'
import fbLandingTheme from './themes/fbLandingTheme'
import CheckoutSuccess from './containers/checkoutSuccess'
import AchCheckout from './containers/achCheckout'
import AlternateCheckOutContainer from './containers/alternateCheckOutContainer'
import TermsAndConditions from './containers/staticPages/termsAndConditions'
import FaqContainer from './containers/staticPages/faqContainer'
import LandingContainer from './containers/landingContainer'
import MembershipLanding from './containers/membershipLanding'
import InPersonMemberships from './component/landing/inPersonMemberships'
import RobotEventsSpreadsheet from './containers/staticPages/robotEventsSpreadsheet'
import EventsLanding from './containers/eventsLanding'
import StaffAccountContainer from './containers/staffAccount'
import AboutUsContainer from './containers/staticPages/aboutUsContainer'
import HolidayCampLanding from './containers/holidayCampLanding'

function App() {
  //Google Analytics / Facebook Pixel data
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ID

  ReactGA.initialize(TRACKING_ID)
  const advancedMatching = { em: 'sam@rollingrobots.com' }
  const options = {
    autoConfig: true,
    debug: false
  }
  ReactPixel.init('997517401615682', advancedMatching, options)

  //Checks if a user has the correct permissions to access a route
  const ProtectedRoute = ({ isAllowed, children }) => {
    let loggedIn = useSelector((state) => state.login.isLoggedIn)
    let user = useSelector((state) => state.login.user)
    if (loggedIn) {
      if (user.permissions[0] >= isAllowed) {
        return children ? children : <Outlet />
      } else {
        return <Navigate to='/enroll' replace />
      }
    } else {
      return <Navigate to='/login' replace />
    }
  }

  const AdminMembershipRedirect = ({ children }) => {
    let loggedIn = useSelector((state) => state.login.isLoggedIn)
    let user = useSelector((state) => state.login?.user)
    let windowLocation = useLocation()
    if (windowLocation.state?.purchaseByAdmin || !loggedIn || (loggedIn && user.permissions[0] <3)) {
      return children ? children : <Outlet />
    } else {
      return <Navigate to='/admin-memberships/all' replace />
    }
  }

  //Removes loading spinner
  window.onload = () => {
    const loader = document.querySelector('.loader')
    loader.classList.add('loader--hide')
  }

  const ErrorPage = () => {
    return (
      <div id='error-page'>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
    )
  }

  const ExternalRedirect = ({ to }) => {
    React.useEffect(() => {
      window.location.href = to
    })
    return null
  }

  const router = createBrowserRouter([
    {
      path: '/ftc',
      element: (
        <ExternalRedirect to='https://rollingrobots.notion.site/First-Tech-Challenge-02d342f6a0324097a377d285e6f26730' />
      )
    },
    {
      path: '/product/75',
      element: (
        <ExternalRedirect to='https://rollingrobots.notion.site/f3dd5f105d6043ffa93d432d6bd27bbc?v=ed803dde550d49cd8f573d1c6b62d365&p=747ef0b043a243a085904142f20345df&pm=s' />
      )
    },
    {
      path: '/product/72',
      element: (
        <ExternalRedirect to='https://rollingrobots.notion.site/f3dd5f105d6043ffa93d432d6bd27bbc?v=ed803dde550d49cd8f573d1c6b62d365&p=c3178691e47245b88292f6ba9492986c&pm=s' />
      )
    },
    {
      path: '/VRC',
      element: (
        <ExternalRedirect to='https://rollingrobots.notion.site/VRC-Competition-Team-747ef0b043a243a085904142f20345df' />
      )
    },
    {
      path: '/Invention',
      element: (
        <ExternalRedirect to='https://rollingrobots.notion.site/Young-Inventors-c3178691e47245b88292f6ba9492986c' />
      )
    },
    {
      path: '/Travel',
      element: (
        <ExternalRedirect to="https://rollingrobots.notion.site/Travel-Events-bafac2c7afaf4108b107054056a120a3?pvs=4" />
      )

    },
    { path: '', element: <Navigate to='/home' replace /> },
    {
      path: '/home',
      element: (
        <ThemeProvider theme={fbLandingTheme}>
          <LandingContainer />
        </ThemeProvider>
      ),
      children: [
        {
          path: '',
          element: <HolidayCampLanding />
        },
        { path: 'events', element: <EventsLanding /> },
        { path: 'memberships/', element: <MembershipLanding /> },
        { path: 'summer-camps/', element: <FBlandingPage /> },
        { path: 'holiday-camps/', element: <HolidayCampLanding />},
        { path: 'memberships/in-person', element: <InPersonMemberships /> }
      ]
    },
    {
      path: '',
      element: (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={rrTheme}>
              <CssBaseline />
              <GlobalStyles styles={{ body: { backgroundColor: '#fafafa' } }} />
              <div className='App'>
                <NavigationContainer />
              </div>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/enroll',
          element: <LandingPage />
        },
        { path: '/terms', element: <TermsAndConditions /> },
        { path: '/faq', element: <FaqContainer /> },
        { path: '/about', element: <AboutUsContainer /> },
        { path: '/password-reset', element: <PasswordResetRoute /> },
        {
          path: '/membership-invite',
          element: (
            <PromptLoginRoute>
              <SubscriptionInvite />
            </PromptLoginRoute>
          )
        },
        {
          path: '/checkout-success',
          element: <CheckoutSuccess />
        },
        {
          path: '/ach',
          element: <AchCheckout />
        },
        /*Public Routes */
        /*Documentation on Dynamic Segments: https://reactrouter.com/en/main/route/route#dynamic-segments
        Question mark means optional dyanmic segment
        */
        {
          path: '/summer-camps/:locationParam?',
          element: <WorkShopsContainer season={'summer'} />
        },
        {
          path: '/holiday-camps/:locationParam?',
          element: <WorkShopsContainer season={'holiday'} />
        },
        {
          path: '/upcoming-camps/:locationParam?',
          element: <WorkShopsContainer season={'other'} />
        },
        {
          path: '/spring-camps/:locationParam?',
          element: <WorkShopsContainer season={'spring'} />
        },
        {
          path: 'memberships',
          element: (
              <SubscriptionsContainer />
          )
        },
        {
          path: 'memberships/:locationParam',
          element: 
            <SubscriptionsContainer />

        },
        {
          path: 'memberships/:locationParam/:membershipID',
          element: <SubscriptionsContainer />
        },
        { path: '/login', element: <LoginContainer /> },
        //path /sign-in is no longer used, but leave it to avoid accidentally breaking links
        { path: '/sign-in', element: <LoginContainer /> },
        {
          path: '/competition-events',
          element: <RobotEventsSpreadsheet />
        },
        {
          path: '/direct',
          element: <DirectLink />
        },
        /*Parent Routes */
        {
          element: <ProtectedRoute isAllowed={1} />,
          children: [
            { path: '/account-view/:userID', element: <AccountContainer /> },
            {
              path: '/alternate-check-out',
              element: <AlternateCheckOutContainer />
            }
          ]
        },
        /*Instructor Routes */
        {
          element: <ProtectedRoute isAllowed={3} />,
          children: [
            { path: '/parents', element: <UserView viewType={1} /> },
            { path: '/students', element: <UserView viewType={2} /> },
            {
              path: '/upcoming-overview/:locationParam?',
              element: <WorkShopsContainer season={'other'} />
            },
            {
              path: '/spring-overview/:locationParam?',
              element: <WorkShopsContainer season={'spring'} />
            },
            {
              path: '/holiday-overview/:locationParam?',
              element: <WorkShopsContainer season={'holiday'} />
            },
            {
              path: '/summer-overview/:locationParam?',
              element: <WorkShopsContainer season={'summer'} />
            },
            { path: '/email', element: <EmailContainer /> },
            ...membershipRoutes
          ]
        },
        /*Admin Routes */
        {
          element: <ProtectedRoute isAllowed={4} />,
          children: [
            { path: 'staff', element: <AdminStaffContainer /> },
            { path: 'staff-view/:userID', element: <StaffAccountContainer /> },
            { path: 'orders', element: <AdminOrdersContainer /> },
            { path: 'locations', element: <AdminLocationsContainer /> },
            { path: 'workshops', element: <AdminProductsContainer /> },
            { path: 'courses', element: <AdminCoursesContainer /> },
            { path: 'message', element: <AdminMessageContainer /> }
          ]
        }
      ]
    }
  ])
  return <RouterProvider router={router} />
}

export default App
