const STUDENT_MODEL = {
  permissions: [2],
  userName: '',
  pw: '',
  active: true,
  createdAt: '',
  updatedAt: '',
  info: {
    dob: '',
    name: {
      lName: '',
      fName: '',
      mName: ''
    },
    address: {
      lineOne: '',
      lineTwo: '',
      city: '',
      state: '',
      postal: '',
      country: ''
    },
    contact: {
      email: '',
      phone: '',
      alt: []
    }
  },
  pNotes: '',
  iNotes: '',
  parents: [],
  courses: {
    active: [],
    completed: [],
    invitedTo: []
  },
  badges: [],
  misc: {
    vax: ''
  },
  waiver: {
    accepted: false,
    acceptedVersion: 1,
    acceptedAt: ''
  }
}

const PARENT_MODEL = {
  permissions: [1],
  userName: '',
  pw: '',
  active: true,
  createdAt: '',
  updatedAt: '',
  info: {
    name: {
      lName: '',
      fName: '',
      mName: ''
    },
    address: {
      lineOne: '',
      lineTwo: '',
      city: '',
      state: '',
      postal: '',
      country: ''
    },
    contact: {
      email: '',
      phone: '',
      alt: 
          {email: '',
          phone: ''}
    }
  },
  eula: {
    accepted: false,
    acceptedVersion: 1,
    acceptedAt: ''
  },
  hasPaymentIssue: false,
  paymentMeta: {
    stripe: {
      stripeID: '',
      transactions: [],
      paymentIssues: []
    }
  },
  children: [],
  misc: {
    vax: false
  },
  survey: ''
}

const USER_MODELS = {
  STUDENT_MODEL,
  PARENT_MODEL
}

export default USER_MODELS
