import { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const ClassCard = ({ item, handleShowDetails, handleRemove }) => {
  const getTime = () => {
    let startHour = item.metadata.startTime
    let startMinute = item.metadata.startTimeMinute
    let endHour = item.metadata.endTime
    let endMinute = item.metadata.endTimeMinute

    if (startHour === '00') {
      startHour = '12'
    }

    if (endHour === '00') {
      endHour = '12'
    }

    return `${startHour}:${startMinute} - ${endHour}:${endMinute}`
  }

  return (
    <Card
      style={{
        maxWidth: '400px',
        minWidth: '300px',
        margin: '5px'
      }}
    >
      <CardHeader
        title={
          <Typography
            variant='h6'
            onClick={() => handleShowDetails(item)}
            style={{ cursor: 'pointer' }}
          >
            {item.metadata.courseName}
          </Typography>
        }
        action={
          <Tooltip describeChild title='Remove Course'>
            <IconButton
              color='primary'
              aria-label='delete'
              onClick={() => handleRemove(item)}
              size='small'
              style={{ marginTop: '-5px' }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        }
        subheader='Workshop'
        avatar={
          <img
            src={require(`../../images/courseIcons/${
              item.icon || 'robie.png'
            }`)}
            style={{ width: '75px', cursor: 'pointer' }}
            onClick={() => handleShowDetails(item)}
          />
        }
      />
      <Divider />
      <CardContent
        onClick={() => handleShowDetails(item)}
        style={{ cursor: 'pointer' }}
      >
        <Typography variant='subtitle2'>{getTime()}</Typography>
        <Typography variant='subtitle2'>
          {item.students.length}{' '}
          {item.students.length === 1 ? 'student' : 'students'}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ClassCard
