import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Button,
  Tooltip
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import dateHandler from '../../utils/dateHandler'
import StatusAlert from '../util/statusAlert'
import { getCreditsByID } from '../../api/credit'
import { useSelector } from 'react-redux'
import ConfirmAltPayButton from './confirmAltPayButton'
import { notifyAdmin } from '../../api/credit'
import { Error, TaskAlt } from '@mui/icons-material'

const AlternatePaymentModal = ({
  selectedCredit,
  displayStatus,
  handleStatus,
  sx,
  iconOnly
}) => {
  const user = useSelector((state) => state.login.user)
  const [creditDetails, setCreditDetails] = useState(null)
  const [displayModal, setDisplayModal] = useState(false)
  const [messageConfirm, setMessageConfirm] = useState(false)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getCreditsByID({
        uID: user._id,
        creditID: selectedCredit
      })
      if (resp.success) {
        setCreditDetails(resp.data[0])
      } else {
        alert('There was a problem retrieving alternate payment orders')
      }
    }
    if (displayModal) {
      handleAsync()
    } else {
      setCreditDetails(null)
    }
  }, [displayModal])

  const COLUMNS = [
    { id: 0, header: 'Status' },
    { id: 8, header: 'Confirmed By:' },
    { id: 1, header: 'PO #' },
    { id: 2, header: 'Parent' },
    { id: 3, header: 'Submitted' },
    { id: 4, header: 'Student' },
    { id: 5, header: 'Activity Dates' },
    { id: 6, header: 'Parent Note' },
    { id: 7, header: 'Admin Note' }
  ]

  const columnValues = (column, credit) => {
    if (Object.keys(credit).length > 0) {
      if (column.id === 0) {
        if (user.permissions[0] > 3) {
          return (
            <ConfirmAltPayButton credit={credit} handleStatus={handleStatus} />
          )
        } else {
          if (creditDetails.confirmed === 0) {
            return 'Pending'
          } else if (creditDetails.confirmed === 1) {
            return 'Confirmed'
          } else {
            return 'Rejected'
          }
        }
      } else if (column.id === 1) {
        return credit.paymentID
      } else if (column.id === 2) {
        return `${credit.parentInfo?.info.name.fName} ${credit.parentInfo?.info.name.lName}`
      } else if (column.id === 3) {
        return dateHandler.toUSDate(credit.paymentDate)
      } else if (column.id === 4) {
        return `${credit.studentInfo?.info.name.fName} ${credit.studentInfo?.info.name.lName}`
      } else if (column.id === 5) {
        let startArr = credit.startDate?.split('-')
        let endArr = credit.endDate?.split('-')
        let dateRange = `${startArr[1]}-${startArr[2]}-${startArr[0]} - ${endArr[1]}-${endArr[2]}-${endArr[0]}`
        return dateRange
      } else if (column.id === 6) {
        return credit.parentNote
      } else if (column.id === 7) {
        return credit.adminNote
      } else if (column.id === 8) {
        return credit.adminInfo
          ? `${credit.adminInfo?.info.name.fName} ${credit.adminInfo?.info.name.lName}`
          : null
      }
    }
  }

  const CreditStatus = () => {
    let color
    let text
    let icon
    switch (displayStatus) {
      case 0:
        color = 'orange'
        text = 'Payment is unconfirmed'
        break
      case 1:
        color = 'blue'
        icon = 'success'
        text = 'Payment is confirmed'
        break
      default:
        color = 'red'
        text = 'Payment is rejected'
      //Add some kind of informational message here
    }
      return (
        <StatusAlert
          color={color}
          icon={icon}
          sx={{ ...sx }}
          onClick={
            user.permissions[0] >= 3 ? () => setDisplayModal(true) : null
          }
          iconOnly={iconOnly}
          text={text}
/>
      )
  }


  const sendEmail = async () => {
    let resp = await notifyAdmin({ creditID: selectedCredit, uID: user._id })
    if (resp.success) {
      setMessageConfirm(true)
    } else {
      alert(resp.data.error.message)
    }
  }

  return (
    <>
      {/**This is the colored bar that appears by the student's information */}
      {typeof displayStatus == 'number' ? (
        <>
          <CreditStatus />
        </>
      ) : (
        <>
          <IconButton
            onClick={
              user.permissions[0] > 3 ? () => setDisplayModal(true) : null
            }
          >
            <MoreHoriz />
          </IconButton>
        </>
      )}

      {/**This is the modal that appears for admins and instructors */}
      <Dialog
        open={displayModal}
        disableEnforceFocus
        onClose={() => setDisplayModal(false)}
      >
        <DialogTitle>PO Details</DialogTitle>
        <DialogContent>
          {!creditDetails ? (
            <>loading...</>
          ) : (
            <>
              <Box sx={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                {COLUMNS.map((column) => {
                  let value = columnValues(column, creditDetails)
                  if (value) {
                    return (
                      <React.Fragment key={column.id}>
                        <Typography fontWeight='bold'>
                          {column.header}
                        </Typography>
                        <Typography>{value}</Typography>
                      </React.Fragment>
                    )
                  }
                })}
              </Box>
              {user.permissions[0] === 3 && creditDetails.confirmed !== 1 ? (
                <>
                  {!messageConfirm ? (
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Tooltip
                        title={`Notify manager about this transaction's payment status`}
                      >
                        <Button variant='contained' onClick={sendEmail}>
                          Notify Manager
                        </Button>
                      </Tooltip>
                    </div>
                  ) : (
                    <Typography>
                      Message Sent (a copy has also been sent to your email)
                    </Typography>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AlternatePaymentModal
