import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { getActivityAttendance } from '../../../api/admin'
import LoadingComp from '../../util/loading'
import { Typography, Box, IconButton, ButtonGroup, Button } from '@mui/material'
import AttendanceTable from './attendanceTable'
import getDayString from '../../../utils/getDayString'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import StudentList from './studentList'
import dateHandler from '../../../utils/dateHandler'

const AttendanceReport = (props) => {
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const activity = useSelector((state) => state.subscription.selectedActivity)
  const uID = useSelector((state) => state.login.user._id)

  const [loading, setLoading] = useState(true)
  const [header, setHeader] = useState(['Students'])
  const [row, setRow] = useState([])
  const [attendanceArr, setAttendanceArr] = useState(null)
  const [showEdit, setShowEdit] = useState(false)
  const [editData, setEditData] = useState(null)

  //date handling to get current month
  const today = new Date()
  let currentMonth = today.getMonth()
  let currentYear = today.getFullYear()
  let startingView = {
    month: currentMonth,
    year: currentYear
  }
  const [monthView, setMonthView] = useState(startingView)
  const [studentType, setStudentType] = useState('current')
  let activityDays = []

  const getMonthName = (index) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    return monthNames[index]
  }

  //get existing attendance information
  useEffect(() => {
    async function handleAsync() {
      if (
        activity.instance.students.length > 0 &&
        activity.instance.attendanceDates.length > 0
      ) {
        let resp = await getActivityAttendance({
          params: {
            uID: uID,
            studentIDs: activity.instance.students,
            activityID: activity.instance._id,
            priceIDs: [subscription.priceID, ...subscription.altPrices],
            subscriptionID: subscription._id,
            studentType: studentType
          }
        })
        if (resp.success) {
          setAttendanceArr(resp.data)
        } else {
          alert('There was an error retrieving attendance')
        }
      }
      setLoading(false)
    }
    handleAsync()
  }, [])

  useEffect(() => {
    //get activity dates for the month
    let daysOfWeek = {
      Sun: 0,
      M: 1,
      T: 2,
      W: 3,
      Thr: 4,
      F: 5,
      Sat: 6
    }
    let firstOfMonth = new Date(`${monthView.month + 1}/1/${monthView.year}`)
    let d = today
    let dow = daysOfWeek[activity.instance.day]
    let arr = []
    d.setDate(1)
    while (firstOfMonth.getDay() !== dow) {
      firstOfMonth.setDate(firstOfMonth.getDate() + 1)
    }
    while (firstOfMonth.getMonth() === monthView.month) {
      arr.push(new Date(firstOfMonth.getTime()))
      firstOfMonth.setDate(firstOfMonth.getDate() + 7)
    }
    activityDays = arr
    let additionalAttendance = activity.instance.attendanceDates.filter(
      (date) => {
        let convertedDate = dateHandler.formatDate(date)
        //filter out dates in present month and not part of default attendance
        if (
          convertedDate.getMonth() === monthView.month &&
          convertedDate.getFullYear() === monthView.year &&
          !activityDays
            .map((day) => day.toLocaleDateString())
            .includes(convertedDate.toLocaleDateString())
        ) {
          return true
        } else {
          return false
        }
      }
    )
    additionalAttendance.forEach((day) =>
      activityDays.push(dateHandler.formatDate(day))
    )
    activityDays.sort((a, b) => a - b)
    //set table data
    let header = ['Students']
    activityDays.forEach((day) => {
      header.push(day)
    })
    setHeader(header)
    getRows(attendanceArr)
  }, [monthView, attendanceArr])

  const handleSwitchStudents = async (newType) => {
    setStudentType(newType)
    setLoading(true)
    let resp = await getActivityAttendance({
      params: {
        uID: uID,
        studentIDs: activity.instance.students,
        activityID: activity.instance._id,
        priceIDs: [subscription.priceID, ...subscription.altPrices],
        subscriptionID: subscription._id,
        studentType: newType
      }
    })
    if (resp.success) {
      setLoading(false)
      setAttendanceArr(resp.data)
    } else {
      setLoading(false)
      alert('There was an error retrieving attendance')
    }
  }

  const getRows = (attendanceArr) => {
    let rows = []
    if (attendanceArr) {
      attendanceArr.forEach((instance) => {
        //Find attendance of day
        let arr = []
        arr.push(instance.student)
        activityDays.map((day) => {
          let check = instance.attendance.filter((obj) => {
            let split = obj.date.split('-')
            let formatDate = `${parseInt(split[1])}/${parseInt(
              split[2]
            )}/${parseInt(split[0])}`
            return formatDate === day.toLocaleDateString()
          })
          if (check.length > 0) {
            arr.push(check[0].status)
          } else {
            //check if student signed up for the class before date
            if (instance.joined === null) {
              /**Check if attendance has been taken prior to activity date
               * Assuming the first entry of the attendance is the earliest date
               */
              if (instance.attendance.length > 0) {
                let tmpDate = new Date(instance.attendance[0].date)
                if (day.getTime() > tmpDate.getTime()) {
                  arr.push('Not Taken')
                } else {
                  arr.push(null)
                }
              } else {
                arr.push(null)
              }
            } else {
              let joinedDate = new Date(instance.joined)
              if (day.getTime() < joinedDate.getTime()) {
                arr.push('Not Joined')
              } else {
                arr.push('Not Taken')
              }
            }
          }
        })
        rows.push(arr)
      })
    } else {
      activityDays.map((day) => {})
    }
    setRow(rows)
  }

  const handlePrevMonth = () => {
    setMonthView((current) => {
      if (current.month === 0) {
        let prevYear = current.year - 1
        return { month: 11, year: prevYear }
      } else {
        let prevMonth = current.month - 1
        return { ...current, month: prevMonth }
      }
    })
  }

  const handleNextMonth = () => {
    setMonthView((current) => {
      if (current.month === 11) {
        let nextYear = current.year + 1
        return { month: 0, year: nextYear }
      } else {
        let nextMonth = current.month + 1
        return { ...current, month: nextMonth }
      }
    })
  }

  const handleEdit = (index) => {
    let students = row.map((arr) => arr[0])
    let date = header[index] //This should always be a date
    let formattedDate = date.toISOString().split('T')[0]
    setEditData({
      index: index,
      day: date,
      students: students,
      attendance: row.map((arr, tmpIndex) => {
        return {
          status: arr[index],
          studentID: students[tmpIndex]._id,
          date: formattedDate
        }
      })
    })
    setShowEdit(true)
  }

  const handleClose = () => {
    setShowEdit(false)
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <div
          style={{
            margin: '0px auto 10px auto',
            width: '90%'
          }}
        >
          {activity.instance.students.length > 0 ? (
            <>
              {activity.instance.attendanceDates.length >= 1 ? (
                <>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography variant='h6' style={{ textAlign: 'left' }}>
                      {`${subscription.name}: ${
                        activity.course.name
                      }, ${getDayString(activity.instance.day)}`}
                    </Typography>
                    <ButtonGroup>
                      <Button
                        variant={studentType === 'current' ? 'contained' : null}
                        onClick={() => handleSwitchStudents('current')}
                      >
                        Current
                      </Button>
                      <Button
                        variant={studentType === 'former' ? 'contained' : null}
                        onClick={() => handleSwitchStudents('former')}
                      >
                        Former
                      </Button>
                    </ButtonGroup>
                  </div>

                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <IconButton onClick={() => handlePrevMonth()}>
                      <ArrowBack />
                    </IconButton>
                    <Typography variant='h6'>
                      {getMonthName(monthView.month)} {monthView.year}
                    </Typography>
                    <IconButton onClick={() => handleNextMonth()}>
                      <ArrowForward />
                    </IconButton>
                  </Box>
                  <AttendanceTable
                    headerCells={header}
                    rowCells={row}
                    isLink={false}
                    handleEdit={handleEdit}
                  />
                </>
              ) : (
                <Typography variant='h4'>
                  No existing attendance report for this activity. Take
                  attendance to generate a report.
                </Typography>
              )}
            </>
          ) : (
            <Typography variant='h4'>This activity has no students</Typography>
          )}
        </div>
      )}
      {showEdit ? (
        <StudentList
          isEdit={true}
          handleClose={handleClose}
          editData={editData}
          date={editData.day}
          setRow={setRow}
          setAttendanceArr={setAttendanceArr}
          handleStudent={props.handleStudent}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default AttendanceReport
