const generateName = (nameObj) => {
  if (nameObj.mName) {
    return `${nameObj.fName} ${nameObj.mName[0]}. ${nameObj.lName}`
  } else {
    return `${nameObj.fName} ${nameObj.lName}`
  }
}

const generateAddrOne = (addrObj) => {
  return `${addrObj.lineOne} ${addrObj.lineTwo || ''}`
}
const generateAddrTwo = (addrObj) => {
  return `${addrObj.city}, ${addrObj.state} ${addrObj.postal}`
}

const generateUserInfo = {
  generateName,
  generateAddrOne,
  generateAddrTwo
}

export default generateUserInfo
