import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComp from '../../util/loading'
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Alert,
  useMediaQuery
} from '@mui/material'
import {
  setStudent,
  setStudents,
  updateSub,
  setSelectedSubscription,
  setPaymentData
} from '../../../store/subscriptionSlice'
import ageHandler from '../../../utils/ageFormatting'
import dateHandler from '../../../utils/dateHandler'
import ErrorIcon from '@mui/icons-material/Error'
import PauseSubscription from '../../subscriptionPause/pauseSubscription'
import EditPause from '../../subscriptionPause/editPause'
import { pauseSubscription } from '../../../api/subscription'
import useNavToAccountView from '../../../utils/navToAccountView'
import { useNavigate } from 'react-router-dom'
import { getSubscriptionStudents } from '../../../api/admin'
import AlternatePaymentModal from '../alternatePaymentModal'
import stripeData from '../../../utils/stripeData'
import StatusAlert from '../../util/statusAlert'

const SubscriptionStudents = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleNavToAccountView = useNavToAccountView()
  const uID = useSelector((state) => state.login.user._id)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const students = useSelector((state) => state.subscription.students)
  const [showPauseBox, setShowPauseBox] = useState(false)
  const [studentToPause, setStudentToPause] = useState(null)
  const [pauseConfirm, setPauseConfirm] = useState(false)
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString('en', 'America/Los_Angeles')
  )
  const [returnDate, setReturnDate] = useState(
    new Date().toLocaleDateString('en', 'America/Los_Angeles')
  )
  const [pauseLoading, setPauseLoading] = useState(false)
  const [pauseError, setPauseError] = useState(false)
  const [showPauseEdit, setShowPauseEdit] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)

  const [loading, setLoading] = useState(true)

  const styles = {
    grid: { margin: '0px auto 100px auto', width: '50%' }
  }
  let check = useMediaQuery('(max-width:600px)')
  if (check) {
    styles.grid = {
      width: '100%'
    }
  }

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let resp = await getSubscriptionStudents({
        params: {
          uID: uID,
          students: subscription.students,
          productID: subscription.productID,
          subscriptionID: subscription._id
        }
      })
      if (mounted) {
        if (resp.success) {
          dispatch(setStudents(resp.data))
          setLoading(false)
        } else {
          alert('There was a problem retrieving students for this subscription')
        }
      }
    }
    if (subscription.students.length > 0) {
      handleAsync()
    } else {
      dispatch(setStudents([]))
      setLoading(false)
    }
    return () => (mounted = false)
  }, [])

  const handleSwitch = (student) => {
    dispatch(setStudent(student))
    navigate('/admin-memberships/switch')
  }

  const handleEnroll = (student) => {
    dispatch(setStudent(student))
    if (student.stripe) {
      dispatch(setPaymentData(student.stripe))
    } else {
      dispatch(setPaymentData(student.credit))
    }
    navigate('/admin-memberships/cancel')
  }

  const handleSubscriptionPause = (student) => {
    setStudentToPause(student)
    setShowPauseBox(true)
  }

  const handleCloseSubscriptionPause = () => {
    setPauseConfirm(false)
    setStartDate(new Date().toLocaleDateString('en', 'America/Los_Angeles'))
    setReturnDate(new Date().toLocaleDateString('en', 'America/Los_Angeles'))
    setPauseLoading(false)
    setPauseError(false)
    setShowPauseBox(false)
  }

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate.toLocaleDateString('en', 'America/Los_Angeles'))
  }

  const handleReturnDateChange = (newDate) => {
    setReturnDate(newDate.toLocaleDateString('en', 'America/Los_Angeles'))
    setPauseConfirm(true)
  }

  const handlePauseCancel = () => {
    setPauseConfirm(false)
    setReturnDate(new Date().toLocaleDateString('en', 'America/Los_Angeles'))
  }

  const handlePauseSubmit = async () => {
    setPauseLoading(true)
    setPauseError(false)
    let today = new Date()

    //format start time
    let start = new Date(startDate)
    let startTime = start.getTime() - today.getTime()
    let formattedStartTime = startTime / (1000 * 3600 * 24)
    //format end time
    let end = new Date(returnDate)
    let endTime = end.getTime() - today.getTime()
    let formattedEndTime = endTime / (1000 * 3600 * 24)
    //time = startDate.getTime() - today.getTime()
    if (formattedEndTime - formattedStartTime >= 14) {
      let resp = await pauseSubscription({
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        productID: subscription.productID,
        studentID: studentToPause._id,
        subscriptionID: subscription._id
      })
      if (resp.success) {
        if (resp.data.subscription !== null) {
          dispatch(setSelectedSubscription(resp.data.subscription))
          dispatch(updateSub(resp.data.subscription))
        } else {
          alert(
            `The student's membership was not found. Please pause membership on Stripe.`
          )
        }
        handleCloseSubscriptionPause()
      } else {
        alert(resp)
      }
    } else {
      setPauseError(true)
      setPauseConfirm(false)
      setPauseLoading(false)
    }
  }

  const handleEditPause = (student) => {
    setSelectedStudent(student)
    setShowPauseEdit(true)
  }

  const handleClosePauseEdit = () => {
    setSelectedStudent(null)
    setShowPauseEdit(false)
  }

  const handleStatus = (value, credit) => {
    let studentIndex = students.findIndex(
      (obj) => obj.credit?._id === credit._id
    )
    let studentObj = { ...students[studentIndex] }
    let tmpCredit = { ...studentObj.credit }
    tmpCredit.confirmed = value
    studentObj.credit = tmpCredit
    let tmpStudents = [...students]
    tmpStudents[studentIndex] = studentObj
    dispatch(setStudents(tmpStudents))
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          {students.length > 0 ? (
            <Grid
              container
              justifyContent='center'
              spacing={2}
              style={styles.grid}
            >
              {students.map((student) => {
                let payedWithCredit = student.hasOwnProperty('credit')
                const studentName = `${student.info.name.fName} ${student.info.name.lName}`
                //Check if student is paused
                let dateOfReturn = null
                let pauseStart = null

                let pausedCheck = subscription.paused.filter(
                  (subItem) => subItem.studentID === student._id
                )
                if (pausedCheck.length > 0) {
                  let pausedDate = pausedCheck[0].returnDate
                  let startDate = pausedCheck[0].startDate
                  if (pausedDate === 'indefinite') {
                    dateOfReturn = 'forever'
                  } else {
                    dateOfReturn = dateHandler.toUSDate(pausedDate)
                  }
                  if (startDate) {
                    pauseStart = dateHandler.toUSDate(startDate)
                  }
                }
                //check if student has a delayed start
                let delayedStart = null
                let delayedCheck = subscription.delayedStart.filter(
                  (sub) => sub.studentID === student._id
                )
                if (delayedCheck.length > 0) {
                  delayedStart = delayedCheck[0].startDate
                }

                return (
                  <Grid item xs={12} key={student._id}>
                    <Card>
                      <CardContent>
                        <Typography variant='h6'>{studentName}</Typography>

                        <Typography variant='body1'>
                          Age: {ageHandler.getAge(student.info.dob)}
                        </Typography>
                        {student.joined ? (
                          <Typography variant='body1'>
                            Joined: {student.joined}
                          </Typography>
                        ) : (
                          <></>
                        )}

                        {student.end ? (
                          <Typography variant='body1'>
                            Ends: {student.end}
                          </Typography>
                        ) : (
                          <></>
                        )}

                        {student.stripe ? (
                          <>
                            <Typography>
                              Paying $
                              {stripeData.getPaymentPlan(student.stripe)}
                            </Typography>
                            {student.stripe?.cancellation_details.reason ===
                            'cancellation_requested' ? (
                              <Typography>Cancelling at period end</Typography>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {student.credit ? (
                          <>
                            <Typography>
                              Payment Type: {student.credit.paymentType}
                            </Typography>
                            {student.credit.paymentID ? (
                              <Typography>
                                PO Number : {student.credit.paymentID}
                              </Typography>
                            ) : (
                              <></>
                            )}
                            <div style={{ width: '50%', margin: 'auto' }}>
                              <AlternatePaymentModal
                                displayStatus={student.credit.confirmed}
                                selectedCredit={student.credit._id}
                                handleStatus={handleStatus}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {student.paymentStatus ? (
                          <Alert severity='error'>
                            This student is enrolled in a payment plan that has{' '}
                            {student.paymentStatus}
                          </Alert>
                        ) : (
                          <></>
                        )}

                        {!student.stripe && !student.credit ? (
                          <Typography color='red'>
                            Payment plan not found
                          </Typography>
                        ) : (
                          <></>
                        )}

                        {dateOfReturn ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <ErrorIcon color='primary' />
                            <>
                              {pauseStart ? (
                                <Typography>
                                  Paused from {pauseStart} to {dateOfReturn}
                                </Typography>
                              ) : (
                                <Typography>Paused {dateOfReturn}</Typography>
                              )}
                            </>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div style={{ width: '50%', margin: '0 auto' }}>
                          {delayedStart ? (
                            <StatusAlert>
                              Start Date: {delayedStart}
                            </StatusAlert>
                          ) : (
                            <></>
                          )}
                        </div>
                      </CardContent>
                      <CardActions
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          onClick={() => handleNavToAccountView(uID, student)}
                        >
                          View
                        </Button>

                        <>
                          {dateOfReturn ? (
                            <Button
                              disabled={delayedStart !== null}
                              onClick={() => handleEditPause(student)}
                            >
                              Edit Pause
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                handleSubscriptionPause(student)
                              }}
                              disabled={
                                delayedStart !== null || payedWithCredit
                              }
                            >
                              Pause
                            </Button>
                          )}
                        </>

                        <Button
                          onClick={() => {
                            handleSwitch(student)
                          }}
                        >
                          Switch
                        </Button>
                        <Button
                          onClick={() => {
                            handleEnroll(student)
                          }}
                        >
                          Unenroll
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <h1>There are no students for this subscription</h1>
          )}
        </>
      )}

      <PauseSubscription
        open={showPauseBox}
        handleClose={handleCloseSubscriptionPause}
        error={pauseError}
        confirm={pauseConfirm}
        subscription={subscription}
        handleReturnDateChange={handleReturnDateChange}
        handleStartDateChange={handleStartDateChange}
        handleCancel={handlePauseCancel}
        handleSubmit={handlePauseSubmit}
        loading={pauseLoading}
        startDate={startDate}
        returnDate={returnDate}
      />

      <EditPause
        open={showPauseEdit}
        handleClose={handleClosePauseEdit}
        subscription={subscription}
        student={selectedStudent}
        source={'membership'}
      />
    </>
  )
}

export default SubscriptionStudents
