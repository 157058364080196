import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react'
import {
  updateActivity,
  updateSelectedActivity
} from '../../../store/subscriptionSlice'
import { getDaysAttendance, submitAttendance } from '../../../api/admin'
import { getTagsWithoutStudents, untagStudent } from '../../../api/tag'
import LoadingComp from '../../util/loading'
import {
  Button,
  Grid,
  Typography,
  Chip,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import getDayString from '../../../utils/getDayString'
import cards from '../../util/cards'
import ageHandler from '../../../utils/ageFormatting'
import { pink, green } from '@mui/material/colors'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import dateHandler from '../../../utils/dateHandler'
import Note from '../../note'
import useNavToAccountView from '../../../utils/navToAccountView'
import StatusAlert from '../../util/statusAlert'
import AlternatePaymentModal from '../alternatePaymentModal'
import AchPaymentStatus from '../achPaymentStatus'
import DeleteTagDialog from '../../account/deleteTagDialog'
import listHandler from '../../../utils/listHandler'

const StudentList = (props) => {
  const dispatch = useDispatch()
  const activity = useSelector((state) => state.subscription.selectedActivity)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const uID = useSelector((state) => state.login.user._id)
  const handleNavToAccountView = useNavToAccountView()

  const [students, setStudents] = useState([])
  const [filteredStudents, setFilteredStudents] = useState([])
  const [daysAttendance, setDaysAttendance] = useState([])

  const [showSubmit, setShowSubmit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [addAttendance, setAddAttendance] = useState(false)

  const [showStudentInfo, setShowStudentInfo] = useState(-1)

  const [sortSelection, setSortSelection] = useState('last')
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)

  //Variables for removing student from tag
  const [studentToRemove, setStudentToRemove] = useState(null)
  const [showRemoveStudent, setShowRemoveStudent] = useState(false)
  const [targetedTag, setTargetedTag] = useState(null)
  //date handling
  let selectedDate = props.date
  const dateString = selectedDate.toISOString().split('T')[0] //formatted date for handling attendance
  let isToday = false
  let daysOfWeek = {
    0: 'Sun',
    1: 'M',
    2: 'T',
    3: 'W',
    4: 'Thr',
    5: 'F',
    6: 'Sat'
  }
  if (daysOfWeek[selectedDate.getDay()] === activity.instance.day) {
    //check if class occurs today
    isToday = true
  }

  const selectedDow = getDayString(daysOfWeek[selectedDate.getDay()]) //get day of week

  useEffect(() => {
    async function handleAsync() {
      if (!props.isEdit) {
        let resp = await getDaysAttendance({
          params: {
            uID: uID,
            studentIDs: activity.instance.students,
            date: dateString,
            activityID: activity.instance._id,
            subscriptionID: subscription._id
          }
        })
        if (resp.success) {
          setStudents(resp.data.students)
          setFilteredStudents(resp.data.students)
          setDaysAttendance(resp.data.attendance)
        }

        //Get tags
        resp = await getTagsWithoutStudents({
          params: { subscriptionID: subscription._id, uID: uID }
        })
        if (resp.success) {
          setTags(resp.data)
        } else {
          alert('There was an error retrieving tags')
        }
      } else {
        setStudents(props.editData.students)
        setFilteredStudents(props.editData.students)
        setDaysAttendance(props.editData.attendance)
      }
      //check if additional attendance has been taken for today
      if (
        !isToday &&
        activity.instance.attendanceDates.filter(
          (date) =>
            dateHandler.formatDate(date).toLocaleDateString() ===
            props.date.toLocaleDateString()
        ).length > 0
      ) {
        setAddAttendance(true)
      }
      setLoading(false)
    }
    handleAsync()
  }, [])

  const handleShowRemoveStudent = (tag, student) => {
    setTargetedTag(tag)
    setStudentToRemove(student)
    setShowRemoveStudent(true)
  }

  const handleCloseRemoveStudent = () => {
    setTargetedTag(null)
    setStudentToRemove(null)
    setShowRemoveStudent(false)
  }

  const statusCheck = (student) => {
    let studentAttendance = daysAttendance.find(
      (att) => att.studentID === student._id
    )
    return studentAttendance?.status
  }

  const handleAttendanceChange = (event, student, index) => {
    //if attendance array does not exist, create one using students list
    if (daysAttendance.length === 0) {
      let newAttendance = {
        studentID: student._id,
        date: dateString,
        status: event.target.value
      }
      let tmp = students.map((obj) => {
        if (student._id === obj._id) {
          return newAttendance
        } else {
          return 'Not Taken'
        }
      })
      setDaysAttendance(tmp)
    } else {
      let newArr = [...daysAttendance]
      let studentIndex = newArr.findIndex(
        (elm) => elm.studentID === student._id
      )
      if (studentIndex === -1) {
        newArr[index] = {
          studentID: student._id,
          date: dateString,
          status: event.target.value
        }
      } else {
        newArr[studentIndex] = {
          studentID: student._id,
          date: dateString,
          status: event.target.value
        }
      }
      setDaysAttendance(newArr)
    }
    setShowSuccess(false)
    setShowSubmit(true)
  }

  const submitData = async (attendance, activityID) => {
    let attendanceArr = [...attendance]
    students.forEach((student, index) => {
      //format attendance array
      if (attendance[index]?.studentID !== student._id) {
        attendanceArr[index] = {
          studentID: student._id,
          date: dateString,
          status: 'Not Taken'
        }
      }
    })

    let resp = await submitAttendance({
      attendance: attendanceArr,
      activityID: activityID,
      date: dateString,
      uID: uID
    })
    if (resp.success) {
      dispatch(updateActivity(resp.data))
      dispatch(updateSelectedActivity(resp.data))
      if (props.isEdit) {
        //if editing attendance report, update render view to show new attendance
        props.setAttendanceArr((prev) => {
          let tmp = [...prev].map((obj) => {
            let update = { ...obj }
            let index = update.attendance.findIndex(
              (item) => item.date === dateString
            )
            if (index !== -1) {
              update.attendance[index] = attendanceArr.filter(
                (attendance) => attendance.studentID === update.student._id
              )[0]
            } else {
              update.attendance.push(
                attendanceArr.filter(
                  (attendance) => attendance.studentID === update.student._id
                )[0]
              )
            }
            return update
          })
          return tmp
        })
      }
    } else {
      alert('There was an error submitting attendance')
    }
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)

    await submitData(daysAttendance, activity.instance._id)

    setShowSubmit(false)
    setShowSuccess(true)
    setSubmitLoading(false)
    if (props.isEdit) {
      props.handleClose()
    }
  }

  const handleDeleteTag = async () => {
    let resp = await untagStudent({
      uID: uID,
      tagID: targetedTag._id,
      studentID: studentToRemove._id
    })

    if (resp.success) {
      //Update student
      let tmpStudents = [...students]
      let studentIndex = tmpStudents.findIndex(
        (tmpStudent) => tmpStudent._id === studentToRemove._id
      )
      let tmpStudent = tmpStudents[studentIndex]
      let tmpTags = [...tmpStudent.tags]
      tmpTags = tmpTags.filter((tmpTag) => tmpTag._id !== targetedTag._id)
      tmpStudent.tags = tmpTags
      tmpStudents.splice(studentIndex, 1, tmpStudent)
      setStudents(tmpStudents)

      //Update filtered students
      let tmpFiltered = [...filteredStudents]
      studentIndex = tmpFiltered.findIndex(
        (tmpStudent) => tmpStudent._id === studentToRemove._id
      )
      tmpStudent = tmpFiltered[studentIndex]
      tmpTags = [...tmpStudent.tags]
      tmpTags = tmpTags.filter((tmpTag) => tmpTag._id !== targetedTag._id)
      tmpStudent.tags = tmpTags
      tmpFiltered.splice(studentIndex, 1, tmpStudent)
      setFilteredStudents(tmpFiltered)
      handleCloseRemoveStudent()
    } else {
      alert('There was an error untagging the student')
      handleCloseRemoveStudent()
    }
  }

  const BlankCard = cards.blankCard

  const getPaused = (student) => {
    let pausedCheck = subscription.paused.filter(
      (subItem) => subItem.studentID === student._id
    )
    if (pausedCheck.length > 0) {
      let pausedDate = pausedCheck[0].returnDate
      if (pausedDate === 'indefinite') {
        return 'indefinitely'
      } else {
        return `until ${dateHandler.toUSDate(pausedDate)}`
      }
    } else {
      return null
    }
  }

  const getDelayedStart = (student) => {
    let startCheck = subscription.delayedStart.filter(
      (sub) => sub.studentID === student._id
    )
    if (startCheck.length > 0) {
      return startCheck[0].startDate
    } else {
      return null
    }
  }

  const handleStudentInfo = (index) => {
    if (index === showStudentInfo) {
      setShowStudentInfo(-1)
    } else {
      setShowStudentInfo(index)
    }
  }

  //Used by Alternate Payment Modal to update a students credit confirmation status on client side
  //Must update both filtered students and unfiltered students
  const handleStatus = (value, credit) => {
    //Unfiltered
    let studentIndex = students.findIndex(
      (obj) => obj.credit?._id === credit._id
    )
    let studentObj = { ...students[studentIndex] }
    studentObj.credit.confirmed = value
    setStudents((prev) => {
      let tmpArr = [...prev]
      tmpArr[studentIndex] = studentObj
      return tmpArr
    })
    //Filtered
    studentIndex = filteredStudents.findIndex(
      (obj) => obj.credit?._id === credit._id
    )
    studentObj = { ...filteredStudents[studentIndex] }
    studentObj.credit.confirmed = value
    setFilteredStudents((prev) => {
      let tmpArr = [...prev]
      tmpArr[studentIndex] = studentObj
      return tmpArr
    })
  }

  const handleChangeFilter = (filter) => {
    let tmpStudents = [...filteredStudents]
    setSortSelection(filter)

    switch (filter) {
      case 'tags':
        let newStudentList = []
        //Get list of tags
        let tagArr = listHandler.alphabetizeList(tags, 'name')
        //sort tags alphabetically
        //sort students by tag
        tagArr.forEach((tag) => {
          let taggedStudents = tmpStudents.filter((tmpStudent) =>
            tag.students.includes(tmpStudent._id)
          )
          taggedStudents.forEach((taggedStudent) => {
            let index = newStudentList.findIndex(
              (elm) => elm._id === taggedStudent._id
            )
            if (index === -1) {
              newStudentList.push(taggedStudent)
            }
          })
        })
        //Get untagged students
        let remainingStudents = tmpStudents.filter(
          (tmpStudent) =>
            !newStudentList.some(
              (newStudent) => newStudent._id === tmpStudent._id
            )
        )
        //Sort remaining students alphabetically
        remainingStudents.sort((a, b) =>
          a.info.name.lName.localeCompare(b.info.name.lName)
        )
        //Add remaining students to list
        newStudentList.push(...remainingStudents)
        setFilteredStudents(newStudentList)
        break
      case 'first':
        tmpStudents.sort((a, b) =>
          a.info.name.fName.localeCompare(b.info.name.fName)
        )
        setFilteredStudents(tmpStudents)
        break
      case 'last':
        tmpStudents.sort((a, b) =>
          a.info.name.lName.localeCompare(b.info.name.lName)
        )
        setFilteredStudents(tmpStudents)
        break
      default:
        break
    }
  }

  const filterByTag = (tag) => {
    let tmpTags = [...tags]
    if (tag.name === 'Show All') {
      //Remove show all from menu options
      tmpTags = tmpTags.filter((tmpTag) => tmpTag.name !== 'Show All')
      setTags(tmpTags)
      setSelectedTag(null)
      setFilteredStudents(students)
    } else {
      //Add option to clear filter to tag menu items
      let showAllCheck = tmpTags.filter((tmpTag) => tmpTag.name === 'Show All')
      if (showAllCheck.length === 0) {
        tmpTags.push({ name: 'Show All', students: [] })
        setTags(tmpTags)
      }
      //Set selected tag
      setSelectedTag(tag)
      //filter students
      let taggedStudents = students.filter((student) =>
        tag.students.includes(student._id)
      )
      setFilteredStudents(taggedStudents)
    }
  }

  const Content = () => {
    let showAttendance = false
    if (props.isEdit || isToday || addAttendance) {
      showAttendance = true
    }

    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '60% 40%',
          textAlign: 'left'
        }}
      >
        <Typography variant='h6'>Student</Typography>
        <Typography variant='h6'>
          {selectedDow} {selectedDate.toLocaleDateString()}
        </Typography>
        <Typography sx={{ gridColumn: '2/3' }}>
          {addAttendance ? 'Additional Attendance' : null}
        </Typography>
        <Divider sx={{ margin: '10px 0px', gridColumn: '1/3' }} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: showAttendance ? '60% 40%' : '100%',
            gridColumn: showAttendance ? '1 / 3' : '1/2'
          }}
        >
          {filteredStudents.map((student, index) => {
            let dateOfReturn = getPaused(student)
            let startDate = getDelayedStart(student)
            return (
              <React.Fragment key={index}>
                <Box sx={{ textAlign: 'left' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      variant='h5'
                      display='inline'
                      sx={{ flexGrow: 1 }}
                    >
                      {student.info.name.fName} {student.info.name.lName}
                    </Typography>
                    <IconButton
                      style={{ marginTop: '0px' }}
                      onClick={() => handleStudentInfo(index)}
                    >
                      {showStudentInfo === index ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </div>
                  <Box sx={{ marginRight: '40px', width: '40%' }}>
                    {dateOfReturn ? (
                      <StatusAlert>Paused {dateOfReturn}</StatusAlert>
                    ) : (
                      <></>
                    )}

                    {student.paymentStatus ? (
                      <StatusAlert>
                        Membership {student.paymentStatus}
                      </StatusAlert>
                    ) : (
                      <></>
                    )}

                    {startDate ? (
                      <StatusAlert>Start Date: {startDate}</StatusAlert>
                    ) : (
                      <></>
                    )}

                    {student.credit ? (
                      <AlternatePaymentModal
                        displayStatus={student.credit.confirmed}
                        selectedCredit={student.credit._id}
                        handleStatus={handleStatus}
                      />
                    ) : (
                      <></>
                    )}
                    {student.achStatus ? (<AchPaymentStatus displayStatus={student.achStatus} />) : <></>}

                    {student.tags.map((tag) => {
                      return (
                        <Chip
                          label={tag.name}
                          onDelete={() => {
                            handleShowRemoveStudent(tag, student)
                          }}
                          style={{ marginRight: '5px' }}
                          color='primary'
                        />
                      )
                    })}
                  </Box>
                  {showStudentInfo === index ? (
                    <>
                      <div>
                        <Typography sx={{ color: 'text.primary' }}>
                          Age: {ageHandler.getAge(student.info.dob)}
                        </Typography>
                        <Typography sx={{ color: 'text.primary' }}>
                          Member since {student.joined}
                        </Typography>
                      </div>
                      <Divider />
                      <div>
                        <Typography sx={{ color: 'text.primary' }}>
                          Parent Name:
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {student.parentInfo?.info.name.fName}{' '}
                          {student.parentInfo?.info.name.lName}
                        </Typography>
                        <Typography sx={{ color: 'text.primary' }}>
                          Parent Email :
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {student.parentInfo?.info.contact.email || student.parentInfo?.userName}
                        </Typography>
                        <Typography sx={{ color: 'text.primary' }}>
                          Parent Phone:
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {student.parentInfo?.info.contact.phone}
                        </Typography>
                        <Note
                          pNote={student.pNotes}
                          iNote={student.iNotes}
                          studentID={student._id}
                          students={filteredStudents}
                          setStudents={setFilteredStudents}
                        />
                        <Button
                          variant='contained'
                          size='small'
                          onClick={() => handleNavToAccountView(uID, student)}
                          style={{ marginTop: '10px' }}
                        >
                          More Details
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
                {showAttendance ? (
                  <Box>
                    <FormControl>
                      <RadioGroup
                        row
                        value={statusCheck(student)}
                        onChange={(event) =>
                          handleAttendanceChange(event, student, index)
                        }
                      >
                        <FormControlLabel
                          value='Present'
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': { color: green[600] }
                              }}
                            />
                          }
                          sx={{ color: green[800], margin: '0px' }}
                          label={<Typography fontSize={10}>Present</Typography>}
                          labelPlacement='top'
                        />
                        <FormControlLabel
                          value='Absent'
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': { color: pink[600] }
                              }}
                            />
                          }
                          sx={{ color: pink[800], margin: '0px' }}
                          label={<Typography fontSize={10}>Absent</Typography>}
                          labelPlacement='top'
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                ) : (
                  <></>
                )}
                {students.length - 1 === index ? (
                  <></>
                ) : (
                  <Divider sx={{ margin: '10px 0px', gridColumn: '1/3' }} />
                )}
              </React.Fragment>
            )
          })}
        </Box>
        {showAttendance ? (
          <></>
        ) : (
          <Box
            sx={{
              gridColumn: '2 / 3',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography>No Class Today</Typography>
            <Button onClick={() => setAddAttendance(true)}>
              Take Additional Attendance
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          {students.length > 0 ? (
            <>
              {props.isEdit ? (
                <>
                  <Dialog open fullWidth onClose={() => props.handleClose()}>
                    <DialogContent>
                      <Content />
                      <DialogActions>
                        <Button
                          onClick={handleSubmit}
                          variant='contained'
                          disabled={submitLoading || !showSubmit}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </>
              ) : (
                <Grid
                  container
                  sx={{ margin: '0px auto 100px auto', width: '90%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '90%',
                      marginBottom: '10px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '5px'
                      }}
                    >
                      <Typography
                        color='text.secondary'
                        variant='h6'
                        style={{ marginRight: '5px' }}
                      >
                        Sort By:
                      </Typography>
                      <Button
                        size='small'
                        variant={
                          sortSelection === 'tags' ? 'contained' : undefined
                        }
                        onClick={() => handleChangeFilter('tags')}
                      >
                        Tags
                      </Button>
                      <Button
                        size='small'
                        variant={
                          sortSelection === 'first' ? 'contained' : undefined
                        }
                        onClick={() => handleChangeFilter('first')}
                      >
                        First Name
                      </Button>
                      <Button
                        size='small'
                        variant={
                          sortSelection === 'last' ? 'contained' : undefined
                        }
                        onClick={() => {
                          handleChangeFilter('last')
                        }}
                      >
                        Last Name
                      </Button>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px'
                      }}
                    >
                      <Typography
                        color='text.secondary'
                        variant='h6'
                        style={{ marginRight: '5px' }}
                      >
                        Show Only:
                      </Typography>
                      <FormControl style={{ width: '200px' }}>
                        <InputLabel
                          id='tag-select-label'
                          style={{ marginTop: '-8px' }}
                        >
                          Tags
                        </InputLabel>
                        <Select
                          labelId='tag-select-label'
                          id='tag-simple-select'
                          value={selectedTag}
                          onChange={(e) => filterByTag(e.target.value)}
                          size='small'
                        >
                          {tags.map((tag) => {
                            return (
                              <MenuItem key={tag._id} value={tag}>
                                {tag.name}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <BlankCard>
                    <Content />
                  </BlankCard>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    {showSubmit ? (
                      <Button
                        onClick={handleSubmit}
                        variant='contained'
                        disabled={submitLoading}
                      >
                        Submit
                      </Button>
                    ) : (
                      <></>
                    )}
                    {showSuccess ? (
                      <Typography>Attendance submitted</Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <h1>{activity.course.name} has no students</h1>
          )}
          <DeleteTagDialog
            tag={targetedTag}
            student={studentToRemove}
            showConfirmDeleteTag={showRemoveStudent}
            handleCloseTagDelete={handleCloseRemoveStudent}
            handleConfirm={handleDeleteTag}
          />
        </>
      )}
    </>
  )
}

export default StudentList
