import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Paper, Typography, TextField, Button, Checkbox } from '@mui/material'
import { getStudents } from '../../../api/admin'
import { inviteToSubscription } from '../../../api/admin'

const SubscriptionInvite = () => {
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [students, setStudents] = useState([])
  const [selectedStudents, setSelectedStudents] = useState([])
  const [error, setError] = useState(null)
  const [confirmMessage, setConfirmMessage] = useState(false)
  const uID = useSelector((state) => state.login.user._id)
  const adminEmail = useSelector((state) => state.login.user.userName)

  const handleFirstNameChange = (value) => {
    setFirstName(value)
    setConfirmMessage(false)
  }

  const handleLastNameChange = (value) => {
    setLastName(value)
    setConfirmMessage(false)
  }

  const handleSearch = async () => {
    let resp = await getStudents({
      fName: firstName.trim(),
      lName: lastName.trim(),
      uID: uID
    })
    if (resp.success) {
      if (resp.data !== '') {
        setStudents((students) => [...students, ...resp.data])
        setError('')
      } else {
        setError('No student found')
      }
      setFirstName('')
      setLastName('')
    } else {
      setError('An error occurred while searching. Please try again')
    }
  }

  const handleInvite = async () => {
    let resp = await inviteToSubscription({
      students: selectedStudents,
      subscription: subscription,
      uID: uID,
      adminEmail: adminEmail
    })
    if (resp.success) {
      setSelectedStudents([])
      setStudents([])
      setConfirmMessage(true)
    } else {
      alert(resp.err)
    }

    setSelectedStudents([])
    setStudents([])
    setConfirmMessage(true)
  }

  const handleCheck = (student) => {
    let index = -1
    //Check if selected students contain value
    for (let i = 0; i < selectedStudents.length; i++) {
      if (selectedStudents[i]._id === student._id) {
        index = i
        break
      }
    }

    //Remove element from array if it exists or add it to array
    if (index !== -1) {
      setSelectedStudents(
        selectedStudents.filter((item) => item._id !== student._id)
      )
    } else {
      setSelectedStudents((selectedStudents) => [...selectedStudents, student])
    }
  }

  return (
    <Paper sx={{ margin: '0px auto 100px auto', width: '90%' }}>
      <Typography variant='h4'>
        Invite Students to {subscription.name}
      </Typography>

      <div style={{ margin: '5px', display: 'flex', justifyContent: 'center' }}>
        <TextField
          id='firstName'
          label='First Name'
          onChange={(e) => handleFirstNameChange(e.target.value)}
          value={firstName}
          style={{ margin: '5px' }}
        ></TextField>
        <TextField
          id='lastName'
          label='Last Name'
          onChange={(e) => handleLastNameChange(e.target.value)}
          value={lastName}
          style={{ margin: '5px' }}
        ></TextField>
        {error ? (
          <Typography style={{ color: 'red' }}>{error}</Typography>
        ) : (
          <></>
        )}
        <Button
          onClick={handleSearch}
          style={{
            color: '#FFFFFF',
            backgroundColor: '#C80F2D',
            height: '40px',
            margin: 'auto 0'
          }}
        >
          Search
        </Button>
      </div>

      <div>
        {students.length > 0 ? (
          <div>
            <Typography variant='h6'>Students to invite</Typography>
            {students.map((student) => {
              return (
                <div
                  key={student._id}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Checkbox onClick={() => handleCheck(student)} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      key={student._id + student.info.name.fName}
                      style={{ marginRight: '10px' }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Name: </span>
                      {`${student.info.name.fName} ${student.info.name.lName}`}
                    </div>
                    <div key={student._id + student.info.dob}>
                      <span style={{ fontWeight: 'bold' }}>Birthday: </span>
                      {student.info.dob}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <></>
        )}
      </div>

      <div>
        {selectedStudents.length > 0 ? (
          <Button
            style={{
              color: '#FFFFFF',
              backgroundColor: '#C80F2D',
              margin: '5px'
            }}
            onClick={handleInvite}
          >
            Invite
          </Button>
        ) : (
          <></>
        )}
      </div>

      <div>
        {confirmMessage ? (
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            Invite sent. Search again to invite more students
          </Typography>
        ) : (
          <></>
        )}
      </div>
    </Paper>
  )
}

export default SubscriptionInvite
