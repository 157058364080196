const alpha = 'abcdefghijklmnopqrstuvwxyz'
const caps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const integers = '0123456789'

const generateSuffix = () => {
  let emailEnd = ''

  emailEnd += caps.charAt(Math.floor(Math.random() * caps.length))
  emailEnd += alpha.charAt(Math.floor(Math.random() * alpha.length))
  emailEnd += integers.charAt(Math.floor(Math.random() * integers.length))
  emailEnd += caps.charAt(Math.floor(Math.random() * caps.length))
  emailEnd += integers.charAt(Math.floor(Math.random() * integers.length))

  return emailEnd
}

const generateEmail = (data) => {
  let suffix = generateSuffix()
  let email = data.fName + '.' + data.lName + suffix + '@void.com'
  email = email.split(' ').join()
  return email
}

export default generateEmail
