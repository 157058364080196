import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { getTags } from '../../../api/tag'
import Tag from './tag'
import AddTag from './addTag'

const Tags = ({ all }) => {
  const uID = useSelector((state) => state.login.user._id)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )

  const [students, setStudents] = useState([])
  const [tags, setTags] = useState([])

  useEffect(() => {
    async function handleAsync() {
      let resp = await getTags({
        params: { subscriptionID: subscription?._id, uID: uID, all: all }
      })
      if (resp.success) {
        setTags(resp.data.tags)
        setStudents(resp.data.students)
      } else {
        alert('There was a problem retrieving tags')
      }
    }
    handleAsync()
  }, [subscription?._id, uID, all])

  return (
    <Grid
      container
      sx={{ margin: '0px auto 100px auto', width: '90%' }}
      spacing={2}
    >
      {tags.map((tag) => {
        return (
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            lg={2}
            style={{ marginTop: '10px' }}
            key={tag?.name}
          >
            <Tag
              tag={tag}
              students={students}
              tags={tags}
              setTags={setTags}
              all={all}
            />
          </Grid>
        )
      })}
      <Grid item xs={3} style={{ marginTop: '10px' }}>
        <AddTag tags={tags} setTags={setTags} all={all} />
      </Grid>
    </Grid>
  )
}

export default Tags
