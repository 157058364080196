import CONSTANTS from "../constants"
// validates a phone number
const validatePhone = (phone) => {
  // rm non digit
  let tmp = phone
  tmp = tmp.split('(').join('')
  tmp = tmp.split(')').join('')
  tmp = tmp.split('-').join('')
  tmp = tmp.split('-').join('')
  tmp = tmp.split('+').join('')

  return CONSTANTS.PHONE_REGEX.test(String(tmp))
}

export default validatePhone
