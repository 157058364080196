//Utilities to handle lists

/**
 * Primarily used when editing an object that exists in a list in the redux store
 * @param {Object} obj - the object to be inserted into the array
 * @param {Array} arr - the array that needs to be updated
 * @returns - the updated array
 */
const updateObjInArr = (obj, arr) => {
  arr.splice(
    arr.findIndex(function (i) {
      return i._id === obj._id
    }),
    1,
    obj
  )
  return arr
}

/**
 * Alphabetizes a list
 * @param {Array} list - the array to alphabetize
 * @param {String, integer} key - the key for the string to use as alphabetical reference. 
 * If the list contains objects, use a string. 
 * If the list contains strings, use 0.
 * @returns - the alphabetized array
 */
const alphabetizeList = (list, key) => {
  let tmp = [...list].sort((a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
  return tmp
}

const listHandler = { updateObjInArr, alphabetizeList }
export default listHandler
