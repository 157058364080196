import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComp from '../../util/loading'
import { cancelSubscription } from '../../../api/admin'
import {
  updateSub,
  setSelectedSubscription
} from '../../../store/subscriptionSlice'
import { setSelectedUser } from '../../../store/userSlice'
import { Typography, Button, Checkbox, FormControlLabel } from '@mui/material'
import stripeData from '../../../utils/stripeData'

const CancelSub = ({ accountInfo, close }) => {
  const dispatch = useDispatch()

  const student = useSelector((state) => state.subscription.student)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const uID = useSelector((state) => state.login.user._id)
  const selectedUser = useSelector((state) => state.user.selectedUser)
  const selectedStudent = useSelector((state) => state.subscription.student)
  const paymentData = useSelector((state) => state.subscription.paymentData)

  const [showSubmit, setShowSubmit] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showProblem, setShowProblem] = useState(false)
  const [showScheduled, setShowScheduled] = useState(false)
  const [checked, setChecked] = useState('immediately')

  const handleCheck = (checkedValue) => {
    setChecked(checkedValue)
  }

  const handleSubmit = async () => {
    setShowSubmit(false)
    setShowLoading(true)
    let resp = await cancelSubscription({
      uID: uID,
      studentID: student._id,
      subscriptionID: subscription._id,
      paymentData: paymentData,
      checked: checked
    })
    setShowLoading(false)
    if (resp.success) {
      if (resp.data.cancelled) {
        if (checked === 'period') {
          setShowScheduled(true)
          setShowSuccess(true)
        } else {
          //Updated redux store
          if (accountInfo) {
            let tmpUser = { ...selectedUser }
            let tmpStudent = { ...selectedStudent }
            let tmpStudents = [...tmpUser.students]

            //update student's subscription list
            let tmpSubs = [...tmpStudent.subscriptionInfo]
            let subIndex = tmpSubs.findIndex(
              (sub) => sub._id === resp.data.subscription._id
            )
            tmpSubs.splice(subIndex, 1)
            tmpStudent['subscriptionInfo'] = tmpSubs

            //update student's inactive subscription list
            let tmpInactive = [...tmpStudent.inactiveSubscriptions]
            tmpInactive.push(resp.data.subscription)
            tmpStudent['inactiveSubscriptions'] = tmpInactive

            //update student's active activity list
            let tmpActivities = [...tmpStudent.activityInfo]
            let activeActivities = tmpActivities.filter(
              (activity) =>
                activity.instance.subscriptionID !== resp.data.subscription._id
            )
            tmpStudent['activityInfo'] = activeActivities

            //update student list with new student object in redux store
            let studentIndex = tmpStudents.findIndex(
              (element) => element._id === tmpStudent._id
            )
            tmpStudents.splice(studentIndex, 1, tmpStudent)
            tmpUser.students = tmpStudents
            dispatch(setSelectedUser(tmpUser))

            close()
          } else {
            dispatch(updateSub(resp.data.subscription))
            dispatch(setSelectedSubscription(resp.data.subscription))
            setShowSuccess(true)
          }
        }
      } else {
        setShowProblem(true)
      }
    } else {
      setShowProblem(true)
    }
  }

  const handleClose = () => {
    close()
  }

  return (
    <>
      {showSubmit ? (
        <>
          <Typography variant='h6'>
            Remove {`${student?.info.name.fName} ${student?.info.name.lName} `}
            from {subscription.name}
          </Typography>
          {paymentData.object === 'subscription' ? (
            <div
              style={{
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '400px'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      handleCheck('immediately')
                    }}
                    checked={checked === 'immediately'}
                  />
                }
                label='Cancel Immediately'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      handleCheck('period')
                    }}
                    checked={checked === 'period'}
                  />
                }
                label={`Cancel at Period End: ${stripeData.getBillDue(
                  paymentData
                )}`}
              />
            </div>
          ) : (
            <></>
          )}
          <Button onClick={handleSubmit}>Submit</Button>
        </>
      ) : (
        <></>
      )}
      {showLoading ? <LoadingComp /> : <></>}
      {showSuccess ? (
        <>
          {showScheduled ? (
            <>
              <Typography>
                {`${student?.info.name.fName} ${student?.info.name.lName}`} is
                scheduled to be removed from {subscription.name} on{' '}
                {stripeData.getBillDue(paymentData)}. They will be removed from
                the enrollment at that time.
              </Typography>
              <Button onClick={handleClose}>Close</Button>
            </>
          ) : (
            <Typography variant='h6'>
              {`${student?.info.name.fName} ${student?.info.name.lName} `}{' '}
              removed from {subscription.name}
            </Typography>
          )}
        </>
      ) : (
        <></>
      )}
      {showProblem ? (
        <>
          <Typography variant='h6' style={{ color: 'red' }}>
            There was a problem canceling the subscription. Data in Stripe and
            the database may be incorrect
          </Typography>
          <Typography>
            Please check Stripe to determine{' '}
            {`${student?.info.name.fName} ${student?.info.name.lName}'s subscription status `}
            in addition to the database
          </Typography>
          <Typography variant='h6'>Data:</Typography>
          <Typography>Subscription ID: {subscription._id}</Typography>
          <Typography>Student ID: {student._id}</Typography>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CancelSub
