import { combineReducers, configureStore } from '@reduxjs/toolkit'
import loginReducer from './loginSlice'
import parentReducer from './parentSlice'
import instructorSlice from './instructorSlice'
import adminSlice from './adminSlice'
import workshopSlice from './workshopSlice'
import cartSlice from './cartSlice'
import subscriptionSlice from './subscriptionSlice'
import userSlice from './userSlice'
import locationSlice from './locationSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const loginPersistConfig = {
  key: 'login',
  storage
}

const appReducer = combineReducers({
  login: persistReducer(loginPersistConfig, loginReducer),
  parent: parentReducer,
  instructor: instructorSlice,
  admin: adminSlice,
  workshop: workshopSlice,
  cart: cartSlice,
  subscription: subscriptionSlice,
  user: userSlice,
  location: locationSlice
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
      // for all keys defined in your persistConfig(s)
      storage.removeItem('persist:login')
      return appReducer(undefined, action); //resets store
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // Allow use of redux dev tools on production
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})

export const persistor = persistStore(store)