import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cards from '../../util/cards'
import { setSelectedRecipients } from '../../../store/adminSlice'
import { getEmails } from '../../../api/admin'
import Recipient from './recipient'
import MembershipFilters from './membershipFilters'
import WorkshopFilters from './workshopFilters'
import EmailList from './emailList'

const SelectRecipients = ({ type, currTab }) => {
  const dispatch = useDispatch()

  const BlankCard = cards.blankCard
  const uID = useSelector((state) => state.login.user._id)

  const recipientOptions = useSelector((state) => state.admin.recipientOptions)
  const selectedRecipients = useSelector(
    (state) => state.admin.selectedRecipients
  )
  const filterApplied = useSelector((state) => state.admin.filterApplied)

  const StyledDivider = () => <Divider sx={{ margin: '10px 0px' }} />

  const [loading, setLoading] = useState(false)
  const [emailAddresses, setEmailAddresses] = useState([])
  const [showEmailAddresses, setShowEmailAddresses] = useState(false)

  useEffect(() => {
    //Clear selected recipients when switching tabs
    dispatch(setSelectedRecipients([]))
  }, [currTab])

  const handleCompose = async () => {
    setLoading(true)
    if (selectedRecipients.length > 0) {
      let parentIDs = []
      //Get parent IDs from students
      selectedRecipients.forEach((selectedRecipient) =>
        parentIDs.push(selectedRecipient.student.parents[0])
      )
      let emails = await getEmails({ uID: uID, parents: parentIDs })
      if (emails.success) {
        setLoading(false)
        emails = emails.data
        if (emails.length > 250) {
          alert(
            'You have selected more than 250 emails, which is more than Google allows. ' +
              'Please reduce the number of recipients and continue.'
          )
        } else {
          let gmail = `https://mail.google.com/mail/?extsrc=mailto&url=mailto%3Ainfo%40rollingrobots.com%3Fbcc%3D${encodeURIComponent(
            emails.toString()
          )}`
          window.open(gmail, '_blank')
        }
      } else {
        alert(emails.error)
      }
    } else {
      setLoading(false)
      alert(
        'Current selection has no students enrolled. Try selecting different activities.'
      )
    }
  }

  const handleEmails = async () => {
    setLoading(true)
    let parentIDs = []
    //Get parent IDs from students
    selectedRecipients.forEach((selectedRecipient) =>
      parentIDs.push(selectedRecipient.student.parents[0])
    )
    let emails = await getEmails({ uID: uID, parents: parentIDs })
    if (emails.success) {
      setShowEmailAddresses(true)
      setEmailAddresses(emails.data)
      setLoading(false)
    } else {
      alert('There was a problem retrieving email addresses')
      setLoading(false)
    }
  }

  const handleCloseEmailAddresses = () => {
    setShowEmailAddresses(false)
  }

  const containsAll = () => {
    let containsAll = true
    if (selectedRecipients.length === 0) {
      containsAll = false
    } else {
      for (const recipientStudent of recipientOptions) {
        let checkArr = selectedRecipients.filter(
          (selectedStudent) =>
            selectedStudent.student._id === recipientStudent.student._id
        )
        if (checkArr.length === 0) {
          containsAll = false
        }
      }
    }
    return containsAll
  }

  const handleSelectAll = () => {
    let tmpOptions = [...recipientOptions]
    let tmpSelected = [...selectedRecipients]
    if (containsAll()) {
      //Take out only recipients from current recipient options list
      tmpOptions.forEach((tmpOption) => {
        let index = tmpSelected.findIndex(
          (tmp) => tmp.student._id === tmpOption.student._id
        )
        if (index !== -1) {
          tmpSelected.splice(index, 1)
        }
      })
    } else {
      //Add all recipient options to selected recipients
      tmpOptions.forEach((tmpOption) => {
        if (
          tmpSelected.filter((tmp) => tmp.student._id === tmpOption.student._id)
            .length === 0
        ) {
          tmpSelected.push(tmpOption)
        }
      })
    }
    dispatch(setSelectedRecipients(tmpSelected))
  }

  const handleClear = () => {
    dispatch(setSelectedRecipients([]))
  }

  return (
    <div style={{ margin: '0px auto', width: '80%' }}>
      <Grid container>
        <Grid item xs={7} sx={{ paddingRight: '15px' }}>
          {type === 'membership' ? <MembershipFilters /> : <WorkshopFilters />}
          <Typography align='left' variant='h4' sx={{ gridColumn: '1/2' }}>
            Select Recipients
          </Typography>
          <BlankCard id='select-recipients'>
            {filterApplied ? (
              recipientOptions.length > 0 ? (
                <>
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    checked={containsAll()}
                    onChange={() => handleSelectAll()}
                    control={<Checkbox sx={{ marginBottom: 'auto' }} />}
                    label={<Typography>Select All</Typography>}
                  />
                  <StyledDivider />
                  {recipientOptions.map((student, index) => (
                    <Recipient
                      key={index}
                      studentInfo={student}
                      index={index}
                      length={recipientOptions.length}
                      type={'option'}
                    />
                  ))}
                </>
              ) : (
                <Typography>No students match this filter.</Typography>
              )
            ) : (
              <Typography>Apply filter to view recipients.</Typography>
            )}
          </BlankCard>
        </Grid>
        <Grid item xs={5}>
          <Typography
            align='left'
            variant='h4'
            sx={{ gridColumn: '2/3', rowStart: '1' }}
          >
            Recipients
          </Typography>

          {selectedRecipients.length > 0 ? (
            <Box style={{ marginBottom: '15px' }}>
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <Button variant='contained' onClick={handleClear}>
                    Clear
                  </Button>
                  <Button variant='contained' onClick={handleEmails}>
                    Get Email List
                  </Button>
                  <Button variant='contained' onClick={handleCompose}>
                    Compose
                  </Button>
                </div>
              )}
            </Box>
          ) : (
            <></>
          )}

          <BlankCard style={{}} id='selected-recipients'>
            {selectedRecipients.length > 0 ? (
              selectedRecipients.map((recipient, index) => {
                return (
                  <>
                    <Recipient
                      studentInfo={recipient}
                      index={index}
                      type={'selected'}
                      length={Object.keys(selectedRecipients).length}
                    />
                  </>
                )
              })
            ) : (
              <Typography>No recipients currently selected.</Typography>
            )}
          </BlankCard>
        </Grid>
      </Grid>
      <EmailList
        emails={emailAddresses}
        open={showEmailAddresses}
        close={handleCloseEmailAddresses}
      />
    </div>
  )
}

export default SelectRecipients
