import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  useMediaQuery
} from '@mui/material'
import Section from '../component/landing/section'
import SummerCampChart from '../component/landing/summerCampChart'
import ReactGA from 'react-ga4'
import { useOutletContext } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import LocationSection from '../component/landing/locationSection'
import STYLES from '../component/landing/landingStyles'
import TestimonialsSection from '../component/landing/testimonialSection'
import HolidayCampsSection from '../component/landing/holidayCampsSection'
import LearningContentSection from '../component/landing/learningContentSection'

const HolidayCampLanding = () => {

  const [locations] = useOutletContext()

  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/holidaycamplanding',
  //     title: 'holidaycamplanding'
  //   })
  //   ReactPixel.pageView()
  // }, [])

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  let styles = STYLES

  window.onload = () => {
    const loader = document.querySelector('.loader')
    loader.classList.add('loader--hide')
    loader.style.setProperty('content', 'none')
  }

  return (
    <>
    <HolidayCampsSection styles={styles} locations={locations}/>
    <Section
        title={'Rolling Robots Holiday Camps'}
        sx={{ backgroundColor: styles.lightGrey }}
      >
        <Box sx={{ ...styles.box, width: { xs: '95%', sm: '90%', md: '80%' } }}>
          <SummerCampChart
            mobile={mobile}
            styles={styles}
            smallScreen={smallScreen}
            largeScreen={largeScreen}
          />
        </Box>
    </Section>
    <TestimonialsSection styles={styles}/>
    
    <LearningContentSection styles={styles} />

    <LocationSection styles={styles} mobile={mobile} locations={locations} type='workshop' title='Explore the Exciting Locations for Rolling Robots Holiday Camps' />

    </>
  )
}

export default HolidayCampLanding
