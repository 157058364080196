import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedLocation, setView } from '../../../store/locationSlice'
import SecondaryNav from '../../util/secondaryNav'
import LocationDetails from './locationDetails'

const LocationView = () => {
  const dispatch = useDispatch()

  const [currTab, setCurrTab] = useState(0)

  const location = useSelector((state) => state.location.selectedLocation)

  const TABS = [{ label: 'Details', content: <LocationDetails /> }]

  const handleBack = () => {
    dispatch(setSelectedLocation(null))
    dispatch(setView(0))
  }

  return (
    <>
      <SecondaryNav
        title={location.name}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
        back={handleBack}
      />
      <>{TABS[currTab].content}</>
    </>
  )
}

export default LocationView
