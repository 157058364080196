import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { editNote } from '../api/admin'
import SaveIcon from '@mui/icons-material/Save'
import {
  Typography,
  TextField,
  IconButton,
  InputAdornment
} from '@mui/material'

const Note = ({ pNote, iNote, studentID, students, setStudents }) => {
  const [instructorNote, setInstructorNote] = useState(iNote)
  const [showSave, setShowSave] = useState(false)
  const [showNotes, setShowNotes] = useState(false)
  const uID = useSelector((state) => state.login.user._id)

  const handleSubmit = async (studentID) => {
    let data = { iNotes: instructorNote, studentID: studentID, uID: uID }
    let resp = await editNote(data)
    if (resp.success) {
      if (students) {
        let tmpStudents = [...students]
        tmpStudents.splice(
          tmpStudents.findIndex(function (i) {
            return i._id === resp.data._id
          }),
          1,
          resp.data
        )
        setStudents(tmpStudents)
      }
      setShowSave(false)
    } else {
      alert('Failed to save note')
    }
  }

  return (
    <>
      {(pNote && pNote !== '') || (iNote && iNote !== '') ? (
        <>
          <Typography
            sx={{ color: 'text.primary' }}
            onClick={() => {
              setShowNotes(!showNotes)
            }}
            style={{ margin: '5px 0', cursor: 'pointer' }}
          >
            Click for notes
          </Typography>
          {showNotes ? (
            <>
              <Typography sx={{ color: 'text.primary' }}>
                Parent Note:
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{pNote}</Typography>
              <Typography sx={{ color: 'text.primary' }}>
                Instructor Note:
              </Typography>
              <TextField
                id='filled-multiline-flexible'
                multiline
                fullWidth
                maxRows={4}
                variant='standard'
                defaultValue={instructorNote}
                onChange={(e) => {
                  setShowSave(true)
                  setInstructorNote(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          handleSubmit(studentID)
                        }}
                      >
                        {showSave ? <SaveIcon /> : <></>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Typography
            sx={{ color: 'text.primary' }}
            style={{ marginTop: '10px' }}
          >
            Add note
          </Typography>
          <TextField
            id='filled-multiline-flexible'
            label='Instructor Note:'
            multiline
            fullWidth
            maxRows={4}
            variant='standard'
            defaultValue={instructorNote}
            onChange={(e) => {
              setShowSave(true)
              setInstructorNote(e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => {
                      handleSubmit(studentID)
                    }}
                  >
                    {showSave ? <SaveIcon /> : <></>}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </>
      )}
    </>
  )
}

export default Note
