import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import InputMask from 'react-input-mask'
import ModelHandler from '../../../models/modelHandler'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export default function EditStudentForm({
  index,
  handleClose,
  handleChange,
  data,
  handleNext,
  isAdmin
}) {
  const [focus, setFocus] = useState(null)
  const [error, setError] = useState([])

  const next = () => {
    let tmp = {
      fName: data.info.name.fName,
      lName: data.info.name.lName,
      dob: data.info.dob,
      waiver: 'waiver'
    }
    let errors = ModelHandler.checkStudentData(tmp, true)
    if (errors.length >= 1) {
      console.log(errors)
      setError(errors)
    } else {
      handleNext()
    }
  }

  const STUDENT_FIELDS = [
    {
      type: 'fName',
      meta: 'name',
      key: 'fName',
      value: data.info.name.fName,
      label: 'First Name',
      isRequired: true
    },
    {
      type: 'lName',
      label: 'Last Name',
      meta: 'name',
      key: 'lName',
      value: data.info.name.lName,
      isRequired: true
    },
    {
      type: 'mName',
      label: 'Middle Name',
      meta: 'name',
      key: 'mName',
      value: data.info.name.mName,
      isRequired: false
    },
    {
      type: 'dob',
      meta: 'dob',
      key: 'dob',
      label: 'Date of Birth',
      value: data.info.dob,
      helper: 'MM/DD/YYYY',
      isRequired: true
    }
  ]
  const standardField = (item) => {
    return (
      <TextField
        required={item.isRequired}
        margin='dense'
        error={error.includes(item.type)}
        id={item.type}
        key={item.type}
        label={item.label}
        defaultValue={item.value}
        onFocus={() => {
          setFocus(item.key)
        }}
        helperText={focus === item.key ? item.helper || null : null}
        type={item.type}
        fullWidth
        onChange={(e) => {
          handleChange(item.type, e.target.value, item.meta)
        }}
        variant='standard'
      />
    )
  }

  const dobField = (item) => {
    return (
      <InputMask
        key={item.key}
        mask='99/99/9999'
        onChange={(e) => {
          handleChange(item.type, e.target.value, item.meta)
        }}
        defaultValue={item.value}
        onFocus={() => {
          setFocus(item.key)
        }}
      >
        {() => (
          <TextField
            required={item.isRequired}
            margin='dense'
            error={error.includes(item.type) || error.includes('invalidDate')}
            fullWidth
            id={item.type}
            key={item.key}
            label={item.label}
            helperText={focus === item.key ? item.helper : null}
            type={item.type}
            variant='standard'
          />
        )}
      </InputMask>
    )
  }

  const styles = {
    tempWeb: {}
  }
  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  return (
    <div>
      <Dialog fullScreen open onClose={handleClose} style={styles.tempWeb}>
        <DialogTitle>
          Edit child's information
          <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {STUDENT_FIELDS.map((item) => {
            if (item.type === 'dob') {
              return dobField(item)
            } else {
              return standardField(item)
            }
          })}
          <TextField
            fullWidth
            id='filled-multiline-flexible'
            label={isAdmin ? 'Instructor Notes' : 'Parent Notes'}
            multiline
            maxRows={4}
            defaultValue={isAdmin ? data.iNotes : data.pNotes}
            onChange={(e) => {
              if (isAdmin) {
                handleChange('iNotes', e.target.value, 'iNotes')
              } else {
                handleChange('pNotes', e.target.value, 'pNotes')
              }
            }}
            variant='filled'
          />
          {isAdmin ? (
            <Typography sx={{ textAlign: 'left', fontSize: '12px' }}>
              Instructor notes are not visible to parents.
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              next()
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
