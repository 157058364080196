import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import CancelSub from '../../adminViews/cancelSubscription/cancelSub'

const CancelSubscription = ({ open, close }) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle style={{ margin: 'auto' }}>Cancel Membership</DialogTitle>
      <DialogContent>
        <CancelSub accountInfo={true} close={close} />
      </DialogContent>
    </Dialog>
  )
}

export default CancelSubscription
