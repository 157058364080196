import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Typography,
  Button,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  IconButton,
  Checkbox
} from '@mui/material'
import { ExpandMore, Launch } from '@mui/icons-material'
import AlternatePaymentModal from '../adminViews/alternatePaymentModal'
import useNavToAccountView from '../../utils/navToAccountView'
import Note from '../note'
import AchPaymentStatus from '../adminViews/achPaymentStatus'

const WorkshopStudents = ({
  students,
  setStudents,
  handleEmail,
  handleAttendance,
  handleInvite,
  handleStatus,
  isLoading,
  invitedStudents,
  setInvitedStudents
}) => {
  const handleNavToAccountView = useNavToAccountView()
  const uID = useSelector((state) => state.login.user._id)
  const permissions = useSelector((state) => state.login.user?.permissions?.[0])
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    event.stopPropagation()
  }

  const handleCheckbox = (studentId) => (event, isExpanded) => {
    setExpanded(isExpanded)

    let index = -1
    //Check if selected students contain value
    for (let i = 0; i < invitedStudents.length; i++) {
      if (invitedStudents[i] === studentId) {
        index = i
        break
      }
    }

    //Remove element from array if it exists or add it to array
    if (index !== -1) {
      setInvitedStudents(invitedStudents.filter((item) => item !== studentId))
    } else {
      setInvitedStudents((invitedStudents) => [...invitedStudents, studentId])
    }
  }

  return (
    <div style={{ marginTop: '10px' }}>
      {permissions >= 3 ? (
        <>
          {isLoading ? (
            <>loading...</>
          ) : (
            <div>
              <Typography
                component='div'
                variant='body2'
                color='text.secondary'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div>Email:</div>
                <div
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    fontWeight: 'bold'
                  }}
                  onClick={() => {
                    handleEmail('pre')
                  }}
                >
                  Pre
                </div>
                <div
                  style={{
                    color: 'rgb(200,16,46)',
                    cursor: 'pointer',
                    fontWeight: 'bold'
                  }}
                  onClick={() => {
                    handleEmail('post')
                  }}
                >
                  Post
                </div>
                <div
                  style={{
                    color: 'rgb(123,175,212)',
                    cursor: 'pointer',
                    fontWeight: 'bold'
                  }}
                  onClick={() => {
                    handleEmail('custom')
                  }}
                >
                  Custom
                </div>
              </Typography>
              <>
                <Typography
                  variant={'h6'}
                  sx={{
                    width: '30%',
                    flexShrink: 0,
                    fontWeight: 'bold'
                  }}
                >
                  Students:
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Button onClick={handleAttendance}>Attendance</Button>
                  <>
                    {invitedStudents.length > 0 ? (
                      <Button
                        onClick={handleInvite}
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#C80F2D'
                        }}
                      >
                        Invite
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </>
              <div
                style={{
                  // display: 'flex',
                  // justifyContent: 'space-between',
                  margin: '0 30px 0 15px',
                  display: 'grid',
                  gridTemplateColumns: '15px auto 10% 10% 10%'
                }}
              >
                <Typography>#</Typography>

                <Typography>Name</Typography>
                <Typography>Age</Typography>
                <Typography>View</Typography>
                <Typography>Invite</Typography>
              </div>
              {students.map((data, index) => {
                return (
                  <Accordion
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                    key={index}
                    disableGutters
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls='panel1bh-content'
                      id='panel1bh-header'
                    >
                      <div
                        className='student'
                        style={{
                          display: 'grid',
                          alignItems: 'center',
                          gridTemplateColumns: '15px auto 10% 10% 10%',
                          width: '100%'
                        }}
                      >
                        <Typography>{index + 1}</Typography>

                        <div>
                          <Typography sx={{ fontWeight: '300' }}>
                            {data.student.name}
                          </Typography>
                        </div>

                        <Typography
                          sx={{
                            color: 'text.secondary',
                            textAlign: 'center'
                          }}
                        >
                          {data.student.age}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            handleNavToAccountView(uID, data.parent._id)
                          }
                        >
                          <Launch />
                        </IconButton>

                        <Checkbox onChange={handleCheckbox(data.student.id)} />

                        {typeof data.student.creditConfirmed == 'number' ? (
                          <AlternatePaymentModal
                            displayStatus={data.student.creditConfirmed}
                            selectedCredit={data.student.creditID}
                            handleStatus={handleStatus}
                            sx={{ gridColumn: '1/3' }}
                          />
                        ) : (
                          <></>
                        )}
                        {data.student.achStatus ? (
                          <AchPaymentStatus
                            displayStatus={data.student.achStatus}
                            sx={{ gridColumn: '1/3' }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>Parent Information</Typography>
                      <Typography sx={{ color: 'text.primary' }}>
                        Parent Name:
                      </Typography>

                      <Typography sx={{ color: 'text.secondary' }}>
                        {data.parent.name}
                      </Typography>

                      <Typography sx={{ color: 'text.primary' }}>
                        Parent Email :
                      </Typography>

                      <Typography sx={{ color: 'text.secondary' }}>
                        {data.parent.email}
                      </Typography>

                      <Typography sx={{ color: 'text.primary' }}>
                        Parent Phone:
                      </Typography>

                      <Typography sx={{ color: 'text.secondary' }}>
                        {data.parent.phone}
                      </Typography>

                      <Note
                        pNote={data.student.pNotes}
                        iNote={data.student.iNotes}
                        studentID={data.student.id}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default WorkshopStudents
