import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography } from '@mui/material'
import { getSubscriptionStudents, changeSubscription } from '../../../api/admin'
import { updateSub, updateActivity } from '../../../store/subscriptionSlice'
import { setSelectedUser } from '../../../store/userSlice'
import SubscriptionSelect from './subscriptionSelect'
import PaymentSelect from './paymentSelect'
import ActivitySelect from './activitySelect'
import LoadingComp from '../../util/loading'
import { useNavigate } from 'react-router-dom'

const SubscriptionSwitch = ({ updateAccount, close }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const subscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )
  const uID = useSelector((state) => state.login.user._id)
  const subscriptionActivities = useSelector(
    (state) => state.subscription.subscriptionActivities
  )
  const selectedUser = useSelector((state) => state.user.selectedUser)

  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  const selectedStudent = useSelector((state) => state.subscription.student)
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  const [selectedActivities, setSelectedActivities] = useState([])

  const [showOptions, setShowOptions] = useState(true)
  const [showPayment, setShowPayment] = useState(false)
  const [showSubscriptions, setShowSubscriptions] = useState(false)
  const [showActivities, setShowActivities] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const [sameSubscription, setSameSubscription] = useState(false)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getSubscriptionStudents({
        params: {
          uID: uID,
          students: subscription.students,
          productID: subscription.productID
        }
      })
      if (resp.success) {
        setStudents(resp.data)
        setLoading(false)
      } else {
        alert('There was a problem retrieving students for this subscription')
      }
    }
    if (subscription.students.length > 0) {
      handleAsync()
    } else {
      setLoading(false)
    }
  }, [])

  const handleOptionSelect = (selection) => {
    setShowOptions(false)
    if (selection === 'plan') {
      setShowPayment(true)
    } else {
      setShowSubscriptions(true)
    }
  }

  const handleSubscriptionSelect = (event) => {
    let subscriptionID = event.target.value
    if (subscriptionID === subscription._id) {
      setSelectedSubscription(subscription)
      setSameSubscription(true)
    } else {
      let selection = subscriptions.filter(
        (pickedSubscription) => pickedSubscription._id === subscriptionID
      )[0]
      setSelectedSubscription(selection)
    }

    setShowSubscriptions(false)
    setShowActivities(true)
  }

  const handleActivitySelect = (activityID) => {
    let tmpActivityArr = [...selectedActivities]
    let index = tmpActivityArr.indexOf(activityID)

    if (index === -1) {
      setSelectedActivities([...selectedActivities, activityID])
    } else {
      tmpActivityArr.splice(index, 1)
      setSelectedActivities([...tmpActivityArr])
    }
  }

  //Check if day limit has been surpassed
  const checkNumOfDays = () => {
    if (selectedActivities.length >= selectedSubscription?.numOfDays) {
      return true
    } else {
      return false
    }
  }

  const getOldActivities = () => {
    let oldActivities = []
    subscriptionActivities.forEach((subActivity) => {
      if (subActivity.instance.students.includes(selectedStudent._id)) {
        oldActivities.push(subActivity.instance._id)
      }
    })
    return oldActivities
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)
    let resp = await changeSubscription({
      uID: uID,
      student: selectedStudent._id,
      oldSubscription: subscription._id,
      newSubscription: selectedSubscription._id,
      newActivities: selectedActivities,
      oldActivities: getOldActivities(),
      stripe: selectedStudent.hasOwnProperty('stripe'),
      creditID: selectedStudent.credit?._id
    })
    if (resp.success) {
      if (updateAccount) {
        //Update account info page
        let tmpUser = { ...selectedUser }
        let tmpStudent = { ...selectedStudent }
        let tmpStudents = [...tmpUser.students]
        let tmpActivityInfo = [...tmpStudent.activityInfo]
        //Update activities
        resp.data.activities.forEach((activity) => {
          if (activity.instance.students.includes(tmpStudent._id)) {
            tmpActivityInfo.push(activity)
          } else {
            let activityIndex = tmpActivityInfo.findIndex(
              (info) => info._id === activity._id
            )
            tmpActivityInfo.splice(activityIndex, 1)
          }
        })
        tmpStudent['activityInfo'] = tmpActivityInfo
        //Update subscription info
        let tmpSubs = [...tmpStudent.subscriptionInfo]
        if (!sameSubscription) {
          let subIndex = tmpSubs.findIndex(
            (sub) => sub._id === resp.data.oldSubscription._id
          )
          let newSubObj = {
            ...resp.data.newSubscription,
            stripe: { ...resp.data.updatedStripeInfo }
          }
          tmpSubs.splice(subIndex, 1, newSubObj)
          tmpStudent['subscriptionInfo'] = tmpSubs
        }

        let studentIndex = tmpStudents.findIndex(
          (element) => element._id === tmpStudent._id
        )
        tmpStudents.splice(studentIndex, 1, tmpStudent)
        tmpUser.students = tmpStudents
        dispatch(setSelectedUser(tmpUser))

        close()
      } else {
        // Update activities in redux store
        if (sameSubscription) {
          resp.data.activities.forEach((activity) => {
            dispatch(updateActivity(activity))
          })
        } else {
          //Update subscriptions in redux store
          dispatch(updateSub(resp.data.oldSubscription))
          dispatch(updateSub(resp.data.newSubscription))
        }
        setSubmitLoading(false)
        setShowActivities(false)
        setShowSuccess(true)
      }
    } else {
      setSubmitLoading(false)
      alert(resp.data.error.message)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          {students.length > 0 ? (
            <Grid
              container
              justifyContent='center'
              spacing={2}
              sx={{ margin: '0px auto 100px auto', width: '70%' }}
            >
              <Grid item xs={12}>
                {showOptions ? (
                  <>
                    {selectedStudent.credit ? (
                      <>
                        <Typography variant='body2' sx={{ fontSize: 18 }}>
                          Switch {selectedStudent.info.name.fName}{' '}
                          {selectedStudent.info.name.lName} into another
                          membership.
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant='body2' sx={{ fontSize: 18 }}>
                          You can either switch the student's payment plan or
                          switch the student into another membership.
                        </Typography>
                        {selectedStudent.stripe === undefined ? (
                          <Typography sx={{ color: 'red' }}>
                            Error: Switching payment plan must be handled
                            through Stripe (Membership can still be switched).
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {selectedStudent.stripe ? (
                      <Button
                        onClick={() => handleOptionSelect('plan')}
                        disabled={selectedStudent.stripe === undefined}
                      >
                        Switch Payment Plan
                      </Button>
                    ) : (
                      <></>
                    )}

                    <Button onClick={() => handleOptionSelect('membership')}>
                      Switch Membership
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                {showPayment ? (
                  <PaymentSelect
                    updateAccount={updateAccount}
                    close={close}
                    setShowSuccess={setShowSuccess}
                    setShowPayment={setShowPayment}
                  />
                ) : (
                  <></>
                )}
                {showSubscriptions ? (
                  <SubscriptionSelect
                    subscriptionID={subscription._id}
                    handleSubscriptionSelect={handleSubscriptionSelect}
                  />
                ) : (
                  <></>
                )}
                {showActivities ? (
                  <ActivitySelect
                    selectedSubscription={selectedSubscription}
                    selectedStudent={selectedStudent}
                    selectedActivities={selectedActivities}
                    handleActivitySelect={handleActivitySelect}
                    sameSubscription={sameSubscription}
                    subscriptionActivities={subscriptionActivities}
                    checkNumOfDays={checkNumOfDays}
                  />
                ) : (
                  <></>
                )}
                {showSuccess ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '10px'
                    }}
                  >
                    <Typography
                      variant='h6'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Success!
                    </Typography>
                    <Button
                      style={{ marginLeft: '10px' }}
                      onClick={handleBack}
                      variant='contained'
                    >
                      Go Back
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={8}>
                {checkNumOfDays() && showActivities ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {!submitLoading ? (
                      <Typography variant='body1'>
                        Transfer {selectedStudent?.info.name.fName}{' '}
                        {selectedStudent?.info.name.lName} to{' '}
                        {selectedSubscription.name}
                      </Typography>
                    ) : (
                      <Typography>Loading</Typography>
                    )}
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                        onClick={handleSubmit}
                        variant='contained'
                        disabled={submitLoading}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          ) : (
            <h1>There are no students for this subscription</h1>
          )}
        </>
      )}
    </>
  )
}

export default SubscriptionSwitch
