import { useState, useEffect } from 'react'
import {useSelector } from 'react-redux'
import LoadingComp from '../util/loading'
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia
} from '@mui/material'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { useNavigate } from 'react-router-dom'

const SelectSubscription = ({ subscriptions }) => {
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/membership_select',
      title: 'Membership Select'
    })
    ReactPixel.pageView()
  }, [])

  const handleClick = (subscription) => {
    navigate(`${subscription._id}`, {replace: true})
  }

  const CardGrid = ({ subscriptions }) => {
    return (
      <>
        {subscriptions.map((subscription) => {
          let subscriptionPrice = subscription.price
          if (subscription.price >= 1000) {
            subscriptionPrice = subscriptionPrice / 100
          }

          let days = 'day'
          if (subscription.numOfDays > 1) {
            days = 'days'
          }

          let name
          if (subscription.name.includes('Membership-')) {
            //cleans up name string
            let tmp = subscription.name.split('-')
            name = tmp[1]
          } else {
            name = subscription.name
          }

          return (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ padding: '10px 10px'}}
              key={subscription._id}
            >
              <Card
                sx={{ display: 'flex', height: '100%'}}
                style={{ cursor: 'pointer' }}
                onClick={() => handleClick(subscription)}
              >
                <CardMedia
                  component='img'
                  sx={{
                    width: '35%',
                    padding: '10px 10px',
                    margin: 'auto 10px auto 0px',
                  }}
                  image={require(`../../images/courseIcons/${
                    subscription.icon || 'robie.png'
                  }`)}
                  alt='Membership Icon'
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component='div' variant='h5' align='left'>
                      {name}
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      color='text.secondary'
                      component='div'
                      align='left'
                    >
                      {`${subscription.numOfDays} ${days} per week`}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      component='div'
                      align='left'
                    >
                      {`$${subscriptionPrice}`}/month
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          )
        })}
      </>
    )
  }

  return (
    <>
      {subscriptions.length == 0 ? (
        <LoadingComp />
      ) : (
        <>
          {subscriptions.length === 0 ? (
            <h1>There are no subscriptions at this location</h1>
          ) : (
            <>
              <Grid
                container
                sx={{ padding: { xs: '0px 3% 0px 3%', md: '0px 10% 0px 10%' }, marginBottom: '200px' }}
              >
                <CardGrid
                  subscriptions={subscriptions.filter(
                    (sub) => !sub.requiresInvite && sub.active
                  )}
                />

                {subscriptions.some(
                  (subscription) => subscription.requiresInvite
                ) ? (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant='h4'
                        align='left'
                        sx={{ marginTop: '10px' }}
                      >
                        Invite Only
                      </Typography>
                    </Grid>
                    <CardGrid
                      subscriptions={subscriptions.filter(
                        (sub) => sub.requiresInvite && sub.active
                      )}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SelectSubscription
