import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import STYLES from './landingStyles'

const MembershipPathwayChart = () => {
  const mobile = useMediaQuery('(max-width:750px)')

  let styles = {
    ...STYLES,
    idText: { fontSize: '2.5rem', fontWeight: 'bold' },
    title: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1.3rem'
    },
    body: { fontSize: mobile ? '0.9rem' : '0.7rem', marginBottom: '10px', fontWeight: 500 }
  }

  const Dot = ({ right, top }) => {
    return (
      <Box
        sx={{
          position: 'absolute',
          right: right,
          top: top,
          width: '10px',
          height: '10px',
          backgroundColor: '#000',
          borderRadius: '50%'
        }}
      />
    )
  }

  const lineStyle = '3px solid #000'
  //Changing the line style may affect the line alignment and require additional adjustments

  const MEMBERSHIP_PATHWAY = [
    {
      id: 1,
      text: 'Explore building and coding skills',
      titleOne: mobile ? 'Junior' : 'Junior Roboteers',
      titleTwo: mobile ? 'Roboteers Intro to Coding' : 'Intro to Coding'
    },
    { id: 2, text: 'Learn to build and code robots', title: 'Roboteers' },
    {
      id: 3,
      text: <>Enhance coding skills <br />Prepare to join the competition team</>,
      titleOne: 'Team Prep',
      titleTwo: 'Advanced Coders'
    },
    {
      id: 4,
      text: (
        <>
          First LEGO League <br />
          VEX VIQC
        </>
      ),
      title: 'Competition Teams'
    },
    {
      id: 5,
      text: (
        <>
          VEX VRC <br />
          Young Inventors
        </>
      ),
      title: 'Advanced Teams'
    }
  ]

  return (
    <Box
      sx={{
        ...styles.box,
        display: 'grid',
        gridTemplateRows: mobile
          ? '20px repeat(5, 46px) repeat(5, auto)'
          : '3% repeat(5, minmax(0, 1fr)) 5%',
        gridTemplateColumns: mobile
          ? '10% repeat(5, minmax(0, 1fr)) 8%'
          : 'repeat(8, minmax(0, 1fr))',
      }}
    >
      {MEMBERSHIP_PATHWAY.map((path, pathIndex) => {
        let startRow = 7 - path.id
        let endRow = startRow + 1
        let startMobileText = pathIndex + 7
        let endMobileText = startMobileText + 1
        let startColumn = mobile ? pathIndex + 2 : pathIndex + 1
        let endColumn = startColumn + 2
        let mobileHeight = [] //calculates how far the vertical lines should go
        for (let i = endRow; i < 7 + pathIndex; i++) {
          mobileHeight.push(i)
        }
        let mobileLineCalc = `50% - ${(4 - pathIndex) * 10}%` //calculates the horizontal offset
        return (
          <>
            <Box
              sx={{
                gridRow: `${startRow} / ${endRow}`,
                gridColumn: `${startColumn} / ${endColumn}`,
                zIndex: 10,
                display: 'flex'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '5px'
                }}
              >
                <Typography sx={styles.idText}>{path.id}</Typography>
              </Box>
              {/* on mobile, text is shown below image grid */}
              {mobile ? null : (
                <Box
                  sx={{
                    zIndex: 10,
                    height: '50%',
                    width: '100%',
                    borderBottom: lineStyle,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography sx={styles.body}>{path.text}</Typography>
                </Box>
              )}
            </Box>

            {mobile ? (
              <>
                {/*Dot and first line */}
                <Box
                  sx={{
                    zIndex: 10,
                    gridRow: `${startRow} / ${endRow}`,
                    gridColumn: `${startColumn + 1} / ${endColumn}`,
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Box
                    sx={{
                      height: '50%',
                      width: `calc(${mobileLineCalc} - 2px)`,
                      borderLeft: lineStyle
                    }}
                  />
                  <Dot
                    right={`calc(${mobileLineCalc} - 5px)`}
                    top={'calc(50% - 3px)'}
                  />
                </Box>
                <Box
                  sx={{
                    gridRow: `${startMobileText} / ${endMobileText}`,
                    gridColumn: `1 / ${endColumn}`,
                    position: 'relative'
                  }}
                >
                  <Box sx={{ width: '100%', display: 'flex' }}>
                    <Typography
                      sx={{
                        ...styles.title,
                        marginRight: '5px',
                        marginTop: '20px'
                      }}
                    >
                      {path.id}. {path.title ? path.title : path.titleOne}
                    </Typography>
                    <Box
                      sx={{
                        flex: 1,
                        borderBottom: lineStyle,
                        height: '35px'
                      }}
                    />
                  </Box>
                  {path.titleTwo ? (
                    <Typography sx={styles.title}>{path.titleTwo}</Typography>
                  ) : null}
                  <Typography sx={styles.body}>{path.text}</Typography>
                </Box>
                <Box
                  sx={{
                    gridRow: `${startMobileText} / ${endMobileText}`,
                    gridColumn: `${startColumn + 1} / ${endColumn}`,
                    display: 'flex',
                    position: 'relative'
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '15px',
                      top: 0,
                      right: `calc(${mobileLineCalc} - 2px)`,
                      borderBottom: lineStyle,
                      borderRight: lineStyle,
                      height: '35px'
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      width: `calc(${mobileLineCalc} - 2px)`,
                      backgroundColor: styles.lightGrey,
                      top: 0,
                      right: 0,
                      borderBottom: `3px solid ${styles.lightGrey}`,
                      height: '35px'
                    }}
                  />
                </Box>
                {/*Vertical lines going down */}
                {mobileHeight.map((row, index) => (
                  <React.Fragment key={row}>
                    <Box
                      sx={{
                        zIndex: 10,
                        gridRow: `${row} / ${row + 1}`,
                        gridColumn: `${startColumn + 1} / ${endColumn}`,
                        position: 'relative',
                        display: 'flex',
                        alignItems:
                          index == mobileHeight.length - 1
                            ? 'flex-start'
                            : 'flex-end'
                      }}
                    >
                      <Box
                        sx={{
                          height: '100%',
                          width: `calc(50% + ${(4 - pathIndex) * 10}% - 1px)`,
                          borderRight: lineStyle
                        }}
                      />
                    </Box>
                  </React.Fragment>
                ))}
              </>
            ) : (
              //Horizontal Line and Dot for nonmobile
              <>
                <Box
                  sx={{
                    zIndex: 10,
                    gridRow: `${startRow} / ${endRow}`,
                    gridColumn: `${endColumn} / ${endColumn + 1}`,
                    position: 'relative'
                  }}
                >
                  <Box
                    sx={{
                      height: '50%',
                      width: '20%',
                      borderBottom: lineStyle
                    }}
                  />
                  <Dot right={'calc(80% - 5px)'} top={'calc(50% - 3px)'} />
                </Box>
                <Box
                  sx={{
                    zIndex: 10,
                    gridRow: `${startRow} / ${endRow}`,//
                    //Takes up remaining columns
                    gridColumn: `${endColumn} / 9`,
                    position: 'relative',
                    //Calculates percentage of width of single column to use as left padding
                    padding: `40px 0 0 calc(${((100 / (6 - pathIndex))) / 2}% + ${((4 - pathIndex) * 2)}px)`
                  }}
                >
                  {path.title ? (
                    <Typography sx={{...styles.title, fontSize: '1.2rem'}}>{path.title}</Typography>
                  ) : (
                    <>
                      <Typography sx={{...styles.title, fontSize: '1.2rem'}}>{path.titleOne}</Typography>
                      <Typography sx={{...styles.title, fontSize: '1.2rem'}}>{path.titleTwo}</Typography>
                    </>
                  )}
                </Box>
              </>
            )}
          </>
        )
      })}
      <Box
        component='img'
        src={require('../../images/membershipLanding/Asset_23.png')}
        sx={{
          height: '100%',
          width: '100%',
          gridRow: mobile ? '1/7' : '1 / 8',
          gridColumn: mobile ? '1/8' : '3/9'
        }}
      />
    </Box>
  )
}

export default MembershipPathwayChart
