import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cards from '../../util/cards'
import LoadingComp from '../../util/loading'
import {
  setAllSubscriptions,
  setAllActivities
} from '../../../store/subscriptionSlice'
import {
  setEmailFilter,
  setRecipientOptions,
  setFilterApplied
} from '../../../store/adminSlice'
import {
  getAllActivities,
  getAllSubscriptions,
  getStudentsById
} from '../../../api/admin'
import Filter from './filter'
import { getTagsWithoutStudents } from '../../../api/tag'

const MembershipFilters = () => {
  const dispatch = useDispatch()
  const BlankCard = cards.blankCard

  const uID = useSelector((state) => state.login.user._id)
  const locations = useSelector((state) => state.user.locations)

  const baseFilter = {
    Location: null,
    Subscription: null,
    Activity: null,
    Tag: null
  }

  const subscriptions = useSelector(
    (state) => state.subscription.allSubscriptions
  )
  const activities = useSelector((state) => state.subscription.allActivities)
  const filter = useSelector((state) => state.admin.emailFilter)

  const [tags, setTags] = useState([])
  const [filteredLocations, setFilteredLocations] = useState(null)
  const [filteredSubscriptions, setFilteredSubscriptions] = useState(null)
  const [filteredActivities, setFilteredActivities] = useState(null)
  const [filteredTags, setFilteredTags] = useState(null)
  const [fetchingStudents, setFetchingStudents] = useState(false)

  console.log(filter)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getAllSubscriptions({ params: { uID: uID } })
      if (resp.success) {
        dispatch(setAllSubscriptions(resp.data))
      } else {
        alert('There was an error retrieving subscriptions. Please reload')
      }

      let activities = await getAllActivities({ params: { uID: uID } })
      if (activities.success) {
        dispatch(setAllActivities(activities.data))
      } else {
        alert('There was an error retrieving activities. Please reload')
      }

      let tagsResp = await getTagsWithoutStudents({ params: { uID: uID } })
      if (tagsResp.success) {
        setTags(tagsResp.data)
      } else {
        alert('There was an error retrieving tags. Please reload')
      }
    }
    if (subscriptions.length === 0 || activities.length === 0) {
      handleAsync()
    }
    dispatch(setEmailFilter(baseFilter))
    dispatch(setRecipientOptions([]))
  }, [])

  useEffect(() => {
    function filterObjects() {
      if (filter['Location']) {
        let tagArr = []
        let filteredSubs = subscriptions.filter(
          (sub) => sub.locationID === filter['Location']['_id']
        )
        setFilteredSubscriptions(filteredSubs)
        let filteredActivities = []
        if (filter['Subscription']) {
          filteredActivities = activities.filter(
            (activity) =>
              activity.instance.subscriptionID === filter['Subscription']['_id']
          )
          tagArr = tags.filter((tmpTag) =>
            tmpTag.subscriptions.includes(filter['Subscription']['_id'])
          )
        } else {
          filteredSubs.forEach((sub) => {
            let tmp = activities.filter(
              (activity) => activity.instance.subscriptionID === sub._id
            )
            filteredActivities.push(...tmp)

            let tmpTags = tags.filter((tmpTag) =>
              tmpTag.subscriptions.includes(sub._id)
            )
            tagArr.push(...tmpTags)
          })
        }
        setFilteredTags(tagArr)
        setFilteredActivities(filteredActivities)
      } else if (filter['Tag']) {
        let selectedTag = filter['Tag']
        let actArr = []
        let locArr = []

        //Select only memberships that contain tag
        let filteredSubs = subscriptions.filter((sub) =>
          selectedTag.subscriptions.includes(sub._id)
        )

        //Iterate through filtered subscriptions
        filteredSubs.forEach((sub) => {
          //Select all activities
          let tmpActivities = activities.filter(
            (activity) => activity.instance.subscriptionID === sub._id
          )
          actArr.push(...tmpActivities)

          //Select all locations
          let tmpLocation = locations.filter(
            (location) => sub.locationID === location._id
          )[0]

          locArr.push(tmpLocation)
        })

        setFilteredSubscriptions(filteredSubs)
        setFilteredActivities(actArr)
        setFilteredLocations(locArr)
      }
    }
    if (subscriptions.length > 0) {
      filterObjects()
    }
  }, [filter])

  const handleFilter = (value, type) => {
    if (type === 'Location') {
      dispatch(
        setEmailFilter({ ...baseFilter, [type]: value, Tag: filter['Tag'] })
      )
    } else if (type === 'Subscription') {
      let location = locations.filter((loc) => loc._id === value.locationID)[0]
      dispatch(
        setEmailFilter({
          ...baseFilter,
          [type]: value,
          Location: location,
          Tag: filter['Tag']
        })
      )
    } else if (type === 'Activity') {
      let subObj = subscriptions.filter(
        (sub) => sub._id === value.instance.subscriptionID
      )[0]
      let location = locations.filter((loc) => loc._id === subObj.locationID)[0]
      dispatch(
        setEmailFilter({
          ...baseFilter,
          [type]: value,
          Location: location,
          Subscription: subObj
        })
      )
    } else if (type === 'Tag') {
      dispatch(
        setEmailFilter({
          ...baseFilter,
          [type]: value,
          Location: filter['Location'],
          Subscription: filter['Subscription']
        })
      )
    }
  }

  const applyFilter = async () => {
    setFetchingStudents(true)
    let filteredStudentIds = []
    if (Object.values(filter).every((value) => value === '')) {
      alert('Filter selection can not be empty.')
    } else {
      if (filter['Activity']) {
        filteredStudentIds.push(...filter['Activity'].instance.students)
      } else if (filter['Subscription']) {
        filteredStudentIds.push(...filter['Subscription'].students)
      } else if (filter['Location']) {
        subscriptions
          .filter((sub) => sub.locationID === filter['Location']['_id'])
          .forEach((obj) => filteredStudentIds.push(...obj.students))
      } else if (filter['Tag']) {
        filteredStudentIds.push(...filter['Tag'].students)
      }
      //Get all student Objects using IDs
      let resp = await getStudentsById({
        params: { uID: uID, studentIDs: filteredStudentIds }
      })
      if (resp.success) {
        let studentObjs = resp.data

        let filteredStudents = studentObjs.map((student) => {
          let subIds = student.subscriptions?.active //array
          if (filter['Subscription']) {
            subIds = subIds.filter((id) => id === filter['Subscription']['_id'])
          }
          let subObjs = []
          subIds.forEach((id) => {
            let subObj = subscriptions.filter((sub) => sub._id === id)[0]
            if (subObj) {
              //check that subscription object exists, workaround for inconsistent dev database
              subObjs.push(subObj)
            }
          }) //convert ids into objs
          let superObjs = subObjs.map((obj) => {
            //for each object we need to get the activity objects
            let actIds = obj.activities
            if (filter['Activity']) {
              actIds = actIds.filter(
                (id) => id === filter['Activity']['instance']['_id']
              )
            }
            let actObjs = actIds.map(
              (id) => activities.filter((act) => act.instance._id === id)[0]
            ) //convert ids into objs array
            let studentActs = actObjs.filter((obj) =>
              obj.instance.students.includes(student._id)
            ) //get activities that student is enrolled in
            return { subscription: obj, activities: studentActs }
          })
          return { student: student, details: superObjs }
        })
        dispatch(setFilterApplied(true))
        dispatch(setRecipientOptions(filteredStudents))

        setFetchingStudents(false)
      } else {
        setFetchingStudents(false)
        alert('There was a problem retrieving students.')
      }
    }
  }

  const resetFilter = () => {
    dispatch(setEmailFilter(baseFilter))
    dispatch(setRecipientOptions([]))
    dispatch(setFilterApplied(false))
    setFilteredTags(null)
    setFilteredLocations(null)
    setFilteredActivities(null)
    setFilteredSubscriptions(null)
  }

  return (
    <>
      {subscriptions.length > 0 && activities.length > 0 ? (
        <>
          <Typography align='left' variant='h4'>
            Filters
          </Typography>
          <BlankCard
            box={{ padding: '0px 0px' }}
            style={{}}
            id='filter-recipients'
          >
            <Grid container sx={{ padding: '10px 10px 0px 10px' }}>
              <Filter
                options={filteredLocations || locations}
                type='Location'
                handleFilter={handleFilter}
              />
              <Filter
                options={filteredSubscriptions || subscriptions}
                type='Subscription'
                handleFilter={handleFilter}
              />
              <Filter
                options={filteredActivities || activities}
                type='Activity'
                handleFilter={handleFilter}
              />
              <Filter
                options={filteredTags || tags}
                type='Tag'
                handleFilter={handleFilter}
              />
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => resetFilter()}>Reset</Button>
              {fetchingStudents ? (
                <CircularProgress />
              ) : (
                <Button onClick={() => applyFilter()}>Apply Filter</Button>
              )}
            </Box>
          </BlankCard>
        </>
      ) : (
        <LoadingComp />
      )}
    </>
  )
}

export default MembershipFilters
