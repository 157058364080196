import {
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material'
import cards from '../util/cards'

const PaymentTypeSelect = ({ paymentType, handleChange }) => {
  const BlankCard = cards.blankCard

  return (
    <>
      <Typography variant={'h4'} sx={{ paddingTop: '10px' }}>
        5. Pay With
      </Typography>
      <BlankCard>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby='row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            onChange={(e) => handleChange(e.target.value)}
          >
            <FormControlLabel
              value='card'
              control={<Radio />}
              label='Credit Card'
              checked={paymentType === 'card'}
            />
            <FormControlLabel
              value='us_bank_account'
              control={<Radio />}
              label='Direct Bank Deposit'
              checked={paymentType === 'us_bank_account'}
            />
          </RadioGroup>
        </FormControl>
      </BlankCard>
      {/* {paymentType === 'card' ? (
        <Typography
          variant='caption'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          align='left'
        >
          A 3.5 percent credit card fee will be added to this payment at
          checkout. Use direct deposit to avoid fee.
        </Typography>
      ) : (
        <></>
      )} */}
    </>
  )
}

export default PaymentTypeSelect
