import { createSlice } from '@reduxjs/toolkit'

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    allSubscriptions: [],
    filteredSubscriptions: [],
    selectedSubscription: null,
    allActivities: [],
    subscriptionActivities: [],
    filteredActivities: [],
    selectedActivity: null,
    selectedLocation: null,
    edit: false,
    checkoutData: null,
    student: null,
    students: [],
    selectedPrice: null,
    paymentData: null,
    view: 0,
    expandedGrid: false
  },
  reducers: {
    setAllSubscriptions: (state, action) => {
      state.allSubscriptions = action.payload
    },
    setFilteredSubscriptions: (state, action) => {
      state.filteredSubscriptions = action.payload
    },
    setSelectedSubscription: (state, action) => {
      state.selectedSubscription = action.payload
    },
    addSubscription: (state, action) => {
      state.allSubscriptions.unshift(action.payload)
      if (state.selectedLocation === null) {
        state.filteredSubscriptions.unshift(action.payload)
      }
    },
    setAllActivities: (state, action) => {
      state.allActivities = action.payload
    },
    setSubscriptionActivities: (state, action) => {
      state.subscriptionActivities = action.payload
    },
    setFilteredActivities: (state, action) => {
      state.filteredActivities = action.payload
    },
    setSelectedActivity: (state, action) => {
      state.selectedActivity = action.payload
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload
    },
    setEdit: (state, action) => {
      state.edit = action.payload
    },
    updateSub: (state, action) => {
      //Update all subscriptions and filtered subscriptions
      state.allSubscriptions.splice(
        state.allSubscriptions.findIndex(function (i) {
          return i._id === action.payload._id
        }),
        1
      )
      state.allSubscriptions.unshift(action.payload)

      state.filteredSubscriptions.splice(
        state.filteredSubscriptions.findIndex(function (i) {
          return i._id === action.payload._id
        }),
        1
      )
      state.filteredSubscriptions.unshift(action.payload)
    },
    updateLinkedSubs: (state, action) => {
      action.payload.forEach((sub) => {
        state.allSubscriptions.splice(
          state.allSubscriptions.findIndex(function (i) {
            return i._id === sub._id
          }),
          1
        )
        state.allSubscriptions.unshift(sub)

        state.filteredSubscriptions.splice(
          state.filteredSubscriptions.findIndex(function (i) {
            return i._id === sub._id
          }),
          1
        )
        state.filteredSubscriptions.unshift(sub)
      })
    },
    updateActivity: (state, action) => {
      //Update all activities and subscription activities
      let index = state.allActivities.findIndex(
        (activity) => activity.instance._id === action.payload.instance._id
      )
      state.allActivities.splice(index, 1)
      state.allActivities.unshift(action.payload)

      index = state.subscriptionActivities.findIndex(
        (activity) => activity.instance._id === action.payload.instance._id
      )
      state.subscriptionActivities.splice(index, 1)
      state.subscriptionActivities.unshift(action.payload)
    },
    updateSelectedActivity: (state, action) => {
      state.selectedActivity = action.payload
    },
    setCheckoutData: (state, action) => {
      state.checkoutData = action.payload
    },
    setStudent: (state, action) => {
      state.student = action.payload
    },
    setStudents: (state, action) => {
      state.students = action.payload
    },
    setSelectedPrice: (state, action) => {
      state.selectedPrice = action.payload
    },
    setPaymentData: (state, action) => {
      state.paymentData = action.payload
    },
    setView: (state, action) => {
      state.view = action.payload
    },
    setExpandedGrid: (state, action) => {
      state.expandedGrid = action.payload
    }
  }
})

export const {
  setAllSubscriptions,
  setFilteredSubscriptions,
  setSelectedSubscription,
  setAllActivities,
  setSubscriptionActivities,
  setFilteredActivities,
  setSelectedActivity,
  setSubscriptionCourses,
  setSelectedLocation,
  addSubscription,
  setEdit,
  updateSub,
  updateActivity,
  updateSelectedActivity,
  setCheckoutData,
  setStudent,
  updateLinkedSubs,
  setStudents,
  setSelectedPrice,
  setPaymentData,
  setView,
  setExpandedGrid
} = subscriptionSlice.actions

export default subscriptionSlice.reducer
