import React from 'react'

const SawyerWidget = ({ locationId }) => {
  const locations = [
    //Oak Park
    {
      prodId: '65d645805641fd52171b1a7e',
      sawyerId: '27211'
    },
    //Westlake Village
    {
      devId: '65e2457efd507f230ad37dda',
      prodId: '65722a6d8bf95898275826d0',
      sawyerId: '24637'
    }
  ]
  const convertId = () => {
    return locations.filter(
      (loc) => loc.devId === locationId || loc.prodId === locationId
    )[0].sawyerId
  }

  return (
    <iframe
      src={`https://www.hisawyer.com/rolling-robots/schedules?location_id%5B%5D=${convertId()}&schedule_id=camps`}
      title='Camp Sign Up Widget'
      width={'100%'}
      height={'1000px'}
    ></iframe>
  )
}

export default SawyerWidget
