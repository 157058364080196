import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Delete } from '@mui/icons-material'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton
} from '@mui/material'
import { setSelectedRecipients } from '../../../store/adminSlice'
import Info from './info'

const Recipient = ({ studentInfo, index, type, length }) => {
  const dispatch = useDispatch()
  const recipients = useSelector((state) => state.admin.selectedRecipients)
  const StyledDivider = () => <Divider sx={{ margin: '10px 0px' }} />
  let student = studentInfo.student
  let details = studentInfo.details

  const removeRecipient = () => {
    let tmp = [...recipients]
    tmp.splice(
      tmp.findIndex(function (i) {
        return i?.student?._id === student._id
      }),
      1
    )
    dispatch(setSelectedRecipients(tmp))
  }

  const getChecked = () => {
    let check = false
    recipients.forEach((recipient) => {
      if (recipient?.student?._id === student._id) {
        check = true
      }
    })
    return check
  }

  const handleSelect = () => {
    let tmp = [...recipients]
    if (getChecked()) {
      removeRecipient()
    } else {
      tmp.push(studentInfo)
      dispatch(setSelectedRecipients(tmp))
    }
  }

  return (
    <React.Fragment key={'option ' + student._id}>
      {type == 'option' ? (
        <FormControlLabel
          sx={{ width: '100%' }}
          value={student._id}
          checked={getChecked()}
          onChange={(e) => handleSelect()}
          control={<Checkbox sx={{ marginBottom: 'auto' }} />}
          label={<Info student={student} details={details} />}
        />
      ) : (
        <Grid container>
          <Grid item xs={10} sx={{ textAlign: 'left' }}>
            <Info student={student} details={details} />
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex' }}>
            <IconButton
              aria-label='delete'
              sx={{ margin: 'auto auto' }}
              onClick={removeRecipient}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {index !== length - 1 ? <StyledDivider /> : <></>}
    </React.Fragment>
  )
}

export default Recipient
