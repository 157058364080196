import React, { useState, useEffect } from 'react'
import OrdersView from '../component/ordersView'
import { GetProductsById } from '../api/products'
import { getParentCredits } from '../api/credit'
import moment from 'moment'
import LoadingComp from '../component/util/loading'
import dateHandler from '../utils/dateHandler'

const OrdersContainer = ({ orders, altOrders, students }) => {
  const [store, setStore] = useState([])
  const [altStore, setAltStore] = useState([])
  const [loading, setLoading] = useState(true)
  const [ordersDone, setOrdersDone] = useState(false)
  const [altOrdersDone, setAltOrdersDone] = useState(false)

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  //Filters data from parent's paymentMeta
  const parseData = () => {
    let ids = []
    let clone = Object.assign([], orders)
    var sortedOrders = clone.sort((x, y) => {
      return y.created - x.created
    }) //sort orders by created date

    let test = []

    sortedOrders.forEach((order) => {
      if (order.object === 'charge') {
        //iterate through orders array
        let tmp = {
          lineItems: []
        }
        tmp.amount = order.amount / 100
        tmp.created = moment.unix(order.created).format('MMM Do YY hh:mm a')
        if (order.payment_method_details.type == 'card') {
          tmp.lastFour = order.payment_method_details.card.last4
        } else if (order.payment_method_details.type == 'us_bank_account') {
          tmp.lastFour = order.payment_method_details.us_bank_account.last4
        } else {
          tmp.lastFour = ''
        }
        
        tmp.receipt = order.receipt_url

        if (order.metadata) {
          //meta is array of objects with student id: prod id, parent ID
          let meta = JSON.parse(order.metadata.meta)
          Object.keys(meta[0]).forEach((item) => {
            //keys = student IDs and "pID"
            if (typeof meta[0][item] === 'object') {
              //workshops
              ids.push(...meta[0][item]) //array of strings
              let student = students.find((x) => x._id === item)
              tmp.lineItems.push({
                student: `${student?.info.name.fName} ${student?.info.name.lName}`,
                prodIds: meta[0][item]
              })
            }
          })
        } else {
          if (typeof order.product === 'string') {
            //memberships
            ids.push(order.product)
            let student = students.find((x) => x._id === order.studentID)
            tmp.lineItems.push({
              student: `${student?.info.name.fName} ${student?.info.name.lName}`,
              prodIds: [order.product]
            })
          }
        }
        test.push(tmp)
      }
    })
    let cleaned = ids.filter(onlyUnique)
    return { store: test, ids: cleaned }
  }

  //Get regular orders
  useEffect(() => {
    async function handleAsync() {
      let data = parseData()

      let resp = await GetProductsById(data.ids)

      data.store.forEach((transaction, i) => {
        let tmp = []

        transaction.lineItems.forEach((item) => {
          //iterate through line items (object of student and their prodIds array)
          item.prodIds.forEach((prod) => {
            //iterate through prodIDs array for specific student
            let product = resp.data.filter((x) => x.id === prod) //match ids to data
            //Get price of each purchase if more than one
            tmp.push({ student: item.student, ...product[0] })
          })
        })
        data.store[i].lineItems = tmp
      })
      setStore(data.store)
      setOrdersDone(true)
    }
    if (orders.length > 0) {
      handleAsync()
    } else {
      setOrdersDone(true)
    }
  }, [students])

  //Get alternate payment orders
  useEffect(() => {
    async function handleAsync() {
      let tmpArr = []
      let resp = await getParentCredits({ params: { creditIDs: altOrders } })
      if (resp.success) {
        resp.data.forEach((data) => {
          let created = dateHandler
            .formatDate(data.credit.paymentDate)
            .toDateString()
          let startDate = dateHandler
            .formatDate(data.credit.startDate)
            .toDateString()
          let endDate = dateHandler
            .formatDate(data.credit.endDate)
            .toDateString()
          let item = {
            amount: data.credit.amount,
            created: created,
            credit: true,
            lineItems: [
              {
                endDate: endDate,
                loc: data.product.locationName,
                name: data.product.name,
                price: data.credit.paymentID,
                startDate: startDate,
                type: data.credit.productType,
                student: `${data.student.info.name.fName} ${data.student.info.name.fName}`
              }
            ]
          }
          tmpArr.push(item)
        })
        setAltStore(tmpArr)
        setAltOrdersDone(true)
      } else {
        alert(resp.data.error.message)
      }
    }
    if (altOrders?.length > 0) {
      handleAsync()
    } else {
      setAltOrdersDone(true)
    }
  }, [students])

  useEffect(() => {
    if (ordersDone && altOrdersDone) {
      setLoading(false)
    }
  })

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <OrdersView data={store} altData={altStore} />
      )}
    </>
  )
}

export default OrdersContainer
