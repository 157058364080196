import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Checkbox } from '@mui/material'
import { getActivities } from '../../../api/admin'
import LoadingComp from '../../util/loading'

const ActivitySelect = ({
  selectedSubscription,
  selectedStudent,
  selectedActivities,
  handleActivitySelect,
  sameSubscription,
  subscriptionActivities,
  checkNumOfDays
}) => {
  const uID = useSelector((state) => state.login.user._id)
  const numOfDays = parseInt(selectedSubscription.numOfDays)

  const [activities, setActivities] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getActivities({
        params: { uID: uID, activityIDs: selectedSubscription.activities }
      })
      if (resp.success) {
        setActivities(resp.data)
      } else {
        alert('There was an error retrieving activities')
      }
    }
    if (sameSubscription) {
      setActivities(subscriptionActivities)
    } else {
      handleAsync()
    }
    setLoading(false)
  }, [])

  const isSelected = (activityID) => {
    if (selectedActivities.includes(activityID)) {
      return true
    } else {
      return false
    }
  }

  const isEnrolled = (activity) => {
    return activity.instance.students.includes(selectedStudent._id)
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          {numOfDays > 1 ? (
            <>
              <Typography variant='h6' style={{ textAlign: 'center' }}>
                Select activities to move student into
              </Typography>
              {sameSubscription ? (
                <Typography
                  variant='subtitle2'
                  style={{
                    textAlign: 'center',
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                >
                  Select all activities in which to enroll student, even if
                  student is already enrolled
                </Typography>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Typography variant='h6' style={{ textAlign: 'center' }}>
              Select activity to move student into
            </Typography>
          )}
          {activities.length > 0 ? (
            <>
              {activities.map((activity) => {
                let studentInCourse = isEnrolled(activity)
                let checked = isSelected(activity.instance._id)
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    key={activity.instance._id}
                  >
                    <Checkbox
                      onChange={() =>
                        handleActivitySelect(activity.instance._id)
                      }
                      checked={checked}
                      disabled={!checked && checkNumOfDays()}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '350px'
                      }}
                    >
                      <Typography variant='body2'>
                        {activity.course.name} &nbsp;
                      </Typography>
                      <Typography variant='body2'>
                        {activity.instance.day} &nbsp;
                      </Typography>
                      <Typography variant='body2'>
                        {activity.instance.startTime} -{' '}
                        {activity.instance.endTime} &nbsp;
                      </Typography>
                      {studentInCourse ? (
                        <Typography variant='body2' style={{ color: 'red' }}>
                          Enrolled
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <Typography variant='h6'>
              There are no activities created for this subscription
            </Typography>
          )}
        </>
      )}
    </>
  )
}

export default ActivitySelect
