import React from 'react';
import { Box, Typography } from '@mui/material';

const SummerCampChart = (props) => {
    let {mobile, styles, smallScreen, largeScreen} = props

    const Line = (props) => {
        //css for red connecting lines between images
        return (
          <>
            {props.mobile ? (
              props.flip ? (
                <>
                  <Box
                    sx={{
                      height: '25px',
                      width: '50%',
                      justifySelf: 'end',
                      alignSelf: 'end',
                      gridColumn: '1/2',
                      gridRow: props.inline,
                      borderTop: `5px solid ${styles.red}`,
                      borderLeft: `5px solid ${styles.red}`,
                      borderTopLeftRadius: '10px 10px'
                    }}
                  />
                  <Box
                    sx={{
                      height: '25px',
                      width: '50%',
                      gridColumn: '2/3',
                      gridRow: props.inline,
                      borderBottom: `5px solid ${styles.red}`,
                      borderRight: `5px solid ${styles.red}`,
                      borderBottomRightRadius: '10px 10px'
                    }}
                  />
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      height: '25px',
                      width: '50%',
                      justifySelf: 'end',
                      gridColumn: '1/2',
                      gridRow: props.inline,
                      borderBottom: `5px solid ${styles.red}`,
                      borderLeft: `5px solid ${styles.red}`,
                      borderBottomLeftRadius: '10px 10px'
                    }}
                  />
                  <Box
                    sx={{
                      height: '25px',
                      width: '50%',
                      alignSelf: 'end',
                      gridColumn: '2/3',
                      gridRow: props.inline,
                      borderTop: `5px solid ${styles.red}`,
                      borderRight: `5px solid ${styles.red}`,
                      borderTopRightRadius: '10px 10px'
                    }}
                  />
                </>
              )
            ) : props.flip ? (
              <>
                <Box
                  sx={{
                    gridRow: '1/2',
                    gridColumn: props.inline,
                    height: '50%',
                    marginLeft: '50%',
                    borderTop: `5px solid ${styles.red}`,
                    borderLeft: `5px solid ${styles.red}`,
                    borderTopLeftRadius: '10px 10px',
                    alignSelf: 'end'
                  }}
                ></Box>
                <Box
                  sx={{
                    gridRow: '2/3',
                    gridColumn: props.inline,
                    height: '50%',
                    // width: '25px',
                    width: '50%',
                    borderBottomRightRadius: '10px 10px',
                    borderBottom: `5px solid ${styles.red}`,
                    borderRight: `5px solid ${styles.red}`
                  }}
                ></Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    gridRow: '1/2',
                    gridColumn: props.inline,
                    height: '50%',
                    marginRight: '50%',
                    borderTopRightRadius: '10px 10px',
                    borderTop: `5px solid ${styles.red}`,
                    borderRight: `5px solid ${styles.red}`,
                    alignSelf: 'end'
                  }}
                ></Box>
                <Box
                  sx={{
                    gridRow: '2/3',
                    gridColumn: props.inline,
                    // marginLeft: '20px',
                    justifySelf: 'end',
                    height: '50%',
                    width: '50%',
                    borderBottom: `5px solid ${styles.red}`,
                    borderLeft: `5px solid ${styles.red}`,
                    borderBottomLeftRadius: '10px 10px'
                  }}
                ></Box>
              </>
            )}
          </>
        )
      }

    const SummerText = (props) => {
        return (
          <>
            <Typography variant='h5'>{props.title}</Typography>
            <Typography
              sx={{
                fontSize: mobile ? '0.88rem' : null,
                lineHeight: mobile ? '1.4em' : null
              }}
            >
              {props.children}
            </Typography>
          </>
        )
      }
      const SummerGraphic = (props) => {
        let lineStyle = {}
        if (props.right) {
          lineStyle = {
            borderRight: `5px solid ${styles.red}`
          }
        } else {
          lineStyle = {
            justifySelf: 'end',
            borderLeft: `5px solid ${styles.red}`
          }
        }
        let startEndStyle = {}
        if (props.isStart) {
          startEndStyle = {
            height: '50%',
            alignSelf: 'end'
          }
        } else if (props.isEnd) {
          startEndStyle = {
            height: '50%',
            alignSelf: 'start'
          }
        }
        return (
          <>
            {smallScreen ? (
              <Box
                sx={{
                  ...lineStyle,
                  ...startEndStyle,
                  width: '50%',
                  gridRow: props.block,
                  gridColumn: props.inline
                }}
              />
            ) : null}
            <Box
              sx={{
                position: 'relative',
                maxWidth: '300px',
                justifySelf: 'center',
                alignSelf: 'center',
                gridRow: props.block,
                gridColumn: props.inline,
    
                padding: { xs: '0px 5%', sm: '0px 10%', md: '0px 0px' }
              }}
            >
              <Box
                component='img'
                src={require(`../../images/fbLandingImages/${props.image}`)}
                sx={{
                  width: '100%',
                  backgroundColor: styles.lightGrey
                }}
              />
              {props.shapes}
            </Box>
          </>
        )
      }

    return (
        <Box
        sx={{
          display: 'grid',
          marginBottom: '50px',
          gridTemplateRows: {
            xs: 'auto 55px auto 55px auto 55px auto',
            md: '300px 300px'
          },
          height: { md: '900px', lg: '800px' },
          gridTemplateColumns: {
            xs: 'auto auto',
            md: 'minmax(0, 300px) 45px minmax(0, 300px) 45px minmax(0, 300px) 45px minmax(0, 300px)'
          }
        }}
      >
        <SummerGraphic
          isStart={true}
          image='Asset_5.png'
          block={{ xs: '1/2' }}
          inline='1/2'
          shapes={
            <>
              <Box
                alt='red-circle'
                component='img'
                src={require(`../../images/fbLandingImages/Asset_23.png`)}
                sx={{
                  position: 'absolute',
                  height: '20%',
                  bottom: '10%',
                  left: { xs: '5%', md: '-20%' }
                }}
              />
            </>
          }
        />
        <Box
          sx={{
            gridColumn: { xs: '2/3', md: '1/2' },
            gridRow: { xs: '1/2', md: '2/3' },
            alignSelf: 'center'
          }}
        >
          <SummerText
            title={
              <>
                Suitable <br />
                for Beginners
              </>
            }
          >
            Discover the excitement of Rolling Robots Summer Camps, where
            kids aged 5 to 15 can unleash their creativity in a fun, safe,
            and friendly environment—even if they're total beginners!
          </SummerText>
        </Box>
        <Line inline={'2/3'} mobile={smallScreen} />
        <Box
          sx={{ alignSelf: 'center', gridRow: { xs: '3/4', md: '1/2' } }}
        >
          <SummerText title='Learn By Doing'>
            With a focus on small groups, individual attention, and working
            around parents' schedules, our camps give the next generation of
            tech superstars opportunities to learn by doing.
          </SummerText>
        </Box>
        <SummerGraphic
          right={true}
          image='Asset_7.png'
          block={{ xs: '3/4', md: '2/3' }}
          inline={{ xs: '2/3', md: '3/4' }}
          shapes={
            <>
              {' '}
              <Box
                alt='red-zig-zag'
                component='img'
                src={require(`../../images/fbLandingImages/Asset_25.png`)}
                sx={{
                  position: 'absolute',
                  height: '8%',
                  top: smallScreen ? '-5%' : null,
                  bottom: smallScreen ? null : '-150px',
                  left: { xs: '-10%', md: '-130%' }
                }}
              />
            </>
          }
        />

        <Line flip={true} inline={'4/5'} mobile={smallScreen} />
        <SummerGraphic
          image='Asset_4.png'
          block={{ xs: '5/6', md: '1/2' }}
          inline={{ xs: '1/2', md: '5/6' }}
        />

        <Box
          sx={{
            gridColumn: { xs: '2/3', md: '5/6' },
            gridRow: { xs: '5/6', md: '2/3' },
            marginTop: largeScreen ? '30px' : '0px'
          }}
        >
          <SummerText
            title={
              <>
                Technical & {largeScreen ? null : <br />}
                Soft Skills
              </>
            }
          >
            Dive into the thrilling world of robotics, coding, Lego
            robotics, Vex IQ, and Battle Bots, all designed to inspire young
            minds to learn through creativity. Watch as kids gain
            confidence, learn new skills, and have a great time with their
            friends in a safe environment, while their parents can keep
            doing what they normally do all summer long.
          </SummerText>
        </Box>
        <Line inline={'6/7'} mobile={smallScreen} />
        <Box sx={{ alignSelf: 'center' }}>
          <SummerText
            title={
              <>
                Learn and {largeScreen ? null : <br />}
                Have Fun
              </>
            }
          >
            Gear up for an unforgettable summer that's both educational and
            entertaining.
          </SummerText>
        </Box>
        <SummerGraphic
          isEnd={true}
          right={true}
          image='Asset_6.png'
          block={{ xs: '7/8', md: '2/3' }}
          inline={{ xs: '2/3', md: '7/8' }}
        />
      </Box>
    );
}

export default SummerCampChart;
