import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import LoadingComp from '../util/loading'
import LocationSelector from '../locationSelector'
import OverviewPage from '../overviewPage'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { useNavigate } from 'react-router-dom'

const SelectLocation = () => {
  const allLocations = useSelector((state) => state.user.locations)
  const [locations, setLocations] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/membership_location',
      title: 'Membership Location'
    })
    ReactPixel.pageView()
  }, [])

  useEffect(() => {
    if (allLocations.length > 0) {
      let filteredLocations = allLocations.filter(
        (loc) => loc.memberships.length > 0
      )
      setLocations(filteredLocations)
    }
  }, [allLocations])

  const handleClick = (location) => {
    ReactGA.event({
      category: 'button',
      action: `Select ${location.name}`
    })
    let urlParam = location.name.toLowerCase().replaceAll(' ', '-')
    navigate(`${urlParam}`, { replace: true })
  }

  return (
    <>
      {locations.length === 0 ? (
        <LoadingComp />
      ) : (
        <>
          <OverviewPage
            title='Continuing the Path to Tech Genius'
            description="Rolling Robots' After-School Program is an in-person membership built around keeping children ages 5-15 engaged in robotics education after regular school hours and weekday. There is a workshop 4pm-6pm, every Monday through Friday. Choose from a variety of topics suitable for your student's age and abilities and we'll provide them with quality after-school activities and a rotating lesson plan to make sure that they are constantly challenged and actively involved."
          >
            <LocationSelector
              locations={locations}
              type={'memberships'}
              onSelect={handleClick}
            />
          </OverviewPage>
        </>
      )}
    </>
  )
}

export default SelectLocation
