import React, { useState } from 'react'
import SecondaryNav from '../../component/util/secondaryNav'
import EmailTemplates from '../../component/adminViews/emailTemplates'
import SelectRecipients from '../../component/adminViews/email/selectRecipients'

const EmailContainer = () => {
  const [currTab, setCurrTab] = useState(0)

  const TABS = [
    {
      label: 'Email Memberships',
      content: <SelectRecipients type='membership' currTab={currTab} />
    },
    {
      label: 'Email Workshops',
      content: <SelectRecipients type='workshop' currTab={currTab} />
    },
    {
      label: 'Templates',
      content: <EmailTemplates />
    }
  ]
  return (
    <>
      <SecondaryNav
        title={'Email'}
        tabs={TABS}
        currTab={currTab}
        setCurrTab={setCurrTab}
      />
      {TABS[currTab].content}
    </>
  )
}

export default EmailContainer
