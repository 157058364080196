const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getItem = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

const deleteItem = (key) => {
    localStorage.removeItem(key)
}

const LS = {
  setItem,
  getItem,
  deleteItem
}

module.exports = LS
