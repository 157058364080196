import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { clearCart } from '../store/cartSlice'

const AchCheckout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/ach',
      title: 'ACH Checkout'
    })

    ReactPixel.track('Purchase', {
      content_category: 'ACH'
    })

    ReactPixel.pageView()
  }, [])

  useEffect(() => {
    if (cart.products?.length > 0) {
      dispatch(clearCart())
    }
  }, [cart])

  const handleHome = () => {
    navigate(`/`)
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Typography variant='h1'>
        A debit request has been sent to your bank
      </Typography>
      <br />
      <Typography align='left' style={{ margin: '10px 10%' }}>
        Please check your email for verification of purchase. Once we have
        received payment, your child's workshop will appear in your account. If
        unsuccessful, contact your bank for more details and attempt to purchase
        again. If you have any questions, email{' '}
        {
          <Link href='mailto:contact@rollingrobots.com'>
            contact@rollingrobots.com
          </Link>
        }
        .
      </Typography>

      <Button
        variant='contained'
        style={{ marginTop: '10px' }}
        onClick={handleHome}
      >
        Home
      </Button>
    </div>
  )
}

export default AchCheckout
