import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getInvites, getParent } from '../../../api/admin'
import { Grid, Typography, Button } from '@mui/material'
import ParentAPI from '../../../api/parentApi'
import LoadingComp from '../../util/loading'
import cards from '../../util/cards'
import PersonIcon from '@mui/icons-material/Person'
import useNavToAccountView from '../../../utils/navToAccountView'

const InviteList = () => {
  const dispatch = useDispatch()
  const handleNavToAccountView = useNavToAccountView()

  const BigCard = cards.bigCard

  const uID = useSelector((state) => state.login.user._id)

  const [invites, setInvites] = useState([])
  const [selectedParent, setSelectedParent] = useState(null)

  useEffect(() => {
    async function handleAsync() {
      let resp = await getInvites({ params: { uID: uID } })
      if (resp.success) {
        setInvites(resp.data)
      } else {
        alert('There was a problem retrieving invites')
      }
    }
    handleAsync()
  }, [])

  const handleDelete = async (invite) => {
    let resp = await ParentAPI.deleteInvite({
      studentID: invite.student._id,
      subscriptionID: invite.subscription._id
    })
    if (resp.success) {
      const tmpInvites = [...invites]
      let tmpArr = []
      tmpInvites.forEach((inviteObj) => {
        if (
          inviteObj.student._id !== invite.student._id ||
          inviteObj.subscription._id !== invite.subscription._id
        ) {
          tmpArr.push(inviteObj)
        }
      })
      setInvites([...tmpArr])
    } else {
      alert('There was a problem deleting the invitation')
    }
  }

  return (
    <>
      <Typography variant='h4'>Invitations</Typography>
      {invites.length > 0 ? (
        <Grid
          container
          justifyContent='center'
          spacing={2}
          sx={{ margin: '0px auto 100px auto', width: '50%' }}
        >
          {invites.map((invite) => {
            let studentName = `${invite.student.info.name.fName} ${invite.student.info.name.lName}`
            let subscriptionDetails = `${invite.subscription.name} at ${invite.subscription.locationName}`
            return (
              <Grid
                item
                xs={12}
                key={invite.student._id + invite.subscription._id}
              >
                <BigCard icon={PersonIcon} title={studentName} id='invite-info'>
                  <Typography variant='h6'>{subscriptionDetails}</Typography>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button onClick={() => handleDelete(invite)}>
                      Delete Invite
                    </Button>
                    <Button onClick={() => handleNavToAccountView(uID, invite.student)}>
                      View Student
                    </Button>
                  </div>
                </BigCard>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <LoadingComp />
      )}
    </>
  )
}

export default InviteList
