import React, { useState } from 'react'
import { Typography } from '@mui/material'

const ReadMore = ({ children }) => {
  const [show, setShow] = useState(false)
  const hover = {'&:hover': { cursor: 'pointer' }}
  const trimText = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    ...hover
  }
  return (
    <Typography
      inline
      align='left'
      sx={show ? hover : trimText}
      onClick={() => setShow(!show)}
    >
      {children}
    </Typography>
  )
}

export default ReadMore
