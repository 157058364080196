import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, IconButton } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import NavBarButton from './util/navBarButton'

const LoginButton = ({ handleLogOut, handleLogIn }) => {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const user = useSelector((state) => state.login.user)

  const logInCheck = () => {
    if (isLoggedIn) {
      return <>{user.info.name.fName}</>
    } else {
      return <>Sign In</>
    }
  }

  const styles = {
    button: {
      color: '#000',
      borderRadius: '10px',
      '& .MuiTouchRipple-root .MuiTouchRipple-child': {
        borderRadius: '10px'
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isLoggedIn ? (
          <>
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              Hello, {user.info.name.fName}
            </Typography>
            <NavBarButton
              startIcon={<PersonIcon />}
              aria-label='logout'
              onClick={() => handleLogOut()}
            >
              Sign Out
            </NavBarButton>
          </>
        ) : (
          <>
            <NavBarButton
              startIcon={<PersonIcon />}
              aria-label='login'
              onClick={() => handleLogIn()}
            >
              Sign In
            </NavBarButton>
          </>
        )}
      </div>
    </>
  )
}

export default LoginButton
