import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider
} from '@mui/material'
import {
  setSelectedActivity,
  setSelectedSubscription
} from '../../store/subscriptionSlice'
import { setToggleBack } from '../../store/instructorSlice'

const ActivityCard = ({ activity }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const courses = useSelector((state) => state.instructor.courses)
  const [course, setCourse] = useState({})
  const memberships = useSelector((state) => state.instructor.memberships)
  const [membership, setMembership] = useState({})
  const [membershipName, setMembershipName] = useState('')

  useEffect(() => {
    let tmpCourses = courses.filter(
      (tmpCourse) => tmpCourse._id === activity.courseID
    )
    setCourse(tmpCourses[0])
    let tmpMemberships = memberships.filter((tmpMembership) =>
      tmpMembership.activities.includes(activity._id)
    )
    setMembership(tmpMemberships[0])
  }, [])

  useEffect(() => {
    if (membership.name) {
      if (membership.name.includes('-')) {
        let tmpName = membership.name.split('-')
        setMembershipName(tmpName[1])
      } else {
        setMembershipName(membership.name)
      }
    }
  }, [membership])

  const handleEnrollment = () => {
    let tmpActivity = { instance: activity, course: course }
    dispatch(setSelectedActivity(tmpActivity))
    dispatch(setToggleBack(true))
    dispatch(setSelectedSubscription(membership))
    navigate('/admin-memberships/enrollment')
  }

  return (
    <Card
      style={{
        maxWidth: '400px',
        minWidth: '300px',
        margin: '5px',
        cursor: 'pointer'
      }}
      onClick={handleEnrollment}
    >
      <CardHeader
        title={<Typography variant='h6'>{course.name}</Typography>}
        subheader={membershipName}
        avatar={
          <img
            src={require(`../../images/courseIcons/${
              membership.icon || 'robie.png'
            }`)}
            style={{ width: '75px' }}
          />
        }
      />
      <Divider />
      <CardContent>
        <Typography variant='subtitle2'>
          {activity.startTime} - {activity.endTime}
        </Typography>
        <Typography variant='subtitle2'>
          {activity.students.length}{' '}
          {activity.students.length === 1 ? 'student' : 'students'}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ActivityCard
