import React, {useState} from 'react'
import {
  Paper,
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  IconButton,
  Popover,
  Menu,
  MenuItem
} from '@mui/material'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { MoreHoriz } from '@mui/icons-material/'
import { Hidden } from '@material-ui/core'

const styles = {
  bigIcon: {
    // margin: '0px 4%',
    marginRight: '4%',
    fontSize: '2rem'
  },
  bigTitle: {
    display: 'flex',
    backgroundColor: 'primary.light',
    borderRadius: '5px 5px 0px 0px',
    color: 'white',
    textAlign: 'left',
    padding: '20px 20px'
  },
  smallIcon: {
    margin: '0px 4%',
    fontSize: '2rem',
    color: 'primary.main'
  },
  smallTitle: {
    // overflow: 'hidden',
    display: 'flex',
    padding: '2% 2%',
    alignItems: 'center',
    height: '100%'
  }
}
const blankCard = (props) => {
  return (
    <>
      <Paper
        {...props.addProps}
        sx={{
          marginBottom: '20px',
          overflowWrap: 'break-word',
          width: '100%',
          ...props.style
        }}
      >
        <Box sx={{ padding: '20px 25px', ...props.box }}>{props.children}</Box>
      </Paper>
    </>
  )
}


const EditMenu = (content) => {

  const [menuAnchor, setMenuAnchor] = useState(null)
  const openMenu = (e) => {
    setMenuAnchor(e.currentTarget)
  }

  const closeMenu = () => {
    setMenuAnchor(null)
  }
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreHoriz sx={{ color: '#fff' }} />
      </IconButton>
<Menu
id='edit-menu'
open={Boolean(menuAnchor)}
anchorEl={menuAnchor}
onClose={closeMenu}
anchorOrigin={{
  vertical: 'bottom',
  horizontal: 'right'
}}
transformOrigin={{
  vertical: 'top',
  horizontal: 'right'
}}
>
  {content}
  {/* <MenuItem>Hello</MenuItem> */}
</Menu>
    </>
  )
}

const bigCard = (props) => {
  const TitleIcon = props.icon || SmartToyIcon
  return (
    <>
      <Paper
        {...props.addProps}
        sx={{
          ...props.style,
          marginBottom: '20px',
          overflowWrap: 'break-word',
          width: '100%'
        }}
      >
        <Box sx={styles.bigTitle} id='card-title'>
          <TitleIcon sx={styles.bigIcon} />
          <Typography variant='h2' sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
          {props.editMenu ? EditMenu(props.editMenu) : null}
        </Box>
        <Box sx={{ padding: '20px 25px' }}>{props.children}</Box>
        {props.accordionContent}
      </Paper>
    </>
  )
}

const smallCard = (props) => {
  const TitleIcon = props.icon || SmartToyIcon

  return (
    <>
      <Paper
        {...props.addProps}
        sx={{
          marginBottom: '20px',
          overflowWrap: 'anywhere',
          width: '100%',
          ...props.style
        }}
      >
        <Box sx={{ ...styles.smallTitle, ...props.titleStyle }}>
          <TitleIcon sx={styles.smallIcon} />
          <span style={{ textAlign: 'left' }}>
            <Typography variant='h3'>{props.title}</Typography>
            <Typography variant='subtitle2'>{props.subtitleOne}</Typography>
            <Typography variant='subtitle2'>{props.subtitleTwo}</Typography>
            <Typography variant='caption'>{props.subtitleThree}</Typography>
          </span>
        </Box>

        {props.children ? (
          <>
            <Divider />
            <Box sx={{ padding: '20px 40px', ...props.box }}>
              {props.children}
            </Box>
          </>
        ) : (
          <></>
        )}
      </Paper>
    </>
  )
}

const cards = {
  bigCard,
  smallCard,
  blankCard
}

export default cards
