import {
  Box,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Alert,
  Typography
} from '@mui/material/'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { setFilteredWorkshops } from '../../../store/workshopSlice'

const ProductsFilter = () => {
  const dispatch = useDispatch()

  let locations = useSelector((state) => state.user.locations)
  const products = useSelector((state) => state.workshop.workshops)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)

  const baseFilter = {
    location: ''
  }
  const [filter, setFilter] = useState(baseFilter)
  const [showAlert, setShowAlert] = useState(false)
  const [noProducts, setNoProducts] = useState(false)
  const [locationOptions, setLocationOptions] = useState(locations)

  useEffect(() => {
    if (defaultRegion._id) {
      //If user has selected a location in a region, reset for viewing a different region
      setFilter(baseFilter)
      let regionLocations = locations.filter(
        (loc) => loc.region == defaultRegion._id
      )
      setLocationOptions(regionLocations)
    }
  }, [defaultRegion])

  const handleFilter = (type, value) => {
    setFilter((prev) => ({ ...prev, [type]: value }))
  }

  const regionFilteredProducts = () => {
    if (defaultRegion._id) {
      return products.filter((workshop) =>
        locationOptions
          .map((loc) => loc._id)
          .includes(workshop.metadata.location)
      )
    } else {
      return products
    }
  }

  const resetFilter = () => {
    setNoProducts(false)
    setFilter(baseFilter)
    dispatch(setFilteredWorkshops(regionFilteredProducts()))
    setShowAlert(false)
  }
  

  const applyFilter = () => {
    setNoProducts(false)
    if (filter.location === '') {
      setShowAlert(true)
    } else {
      let tmpProducts = products.filter(
        (product) => product.metadata.location == filter.location
      )
      if (tmpProducts.length === 0) {
        setNoProducts(true)
      } else {
        dispatch(setFilteredWorkshops(tmpProducts))
      }
    }
  }

  return (
    <Paper
      sx={{
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px'
      }}
    >
      <Typography variant='h4'>Filter</Typography>
      <FormControl>
        <InputLabel id='location-select'>Location</InputLabel>
        <Select
          labelId='location-select'
          label='Location'
          value={filter.location}
          onChange={(e) => handleFilter('location', e.target.value)}
        >
          {locationOptions.map((location) => {
            return (
              <MenuItem key={location._id} value={location._id}>
                {location.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => resetFilter()}>Reset Filter</Button>
        <Button onClick={() => applyFilter()}>Apply Filter</Button>
      </Box>
      {showAlert ? (
        <Alert severity='error'>
          No filter selection made. Select filter options and try again.
        </Alert>
      ) : (
        <></>
      )}
      {noProducts ? (
        <Alert severity='error'>No workshops matched your selections.</Alert>
      ) : (
        <></>
      )}
    </Paper>
  )
}

export default ProductsFilter
