import React, { useState } from 'react'
import { MobileStepper, Typography, Box, IconButton } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

const TextStepper = (props) => {
  let textOptions = props.textOptions
  const [activeStep, setActiveStep] = useState(0)
  const handleNext = () => {
    if (activeStep === textOptions.length - 1) {
      setActiveStep(0)
    } else {
      setActiveStep((prev) => prev + 1)
    }
  }
  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(textOptions.length - 1)
    } else {
      setActiveStep((prev) => prev - 1)
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto auto',
        position: 'relative'
      }}
    >
      <Box
      alt='background gears'
        component='img'
        src={require(`../../images/fbLandingImages/${
          props.yellow ? 'Asset_2.png' : 'Asset_18.png'
        }`)}
        sx={{ width: '550px', left:  {xs: '-500px', md:'-600px'},top: '-70px', position: 'absolute', opacity: {xs: '0', sm: '100'} }}
      />
      <Box
        component='img'
        src={require(`../../images/fbLandingImages/${
          props.yellow ? 'Asset_2.png' : 'Asset_18.png'
        }`)}
        sx={{ width: '550px', left:{xs: '80%', md:'100%'},top: {xs: '100px', md:'-50px'}, position: 'absolute', opacity: {xs: '0', sm: '100'} }}
      />
      <Typography
        sx={{ fontSize: '24px', whiteSpace: 'pre-wrap', marginBottom: '10px', zIndex: '1000' }}
      >
        "{textOptions[activeStep].details}"
      </Typography>
      <Typography variant='h5'>- {textOptions[activeStep].name}</Typography>
      <Typography sx={{ fontStyle: 'italic', fontWeight: 500, marginBottom: '20px' }}>
        {textOptions[activeStep].subtitle}
      </Typography>

      <MobileStepper
        variant='dots'
        steps={textOptions.length}
        activeStep={activeStep}
        position='static'
        sx={{ maxWidth: 400, flexGrow: 1, background: 'rgba(0, 0, 0, 0)' }}
        nextButton={
          <IconButton size='small' onClick={handleNext}>
            <KeyboardArrowRight />
          </IconButton>
        }
        backButton={
          <IconButton size='small' onClick={handleBack}>
            <KeyboardArrowLeft />
          </IconButton>
        }
      />
    </Box>
  )
}

export default TextStepper
