
const processImages = (data) => {
  let tmp = []
  data.keys().forEach((key) => { tmp.push(key.split('./')[1]) })
  return tmp
}

const getCourseImages = () => {
  let imageNames = processImages(require.context('../images/courseIcons', false, /\.(png|jpe?g|svg)$/))
  return imageNames
}

export default getCourseImages
