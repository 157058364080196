import React from 'react'
import Section from './section'
import STYLES from './landingStyles'
import { Box, Button, Typography } from '@mui/material'

const InPersonMemberships = () => {
  const PARAGRAPH = '20px'

  let styles = {
    ...STYLES
  }

  const OPTIONS = [
    {
      key: 'junior-roboteers',
      title: 'Junior Roboteers',
      ages: '5 - 7',
      description:
        'No experience needed. Explore building and coding skills with robots.'
    },
    {
      key: 'intro-to-coding',
      title: 'Intro to Coding',
      ages: '7 - 9',
      description:
        'No experience needed. Learn to code on a computer with robots and electronics.'
    },
    {
      key: 'roboteers',
      title: 'Roboteers',
      ages: '8 - 12',
      description:
        'No experience needed. Learn to build and code MicroBits, Lego, and VEX IQ robots.'
    },
    {
      key: 'team-prep',
      title: 'Team Prep',
      ages: '10 - 15',
      description:
        'For students who are preparing to join the robotics competition team. Learn to code to control the robots.'
    },
    {
      key: 'advanced-coders',
      title: 'Advanced Coders',
      ages: '11 - 15',
      description:
        'For students who are excited about coding projects. Learn to code in the Advanced Scratch program and Python.'
    }
  ]
  return (
    <>
      <Section
        id='in-person-details'
        title={'In Person Membership'}
        capitalCase
        sx={{ borderTop: `15px solid ${styles.red}`, position: 'relative', overflow: null }}
      >
        <Box
          component='img'
          alt='blue-gears-left'
          src={require('../../images/membershipLanding/Asset_26.png')}
          sx={{
            height: { xs: '280px', sm: '320px', lg: '420px' },
            bottom: { xs: '-150px', sm:'-172px', lg: '-225px' },
            zIndex: 10,
            position: 'absolute',
            left: '-90px'
          }}
        />
        <Box sx={{ ...styles.box }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '50% auto',
              gridGap: '30px',
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Box sx={{ gridColumn: { xs: '1/3', md: '1/2' } }}>
              <Typography sx={{ marginBottom: PARAGRAPH, fontSize: '1rem' }}>
                Rolling Robots’ After-School Program is an in-person membership
                built around keeping children ages 5-15 engaged in robotics
                education after regular school hours and weekday. There is a
                workshop 4pm-6pm, every Monday through Friday.
              </Typography>
              <Typography sx={{ marginBottom: PARAGRAPH, fontSize: '1rem' }}>
                Choose from a variety of topics suitable for your student's age
                and abilities and we'll provide them with quality after-school
                activities and a rotating lesson plan to make sure that they are
                constantly challenged and actively involved.
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundImage: `url(${require('../../images/membershipLanding/Asset_28.jpg')})`,
                backgroundSize: 'cover',
                backgroundPositionX: 'center',
                backgroundPositionY: 'center',
                WebkitBoxShadow: `inset 0 0 0 6px ${styles.red}`,
                boxShadow: `inset 0 0 0 6px ${styles.red}`,
                borderRadius: '20px',
                minHeight: { xs: '300px', sm: '400px' },
                gridRowStart: { xs: 1, md: null },
                gridColumn: { xs: '1/3', md: '2/3' }
              }}
            />
          </Box>
        </Box>
      </Section>
      <Section
        id='membership-options'
        sx={{ backgroundColor: styles.lightGrey }}
      >
        <Box sx={{ ...styles.box }}>
          {OPTIONS.map((option) => (
            <Box key={option.key} sx={{ marginBottom: '30px' }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: '8% auto' }}>
                <Box
                  sx={{
                    border: `4px solid ${styles.red}`,
                    width: '9px',
                    height: '9px',
                    margin: 'auto 15px auto auto'
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontSize: '1.3rem',
                      fontWeight: 800,
                      marginRight: '5px'
                    }}
                  >
                    {option.title}
                  </Typography>
                  <Typography
                    sx={{
                      marginRight: '5px',
                      color: styles.red,
                      fontStyle: 'italic',
                      fontWeight: 600,
                      fontSize: '1.3rem'
                    }}
                  >
                    (Age {option.ages})
                  </Typography>
                  {/* <Typography sx={{ fontSize: '1.3rem' }}> - </Typography> */}
                </Box>
                <Typography sx={{ gridColumnStart: '2', fontSize: '1rem' }}>
                  {option.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '40px 0px'
          }}
        >
          <Button
            onClick={() => {
              window.open('/memberships')
            }}
          >
            Register
          </Button>
        </Box>
      </Section>
    </>
  )
}

export default InPersonMemberships
