import React, { useState, useEffect } from 'react'
import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material'

import { getAllUnconfirmed, getMoreCredits } from '../../api/credit'
import { useSelector } from 'react-redux'
import dateHandler from '../../utils/dateHandler'
import AlternatePaymentModal from './alternatePaymentModal'
import ConfirmAltPayButton from './confirmAltPayButton'

const AlternatePaymentsTable = () => {
  let uID = useSelector((state) => state.login.user._id)
  const [allCredits, setAllCredits] = useState([])
  const [startRange, setStartRange] = useState('start')
  const [showMoreLoading, setShowMoreLoading] = useState(false)
  const [gridColumns, setGridColumns] = useState([])

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const medium = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const large = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let resp = await getAllUnconfirmed({ uID: uID })
      if (resp.success) {
        setAllCredits(resp.data)
      } else {
        alert('There was a problem retrieving alternate payment orders')
      }
    }
    if (mounted) {
      handleAsync()
    }
    return () => (mounted = false)
  }, [])

  let buttonWidth = mobile ? 'auto' : medium ? '100px' : '150px'

  const COLUMNS = [
    { id: 0, header: 'Status' },
    { id: 1, header: 'PO #' },
    { id: 6, header: 'Product' },
    { id: 7, header: 'Price' },
    { id: 4, header: 'Student' },
    { id: 5, header: 'Activity Dates' },
    { id: 2, header: 'Parent' },
    { id: 3, header: 'Submitted' }
  ]

  const handleStatus = (value, credit) => {
    //Used by Alternate Payment Modal to update a students credit confirmation status on client side
    let creditIndex = allCredits.findIndex((obj) => obj._id == credit._id)

    setAllCredits((prev) => {
      let tmpArr = [...prev]
      let tmpObj = { ...credit, confirmed: value }
      tmpArr[creditIndex] = tmpObj
      return tmpArr
    })
  }

  const columnValues = (column, credit) => {
    if (Object.keys(credit).length > 0) {
      if (column.id === 0) {
        return (
          <ConfirmAltPayButton credit={credit} handleStatus={handleStatus} />
        )
      } else if (column.id === 1) {
        return credit.paymentID
      } else if (column.id === 2) {
        return `${credit.parentInfo?.info.name.fName} ${credit.parentInfo?.info.name.lName}`
      } else if (column.id === 3) {
        return dateHandler.toUSDate(credit.paymentDate)
      } else if (column.id === 4) {
        return `${credit.studentInfo?.info.name.fName} ${credit.studentInfo?.info.name.lName}`
      } else if (column.id === 5) {
        let startArr = credit.startDate?.split('-')
        let endArr = credit.endDate?.split('-')
        let dateRange = `${startArr[1]}-${startArr[2]}-${startArr[0]} - ${endArr[1]}-${endArr[2]}-${endArr[0]}`
        return dateRange
      } else if (column.id === 6) {
        if (credit.productType == 'workshop') {
          return credit.productInfo.courseName
        } else {
          return credit.productInfo.name
        }
      } else if (column.id === 7) {
        return credit.amount ? `$${credit.amount / 100}` : ''
      }
    }
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (mobile) {
        setGridColumns(COLUMNS.slice(0, 3))
      } else if (medium) {
        setGridColumns(COLUMNS.slice(0, 4))
      } else if (large) {
        setGridColumns(COLUMNS.slice(0, 5))
      } else {
        setGridColumns(COLUMNS)
      }
    }
    return () => (mounted = false)
  }, [mobile, medium, large])

  const Row = ({ credit, index }) => {
    const Cell = (props) => {
      return (
        <Box
          key={Math.random()}
          sx={{
            backgroundColor: index % 2 == 0 ? '#f5f5f5' : null,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '5px'
          }}
        >
          {typeof props.children === 'string' ? (
            <Typography>{props.children}</Typography>
          ) : (
            props.children
          )}
        </Box>
      )
    }

    return (
      <React.Fragment key={index + credit.paymentID}>
        {gridColumns.map((column) => {
          return <Cell key={Math.random()}>{columnValues(column, credit)}</Cell>
        })}

        <Cell>
          <AlternatePaymentModal
            handleStatus={handleStatus}
            selectedCredit={credit._id}
          />
        </Cell>
      </React.Fragment>
    )
  }

  const handleShowMore = async () => {
    setShowMoreLoading(true)
    if (startRange !== 'end') {
      let moreCredits = await getMoreCredits({
        uID: uID,
        startRange: startRange
      })
      if (moreCredits.success) {
        let tmp = [...allCredits, ...moreCredits.data]
        setAllCredits(tmp)
        setShowMoreLoading(false)
        //TO DO: create better way to stop at the end, this doesn't account for if the total number of documents is divisible by the limit
        if (moreCredits.data.length < 11) {
          //this number is the limit # of documents from Mongo
          setStartRange('end')
        } else {
          setStartRange(moreCredits.data[moreCredits.data.length - 1]._id)
        }
      }
    }
  }

  return (
    <>
      <Box sx={{ width: '90%', margin: 'auto auto' }}>
        <Paper sx={{ marginBottom: '100px' }}>
          <Box>
            {allCredits.length == 0 ? (
              <>Loading</>
            ) : (
              <Box
                sx={{
                  display: 'grid',
                  padding: '5px 10px',
                  gridTemplateColumns: `${buttonWidth} repeat(${
                    gridColumns.length - 1
                  }, minmax(0, 1fr)) 50px`
                }}
              >
                {gridColumns.map((column) => (
                  <Box key={column.id}>
                    <Typography variant='h6'>{column.header}</Typography>
                  </Box>
                ))}
                <Box></Box>
                {allCredits.map((credit, index) => (
                  <Row key={index} credit={credit} index={index} />
                ))}
              </Box>
            )}
          </Box>
          {startRange !== 'end' ? (
            <Button onClick={() => handleShowMore()}>
              {showMoreLoading ? 'Loading...' : 'Show More'}
            </Button>
          ) : (
            <></>
          )}
        </Paper>
      </Box>
    </>
  )
}

export default AlternatePaymentsTable
