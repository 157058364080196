import React, { useState, useEffect } from 'react'
import WorkshopInfo from './workshopInfo'
import {
  Dialog,
  DialogTitle,
  Button,
  Typography,
  DialogContent,
  IconButton,
  DialogActions
} from '@mui/material'
import AddToCartButton from './addToCartButton'
import { Close } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getEnrolledStudents } from '../../api/admin'
import AttendanceModal from './AttendanceModal'
import InviteModal from '../inviteModal'
import EmailModal from '../EmailModal'
import WorkshopStudents from './workshopStudents'

const WorkshopModal = ({
  workshop,
  handleProductForm,
  showModal,
  handleModal,
  inThePast,
  checkCapacity,
  toggleCustomer,
  studentList,
  workshopID,
  disableEdit
}) => {
  const uID = useSelector((state) => state.login.user._id)
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
  const permissions = useSelector((state) => state.login.user?.permissions?.[0])
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [invitedStudents, setInvitedStudents] = useState([])
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [emailType, setEmailType] = useState('')
  const [showAttendanceModal, setShowAttendanceModal] = useState(false)
  const [studentsForAdmin, setStudentsForAdmin] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [displayAdd, setDisplayAdd] = useState(false)

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      if (permissions >= 3) {
        let tmp = await getEnrolledStudents({
          uID: uID,
          students: studentList,
          productID: workshopID
        })
        if (mounted) {
          setStudentsForAdmin(tmp.data)
          console.log(tmp.data)
          setIsLoading(false)
        }
      }
    }
    if (showModal && studentList.length > 0) {
      handleAsync()
    }
    return () => (mounted = false)
  }, [showModal])

  const handleEmail = (emailType) => {
    setEmailType(emailType)
    setShowEmailModal(true)
  }

  const handleInvite = () => {
    setShowInviteModal(true)
  }

  const handleAttendance = () => {
    setShowAttendanceModal(true)
  }

  const handleStatus = (value, credit) => {
    //Used by Alternate Payment Modal to update a students credit confirmation status on client side
    //API call is handled within embedded component
    let studentIndex = studentsForAdmin.findIndex(
      (obj) => obj.student.creditID === credit._id
    )
    let studentObj = { ...studentsForAdmin[studentIndex] }
    studentObj.student.creditConfirmed = value
    setStudentsForAdmin((prev) => {
      let tmpArr = [...prev]
      tmpArr[studentIndex] = studentObj
      return tmpArr
    })
  }

  return (
    <>
      <Dialog scroll='body' open={showModal} onClose={handleModal}>
        <DialogTitle
          sx={{
            fontWeight: 'bold'
          }}
        >
          Course Info
          <IconButton
            style={{ position: 'absolute', right: '20px', top: '12px' }}
            onClick={handleModal}
          >
            <Close />
          </IconButton>
          {permissions === 4 && !toggleCustomer ? (
            <Button
              variant='contained'
              size='small'
              style={{
                margin: '0 5px',
                minWidth: '0'
              }}
              onClick={() => {
                handleProductForm(workshop)
              }}
              disabled={disableEdit}
            >
              Edit
            </Button>
          ) : (
            <></>
          )}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <WorkshopInfo
            workshop={workshop}
            showAdminDetails={permissions >= 3 && !toggleCustomer}
            expandedAdminDetails={true} //will only show if showAdminDetails is true
            showParentDetails={true}
            showCourseLesson={permissions >= 3 && !toggleCustomer}
          />
          {permissions >= 3 && !toggleCustomer ? (
            <>
              {studentList?.length > 0 ? (
                <WorkshopStudents
                  students={studentsForAdmin}
                  setStudents={setStudentsForAdmin}
                  handleEmail={handleEmail}
                  handleAttendance={handleAttendance}
                  handleInvite={handleInvite}
                  isLoading={isLoading}
                  invitedStudents={invitedStudents}
                  setInvitedStudents={setInvitedStudents}
                  handleStatus={handleStatus}
                />
              ) : (
                <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
                  No Students enrolled
                </Typography>
              )}
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          {/**Don't show enroll button if you are logged in as an admin */}
          {isLoggedIn && permissions >= 3 && !toggleCustomer ? (
            <></>
          ) : inThePast ? (
            <Typography>
              This course session has already passed. Check another date.
            </Typography>
          ) : checkCapacity === 'Full' ? (
            <Button
              id='closed-button'
              variant='contained'
              style={{ backgroundColor: 'grey' }}
            >
              CLOSED
            </Button>
          ) : (
            <AddToCartButton
              course={workshop}
              displayAdd={displayAdd}
              setDisplayAdd={setDisplayAdd}
            />
          )}
        </DialogActions>
      </Dialog>
      {showAttendanceModal ? (
        <AttendanceModal
          product={workshop}
          handleClose={() => setShowAttendanceModal(false)}
          studentData={studentsForAdmin}
        ></AttendanceModal>
      ) : (
        <></>
      )}
      {showInviteModal ? (
        <InviteModal
          handleClose={() => setShowInviteModal(false)}
          uID={uID}
          invitedStudents={invitedStudents}
        />
      ) : (
        <></>
      )}
      {showEmailModal ? (
        <EmailModal
          studentData={studentsForAdmin}
          course={workshop}
          handleClose={() => setShowEmailModal(false)}
          emailType={emailType}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default WorkshopModal
