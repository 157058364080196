import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Divider,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress
} from '@mui/material'
import { assignStudent } from '../../store/cartSlice'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import StudentForm from '../studentForm'
import ModelHandler from '../../models/modelHandler'
import generateEmail from '../../utils/generateFakeEmail'
import { createUser } from '../../api/userApi'
import { addStudent } from '../../store/parentSlice'
import ParentAPI from '../../api/parentApi'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { setStudents } from '../../store/parentSlice'
import AlternatePaymentToolTip from '../alternatePaymentToolTip'
import CartCard from './cartCard'

const CartView = ({
  handleClose,
  handleSubmit,
  total,
  errors,
  useAlternate,
  handleAlternate,
  purchaseByAdmin,
  setErrors,
  setTermsCheck,
  termsCheck,
  handleCoupon,
  loading,
  paymentType,
  setPaymentType,
  handleErrors,
  achAuthorized
}) => {
  const dispatch = useDispatch()

  let cartInv = useSelector((state) => state.cart.products)
  let students = useSelector((state) => state.parent.students)

  //Checks if user is admin or parent
  let user = useSelector((state) => {
    if (
      state.login.user.permissions && //checks if a user is logged in
      state.login?.user?.permissions[0] === 4 //checks if user is an admin
    ) {
      return state.user.selectedUser //gets user selected by admin
    } else {
      return state.login.user //gets logged in user
    }
  })

  const createNewChild = {
    _id: 'create',
    info: { name: { fName: 'Add Roboteer' } }
  }

  const [childList, setChildList] = useState([])
  const [termsError, setTermsError] = useState(false)
  const [view, setView] = useState(0)

  const styles = {
    actions: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    helpText: {
      color: '#d32f2f',
      fontSize: 12
    },
    tempWeb: {}
  }

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let resp = await ParentAPI.getParentStudents({
        params: { parentID: user._id }
      })
      if (resp.success) {
        dispatch(setStudents(resp.data))
        let tmpArr = [...resp.data]
        tmpArr.push(createNewChild)
        if (mounted) {
          setChildList(tmpArr)
        }
      } else {
        alert(resp)
      }
    }
    //check if user is logged in/selected
    if (user._id) {
      if (
        user.children.length > 0 && //user.children is list of studentIDs
        students.length === 0 //students is list of student objects
      ) {
        handleAsync()
      } else {
        let tmpArr = [...students]
        tmpArr.push(createNewChild)
        if (mounted) {
          setChildList(tmpArr)
        }
      }
    }
    ReactGA.send({ hitType: 'pageview', page: '/cart', title: 'Cart' })
    ReactPixel.pageView()
    return () => (mounted = false)
  }, [])

  useEffect(() => {
    if (childList.length === 1) {
      setChildList((prevState) => [...prevState, ...students])
    }
  }, [students])

  useEffect(() => {
    //Clears error warnings when a user fixes them
    let mounted = true
    if (mounted && Object.keys(errors).length > 0) {
      let errors = handleErrors()
      setErrors(errors)
    }
    return () => (mounted = false)
  }, [cartInv])

  //StudentForm logic
  const [showStudentForm, setShowStudentForm] = useState(false)
  const [newChild, setNewChild] = useState({})
  const [studentErr, setStudentErr] = useState([])
  const [currentProduct, setCurrentProduct] = useState(null)

  const handleSelectStudent = (student, product) => {
    if (student === 'create') {
      setShowStudentForm(true)
      setCurrentProduct(product)
    } else {
      dispatch(assignStudent({ student, product }))
    }
  }
  const handleNewUser = (type, value) => {
    setNewChild((prevState) => ({
      ...prevState,
      [type]: value
    }))
  }

  const handleNext = async () => {
    var tmp = newChild
    tmp.parentID = user._id
    tmp.addrCheck = true // temp work around for not adding extra fields
    if (tmp.addrCheck === undefined || tmp.addrCheck === true) {
      tmp.address = user.info.address
    }
    let err = ModelHandler.checkStudentData(tmp, true)
    if (err.length === 0) {
      if (!('email' in tmp) || tmp.email === '') {
        tmp.email = generateEmail(tmp)
      }
      let data = ModelHandler.student(tmp)
      let resp = await createUser(data)

      setNewChild(resp)
      setShowStudentForm(false)
      dispatch(addStudent(resp.data))
      dispatch(
        assignStudent({ student: resp.data._id, product: currentProduct })
      )
      setChildList((prevState) => [...prevState, resp.data])
    } else {
      // handle
      // setStudentErr(errors)
    }
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '500px',
      margin: '0 auto',
      textAlign: 'center',
      height: '90vh',
      paddingTop: '10vh'
    }
  }

  const handleTermsCheck = () => {
    setTermsCheck(!termsCheck)
    setTermsError(false)
  }

  const handleChange = (value) => {
    setPaymentType(value)
  }

  const handleDisable = (value) => {
    if (useAlternate) {
      return value !== 'alternate_payment'
    }
    if (!achAuthorized) {
      return value == 'us_bank_account'
    }
  }

  return (
    <>
      <Dialog fullScreen open onClose={handleClose} style={styles.tempWeb}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&.MuiDialogTitle-root': {}
          }}
        >
          Shopping Cart
          <IconButton
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {view === 0 ? (
            <>
              {cartInv.length === 0 ? (
                <Typography>Your Cart is Empty</Typography>
              ) : (
                cartInv.map((course, index) => {
                  return (
                    <div
                      style={{ margin: '10px' }}
                      key={`${course.id}_${index}`}
                    >
                      <CartCard
                        children={childList}
                        workshop={course}
                        handleSelectStudent={handleSelectStudent}
                        errors={errors?.[course['cartID']]}
                      />
                    </div>
                  )
                })
              )}
            </>
          ) : (
            <Box sx={{ textAlign: 'left' }}>
              {user.permissions ? (
                <>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Promo Code:
                  </Typography>
                  <TextField
                    fullWidth
                    label='Add a Valid Promo Code'
                    onChange={(e) => handleCoupon(e)}
                    error={errors['coupon']}
                    helperText={errors['coupon'] ? 'Invalid Promo Code' : ''}
                  />
                </>
              ) : (
                <></>
              )}
              <Typography sx={{ fontWeight: 'bold', marginTop: '5px' }}>
                Pay with:
              </Typography>
              <FormControl
                sx={{
                  '& .MuiFormControlLabel-root': { alignItems: 'flex-start' }
                }}
              >
                <RadioGroup
                  aria-labelledby='payment-options-radio-group'
                  name='payment-options-radio-group'
                  onChange={(e) => handleChange(e.target.value)}
                  defaultValue={useAlternate ? 'alternate_payment' : 'card'}
                >
                  <FormControlLabel
                    disabled={handleDisable('card')}
                    value='card'
                    control={<Radio />}
                    label={
                      <>
                        <Typography textAlign='left' sx={{ marginTop: '8px' }}>
                          Credit Card
                        </Typography>
                        {/* <Typography textAlign='left' sx={{ fontSize: '12px' }}>
                          A 3.5 percent credit card fee will be added to this
                          payment at checkout.
                        </Typography> */}
                      </>
                    }
                    checked={paymentType === 'card'}
                  />
                  <FormControlLabel
                    disabled={handleDisable('us_bank_account')}
                    value='us_bank_account'
                    control={<Radio />}
                    label={
                      <>
                        <Typography textAlign='left' sx={{ marginTop: '8px' }}>
                          Direct Debit (ACH)
                        </Typography>
                        {handleDisable('us_bank_account') ? (
                          <Typography
                            textAlign='left'
                            sx={{ fontSize: '12px' }}
                          >
                            Direct Debit (ACH) is not available for camps that
                            start within 2 days.
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                    checked={paymentType === 'us_bank_account'}
                  />
                  {user.creditAuthorized || purchaseByAdmin ? (
                    <FormControlLabel
                      disabled={handleDisable('alternate_payment')}
                      value='alternate_payment'
                      control={<Radio />}
                      label={
                        <>
                          <Typography
                            display='inline'
                            sx={{ marginTop: '8px' }}
                          >
                            Alternate Payment
                          </Typography>
                          <AlternatePaymentToolTip />
                        </>
                      }
                      checked={paymentType === 'alternate_payment'}
                    />
                  ) : (
                    <></>
                  )}
                </RadioGroup>
              </FormControl>
              <Typography sx={{ fontWeight: 'bold', marginTop: '5px' }}>
                Terms and Conditions
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end'
                }}
              >
                <Checkbox
                  checked={termsCheck}
                  onChange={(e) => handleTermsCheck()}
                  sx={{
                    '.MuiSvgIcon-root': { color: termsError ? 'red' : null }
                  }}
                />
                <Typography sx={{ fontSize: '11px', textAlign: 'left' }}>
                  By checking this box, you confirm that you have read and agree
                  to our{' '}
                  <b>
                    <a href='/terms#terms-and-conditions' target='_blank'>
                      terms and conditions
                    </a>
                  </b>
                  , as well as the
                  <b>
                    {' '}
                    <a href='/terms#waiver' target='_blank'>
                      liability waiver
                    </a>
                  </b>
                  .
                </Typography>
              </Box>
              {termsError ? (
                <Typography sx={{ fontSize: '11px', color: 'red' }}>
                  Please check above to agree to Terms & Conditions and
                  liability waiver.
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          )}
        </DialogContent>
        <Divider sx={{ marginBottom: '10px' }} />
        <div style={{ color: '#d32f2f' }}>
          {cartInv.length > 0 && Object.values(errors).length > 0
            ? 'Please correct errors.'
            : null}
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0px 24px 0px 24px',
            alignSelf: 'end'
          }}
        >
          <Typography variant='h6'>
            Total: <b>${total}</b>
          </Typography>
        </Box>

        <DialogActions
          sx={{
            justifyContent: view == 0 ? 'flex-end' : 'space-between',
            width: '100%',
            '&.MuiDialogActions-root': { padding: '8px 24px 16px 24px' }
          }}
        >
          {view === 1 ? (
            <Button
              onClick={() => {
                setView(0)
              }}
              variant='outlined'
            >
              Back to Cart
            </Button>
          ) : (
            <></>
          )}
          {!user._id ? (
            <Button
              variant='contained'
              onClick={() => {
                window.location.pathname = '/login'
              }}
            >
              Sign in to continue checkout
            </Button>
          ) : (
            <>
              {!loading ? (
                <Button
                  disabled={cartInv.length === 0}
                  variant='contained'
                  sx={{ justifySelf: 'flex-end' }}
                  onClick={() => {
                    if (view === 0) {
                      let errors = handleErrors()
                      if (Object.keys(errors).length === 0) {
                        setView(1)
                      } else {
                        setErrors(errors)
                      }
                    } else {
                      if (termsCheck) {
                        handleSubmit()
                      } else {
                        setTermsError(true)
                      }
                    }
                  }}
                >
                  {view === 0 ? 'Proceed to Check Out' : 'Check Out'}
                </Button>
              ) : (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress />
                  <Typography>Checking Data</Typography>
                </Box>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
      {showStudentForm ? (
        <StudentForm
          data={newChild}
          errors={studentErr}
          handleChange={handleNewUser}
          handleNext={handleNext}
          handleClose={() => {
            setShowStudentForm(false)
          }}
        />
      ) : (
        <React.Fragment />
      )}
    </>
  )
}

export default CartView
