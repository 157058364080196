import React, { useEffect, useState } from 'react'
import { Box, Paper, IconButton, Typography } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

const CompetitionCalendar = (props) => {
  const { teamEventData, selectedTeam, programFilter, loading, currTab } = props

  const [monthIndex, setMonthIndex] = useState([])
  const [monthDates, setMonthDates] = useState([])
  const [calendarData, setCalendarData] = useState([])
  const [filteredCalendarData, setFilteredCalendarData] = useState([])

  const styles = {
    cell: {
      border: '1px solid grey'
    }
  }
  useEffect(() => {
    if (teamEventData.length > 0 && calendarData.length == 0) {
      //Get all dates and sort
      let allDates = teamEventData
        .map((obj) => obj.events.map((event) => event.start.split('T')[0]))
        .flat()
      let sortedDates = Array.from(new Set(allDates)).sort()

      //Create arrays of each month's dates
      let firstDate = new Date(sortedDates[0])
      let lastDate = new Date(sortedDates[sortedDates.length - 1])
      let numOfMonths =
        (lastDate.getFullYear() - firstDate.getFullYear()) * 12 -
        firstDate.getMonth() +
        lastDate.getMonth()
      let tmpDateArr = []
      let currentMonthValue = 0
      for (let i = 0; i <= numOfMonths; i++) {
        let dateNumber = firstDate.getMonth() + i
        dateNumber = dateNumber >= 12 ? dateNumber - 12 : dateNumber
        //Get the first month in the array that matches the current month, 
        if (currentMonthValue == 0 && new Date().getMonth() == dateNumber) {
            currentMonthValue = i
        }
        let getMonthDates = sortedDates.filter(
          (date) => new Date(date).getMonth() == dateNumber
        )
        tmpDateArr.push(getMonthDates)
      }
      setMonthIndex(currentMonthValue) //Index of array that will render
      setMonthDates(tmpDateArr) //Array of arrays with dates sorted by month

      //Sort each team's events by ALL dates and create ALL calendar rows array
      let calendarRows = []
      teamEventData.forEach((item) => {
        let teamLink = `https://www.robotevents.com/teams/${item.team.program.code}/${item.team.number}`
        //Initialize row array with team object
        let row = [
          {
            link: teamLink,
            label: item.team.number,
            programCode: item.team.program.code
          }
        ]
        sortedDates.forEach((date) => {
          let dateEvents = item.events.filter(
            (event) => event.start.split('T')[0] == date
          )
          if (dateEvents.length > 0) {
            row.push(dateEvents[0])
          } else {
            row.push(null)
          }
        })
        calendarRows.push(row)
      })
      setCalendarData(calendarRows)
    }
  }, [teamEventData])

  useEffect(() => {
    let mounted = true
    async function handleFilter() {
      let filterByTeam = calendarData.filter((row) => {
        if (row[0].label == selectedTeam || selectedTeam == 'All') {
          if (
            row[0].programCode === programFilter ||
            (programFilter === 'AI' && //AI has two program codes
              (row[0].programCode === 'VAIRC' ||
                row[0].programCode === 'VAIC-HS'))
          ) {
            return true
          }
        } else {
          return false
        }
      })

      let filterByMonth = filterByTeam.map((row) => {
        let events = row.slice(1)
        let tmpRow = [row[0]]
        monthDates[monthIndex].forEach((date) => {
          let dateEvents = events.filter(
            (event) => event?.start.split('T')[0] == date
          )
          if (dateEvents.length > 0) {
            tmpRow.push(dateEvents[0])
          } else {
            tmpRow.push(null)
          }
        })
        return tmpRow
      })
      setFilteredCalendarData(filterByMonth)
    }
    if (mounted) {
      handleFilter()
    }
    return () => {
      mounted = false
    }
  }, [selectedTeam, programFilter, loading, monthIndex, currTab])

  const handleMonth = (value) => {
    if (value == 'prev') {
        setMonthIndex((prev) => prev - 1)
    } else {
        setMonthIndex((prev) => prev + 1)
    }
  }

  const checkMonthRange = (value) => {
    if (value == 'prev' && monthIndex !== 0) {
        return true
    } else if (value == 'next' && monthIndex < monthDates.length - 1) {
        return true
    } else {
        return false
    }
  }

  return (
    <>
      {selectedTeam ? (
        <Paper>
          {filteredCalendarData.length > 0 ? (
            <>
              {/* Month Row */}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '10% auto 10%',
                  backgroundColor: '#3790CB',
                  padding: '10px 10px'
                }}
              >
                <Box>
                  {checkMonthRange('prev') ? (
                    <IconButton
                      sx={{ color: '#fff' }}
                      onClick={() => handleMonth('prev')}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant='h5' sx={{ color: '#fff' }}>
                    {new Date(monthDates[monthIndex][0]).toLocaleString('default', {
                      month: 'long',
                      year: 'numeric'
                    })}
                  </Typography>
                </Box>
                <Box>
                  {checkMonthRange('next') ? (
                    <IconButton
                      sx={{ color: '#fff' }}
                      onClick={() => handleMonth('next')}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: `100px repeat(${monthDates[monthIndex].length}, minmax(0, 1fr))`
                }}
              >
                {/* Dates Row */}
                <Box></Box>
                {monthDates[monthIndex].map((headerItem) => {
                  let displayText = headerItem
                  if (headerItem.includes('-')) {
                    let dateSplit = headerItem.split('-')
                    displayText = `${dateSplit[1]}/${dateSplit[2]}`
                  }
                  return <Box key={headerItem} sx={{...styles.cell, fontWeight: 'bold'}}>{displayText}</Box>
                })}
                {/* Teams and Events Rows */}
                {filteredCalendarData.map((calendarRow, rowIndex) => (
                  <React.Fragment key={'row' + rowIndex}>
                    {calendarRow.map((col) => {
                      if (col?.sku) {
                        let eventLink =
                          'https://www.robotevents.com/robot-competitions/vex-robotics-competition/' + //Using the VRC URL will redirect to the correct program code
                          col.sku +
                          '.html'

                        return (
                          <Box
                            sx={{ ...styles.cell }}
                            key={rowIndex + col.sku}
                          >
                            <a href={eventLink}>{col.name}</a>
                          </Box>
                        )
                      } else if (col?.label) {
                        return (
                          <Box
                            sx={{ ...styles.cell }}
                            key={rowIndex + col.label}
                          >
                            <a href={col.link} style={{fontWeight: 'bold'}}>{col.label}</a>
                          </Box>
                        )
                      } else {
                        return (
                          <Box
                            sx={{ ...styles.cell }}
                            key={Math.random()}
                          ></Box>
                        )
                      }
                    })}
                  </React.Fragment>
                ))}
              </Box>
            </>
          ) : (
            <>Loading...</>
          )}
        </Paper>
      ) : (
        <></>
      )}
    </>
  )
}

export default CompetitionCalendar
