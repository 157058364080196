import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import { getStudentAttendance } from '../../api/subscription'
import CloseIcon from '@mui/icons-material/Close'
import LoadingComp from '../util/loading'

const SubscriptionAttendance = ({ open, handleClose }) => {
  const user = useSelector((state) => state.login.user)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const student = useSelector((state) => state.subscription.student)
  const [loading, setLoading] = useState(false)
  const [headers, setHeaders] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    async function handleAsync() {
      setLoading(true)
      let resp = await getStudentAttendance({
        params: {
          subscriptionID: subscription._id,
          studentID: student._id,
          activityIDs: subscription.activities,
          uID: user._id
        }
      })
      if (resp.success) {
        //Sort data into headers and rows
        let tmpHeaders = ['Dates']

        //Check if any activities have no attendance attached
        resp.data.activities.forEach((activity) => {
          let attendance = resp.data.attendance.filter(
            (tmpAtt) => tmpAtt.activityID === activity.instance._id
          )
          //if attendance has been taken for date, add activity name to header
          if (attendance.length !== 0) {
            tmpHeaders.push({
              name: `${activity.instance.day} - ${activity.course.name}`,
              id: activity.instance._id
            })
          }
        })
        setHeaders(tmpHeaders)

        //Get unique dates
        const uniqueDates = [
          ...new Set(resp.data.attendance.map((item) => item.date))
        ]

        //Sort unique dates from most recent to least recent
        let sortedDates = uniqueDates.sort(function (a, b) {
          let aDate = new Date(a)
          let bDate = new Date(b)

          return bDate.getTime() - aDate.getTime()
        })

        let tmpRows = []
        sortedDates.forEach((sortedDate) => {
          let row = []
          //Get attendance for date
          let attendance = resp.data.attendance.filter(
            (att) => att.date === sortedDate
          )

          //align status to activity
          for (let i = 0; i < tmpHeaders.length; i++) {
            if (i === 0) {
              //Add date to first column of table
              row.push(sortedDate)
            } else {
              //Get activityID
              let activityID = tmpHeaders[i].id

              //Filter attendance for day and activity
              let activityAttendance = attendance.filter(
                (att) =>
                  att.date === sortedDate && att.activityID === activityID
              )

              //Safeguard in case no attendance is found
              if (activityAttendance.length > 0) {
                //If it is found, push string to row
                row.push(activityAttendance[0].status)
              } else {
                row.push('Not Taken')
              }
            }
          }
          tmpRows.push(row)
        })
        setRows(tmpRows)
        setLoading(false)
      } else {
        alert(resp.data.error.message)
      }
    }
    if (open) {
      handleAsync()
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(240, 240, 240, 0.3)'
        },
        '& .MuiPaper-root': { boxShadow: 'none' }
      }}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle>
        Attendance for {subscription?.name}
        <IconButton
          style={{ position: 'absolute', right: '20px', top: '12px' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingComp />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: 'black' }}>
                <TableRow>
                  {headers.map((header, index) => {
                    if (index === 0) {
                      return (
                        <TableCell
                          sx={{ color: 'white', border: '1px solid white' }}
                          key={header}
                        >
                          {header}
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell
                          sx={{ color: 'white', border: '1px solid white' }}
                          key={header._id}
                        >
                          {header.name}
                        </TableCell>
                      )
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <TableRow key={'row' + index}>
                      {row.map((cell, index) => {
                        return (
                          <TableCell
                            sx={{ border: '1px solid gray' }}
                            key={'cell' + index}
                          >
                            {cell}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SubscriptionAttendance
