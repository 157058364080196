import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { IconButton, Typography, useMediaQuery } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CONSTANTS from '../../constants'
import { getInviteRequests } from '../../api/admin'
import InviteRequestModal from './InviteRequestModal'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: theme.palette.background.paper,
    backgroundColor: CONSTANTS.RED_HEX
  }
}))

const AdminNotification = () => {
  const [showRequests, setShowRequests] = useState(false)
  const [requests, setRequests] = useState([])
  const uID = useSelector((state) => state.login.user._id)

  const styles = {
    cart: {
      padding: '0px 10px'
    }
  }

  useEffect(() => {
    async function handleAsync() {
      let resp = await getInviteRequests({ uID })
      if (resp.success) {
        setRequests(resp.data)
      } else {
        alert('There was an error retrieving request invites')
      }
    }
    handleAsync()
  }, [])

  const handleClose = () => {
    setShowRequests(false)
  }

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <>
      <IconButton
        aria-label='requests'
        onClick={() => {
          setShowRequests(true)
        }}
        sx={{
          color: '#000',
          borderRadius: '10px',
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: "10px"
          }
        }}
      >
        <StyledBadge
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          badgeContent={requests.length}
        >
          <NotificationsIcon color='secondary' fontSize='small'/>
        </StyledBadge>
        {mobile ? <></> : <Typography variant='body1' sx={styles.cart}>Requests</Typography>}
      </IconButton>
      {showRequests ? (
        <InviteRequestModal
          requests={requests}
          setRequests={setRequests}
          handleClose={handleClose}
          uID={uID}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default AdminNotification
