import { createSlice } from '@reduxjs/toolkit'

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    locations: [],
    selectedLocation: null,
    view: 0,
    edit: false
  },
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload
    },
    setView: (state, action) => {
      state.view = action.payload
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload
    },
    setEdit: (state, action) => {
      state.edit = action.payload
    },
    updateLoc: (state, action) => {
      state.locations.splice(
        state.locations.findIndex(function (i) {
          return i._id === action.payload._id
        }),
        1
      )
      state.locations.unshift(action.payload)
    },
    addLocation: (state, action) => {
      state.locations.unshift(action.payload)
    }
  }
})

export const {
  setLocations,
  setView,
  setSelectedLocation,
  setEdit,
  updateLoc,
  addLocation
} = locationSlice.actions

export default locationSlice.reducer
