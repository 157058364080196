import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button, Box } from '@mui/material'
import CompressedSubscriptionGrid from './compressedSubscriptionGrid'
import ExpandedSubscriptionGrid from './expandedSubscriptionGrid'
import LocationSelector from './locationSelector'
import { setView, setExpandedGrid } from '../../../store/subscriptionSlice'

const SubscriptionGrids = ({activitiesExist}) => {
  const dispatch = useDispatch()
  const expanded = useSelector((state) => state.subscription.expandedGrid)
  const handleClick = (type) => {
    dispatch(setExpandedGrid(type))
  }

  //Set view to return to this location on back
  useEffect(() => {
    dispatch(setView(1))
  }, [])

  return (
    <Box
      sx={{
        width: '95%',
        margin: 'auto auto'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonGroup size='small'>
          <Button
            variant={expanded ? 'contained' : null}
            onClick={() => handleClick(true)}
          >
            Expanded
          </Button>
          <Button
            variant={!expanded ? 'contained' : null}
            onClick={() => handleClick(false)}
          >
            Compressed
          </Button>
        </ButtonGroup>
        <LocationSelector expanded={expanded} />
      </div>
        {activitiesExist ? 
        (<>
          {expanded ? <ExpandedSubscriptionGrid /> : <CompressedSubscriptionGrid />}  
        </>) : 
        (<>
          <h1>There are no memberships at this location</h1>

        </>)}
      
    </Box>
  )
}

export default SubscriptionGrids
