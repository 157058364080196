import { useSelector, useDispatch } from 'react-redux'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
  Chip,
  Stack,
  Grid
} from '@mui/material'
import LoadingComp from '../../util/loading'
import {
  setSelectedLocation,
  setView,
  setEdit
} from '../../../store/locationSlice'
import MapSelector from '../../../utils/mapSelector'
import ListPriorityToolTip from './listPriorityToolTip'

const LocationsList = ({ setCurrTab }) => {
  const styles = {
    cardItem: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '90%',
      marginBottom: '5px'
    },
    cardName: { width: '150px' },
    cardInfo: { width: '250px', display: 'flex', justifyContent: 'center' }
  }

  const dispatch = useDispatch()
  const locations = useSelector((state) => state.user.locations)
  const regions = useSelector((state) => state.admin.regions)

  const handleView = (location) => {
    dispatch(setSelectedLocation(location))
    dispatch(setView(1))
  }

  const handleEdit = (location) => {
    dispatch(setSelectedLocation(location))
    dispatch(setEdit(true))
    setCurrTab(1)
  }
  const getRegionInfo = (id) => {
    if (id) {
      let filter = regions.filter((region) => region._id == id)
      if (filter.length > 0) {
        return filter[0].name
      } else {
        return 'Error loading region'
      }
    } else {
      return 'N/A'
    }
  }
  return (
    <>
      {locations.length > 0 ? (
        <Grid container spacing={2}>
          {locations.map((location) => (
            <Grid item style={{ margin: '0 auto' }} key={location._id}>
              <Card sx={{ maxWidth: '500px' }}>
                <CardHeader title={location.name} action={location.listPriority ? <ListPriorityToolTip /> : <></>} />
                <CardContent>
                  <MapSelector
                    location={location}
                    width='100%'
                    height='200px'
                  />
                  <div style={styles.cardItem}>
                    <Typography variant='body2' style={styles.cardName}>
                      Address:
                    </Typography>
                    <Typography
                      style={styles.cardInfo}
                    >{`${location.address.lineOne}, ${location.address.lineTwo}, ${location.address.city}, ${location.address.postal}`}</Typography>
                  </div>
                  <div style={styles.cardItem}>
                    <Typography variant='body2' style={styles.cardName}>
                      Region:
                    </Typography>
                    <Typography style={styles.cardInfo}>
                      {getRegionInfo(location.region)}
                    </Typography>
                  </div>

                  <div style={styles.cardItem}>
                    <Typography variant='body2' style={styles.cardName}>
                      Manager:
                    </Typography>

                    {location.manager?.info ? (
                      <Typography style={styles.cardInfo}>
                        {`${location.manager.info.name.fName} ${location.manager.info.name.lName}`}
                      </Typography>
                    ) : (
                      <Typography style={styles.cardInfo}></Typography>
                    )}
                  </div>

                  <div style={styles.cardItem}>
                    <Typography variant='body2' style={styles.cardName}>
                      Info:
                    </Typography>

                    <Typography style={styles.cardInfo}>
                      {location.info}
                    </Typography>
                  </div>

                  <div style={styles.cardItem}>
                    <Typography varianth='body2' style={styles.cardName}>
                      Email:
                    </Typography>
                    <Typography style={styles.cardInfo}>
                      {location.email}
                    </Typography>
                  </div>

                  <div style={styles.cardItem}>
                    <Typography varianth='body2' style={styles.cardName}>
                      Phone:
                    </Typography>
                    <Typography style={styles.cardInfo}>
                      {location.phone}
                    </Typography>
                  </div>

                  <div style={styles.cardItem}>
                    <Typography varianth='body2' style={styles.cardName}>
                      Workshop Seasons:
                    </Typography>
                    <Stack direction='row' spacing={1} style={styles.cardInfo}>
                      {location.workshopSeasons?.map((season) => {
                        return (
                          <Chip
                            color='error'
                            size='small'
                            label={season}
                            key={location._id + season}
                          />
                        )
                      })}
                    </Stack>
                  </div>
                </CardContent>
                <CardActions
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button onClick={() => handleEdit(location)}>Edit</Button>
                  <Button onClick={() => handleView(location)}>
                    View Classes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <LoadingComp />
      )}
    </>
  )
}

export default LocationsList
