import React from 'react'
import {
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Alert,
  TextField,
  Typography,
  Button
} from '@mui/material'
import dateHandler from '../../utils/dateHandler'
import { useSelector } from 'react-redux'

const RecommendForMembershipForm = (props) => {
  let { showRecommend, handleCloseRecommend, hasRecommendations, data, handleSave, noteError, recommenderNote, setRecommenderNote } = props
  let user = useSelector((state) => state.login.user)

  return (
    <Dialog open={showRecommend} onClose={handleCloseRecommend} fullWidth>
      <DialogTitle>Recommend Student for Membership</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: '10px' }}>
          Use this note to recommend a student for a membership. This note will
          be visible to both parents and instructors.
        </Typography>
        {hasRecommendations(data) ? (
          <Alert severity='warning' sx={{ marginBottom: '10px' }}>
            This student has already been recommended for membership. Creating a
            new recommendation will overwrite the previous recommendation.
          </Alert>
        ) : null}{' '}
        <TextField
            error={noteError}
            helperText={noteError ? 'Note cannot be blank' : null}
          fullWidth
          multiline
          label='Instructor Note'
          onChange={(e) => setRecommenderNote(e.target.value)}
        />
        <Typography sx={{ marginTop: '10px' }}>Note Preview:</Typography>
        <Grid
          container
          sx={{
            backgroundColor: '#eef6fb',
            borderRadius: '5px',
            padding: '5px 5px',
            textAlign: 'left'
          }}
        >
          <Grid item xs={12} sm={8} md={10} sx={{ display: 'flex' }}>
            <Typography variant='h5' sx={{ flexGrow: 1, textAlign: 'left' }}>
              Membership Recommendation
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Typography sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
              {dateHandler.toUSDate(new Date().toISOString())}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography fontWeight={'bold'}>Recommended by</Typography>
          </Grid>
          <Grid item xs={6} sm={8}>
            <Typography>
              {user.info.name.fName} {user.info.name.lName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography fontWeight={'bold'}>Note</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{recommenderNote}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave(data, recommenderNote)}>
          Save Recommendation
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RecommendForMembershipForm
