import React, { useState, useEffect } from 'react'
import { CompositeDecorator, EditorState, Modifier } from 'draft-js'
import { convertFromHTML, Editor, RichUtils, ContentState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@mui/material'
import { InsertLink, ExpandMore } from '@mui/icons-material/'

const TemplateEditor = (props) => {
  const [showHyperlink, setShowHyperlink] = useState(false)
  const [hyperLink, setHyperLink] = useState('')
  const [linkText, setLinkText] = useState('')

  let editorState = props.editorState
  let setEditorState = props.setEditorState

  const createLinkDecorator = () =>
    new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link
      }
    ])

  //convert html into editor state
  useEffect(() => {
    async function handleAsync() {
      let blocksFromHTML = convertFromHTML(props.data.content)
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          ),
          createLinkDecorator()
        )
      )
    }
    handleAsync()
  }, [props.data])

  //START handling hyperlink creation
  const Link = (props) => {
    let { url, linkText } = props.contentState
      .getEntity(props.entityKey)
      .getData()

    return (
      <a
        style={{ color: 'blue', fontStyle: 'italic' }}
        href={url}
        target='_blank'
        rel='noreferrer'
      >
        {linkText || props.children}
      </a>
    )
  }

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      )
    }, callback)
  }

  const generateLink = (hyperLink, linkDisplayText) => {
    const decorator = createLinkDecorator()
    let link = hyperLink

    if (!hyperLink.includes('http://')) {
      if (!hyperLink.includes('https://')) {
        link = `http://${hyperLink}`
      }
    }

    const currentContent = editorState.getCurrentContent()

    currentContent.createEntity('LINK', 'MUTABLE', {
      url: link,
      target: '_blank'
    })

    const entityKey = currentContent.getLastCreatedEntityKey()

    const selection = editorState.getSelection()

    const textWithEntity = Modifier.replaceText(
      currentContent,
      selection,
      linkDisplayText,
      editorState.getCurrentInlineStyle(),
      entityKey
    )

    const newState = EditorState.createWithContent(textWithEntity, decorator)
    setEditorState(newState)
  }

  const handleOpen = () => {
    const selectionState = editorState.getSelection()
    const anchorKey = selectionState.getAnchorKey()
    const currentContent = editorState.getCurrentContent()
    const currentContentBlock = currentContent.getBlockForKey(anchorKey)
    const start = selectionState.getStartOffset()
    const end = selectionState.getEndOffset()
    const selectedText = currentContentBlock.getText().slice(start, end)

    if (selectedText) {
      setLinkText(selectedText)
    }
    setShowHyperlink(true)
  }

  const handleClose = () => {
    setShowHyperlink(false)
  }
  const handleLinkSubmit = () => {
    generateLink(hyperLink, linkText)
    setHyperLink('')
    setLinkText('')
    handleClose()
  }
  //END handling hyperlink creation

  //START toggle inline styles and toggle block types
  const toggleInlineStyle = (inlineStyle) => {
    setEditorState((prev) => RichUtils.toggleInlineStyle(prev, inlineStyle))
  }

  const toggleBlockType = (blockType) => {
    setEditorState((prev) => RichUtils.toggleBlockType(prev, blockType))
  }

  const StyleButton = (props) => {
    //render buttons for block type editing and inline style editing
    return (
      <span
        style={{
          color: props.active ? '#5890ff' : '#999',
          cursor: 'pointer',
          marginRight: ' 16px',
          padding: '2px 0',
          display: ' inline-block'
        }}
        onMouseDown={(e) => {
          e.preventDefault()
          if (props.toggle == 'block') {
            toggleBlockType(props.style)
          } else {
            toggleInlineStyle(props.style)
          }
        }}
      >
        {props.label}
      </span>
    )
  }

  const InlineStyleControls = (props) => {
    var INLINE_STYLES = [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
      // { label: 'Monospace', style: 'CODE' }
    ]
    var currentStyle = props.editorState.getCurrentInlineStyle()
    return (
      <div style={{ marginBottom: '5px', display: 'inline' }}>
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            toggle='inline'
            style={type.style}
          />
        ))}
      </div>
    )
  }

  const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' }
  ]

  const BlockStyleControls = (props) => {
    const selection = editorState.getSelection()
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()

    return (
      <div style={{ marginBottom: '5px' }}>
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            toggle='block'
            style={type.style}
          />
        ))}
      </div>
    )
  }

  //END toggle inline styles and toggle block types

  let AVAILABLE_VARIABLES = []
  if (
    props.data.type == 'Post Course Notification' ||
    props.data.type == 'Pre Course Notification' ||
    props.data.type == 'custom'
  ) {
    AVAILABLE_VARIABLES = [
      {
        variable: '${courseName}',
        name: 'Course Name',
        example: 'Vex Robotics'
      },
      { variable: '${time}', name: 'Course Time', example: '10AM - 11AM' },
      {
        variable: '${startDate}',
        name: 'Course Start Date',
        example: '02-02-2023'
      },
      {
        variable: '${endDate}',
        name: 'Course End Date',
        example: '02-06-2023'
      },
      { variable: '${minAge}', name: 'Course Min Age', example: '4' },
      { variable: '${maxAge}', name: 'Course Max Age', example: '6' },
      {
        variable: '${location}',
        name: 'Course Location Name',
        example: 'Palos Verdes'
      },
      {
        variable: '${address.lineOne}',
        name: 'Address Line One',
        example: '123 Street'
      },
      {
        variable: '${address.lineTwo}',
        name: 'Address Line Two',
        example: 'Unit 4'
      },
      {
        variable: '${address.city}',
        name: 'City',
        example: 'Rolling Hills Estates'
      }
    ]
  } else if (props.data.type == 'Camp Sign Up Confirmation') {
    AVAILABLE_VARIABLES = [
      {
        variable: '${emailStr}',
        name: 'Camp Details',
        example:
          'Pickles Kirkman:Vex Robotics, 2023-06-05 to 2023-06-09, from 12:00-1:01 at the Rolling Robots - PV Office - camp site located at 123 fake street, , San Diego'
      },
      {
        variable: '${parent.name.fName}',
        name: 'Parent First Name',
        example: 'John'
      }
    ]
  } else if (props.data.type == 'Student Sign Up') {
    AVAILABLE_VARIABLES = [
      {
        variable: '${sName}',
        name: 'Student Name',
        example: 'Pickles Kirkman'
      },
      {
        variable: '${memName}',
        name: 'Membership Name',
        example: 'Regular In Person'
      },
      {
        variable: '${courseString}',
        name: 'Activity Names',
        example: 'M - Python'
      }
    ]
  } else if (props.data.type == 'Membership Change') {
    AVAILABLE_VARIABLES = [
      {
        variable: '${managerEmail}',
        name: 'Manager Email',
        example: 'admin@rollingrobots.com'
      },
      {
        variable: '${student.fName}',
        name: 'Student First Name',
        example: 'Pickles'
      },
      {
        variable: '${membershipName}',
        name: 'Membership Name',
        example: 'Regular In person'
      }
    ]
  }

  return (
    <>
      <Dialog open={showHyperlink} onClose={handleClose}>
        <DialogTitle>Hyperlink</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
            label='Display Text'
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            fullWidth
            value={hyperLink}
            onChange={(e) => setHyperLink(e.target.value)}
            label='Link Address'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleLinkSubmit()}>Submit</Button>
        </DialogActions>
      </Dialog>
      <div style={{ width: '100%', border: '1px solid black' }}>
        <div>
          <BlockStyleControls editorState={editorState} />
          <InlineStyleControls editorState={editorState} />
          <button onClick={() => handleOpen()} style={{ display: 'inline' }}>
            <InsertLink />
          </button>
        </div>
        <Accordion disableGutters>
          <AccordionSummary expandIcon={<ExpandMore />} id='variable-accordion'>
            Available Variables
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto'
              }}
            >
              <Typography align='left' sx={{ fontWeight: 'bold' }}>
                Name
              </Typography>
              <Typography align='left' sx={{ fontWeight: 'bold' }}>
                Variable
              </Typography>
              <Typography align='left' sx={{ fontWeight: 'bold' }}>
                Example
              </Typography>
              {AVAILABLE_VARIABLES.map((option) => {
                return (
                  <>
                    <Typography align='left'>{option.name}</Typography>
                    <Typography align='left'>{option.variable}</Typography>
                    <Typography align='left'>{option.example}</Typography>
                  </>
                )
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
        <div
          style={{
            cursor: 'text',
            marginTop: '10px',
            minHeight: '40vh',
            padding: '10px 10px'
          }}
        >
          <Editor
            editorState={editorState}
            onChange={setEditorState}
          />
        </div>
      </div>
    </>
  )
}

export default TemplateEditor
